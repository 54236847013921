/* eslint-disable max-lines */
const fields = {
  subTitle: {
    value: 'Fortune 500\r\ncompanies',
  },
  hireUrl: {
    value: {
      href: '',
    },
  },
  number: {
    value: '106',
  },
  findText: {
    value: 'Find your next job',
  },
  hireText: {
    value: 'Hire a professional',
  },
  title: {
    value: 'Experis works with',
  },
  text: {
    value:
      'Lorem ipsum dolor sit amet, conset incididunt tempre minim veniam quis cons ectetur adipis cing elit. Labore et elitmor lao dolore sit eiusmod tem mi tal incididunt sit eius mod por mi talrea atl elitmor lao dolore.',
  },
  findUrl: {
    value: {
      href: '',
    },
  },
};

export default fields;
