import colorVariables from '../styles/scss_variables/colorVariables.scss';

export const Colors = {
  ORANGE: colorVariables.color_orange,
  WHITE: colorVariables.color_white,
  BLUE: colorVariables.color_blue,
  GREY: colorVariables.white_faded,
  BLACK: colorVariables.black,
  DARK_BLUE: '',
  DARK_GREEN: '',
  GREEN: colorVariables.dark_green,
  GREY: '',
  LIGHT_BLUE: '',
  LIGHT_GREEN: '',
  RED: colorVariables.color_red,
  LIGHT: '',
  DARK: '',
};

export const authstates = {
  AUTHENTICATED: 'AUTHENTICATED',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  PROGRESS: 'PROGRESS',
};

export const SORTORDER = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};

export const buildProfile = {
  EditPersonalInfo: 0,
  EditExperience: 1,
  EditExpertiseAndSkills: 2,
  EditEducation: 3,
  EditCertifications: 4,
};

export const DATE_FORMATS = {
  LOCALE: 'LL',
  DEFAULT_US: 'MMMM DD, YYYY',
  TIME_FULL_HR_MIN: 'hh:mma',
  TIME_HR_MIN: 'h:mm a ',
  LOCALE_SMALL: 'll',
  ROBOTS_FORMAT: 'YYYY-MM-DD',
  ROBOTS_FORMAT_REV: 'DD/MM/YYYY',
  DEFAULT_US_SMALL: 'MMM DD, YYYY',
};

export const DEFAULT_APPLICATION_COLUMNS = [
  'dateAdded',
  'position',
  'companyName',
  'employmentType',
  'status',
  'path',
];

export const countryCodesList =
  'de,no,se,dk,fi,is,ee,lv,lt,be,es,nl,lu,at,ie,ge,gr,va,fr,si,ch,li,pt,gb,cy,hu,mt,pl,sk,bg,ro,cr';

export const fileTypesAllowed = ['HTML', 'PDF', 'DOC', 'DOCX'];

export const buildProfileItems = {
  EditPersonalInfo: 'PersonalInfo',
  EditBasicInfo: 'PersonalInfo',
  EditExperience: 'Experience',
  EditEducation: 'Education',
  UploadResume: 'AttachmentDetails',
  EditExpertiseAndSkills: 'PersonalInfo',
  EditGamingSkills: 'PersonalInfo',
  EditCompetency: 'PersonalInfo',
  EditCoverLetters: 'AttachmentDetails',
  EditBasicInfo: 'PersonalInfo',
  EditCertifications: 'PersonalInfo',
};

export const dashboardItems = {
  PersonalInfo: 'PersonalDetails',
  Experience: 'ExperienceDetails',
  Education: 'EducationDetails',
  Resume: 'PersonalDetails',
  ExpertiseAndSkills: 'PersonalDetails',
  Gaming: 'PersonalDetails',
  CoverLetters: 'PersonalDetails',
  MyDocuments: 'AttachmentDetails',
  Certifications: 'PersonalDetails',
};

export const jobApply = {
  PersonalInfo: 'PersonalDetails',
  Experience: 'ExperienceDetails',
  Education: 'EducationDetails',
  UploadResume: 'AttachmentDetails',
  Consent: 'PersonalDetails',
};

export const TYPES = {
  ROMA: 'ROMA',
};

export const searchFilterLayout = {
  radius: { xl: '6', lg: '6', md: '6', sm: '12' },
  industries: { xl: '6', lg: '6', md: '6', sm: '12' },
  cities: { xl: '6', lg: '6', md: '6', sm: '12' },
  salaryRange: { xl: '6', lg: '6', md: '6', sm: '12' },
  jobTypes: { xl: '3', lg: '3', md: '6', sm: '12' },
  specialization: { xl: '3', lg: '3', md: '6', sm: '12' },
  experienceLevel: { xl: '3', lg: '3', md: '6', sm: '12' },
  education: { xl: '3', lg: '3', md: '6', sm: '12' },
  employmentType: { xl: '3', lg: '3', md: '6', sm: '12' },
  language: { xl: '3', lg: '3', md: '6', sm: '12' },
  workingHours: { xl: '6', lg: '6', md: '6', sm: '12' },
  managementLevel: { xl: '6', lg: '6', md: '6', sm: '12' },
  jobOfferType: { xl: '6', lg: '6', md: '6', sm: '12' },
  contractType: { xl: '6', lg: '6', md: '6', sm: '12' },
  remotJobs: { xl: '6', lg: '6', md: '6', sm: '12' },
};

export const searchFilterLayoutGlobal = {
  sortOrder: { xl: '12', lg: '12', md: '12', sm: '12' },
  radius: { xl: '12', lg: '12', md: '12', sm: '12' },
  industries: { xl: '12', lg: '12', md: '12', sm: '12' },
  cities: { xl: '12', lg: '12', md: '12', sm: '12' },
  salaryRange: { xl: '12', lg: '12', md: '12', sm: '12' },
  salaryRangeLevels: { xl: '12', lg: '12', md: '12', sm: '12' },
  jobTypes: { xl: '12', lg: '12', md: '12', sm: '12' },
  specialization: { xl: '12', lg: '12', md: '12', sm: '12' },
  experienceLevel: { xl: '12', lg: '12', md: '12', sm: '12' },
  education: { xl: '12', lg: '12', md: '12', sm: '12' },
  employmentType: { xl: '12', lg: '12', md: '12', sm: '12' },
  language: { xl: '12', lg: '12', md: '12', sm: '12' },
  workingHours: { xl: '12', lg: '12', md: '12', sm: '12' },
  managementLevel: { xl: '12', lg: '12', md: '12', sm: '12' },
  jobOfferType: { xl: '12', lg: '12', md: '12', sm: '12' },
  contractType: { xl: '12', lg: '12', md: '12', sm: '12' },
  scheduleType: { xl: '12', lg: '12', md: '12', sm: '12' },
  showDisabilityIcon: { xl: '12', lg: '12', md: '12', sm: '12' },
  remoteJobs: { xl: '12', lg: '12', md: '12', sm: '12' },
  sectors: { xl: '12', lg: '12', md: '12', sm: '12' },
  publicationDates: { xl: '12', lg: '12', md: '12', sm: '12' },
};
