import { JOBAPPLY_FORM_DATA } from '../actions/actionTypes';

const initialState = {
  data: {},
  triggered: [],
  validComponents: [],
};

/**
 * @description - Reducer to store the data from job apply form.
 * @param {*} state - Initial state.
 * @param {*} action - Dispatched action.
 * @returns {undefined} - Save data in store.
 */
const jobApplyFormDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOBAPPLY_FORM_DATA.SAVE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case JOBAPPLY_FORM_DATA.SAVE_TRIGGERED:
      return {
        ...state,
        triggered: [...state.triggered, action.payload],
      };
    case JOBAPPLY_FORM_DATA.SAVE_VALID_FORM_NAME:
      return {
        ...state,
        validComponents: [...new Set([...state.validComponents, action.payload])],
      };
    case JOBAPPLY_FORM_DATA.CLEAR_TRIGGERED:
      return {
        ...state,
        triggered: [],
      };
    case JOBAPPLY_FORM_DATA.CLEAR:
      return initialState;
    default:
      return state;
  }
};
export default jobApplyFormDataReducer;
