/* eslint-disable require-jsdoc */
import React from 'react';
import PropTypes from 'prop-types';
import CardIcon from '../Cards/CardIcon';
import { isDataExists, checkDataExists } from '../../../utils/helperUtils';
import TextLink from '../../../components/core/globals/link/TextLink';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import './IndustryCardBlock.scss';

/**
 *@description -  IndustryCardBlock standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */

class IndustryCardBlock extends React.Component {
  constructor() {
    super();
    this.state = {
      mobile: false,
    };
  }

  componentDidMount() {
    const { mobile } = this.state;
    const x =
      typeof window !== 'undefined' && window.matchMedia('(max-width: 768px)');
    if (x?.matches) {
      if (mobile === false) {
        this.setState({ mobile: true });
      }
    }
  }

  render() {
    const { fields } = this.props;
    const { mobile } = this.state;
    const { items, componentBackgroundColor } = fields;
    let color_codes = [];
    const cardBackground =
      componentBackgroundColor &&
      componentBackgroundColor?.map((item) => {
        const { colorCode } = item?.fields;
        let color_code = checkDataExists(colorCode)
          ? checkDataExists(colorCode)
          : '';
        color_codes = color_code.split(',');
        if (color_codes.length > 1) {
          if (mobile) {
            color_code = `linear-gradient(
            to bottom,
            ${color_codes[0]},${color_codes[1]}
          )`;
          } else {
            color_code = `linear-gradient(267deg, ${color_codes[0]}, ${color_codes[1]})`;
          }
        } else {
          color_code = ` ${color_code}`;
        }
        return color_code;
      });

    const isItemExists = isDataExists(items);
    let template = null;
    if (items?.length > 2) {
      template = (
        <section
          className="card-grid industry"
          style={{ background: `${cardBackground}` }}
        >
          <div className="container">
            <div className="card-grid__header">
              <h2
                className="title"
                style={color_codes.length === 1 ? { color: '#282A32' } : {}}
              >
                <Text field={fields?.title} />
              </h2>
            </div>
            <div className="cards__wrap">
              {isItemExists ? (
                <div className="row card__box">
                  {items?.map((item) => (
                    <div className="col-lg-3 col-6">
                      <CardIcon
                        img={item?.fields?.icon?.value?.src}
                        altText={item?.fields?.icon?.value?.alt}
                        title={item?.fields?.title?.value}
                        ctaUrl={item?.fields?.linkUrl}
                      />
                    </div>
                  ))}{' '}
                </div>
              ) : null}
            </div>
            {fields.ctaText && (
              <div className="card-grid__cta">
                <TextLink
                  cssClass="reversed boder-btm"
                  ctaText={fields.ctaText}
                  ctaUrl={fields.ctaUrl}
                />
              </div>
            )}
          </div>
        </section>
      );
    }
    return template;
  }
}
IndustryCardBlock.defaultProps = {
  fields: {
    title: {
      value: '',
    },
    ctaText: {
      value: '',
    },
    ctaUrl: {
      value: {
        href: '#',
      },
    },
    cssClass: {
      value: '',
    },
    componentBackgroundColor: [],
    items: [],
  },
};
IndustryCardBlock.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    ctaText: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string.isRequired,
      }),
    }),
    cssClass: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    componentBackgroundColor: PropTypes.arrayOf(PropTypes.shape({})),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
export default IndustryCardBlock;
