/**
 * @description - Get filter list.
 * @param {object} options - Search object.
 * @param {string} noneLabel - None string.
 * @returns {Array} - Filtered Array.
 */
export const getFilterArray = (options, noneLabel) => {
  const items = options ? options[0]?.values || [] : [];
  const filterArray = [];
  items.map((itemVal, index) => {
    const val = {
      title: itemVal?.item?.field?.name,
      value: itemVal?.item?.field?.name,
      id: itemVal?.value,
      pos: index + 1,
    };
    if (val?.value && val?.id) {
      filterArray.push(val);
    }
    return val;
  });
  if (filterArray && filterArray.length) {
    filterArray.unshift({
      title: noneLabel,
      value: noneLabel,
      id: null,
      pos: 0,
    });
  }
  return filterArray;
};
