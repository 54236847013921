/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
import React, { Fragment } from 'react';
import SingleJob from './SingleJobBlockDetails';
import ApplyJob from '../../Search/SearchJob/ApplyJob';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './detailsblock.scss';

const JobDetailsInfo = (props) => {
  const {
    t,
    sideBarDetails = [],
    fieldsData: { items, applyJobUrl, AlreadyAppliedDashboardJobMessage } = {},
    jobDetailsObj,
  } = props;
  const company = items?.companyName || '';
  const logo = items?.customerLogoUrl || '';

  return (
    <section className="details-block job">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 offset-xl-1">
            <div className="row">
              <div className="col-lg-8">
                <SingleJob {...props} />
                {!jobDetailsObj?.isExpired && (
                  <div className="cta-wrapper">
                    <ApplyJob
                      reversedClass={false}
                      cssClass="job-details-cta"
                      apply={t('apply-now')}
                      expapply={t('exp-apply-now')}
                      jobDetails={items}
                      applyJobUrl={{ href: applyJobUrl?.applyJobUrl }}
                      dashboardURL={
                        AlreadyAppliedDashboardJobMessage?.appliedJobDashboardLink
                      }
                      dashboardText={
                        AlreadyAppliedDashboardJobMessage?.appliedJobDashboardText
                      }
                    />
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <div className="company">
                  {/* {company && <p className="company-name">{company}</p>} */}
                  {logo && (
                    <img
                      src={logo}
                      alt={`${company ? company : 'company logo'}`}
                      className="company-logo"
                    ></img>
                  )}
                </div>
                {Array.isArray(sideBarDetails) &&
                  sideBarDetails.map((component, index) => {
                    if (component.props && component.props.type === 'text/sitecore')
                      return component;
                    return <Fragment key={index}>{component}</Fragment>;
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withSitecoreContext()(withTranslation()(JobDetailsInfo));
