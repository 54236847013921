/* eslint-disable */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import useStateRef from 'react-usestateref';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  saveFormActions,
  saveProfileInfoForm,
  saveProfileInfoFormName,
  saveProfileInfoFormNameIfFormValid,
  saveFormSubmitting,
  saveFormData,
} from '../../../../../redux/actions/actions';
import {
  replaceTextwithJSX,
  traverseAndDataExist,
  isObjNotEmpty,
} from '../../../../../utils/helperUtils';
import DynamicForm from '../../../../core/Forms/DynamicForm';
import Button from '../../../../core/globals/buttons/Button';
import { formObjIteration } from '../../../Utils/helper';
import { saveCandidateProfile } from '../../../../../services/apiServices/candidateService';
import { buildProfileItems } from '../../../../../utils/enums';
import { BuildProfileContext } from '../..';
import { dataFetcher } from '../../../../../dataFetcher';
import { API } from '../../../../../constants';

/**
 * @description - SingleForm component.
 * @param {Object} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const SingleForm = ({ inputRef, ...props }) => {
  const {
    profileInfo,
    fields,
    t,
    handleClick,
    accordionId,
    lastAccordionIndex,
    profileInfoFormData,
    formData,
    componentName,
    getEmptyFields,
    setLoader,
  } = props;

  const {
    subTitle,
    formText,
    items,
    subText,
    ctaUrl,
    ctaText,
    noValidationField,
    saveEndPoint,
    apiKeyMapper,
    isContentLoaderRequired,
    apiList,
  } = fields;

  const {
    register,
    handleSubmit,
    getValues,
    errors,
    setValue,
    control,
    formState,
    setError,
    clearErrors,
    trigger,
    unregister,
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
    shouldFocusError: true,
  });
  const formSubmitRef = useRef();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [triggered, setTriggered, triggeredRef] = useStateRef(false);
  const [fileUpload, setFileUpload, fileUploadRef] = useStateRef({});
  const [fieldList, setFieldList, fieldListRef] = useStateRef([]);
  const [isDelete, setUploadDelete] = useState(false);
  const [isUpload, setUploadStatus] = useState(false);
  const [isParse, setParse] = useState(false);
  const [formError, showFormError] = useState(false);
  const [saveClickStatus, setSaveClickStatus] = useState(false);
  const [candidateId, setCandidateId] = useState('');
  const apiEndPoints = apiList?.value?.split(',')?.filter((el) => el !== '') || [];
  const [apiCollection, setApiCollection] = useState({});
  const [apiCalledCount, setApiCalledCount] = useState(0);
  const [newFieldArr, setFieldArr] = useState([]);
  const apiMapper = apiKeyMapper?.value
    ? apiKeyMapper?.value
    : buildProfileItems[componentName];

  const { isValid, isDirty, touched, isValidating } = formState;
  const value = useContext(BuildProfileContext);
  const mandatorySection = value?.mandatorySection;

  useEffect(() => {
    if (
      isContentLoaderRequired?.value &&
      mandatorySection?.includes(componentName)
    ) {
      setLoader(true);
    }
    if (apiEndPoints && apiEndPoints?.length > 0) {
      triggerApiCollection();
    }
  }, []);

  const triggerApiCollection = () => {
    let count = 0;
    let collectionObj = {};
    apiEndPoints.map((api) => {
      dataFetcher(`${API.HOST}${getApi(api)}`).then(
        (response) => {
          collectionObj = response?.data && {
            ...collectionObj,
            [getApi(api)]: response?.data,
          };
          count++;
          setApiCollection(collectionObj);
          setApiCalledCount(count);
        },
        () => {
          collectionObj = { ...collectionObj, [getApi(api)]: [] };
          count++;
          setApiCollection(collectionObj);
          setApiCalledCount(count);
        }
      );
    });
  };

  useEffect(() => {
    if (apiCalledCount !== 0 && apiCalledCount === apiEndPoints?.length) {
      setLoader(false);
    }
    getEmptyFields({ [componentName]: { isValid, touched, isParse } });
  }, [isValid, touched, apiCalledCount, isParse]);

  /**
     * @description - Get APi
     * @param {string} apiEndPoint - endpoint
     * @returns {string} - Formatted data.
  
     */
  const getApi = (apiEndPoint, APIQuerryParams = {}) => {
    let api = apiEndPoint;
    if (
      Object.keys(APIQuerryParams).length > 0 &&
      apiEndPoint &&
      apiEndPoint !== null
    ) {
      Object.keys(APIQuerryParams).forEach((param) => {
        api = api.replace(`{${param}}`, `${param}=${APIQuerryParams[param]}`);
      });
    }
    return api;
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        saveFormActions({
          [componentName]: {
            isDirty,
            isValid,
            touched,
            isDelete,
            isUpload,
          },
        })
      );
    }, 20);
  }, [isValid, isValidating, isDirty, touched, isDelete, isUpload]);

  useEffect(() => {
    if (profileInfo?.Id) {
      formReset();
      setTimeout(() => {
        setCandidateId(profileInfo?.Id);
        setData({
          data: profileInfo?.[apiMapper]?.data?.[0] || {},
          attachment: profileInfo?.AttachmentDetails,
        });
        setFieldArr(items);
      }, 100);
      if (profileInfoFormData?.parse) {
        setParse(true);
        showFormError(true);
        handleClick(0, false);
      }
    }
  }, [profileInfo]);

  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    onSubmitFunc(formattedData);
  };

  const handleNext = () => {
    showFormError(true);
    externalClick();
  };

  const formReset = () => {
    setData({ data: {} });
    setFieldArr([]);
    reset(
      {},
      {
        touched: false,
        isDirty: false,
        isValid: true,
      }
    );
  };

  const saveFn = (resData) => {
    toast.success(t('details-saved-successfully'), {
      position: 'top-center',
    });
    dispatch(saveFormSubmitting(false));
    setSaveClickStatus(false);
    setUploadDelete(false);
    setUploadStatus(false);
    setFileUpload({});
    setFieldList([]);
    dispatch(
      saveFormData({
        ...formData.data,
        ...{
          [apiMapper]: {
            data: [
              {
                ...formData?.data?.[apiMapper]?.data[0],
                ...resData?.[componentName],
              },
            ],
          },
        },
      })
    );
    dispatch(
      saveProfileInfoForm({
        ...profileInfoFormData.data,
        [componentName]: undefined,
        DeletedFiles: undefined,
      })
    );
    formReset();
    setTimeout(() => {
      setFieldArr(items);
      setData({
        data: resData?.[componentName] || {},
        attachment: resData?.['attachments'] || [],
      });
    }, 100);
    if (lastAccordionIndex !== accordionId) handleClick(parseInt(accordionId) + 1);
  };

  const showError = (msg) => {
    toast.error(t(msg), {
      position: 'top-center',
    });
    setSaveClickStatus(false);
    dispatch(saveFormSubmitting(false));
  };

  /**
   * @description - onsubmit hander.
   * @returns {undefined}
   */
  const onSubmit = (inputData) => {
    const postData = {
      [componentName]: Object.keys(inputData).length > 0 ? inputData : undefined,
      files: isObjNotEmpty(profileInfoFormData?.data?.files)
        ? { ...profileInfoFormData?.data?.files, ...fileUploadRef.current }
        : fileUploadRef.current,
      DeletedFiles:
        profileInfoFormData?.data?.DeletedFiles?.length > 0
          ? [
              ...new Set([
                ...profileInfoFormData?.data?.DeletedFiles,
                ...fieldListRef.current,
              ]),
            ]
          : fieldListRef.current.length > 0
          ? fieldListRef.current
          : undefined,
    };
    if (triggeredRef.current) {
      setTriggered(false);
      //Copy of profile Info Reducer with updated form values
      dispatch(
        saveFormData({
          ...formData.data,
          ...{
            [apiMapper]: {
              data: [{ ...formData?.data?.[apiMapper]?.data?.[0], ...inputData }],
            },
          },
        })
      );
      dispatch(
        saveProfileInfoForm({
          ...profileInfoFormData.data,
          ...postData,
        })
      );
      dispatch(saveProfileInfoFormNameIfFormValid(componentName));
    } else {
      if (
        (isObjNotEmpty(touched) && isValid) ||
        profileInfoFormData?.parse ||
        isDelete
      ) {
        dispatch(saveFormSubmitting(true));
        setSaveClickStatus(true);
        saveCandidateProfile(
          saveEndPoint?.value,
          postData,
          fileUploadRef.current,
          saveFn,
          showError
        );
      } else {
        toast.error(t('no-changes'), {
          position: 'top-center',
        });
      }
    }
  };

  const externalClick = () => {
    let event;
    if (typeof Event === 'function') {
      event = new Event('submit', { cancelable: true });
    } else {
      event = document.createEvent('Event');
      event.initEvent('submit', false, { cancelable: true });
    }
    formSubmitRef?.current?.dispatchEvent(event);
  };

  /**
   * @description - useImperativeHandle function.
   * @returns {undefined}
   */
  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      setTriggered(true);
      setTimeout(() => dispatch(saveProfileInfoFormName(componentName), 50));
      externalClick();
    },
    invalidTrigger: () => {
      showFormError(true);
      handleClick(parseInt(accordionId));
    },
  }));

  const handleUpload = (fileData, name) => {
    const fileList = fileUploadRef.current;
    const obj = { [name]: fileData };
    if (fileUploadRef.current.hasOwnProperty(name)) {
      delete fileList[name];
    }
    setFileUpload({ ...fileList, ...obj });
    setUploadStatus(true);
  };

  const handleDelete = (id, name, index) => {
    if (fileUploadRef.current.hasOwnProperty(name)) {
      const temp = fileUploadRef.current[name];
      delete temp[index];
      const newtemp = temp?.filter((item) => item);
      setFileUpload({ ...fileUploadRef.current, [name]: newtemp });
    }
    if (id) {
      setFieldList([...fieldListRef.current, id]);
    }
    setUploadDelete(true);
  };

  const handleChange = () => {
    setUploadDelete(true);
    showFormError(true);
  };

  const contentLoading = (val) => {
    if (mandatorySection?.includes(componentName)) {
      setLoader(val);
    }
  };

  return (
    <div className="personalInfo">
      <div className="build-profile signinup">
        <div className="text">{subTitle?.value}</div>
        <section className="forms signinup">
          <div className="form-text">{formText?.value}</div>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(transform(onSubmit))}
            ref={formSubmitRef}
          >
            <div className="personal-info-form">
              <DynamicForm
                formData={newFieldArr}
                register={register}
                errors={formError || isObjNotEmpty(touched) ? errors : ''}
                getValues={getValues}
                values={data}
                setValue={setValue}
                setError={setError}
                handleChange={handleChange}
                clearErrors={clearErrors}
                control={control}
                validateOnLoad={true}
                handleUpload={handleUpload}
                handleDelete={handleDelete}
                trigger={trigger}
                formState={formState}
                APIQuerryParams={{ cid: candidateId }}
                apiCollection={apiCollection}
                apiList={apiList?.value}
                unregister={unregister}
                contentLoading={contentLoading}
                watch={watch}
              />
            </div>
          </form>
        </section>
        {subText && (
          <div className="subTextContainer">
            {replaceTextwithJSX(
              subText?.value,
              '{0}',
              <Link key="personalInfoSubText" to={ctaUrl?.value?.href}>
                {ctaText?.value}
              </Link>
            )}
          </div>
        )}
        <div className="accordionNextBtn">
          <Button
            text={t('save-section')}
            cssClass={`${isValid ? 'button-enabled' : 'button-disabled'}`}
            handleButtonClick={handleNext}
            isLoading={saveClickStatus}
            isDisabled={saveClickStatus}
          />
        </div>
      </div>
    </div>
  );
};

SingleForm.defaultProps = {
  fields: {},
  profileInfo: {},
  getEmptyFields: () => {},
  profileInfoFormData: {},
  formData: {},
  profileEmptyFieldsData: {},
  t: () => {},
};

SingleForm.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}).isRequired,
  profileInfoFormData: PropTypes.shape({}).isRequired,
  formData: PropTypes.shape({}),
  getEmptyFields: PropTypes.func.isRequired,
  profileEmptyFieldsData: PropTypes.shape({}).isRequired,
  t: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    profileInfoFormData: state.profileInfoFormReducer,
    formData: state.formDataReducer,
    profileEmptyFieldsData: state.profileEmptyFieldsReducer,
  };
};

const ConnectedComponent = connect(mapStateToProps)(SingleForm);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
