/* eslint-disable */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  Fragment,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from '../../../core/ErrorBoundary/Loader';
import ScreenerQuestionsForm from '../../../core/Forms/ScreenerQuestionsForm';
import { SCREENER_QUESTIONS } from '../../../../constants';
import { dataFetcher } from '../../../../dataFetcher';
import './screener_question.scss';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import SnackBar from '../../../core/ErrorBoundary/SnackBar';
import Error from '../../../core/ErrorBoundary/Error';
import PropTypes from 'prop-types';
import { formObjIteration } from '../../Utils/helper';
import { saveScreenerQuestions } from '../../../../redux/actions/actions';

// const mockData = [
//   {
//     questionID: 1,
//     questionType: 'Pre-Screen',
//     sortOrder: '1',
//     industry: null,
//     editType: 'FreeText',
//     question: 'Describe yourself(textArea)',
//     sectionHeading: 'About You',
//     listofAnswers: [],
//     expectedAnswer: '',
//     automaticRejection: null,
//     score: 0,
//   },
//   {
//     questionID: 2,
//     questionType: 'Pre-Screen',
//     sortOrder: '2',
//     industry: null,
//     editType: 'Radio Button',
//     question: 'Are you currently Employed?(Radio)',
//     sectionHeading: '',
//     listofAnswers: ['Yes', 'No', 'Temporary'],
//     expectedAnswer: '',
//     automaticRejection: 'Yes',
//     score: 0,
//   },
//   {
//     questionID: 3,
//     questionType: 'Pre-Screen',
//     sortOrder: '3',
//     industry: null,
//     editType: 'Multi-Select',
//     question: 'Which is your domain?(Multi Select)',
//     sectionHeading: 'Job',
//     listofAnswers: ['Front End', 'Back End', 'Full Stack', 'Tester'],
//     expectedAnswer: '',
//     automaticRejection: 'Yes',
//     score: 0,
//   },
//   {
//     questionID: 4,
//     questionType: 'Pre-Screen',
//     sortOrder: '4',
//     industry: null,
//     editType: 'Single-Select',
//     question: 'Which is your Current Role?(Single Select)',
//     sectionHeading: '',
//     listofAnswers: ['Developer', 'Lead', 'Architect', 'Manager'],
//     expectedAnswer: '',
//     automaticRejection: 'Yes',
//     score: 0,
//   },
// ];

/**
 * @description - Screener Questions Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const ScreenerQuestions = ({ inputRef, ...props }) => {
  const {
    register,
    handleSubmit,
    getValues,
    errors,
    setValue,
    control,
    trigger,
  } = useForm({
    mode: 'onBlur',
    revalidate: 'onBlur',
  });
  const dispatch = useDispatch();
  const formSubmitRef = useRef();
  const { fields, t, onSubmitHandler } = props;
  // fields = mockData;
  const { title, subTitle, text, Validations = [] } = fields;
  const { isActive, message, isSuccess, openSnackBar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  const [errMsg, setMessage] = useState('');
  const history = useHistory();
  const queryStrings = new URLSearchParams(useLocation().search);
  const jobItemId = queryStrings.get('id');

  const showSnackbarHandler = (openSnackBar, msg, isSuccessMsg = false) => {
    openSnackBar(msg, isSuccessMsg);
  };

  useEffect(() => {
    setLoading(false);
    setMessage('');
  }, [history.location]);

  useEffect(() => {
    if (jobItemId) {
      // setFormData(mockData);
      setFormLoading(true);
      dataFetcher(
        `${SCREENER_QUESTIONS.GET_SCREENER_QUESTIONS}?jobItemID=${jobItemId}`
      ).then(
        (response) => {
          setFormLoading(false);
          // setFormData(formMockData);
          setFormData(response?.data?.data);
        },
        () => {
          setFormLoading(false);
          setMessage('error-message');
          showSnackbarHandler(openSnackBar, t('error-message'), false);
        }
      );
    }
  }, []);

  const getFormattedData = (formData) => {
    const tempData = { ...formData };
    Object.keys(tempData).forEach((key) => {
      if (Array.isArray(tempData?.[key])) {
        tempData?.[key].map((data) => {
          if (data?.answer && Array.isArray(data?.answer))
            data.answer = data?.answer?.join(',');
        });
      }
    });
    return tempData;
  };

  /**
   * @description - onsubmit hander.
   * @returns {undefined}
   */
  const onSubmit = (formData) => {
    const newFormData = formObjIteration(formData);
    const formattedData = getFormattedData(newFormData);
    dispatch(
      saveScreenerQuestions({
        ...formattedData,
      })
    );
    onSubmitHandler();
  };

  /**
   * @description - useImperativeHandle function.
   * @returns {undefined}
   */
  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      if (!formLoading) {
        let event;
        if (typeof Event === 'function') {
          event = new Event('submit', { cancelable: true });
        } else {
          event = document.createEvent('Event');
          event.initEvent('submit', false, { cancelable: true });
        }
        formSubmitRef.current.dispatchEvent(event);
      }
    },
  }));

  return (
    <div className="screener-question">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">{title?.value}</h1>
            <div className="text">{subTitle?.value}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3 ">
            <div className="screener-question-form">
              {formLoading ? (
                <div style={{ paddingBottom: '60px' }}>
                  <Loader
                    loaderMsg={t('fetching-screener-question')}
                    loaderWithMsg
                    customLoaderStyle={{
                      width: '4rem',
                      height: '4rem',
                      color: '#C25700',
                    }}
                    customTextStyle={{ color: '#C25700' }}
                  />
                </div>
              ) : errMsg ? (
                <Error errorMsg={t('error-message')} t={t} />
              ) : (
                <Fragment>
                  {formData?.length > 0 && (
                    <div className="form-text">{text?.value}</div>
                  )}
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    ref={formSubmitRef}
                    className="form"
                  >
                    <ScreenerQuestionsForm
                      register={register}
                      errors={errors}
                      getValues={getValues}
                      Validations={Validations}
                      formData={formData}
                      setValue={setValue}
                      trigger={trigger}
                      control={control}
                      formName={'questionsAndAnswers'}
                    />
                  </form>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <SnackBar isActive={isActive} message={message} isSuccess={isSuccess} />
    </div>
  );
};

ScreenerQuestions.propTypes = {
  fields: PropTypes.shape({
    text: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  candidateJobDetails: PropTypes.object,
  t: PropTypes.func.isRequired,
};

ScreenerQuestions.defaultProps = {
  fields: {},
  candidateJobDetails: {},
  t: (val) => val,
};

const ConnectedComponent = ScreenerQuestions;
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
