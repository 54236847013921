import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { checkArray } from '../../../utils/helperUtils';
import SiteFooterNavList from './SiteFooterNavList';

/**
 * @description - Site Footer component.
 * @param {object} menuItems - Input Props.
 * @returns {undefined}
 */
const SiteFooterNavManpower = ({ menuItems }) => {
  return (
    <Fragment>
      <div className="row footer-nav">
        {checkArray(menuItems).map((colmns, idx) => {
          const { fields } = colmns;
          const itemVals = (fields && fields.items) || [];

          return (
            <div className="col-lg-3 col-sm-12" key={`${colmns.name}_${idx}`}>
              <SiteFooterNavList items={itemVals} section="section 1" />
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

SiteFooterNavManpower.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
};

SiteFooterNavManpower.defaultProps = {
  menuItems: [],
};

export default SiteFooterNavManpower;
