import React from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import SearchEverythingResults from '../SearchEverything/SearchEverythingResults';
import SearchJobResultsGlobal from './SearchJobResultsGlobal';

import './search_global.scss';

/**
 * @description to list search results
 * @param {object} props - component properties
 * @returns {node} -html
 */
const SearchResultGlobal = ({ fields, t, JobFilters }) => {
  const queryStrings = new URLSearchParams(useLocation().search);
  return queryStrings.get('mode') === 'site' ? (
    <SearchEverythingResults fields={fields} t={t} />
  ) : (
    <SearchJobResultsGlobal fields={fields} t={t} JobFilters={JobFilters} />
  );
};

SearchResultGlobal.defaultProps = {
  fields: {},
  t: () => {},
  JobFilters: [],
};

SearchResultGlobal.propTypes = {
  fields: PropTypes.shape({}),
  t: PropTypes.func,
  JobFilters: PropTypes.arrayOf(PropTypes.shape()),
};

export default withTranslation()(
  withPlaceholder([{ placeholder: 'jss-left', prop: 'JobFilters' }])(
    withRouter(withSitecoreContext()(SearchResultGlobal))
  )
);
