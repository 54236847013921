/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { geolocated, geoPropTypes } from 'react-geolocated';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';

import Button from '../globals/buttons/Button';
import LocationsFinderItem from './LocationsFinderItem';
import Map from '../Map/Map';
import { checkArray } from '../../../utils/helperUtils';
import { checkLocationExists } from '../../../utils/locationUtils';
import { NearbyFinder } from '../Map/NearbyFinder';
import LocationSearch from '../../core/globals/forms/LocationSearch';
import { saveSelectedLocation } from '../../../redux/actions';

import './LocationsFinder.scss';

/**
 * @description location finder component
 */

/**
 *
 * @param {array} locations - locations list to display
 * @param {array} filteredLocations - all locations
 * @param {function} handlePinClick - to handle pin click
 * @param {function} translate - to tarnslate language
 * @param {function} handleLoadMore - to handle load more
 * @param {number} selectedLoc - selected location index
 * @returns {node} - html
 */
const LocationList = ({
  locations,
  filteredLocations,
  handlePinClick,
  translate,
  handleLoadMore,
  focusloadmore,
  focusIndex,
  selectedLoc,
}) => {
  return (
    <div className="location-finder-item-list">
      {checkArray(locations).map((item, index) => (
        <LocationsFinderItem
          title={item?.fields?.title}
          address={item?.fields?.address}
          phone={item?.fields?.phone}
          hours={item?.fields?.hours}
          ctaText={translate('view-details')}
          ctaUrl={item?.fields?.ViewDetailsUrl}
          key={index}
          focusloadmore={focusIndex === index ? focusloadmore : null}
          cssClass={index === selectedLoc ? 'active' : ''}
          click={() => handlePinClick(index, false)}
        />
      ))}
      {filteredLocations.length > 5 &&
        filteredLocations.length !== locations.length && (
          <div className="load-more-btn mb-4">
            <Button
              text={translate('load-more')}
              handleButtonClick={handleLoadMore}
            />
          </div>
        )}
    </div>
  );
};

LocationList.defaultProps = {
  locations: [],
  filteredLocations: [],
  handlePinClick: () => {},
  translate: () => {},
  handleLoadMore: () => {},
  selectedLoc: '',
};

LocationList.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.string.isRequired,
      lng: PropTypes.string.isRequired,
      isPrimary: PropTypes.bool.isRequired,
    })
  ),
  filteredLocations: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.string.isRequired,
      lng: PropTypes.string.isRequired,
      isPrimary: PropTypes.bool.isRequired,
    })
  ),
  handlePinClick: PropTypes.func,
  translate: PropTypes.func,
  handleLoadMore: PropTypes.func,
  selectedLoc: PropTypes.number,
};

/**
 *
 * @param {array} locations - locations to be drawn
 * @param {function} handlePinClick - to handle map pin click
 * @returns {node} - html
 */
const LocationMap = ({ locations, handlePinClick, mapInit }) => {
  return (
    <div className="location-finder-map">
      <div className="mapouter">
        {Array.isArray(locations) && locations.length > 0 && mapInit && (
          <Map
            markers={locations}
            locationFinder={false}
            onPinClick={handlePinClick}
            fitBound={true}
          />
        )}
      </div>
    </div>
  );
};

LocationMap.defaultProps = {
  locations: [],
  handlePinClick: () => {},
  mapInit: false,
};

LocationMap.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.string.isRequired,
      lng: PropTypes.string.isRequired,
      isPrimary: PropTypes.bool.isRequired,
    })
  ),
  handlePinClick: PropTypes.func,
  mapInit: PropTypes.bool,
};
/**
 * @description to enable scroll
 * @returns {undefined}
 */
const scroll = () => {
  const elmnt = document.getElementsByClassName('location-finder-item active');
  setTimeout(() => {
    elmnt[0].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }, 1000);
};

/**
 *
 * @param {object} props - component properties including office locations
 * @returns {Node} - html
 */
const LocationsFinder = (props) => {
  const itemsPerPage = 5;
  const { coords, sitecoreContext, t, mapInit } = props;
  const { locationState, dispatch } = props;
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [limitTo, setLimitTo] = useState(itemsPerPage);
  const [selectedLoc, setSelecteLoc] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showAllPins, setShowAllPins] = useState();
  const [mapLocations, setMapLocations] = useState([]);
  const [mapPinLimit, setMapPinLimit] = useState(itemsPerPage);
  const [focusIndex, setFocusIndex] = useState(null);
  const focusloadmore = useRef(null);
  useEffect(() => {
    setShowAllPins(true);
    getOfficeLocations(true);
  }, []);

  useEffect(() => {
    if (coords) {
      setShowAllPins(false);
      getOfficeLocations(false);
    }
  }, [coords]);

  useEffect(() => {
    if (checkLocationExists(locationState)) {
      setSelectedLocation(locationState);
      setShowAllPins(false);
      setLimitTo(itemsPerPage);
      setMapPinLimit(itemsPerPage);
      updateMap(locationState);
    }
  }, [locationState]);

  /**
   * @description to get locations displayed in google map
   * @param {Object} showAllMapPins -show all map pins
   * @returns {undefined}
   */
  const getOfficeLocations = (showAllMapPins) => {
    const showPin = coords !== null ? true : false;
    showPin ? setSelecteLoc(0) : setSelecteLoc(null);
    updateNearbyLocation(
      props,
      coords?.latitude,
      coords?.longitude,
      showPin,
      showAllMapPins
    );
  };

  /**
   * @description to find nearest location
   * @param {object} props - location details
   * @param {number} lat - from location latitude
   * @param {number} lng - from location longitude
   * @param {boolean} highlightNearest - whether to show nearest location highlighted
   * @param {Object} showAllMapPins -show all map pins
   * @returns {undefined}
   */
  const updateNearbyLocation = (
    props,
    lat,
    lng,
    highlightNearest,
    showAllMapPins
  ) => {
    NearbyFinder(props, lat, lng, highlightNearest).then((sortedNearbyLocations) => {
      updateLocations(sortedNearbyLocations, showAllMapPins);
    });
  };

  /**
   * @description - to update locations
   * @param {array} sortedNearbyLocations - locations array
   * @param {Object} showAllMapPins -show all map pins
   * @returns {undefined}
   */
  const updateLocations = (sortedNearbyLocations, showAllMapPins) => {
    if (Array.isArray(sortedNearbyLocations)) {
      setLocations(sortedNearbyLocations.slice(0, limitTo));
      showAllMapPins
        ? setMapLocations(sortedNearbyLocations)
        : setMapLocations(sortedNearbyLocations.slice(0, mapPinLimit));
      setFilteredLocations(sortedNearbyLocations);
    }
  };

  /**
   * @description handle load more click
   * @returns {undefined}
   */
  const handleLoadMore = () => {
    setLocations(filteredLocations.slice(0, limitTo + itemsPerPage));
    if (!showAllPins) {
      setMapLocations(filteredLocations.slice(0, limitTo + itemsPerPage));
      setMapPinLimit(limitTo + itemsPerPage);
    }
    setLimitTo(limitTo + itemsPerPage);
    setFocusIndex(limitTo);
    setTimeout(() => {
      if (focusloadmore.current) focusloadmore.current.focus();
    }, 0);
  };

  /**
   *@description update map based on the location selected by user
   @param {Object} selectedLocation -selected location
   @return {undefined}
   */
  const updateMap = (selectedLocation) => {
    updateNearbyLocation(
      props,
      selectedLocation.lat,
      selectedLocation.lng,
      true,
      false
    );
  };

  /**
   * @description function called followed by place selection
   * @param {object} location  - geometry of location
   * @returns {undefined} - no return
   */
  const locationSelected = (location) => {
    setShowAllPins(false);
    setLimitTo(itemsPerPage);
    setMapPinLimit(itemsPerPage);
    if (location) {
      dispatch(saveSelectedLocation(location));
      setSelecteLoc(0);
    } else {
      updateLocations([]);
    }
  };

  /**
   * @description to handle pic selection
   * @param {number} selectedLocationIndex - selected office location array index
   * @param {boolean} setViewArea - to get selected item viewable
   * @returns {undefined}
   */
  const handlePinClick = (selectedLocationIndex) => {
    setSelecteLoc(selectedLocationIndex);
    const locationList = [...locations];
    locationList.map((location, index) => {
      location.isPrimary = selectedLocationIndex === index ? true : false;
    });
    setLocations(locationList);
    setMapLocations(mapLocations);
  };

  /**
   * @description- click actoin for map location pins
   * @param {*} selectedLocationIndex - array index of selected location
   * @returns {no} - no returns
   */
  const handleMapPinClick = (selectedLocationIndex) => {
    setSelectedLocation({ lat: null, lng: null });
    setSelecteLoc(0);
    const locationList = [...filteredLocations];
    updateNearbyLocation(
      props,
      locationList[selectedLocationIndex].lat,
      locationList[selectedLocationIndex].lng,
      true,
      showAllPins
    );
    scroll();
  };

  /**
   * @deescription location finder node
   * @returns {node} - html node
   */

  return (
    <section className="location-finder">
      <div className="location-finder-panel">
        <div className="left-side-container">
          <div className="location-finder-search">
            <div className="location-search">
              <LocationSearch
                onLocationSelect={locationSelected}
                label={t('location')}
                region={sitecoreContext.Country?.code}
                errorMessage={t('invalid-location')}
                showCurrentLocation={false}
                selectedLocation={selectedLocation}
              ></LocationSearch>
              <h1 className="sr-only">Find a Branch</h1>
            </div>
            {sitecoreContext.Country?.code?.toLowerCase() === 'de' ? (
              <div className="location-results">
                {filteredLocations.length > 0
                  ? `${filteredLocations.length} ${t('locations-results-found')}`
                  : `${filteredLocations.length} ${t('locations-results')}`}
              </div>
            ) : null}
          </div>
          <LocationList
            locations={locations}
            filteredLocations={filteredLocations}
            handlePinClick={handlePinClick}
            translate={t}
            handleLoadMore={handleLoadMore}
            focusloadmore={focusloadmore}
            focusIndex={focusIndex}
            selectedLoc={selectedLoc}
          ></LocationList>
        </div>
      </div>
      {locations && (
        <LocationMap
          locations={mapLocations}
          handlePinClick={handleMapPinClick}
          key={mapLocations.length}
          mapInit={mapInit}
        ></LocationMap>
      )}
    </section>
  );
};

LocationsFinder.defaultProps = {
  fields: {
    items: [],
  },
};

LocationsFinder.propTypes = {
  fields: {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
        ctaText: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
        ctaUrl: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
        hours: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
        latitude: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
        longtitude: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
        title: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
        phone: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
        address: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }),
      })
    ),
  },
  dispatch: PropTypes.func.isRequired,
};

LocationsFinder.propTypes = { ...LocationsFinder.propTypes, ...geoPropTypes };
/**
 * @description Mapping the state to props.
 * @param {object} state - state.
 * @returns {object} - Slice of state.
 */
const mapStateToProps = (state) => ({
  mapInit: state.mapReducer.data,
  locationState: state.jobLocationReducer.location,
});

export default withTranslation()(
  withSitecoreContext()(
    connect(mapStateToProps)(
      geolocated({
        positionOptions: {
          enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
      })(LocationsFinder)
    )
  )
);
