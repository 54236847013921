/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../../globals/buttons/Button';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from '../../../../dataFetcher';
import { useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import DynamicForm from '../../Forms/DynamicForm';
import { withRouter } from 'react-router-dom';
import { CONTACT_US_FORM } from '../../../../constants';
import {
  formObjIteration,
  recursiveWithConstantCallback,
  YesNo,
} from '../../../business/Utils/helper';

/**
 * @description - TrainingRegistration form.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Form.
 */
const TrainingRegistration = (props) => {
  const { t, location, sitecoreContext, fields, params } = props;
  const { route = {} } = sitecoreContext;
  const { itemId = '' } = route;
  const { Title, SubTitle, items = [] } = fields;
  const { acknowledgementmail, servicerequestmail } = params;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [errorCode, setErrorCode] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
    reset,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    setLoading(false);
    setErrorCode(false);
    setMessage('');
  }, [location]);

  /**
   * @description - Handle form submit function.
   * @param {Object} data - Form Data.
   * @returns {undefined}
   */
  const onSubmit = (data) => {
    let formattedData = formObjIteration(data, {}, 'label');
    formattedData = recursiveWithConstantCallback(formattedData, YesNo);
    const postData = {
      ...formattedData,
      acknowledgementmail,
      servicerequestmail,
      ...{ trainingname: route?.fields?.pageTitle?.value },
    };
    setLoading(true);
    dataFetcher(CONTACT_US_FORM.POST_URL, 'POST', postData).then(
      (response) => {
        setLoading(false);
        if (response?.data?.status && response?.data?.status !== 200) {
          setMessage('training-registration-status-message-failed');
          setErrorCode(true);
          reset();
        } else {
          setMessage('training-registration-status-message-success');
          setErrorCode(false);
          //To rest form
          reset();
        }
        document.getElementById('formTitle').scrollIntoView();
      },
      () => {
        setLoading(false);
        setMessage('error-message');
        setErrorCode(true);
        reset();
        document.getElementById('formTitle').scrollIntoView();
      }
    );
  };
  return (
    <div className="rsvp">
      <h2 className="title" id="formTitle">
        <Text field={Title} />
      </h2>
      {message !== '' ? (
        <p className={`statusMessage${errorCode ? 'Error' : 'Success'}`}>
          {t(message)}
        </p>
      ) : (
        <Fragment>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-indicator">
                <Text field={SubTitle} />
              </div>
              <DynamicForm
                formData={items}
                register={register}
                apiQuerryParam={itemId}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
                control={control}
              />
            </div>
          </div>
          <div className="cta">
            <Button
              text={t('register-now')}
              handleButtonClick={handleSubmit(onSubmit)}
              isLoading={loading}
              isDisabled={loading}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

TrainingRegistration.defaultProps = {
  fields: {},
  params: {},
};

TrainingRegistration.propTypes = {
  fields: PropTypes.shape({
    Title: PropTypes.string.isRequired,
    SubTitle: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  prevLocation: PropTypes.string.isRequired,
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
  }).isRequired,
  params: PropTypes.shape({
    acknowledgementmail: PropTypes.string.isRequired,
    servicerequestmail: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withSitecoreContext()(
  withTranslation()(withRouter(TrainingRegistration))
);
