import React, { useEffect, useRef } from 'react';
import AuthWrapper from '../LoginMSAL/AuthWrapper';
import store from '../../../redux/store';
import Loader from '../ErrorBoundary/Loader';

const RedirectToLogin = () => {
  const buttonRef = useRef(null);

  const {
    adb2cConfigReducer: { msalConfig },
  } = store.getState();

  useEffect(() => {
    (() => {
      buttonRef.current.click();
    })();
  }, [msalConfig]);

  const handleLogin = (login) => {
    pushToStorage();
    login();
  };

  const pushToStorage = () => {
    localStorage.setItem('authenticationType', 'Create an account/Sign in');
  };

  return (
    <AuthWrapper>
      {({ login }) => {
        return (
          <div className="my-5">
            <Loader
              loaderWithMsg
              customLoaderStyle={{
                width: '4rem',
                height: '4rem',
                color: '#C25700',
              }}
            />
            <button
              className="d-none"
              ref={buttonRef}
              onClick={() => {
                handleLogin(login);
              }}
            >
              Login
            </button>
          </div>
        );
      }}
    </AuthWrapper>
  );
};

export default RedirectToLogin;
