import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * @description to list search results
 * @param {string} title - result item title
 * @param {string} text - result item small description
 * @param {string} ctaUrl - url to redirect
 * @returns {node} -html
 */
const SearchEverythingItem = ({ title, text, ctaUrl }) => (
  <section className="everything-search-item">
    <div className="container">
      <div className="row">
        <div className="col-md-10 col-12">
          <div className="content">
            <Link to={ctaUrl}>
              <h2 className="title">{title}</h2>
            </Link>

            <div>{text}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

SearchEverythingItem.defaultProps = {
  title: '',
  text: '',
  ctaUrl: '',
};

SearchEverythingItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
};

export default SearchEverythingItem;
