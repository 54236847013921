import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CardArticle from '../../core/Cards/CardArticle';
import { isDataExists, getImg } from '../../../utils/helperUtils';
import { DATE_FORMATS } from '../../../utils/enums';

/**
 * @description - Card Ad component.
 * @param {object} param0 - Input params.
 * @returns {Node} - HTML components.
 */
const CardAd = ({ cardBlocks }) => {
  let adCard = null;
  if (isDataExists(cardBlocks)) {
    adCard = cardBlocks.map((component, index) => {
      if (component.props && component.props.type === 'text/sitecore')
        return component;
      return (
        <div className="col-xl-4 col-md-6" key={index}>
          {component}
        </div>
      );
    });
  }
  return adCard;
};

/**
 * @description - Image Cards.
 * @param {object} props - Input props.
 * @returns {Node} - HTML cards.
 */
export const ImageCards = ({
  imageCards,
  jobCardPosition,
  placeholderImage,
  cardBlocks,
  dateFormat,
}) => (
  <div className="row">
    {imageCards.map((cardVals, index) => {
      const { item } = cardVals;
      return (
        <Fragment key={item?.id}>
          {jobCardPosition?.value > 0 &&
            jobCardPosition?.value <= 4 &&
            jobCardPosition.value - 1 === index && (
              <CardAd cardBlocks={cardBlocks} />
            )}
          <div className="col-lg-6">
            <CardArticle
              isFeatured={true}
              title={item?.headline}
              subTitle={item?.contentType?.targetItem?.field}
              date={item?.date?.jss || item?.date}
              img={
                item?.image?.src || getImg(item?.image) || getImg(placeholderImage)
              }
              altText=" "
              dateFormat={dateFormat ? dateFormat : DATE_FORMATS.LOCALE}
              ctaUrl={item?.url}
            />
          </div>
        </Fragment>
      );
    })}
  </div>
);

ImageCards.propTypes = {
  imageCards: PropTypes.arrayOf({}).isRequired,
  jobCardPosition: PropTypes.shape({
    value: PropTypes.number,
  }),
  placeholderImage: PropTypes.shape({}),
  cardBlocks: PropTypes.arrayOf(PropTypes.shape({})),
};

ImageCards.defaultProps = {
  jobCardPosition: {},
  placeholderImage: {},
  cardBlocks: [],
};

/**
 * @description - Small Cards.
 * @param {object} props - Input props.
 * @returns {Node} - HTML cards.
 */
export const SmallCards = ({
  smallCards,
  jobCardPosition,
  cardBlocks,
  ctaMode,
  ctaText,
  type,
  dateFormat,
}) => (
  <div className="row">
    {smallCards.map((cardVals, index) => {
      const { item } = cardVals;
      return (
        <Fragment key={item?.id}>
          {type === 'partner'
            ? jobCardPosition?.value > 0 &&
              jobCardPosition.value - 1 === index && (
                <CardAd cardBlocks={cardBlocks} />
              )
            : jobCardPosition?.value > 4 &&
              jobCardPosition.value - 5 === index && (
                <CardAd cardBlocks={cardBlocks} />
              )}
          <div className="col-xl-4 col-md-6" key={item?.id}>
            <CardArticle
              title={item?.headline}
              subTitle={item?.contentType?.targetItem?.field}
              date={item?.date?.jss || item?.date}
              altText={`card ${index}`}
              dateFormat={dateFormat ? dateFormat : DATE_FORMATS.LOCALE}
              ctaUrl={item?.url}
              ctaMode={ctaMode}
              ctaText={ctaText}
            />
          </div>
        </Fragment>
      );
    })}
  </div>
);

SmallCards.propTypes = {
  smallCards: PropTypes.arrayOf({}).isRequired,
  jobCardPosition: PropTypes.shape({
    value: PropTypes.number,
  }),
  placeholderImage: PropTypes.shape({}),
  cardBlocks: PropTypes.arrayOf(PropTypes.shape({})),
};

SmallCards.defaultProps = {
  jobCardPosition: {},
  placeholderImage: {},
  cardBlocks: [],
};
