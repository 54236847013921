/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withSitecoreContext,
  RichText,
  mediaApi,
} from '@sitecore-jss/sitecore-jss-react';
import TextButton from '../../globals/buttons/TextButton';
import { SEARCH, API_STATUS_CODES, GTM } from '../../../../constants';
import { addSavedJob } from '../../../../redux/actions/actions';
import AuthWrapper from '../../../core/LoginMSAL/AuthWrapper';
import { authstates, DATE_FORMATS, TYPES } from '../../../../utils/enums';
import { useDispatch, connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { searchResultClickEvent } from '../../../../../src/services/apiServices/gtmService';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isNullorEmpty } from '../../../../utils/helperUtils';

const SearchJobResultCard = (props) => {
  const {
    active,
    jobDetails,
    company,
    type,
    icons,
    lang,
    index,
    brandLabel,
    domain,
    buttonLabel,
    savedjobs,
    sitecoreContext,
    t,
    jobCategory,
    isCompanySearch,
    showJobDesc,
  } = props;
  const {
    jobItemID,
    publishfromDate,
    jobTitle,
    salaryRate,
    jobLocation,
    jobAdvertisementTeaser,
    publicDescription,
    jobURL,
    isNewLabel,
    isSaved,
    showDisabilityIcon,
  } = jobDetails;

  let {
    iconSave,
    iconSaved,
    iconJobType,
    iconIndustry,
    iconLocation,
    iconAccessibility,
  } = {};
  const history = useHistory();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [jobAdTeaser, setJobAdTeaser] = useState('');
  const [publicDesc, setpublicDesc] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loginReducer = useSelector((state) => state.loginReducer);
  const loginState = loginReducer?.state;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';

  const jobSaved = isSaved || savedjobs?.indexOf(jobItemID) !== -1 ? true : false;
  const login_type = sitecoreContext?.LoginType;
  const country_name = sitecoreContext?.Country?.name;
  const [showToaster, setToasterVisibility] = useState(false);

  /**
   * @description to set  icons
   * @returns {undefined} -  no returns
   */
  const setIcons = () => {
    if (Array.isArray(icons)) {
      icons.forEach((iconItem) => {
        switch (iconItem?.itemName) {
          case 'Save':
            iconSave = iconItem.icon;
            break;
          case 'Saved':
            iconSaved = iconItem.icon;
            break;
          case 'Industry':
            iconIndustry = iconItem.icon;
            break;
          case 'JobType':
            iconJobType = iconItem.icon;
            break;
          case 'Location':
            iconLocation = iconItem.icon;
            break;
          case 'Accessibility Icon':
            iconAccessibility = iconItem.icon;
          default:
            break;
        }
      });
    }
  };
  setIcons();

  useEffect(() => {
    if (
      loginReducer.initialized &&
      loginReducer.state === authstates.AUTHENTICATED
    ) {
      const savejobButtonClickedToLogin = sessionStorage.getItem(
        'savejobButtonClickedToLogin'
      );
      if (savejobButtonClickedToLogin === 'true') {
        //job save flow
        const saveJobBtnClickDetails = JSON.parse(
          sessionStorage.getItem('saveJobBtnClickDetails')
        );
        if (jobItemID === saveJobBtnClickDetails.jobItemID) {
          sessionStorage.removeItem('savejobButtonClickedToLogin');
          sessionStorage.removeItem('saveJobBtnClickDetails');
          handleSave(null, loginReducer.state);
        }
      }
    }
  }, []);

  useEffect(() => {
    setJobAdTeaser(jobAdvertisementTeaser?.replace(/(<([^>]+)>)/gi, ''));
    setpublicDesc(
      publicDescription?.replace(/<br>/gi, ' ').replace(/(<([^>]+)>)/gi, '')
    );
  }, []);

  /**
   * @description - login user and saves job
   * @param {*} login - to login
   * @returns {undefined} -
   */
  const loginUserAndSaveJob = (login) => {
    if (login !== null && typeof window !== 'undefined') {
      sessionStorage.setItem(
        'saveJobBtnClickDetails',
        JSON.stringify({
          jobItemID,
        })
      );
      sessionStorage.setItem('savejobButtonClickedToLogin', true);
      localStorage.setItem(
        'authenticationType',
        'Apply > Create Account / Apply > Sign in'
      );
      login(null, 'saveJob');
    }
  };

  const handleSave = async (login, authenticationState) => {
    setLoading(true);
    setError('');
    setMessage('');
    authenticationState === authstates.AUTHENTICATED
      ? await axios
          .get(`${SEARCH.JOB_SEARCH.SAVE_JOB_API}`, {
            params: { jobItemID: jobItemID },
          })
          .then((result) => {
            setLoading(false);
            if (result.data.status === API_STATUS_CODES.SUCCESS) {
              dispatch(addSavedJob(jobItemID));
              setMessage(t('job-save-success'));
            } else if (result.data.status === API_STATUS_CODES.DATA_EXIST) {
              dispatch(addSavedJob(jobItemID));
              setError(t('job-already-saved'));
            }
          })
          .catch(() => {
            setLoading(false);
            setError(t('error-save'));
          })
      : loginUserAndSaveJob(login);

    setToasterVisibility(true);
    setTimeout(function () {
      setToasterVisibility(false);
    }, 1500);
  };

  return (
    <div className="job-search-result card ">
      <div className="card-body">
        <div className="job-actionbar">
          {publishfromDate && (
            <div className="date">
              {moment(publishfromDate).format(DATE_FORMATS.ROBOTS_FORMAT_REV)}
            </div>
          )}
          {isNewLabel && <div className="recent">{t('new-label')}</div>}
          <AuthWrapper>
            {({ login, authenticationState }) => {
              return (
                <>
                  {login_type !== TYPES.ROMA && (
                    <button
                      className="save"
                      aria-describedby={`jobTitle_${index}`}
                      aria-label="save"
                      onClick={(e) => {
                        if (window?.dataLayer) {
                          window.dataLayer.push({
                            event: 'saveVacancy',
                            products: {
                              name: jobDetails?.jobTitle,
                              id: jobDetails?.jobID,
                              brand: jobDetails?.addtionalForDataLayer?.companyName
                                ? jobDetails?.addtionalForDataLayer?.companyName
                                : jobDetails?.companyName
                                ? jobDetails?.companyName
                                : null,
                              category: jobDetails?.domain,
                              variant: jobDetails?.employmentType,
                              dimension11: `Location: ${
                                jobDetails?.jobLocation
                              } | Education: ${
                                jobDetails?.addtionalForDataLayer?.education
                                  ? jobDetails?.addtionalForDataLayer?.education.join(
                                      ', '
                                    )
                                  : jobDetails?.education
                                  ? jobDetails?.education.join(', ')
                                  : undefined
                              } | Salary: ${jobDetails?.salaryRate} | Hours: ${
                                jobDetails?.jobType
                              }`,
                              dimension12: `${
                                authstates?.[loginState || 'UNAUTHENTICATED']
                              }`,
                              dimension13: jobDetails?.addtionalForDataLayer
                                ?.applicationType
                                ? jobDetails?.addtionalForDataLayer?.applicationType
                                : jobDetails?.applicationType
                                ? jobDetails?.applicationType
                                : undefined,
                              dimension14: undefined,
                              dimension15: undefined,
                              dimension16: jobDetails?.clientID
                                ? jobDetails?.clientID
                                : undefined,
                              dimension17: jobDetails?.jobLocation
                                ? jobDetails?.jobLocation
                                : undefined,
                              dimension18: jobDetails?.addtionalForDataLayer
                                ?.education
                                ? jobDetails?.addtionalForDataLayer?.education.join(
                                    ', '
                                  )
                                : jobDetails?.education
                                ? jobDetails?.education.join(', ')
                                : undefined,
                              dimension19: jobDetails?.salaryRate
                                ? jobDetails?.salaryRate
                                : undefined,
                              dimension20: jobDetails?.jobType
                                ? jobDetails?.jobType
                                : undefined,
                            },
                          });
                        }
                        e.preventDefault();
                        !jobSaved && handleSave(login, authenticationState);
                      }}
                    >
                      <div className="icon-bookmark">
                        {!loading ? (
                          <>
                            {!jobSaved && (
                              <img
                                src={mediaApi.updateImageUrl(iconSave)}
                                alt="save icon"
                                aria-hidden="true"
                              />
                            )}
                            {jobSaved && (
                              <img
                                src={mediaApi.updateImageUrl(iconSaved)}
                                alt="saved icon"
                                aria-hidden="true"
                              />
                            )}
                          </>
                        ) : (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </div>
                      {/* {jobSaved ? t('saved') : t('save')} */}
                    </button>
                  )}
                </>
              );
            }}
          </AuthWrapper>
          {/* <a className="save" href="/" onClick={() => setActive(!isActive)}>
            <div className="icon-bookmark">
              {!isActive && (
                <img src={mediaApi.updateImageUrl(iconSave)} alt="save icon" />
              )}
              {isActive && (
                <img src={mediaApi.updateImageUrl(iconSaved)} alt="saved icon" />
              )}
            </div>
          </a> */}
        </div>
        {(error || message) && showToaster ? (
          <div className="action-bar-messages global">
            {error && (
              <span role="alert" className="custom-tooltip">
                {error}
              </span>
            )}
            {message && (
              <span role="alert" className="custom-tooltip">
                {message}
              </span>
            )}
          </div>
        ) : null}
        <div className="job-skills">
          {company &&
            country_name.toLowerCase() !== 'manpower-germany' &&
            country_name.toLowerCase() !== 'sweden' && (
              <div className="company">
                {company}
                {/* {companyLogo && <img src={companyLogo} alt="logo" />} */}
              </div>
            )}

          {brandLabel && country_name.toLowerCase() !== 'sweden' && (
            <div className="brandLabel">{brandLabel}</div>
          )}
        </div>
        <div className="job-position">
          <h2 className={`title ${brandName}`}>
            <Link
              to={jobURL}
              onClick={() => {
                searchResultClickEvent(
                  GTM.EVENTS.SEARCH_RESULT_DETAILS,
                  jobDetails,
                  sitecoreContext,
                  loginState,
                  jobCategory,
                  isCompanySearch,
                  index
                );
              }}
            >
              {jobTitle}
            </Link>
          </h2>
          {salaryRate && <div className="salary">{salaryRate}</div>}
        </div>
        <div className="job-details">
          {jobLocation && (
            <div className="location">
              <img src={mediaApi.updateImageUrl(iconLocation)} alt="location icon" />
              {jobLocation}
            </div>
          )}
          {type && (
            <div className="type">
              <img src={mediaApi.updateImageUrl(iconJobType)} alt="type icon" />
              {type}
            </div>
          )}
          {showDisabilityIcon && (
            <div className="accessibility">
              <img
                src={mediaApi.updateImageUrl(iconAccessibility)}
                alt="accessibility icon"
              />
              {showDisabilityIcon}
            </div>
          )}
          {domain && (
            <div className="industry">
              <img src={mediaApi.updateImageUrl(iconIndustry)} alt="domain icon" />
              {domain}
            </div>
          )}
        </div>
        <div className="job-description">
          {!isNullorEmpty(jobAdTeaser) && country_name.toLowerCase() === 'sweden'
            ? jobAdTeaser && (
                <RichText
                  tag="p"
                  className="excerpt"
                  field={{ value: jobAdTeaser }}
                />
              )
            : publicDescription &&
              showJobDesc && (
                <RichText
                  tag="p"
                  className="excerpt"
                  field={{ value: publicDesc }}
                />
              )}
        </div>
      </div>

      <div
        className="apply-now"
        onClick={() => {
          searchResultClickEvent(
            GTM.EVENTS.SEARCH_RESULT_DETAILS,
            jobDetails,
            sitecoreContext,
            loginState,
            jobCategory,
            isCompanySearch,
            index
          );
          history.push(jobURL);
        }}
      >
        <TextButton text={buttonLabel} arrow />
      </div>
    </div>
  );
};

SearchJobResultCard.defaultProps = {
  company: '',
  type: '',
  domain: '',
  cssClass: '',
  jobDetails: {},
  savedjobs: [],
  showJobDesc: true,
};

SearchJobResultCard.propTypes = {
  jobDetails: PropTypes.shape({}),
  company: PropTypes.string,
  type: PropTypes.string,
  domain: PropTypes.string,
  cssClass: PropTypes.string,
  savedjobs: PropTypes.arrayOf([]),
  showJobDesc: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  savedjobs: state.savedJobsReducer.data,
});

export default withTranslation()(
  withSitecoreContext()(connect(mapStateToProps)(SearchJobResultCard))
);
