import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import './featurette.scss';
import moment from 'moment';
import { getImg, isDataExists } from '../../../utils/helperUtils';
import { Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import EventsCardScroll from '../CardScrolls/EventsCardScroll';

import { DATE_FORMATS } from '../../../utils/enums';

/**
 * @description - FeaturetteEvent component;
 * @param {object}  props - Input props;
 * @returns{Node} - return html block;
 */
const FeaturetteEvent = (props) => {
  const { fields, t, sitecoreContext } = props;
  const { items = [], backgroundImage, backgroundColor, labelColor } = fields;
  const items_count = items.length;
  const isItemExists = isDataExists(items);
  const dateFormat = sitecoreContext?.Country?.dateFormat || DATE_FORMATS.LOCALE;
  const dateFormat1 = DATE_FORMATS.TIME_HR_MIN;
  const color_codes = backgroundColor?.value?.split(',');
  const card_backgound = color_codes ? color_codes[0] : '';
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
  return (
    <div>
      {fields?.items && isItemExists !== 0 && (
        <div className="eventcardscroll">
          {items_count === 1 ? (
            <section
              className={`featurette-section event ${brandName}`}
              style={{
                backgroundImage: `url(${getImg(backgroundImage)})`,
              }}
              style={
                brandName === 'manpower'
                  ? { backgroundColor: `${card_backgound}` }
                  : { backgroundColor: 'none' }
              }
            >
              <div className="container">
                <div className="row">
                  {items.map((x, index) => (
                    <Fragment key={index}>
                      {brandName !== 'manpower' ? (
                        <div className="col-lg-5">
                          <div className="featurette-imgs">
                            <div className="blurred">
                              <Image
                                field={x.fields?.image}
                                alt="event"
                                editable={false}
                              />
                            </div>
                            <Image
                              field={x.fields?.image}
                              alt="event"
                              editable={false}
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        className={
                          brandName === 'manpower'
                            ? 'col-lg-8 offset-lg-2'
                            : 'col-lg-5'
                        }
                      >
                        <div className="featurette-body">
                          <h2
                            className="eventtitle"
                            style={
                              brandName === 'manpower'
                                ? {
                                    color: `${
                                      labelColor ? labelColor.value : '#FFFFFF'
                                    }`,
                                  }
                                : { color: 'none' }
                            }
                          >
                            {t('upcoming-events')}
                          </h2>
                          {x.fields?.startDate && x?.fields?.endDate && (
                            <div>
                              <div className="time-text">
                                {moment(x?.fields?.startDate).format(dateFormat)}
                              </div>
                              <div className="time-text">
                                {moment(x?.fields?.startDate).format(dateFormat1)} -
                                {' ' +
                                  moment(x?.fields?.endDate).format(dateFormat1)}
                              </div>
                            </div>
                          )}
                          <h3 className="light">{x.fields?.headline}</h3>
                          <p className="large">{x.fields?.subTitle}</p>

                          {brandName === 'manpower' ? (
                            <ButtonLink
                              ctaText={t('register-now')}
                              ctaUrl={x.fields?.url}
                              arrow
                            />
                          ) : (
                            <ButtonLink
                              cssClass="margin-top-40 margin-right-40"
                              ctaText={t('register-now')}
                              ctaUrl={x.fields?.url}
                            />
                          )}
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            </section>
          ) : (
            <EventsCardScroll {...props} />
          )}
        </div>
      )}
    </div>
  );
};

FeaturetteEvent.defaultProps = {
  fields: { backgroundImage: {}, items: [] },
};

FeaturetteEvent.propTypes = {
  fields: PropTypes.shape({
    backgroundImage: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        headline: PropTypes.string.isRequired,
        dateText: PropTypes.string.isRequired,
        timeText: PropTypes.string.isRequired,
        subTitle: PropTypes.string.isRequired,
        img: PropTypes.string.isRequired,
        altText: PropTypes.string,
        ctaText: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

export default withSitecoreContext()(withTranslation()(FeaturetteEvent));
