import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * @description - Breadcrumb Links component.
 * @param {Object} param0 - Input props.
 * @returns {Node} - HTML template for Breadcrumb.
 */
const BreadcrumbLink = ({ text, url, isLastItem }) => {
  return (
    <li>
      <Link
        to={url === null ? '#' : url}
        {...(isLastItem ? { 'aria-current': 'page' } : '')}
      >
        {text}
      </Link>
    </li>
  );
};

BreadcrumbLink.defaultProps = {
  text: '',
  url: '',
  isLastItem: '',
  isJobbIncludes: false,
};
BreadcrumbLink.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  isLastItem: PropTypes.bool,
  isJobbIncludes: PropTypes.bool,
};
export default BreadcrumbLink;
