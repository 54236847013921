import React from 'react';
import PropTypes from 'prop-types';
import './Spacer.scss';

/**
 * @description - Spacer component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const Spacer = (props) => {
  const { params } = props;
  const { height } = params;
  return <div className="bgColor" style={{ height: `${height}px` }}></div>;
};

Spacer.defaultProps = {
  params: {
    height: '60px',
  },
};

Spacer.propTypes = {
  params: PropTypes.shape({
    height: PropTypes.string,
  }),
};

export default Spacer;
