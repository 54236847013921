import { useEffect, useState } from 'react';

/**
 * @description - Custom hook to get current window width.
 * @returns {number} - window inner width.
 */
const useCurrentWidth = () => {
  const [width, setWidth] = useState(0);

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    /**
     * @description - save current window width in the state object
     * @returns {number} - width.
     */
    const getWidth = () =>
      typeof window !== undefined
        ? window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
        : 0;

    /**
     * @description - window resize listener
     * @returns {undefined}
     */
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return width;
};

export default useCurrentWidth;
