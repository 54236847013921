import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import PropTypes from 'prop-types';
import { checkDataExists } from '../../../../src/utils/helperUtils';
import './EmergencyMessage.scss';

/**
 * @description large callout bar component
 * @param {object} props - parameters get from sitecore
 * @returns {node} - html node
 */
const EmergencyMessage = (props) => {
  const { fields } = props;
  const { title, componentBackgroundColor } = fields;
  let color_code = '';
  const updatebackgroundColor = componentBackgroundColor?.map((item) => {
    const { colorCode } = item?.fields;
    color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
    color_code = color_code.split(',');
    if (color_code.length > 1) {
      color_code = `linear-gradient(to bottom, ${color_code[0]}, ${color_code[1]})`;
    } else {
      color_code = ` ${color_code}`;
    }
    return color_code;
  });
  return (
    <div
      className="emergency-msg"
      style={{ background: `${updatebackgroundColor}` }}
    >
      <RichText className="container" field={title} />
    </div>
  );
};
EmergencyMessage.defaultProps = {
  fields: {
    title: {},
    componentBackgroundColor: [],
  },
};
EmergencyMessage.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    },

    componentBackgroundColor: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
export default EmergencyMessage;
