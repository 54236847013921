import { UPLOAD_FIELDS } from '../actions/actionTypes';

const initialState = [];

/**
 *
 * @param {*} state -state
 * @param {*} action -action
 * @returns {*} - Action.
 */
const uploadFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FIELDS.SAVE:
      return [...state, action.payload];
    default:
      return state;
  }
};
export default uploadFieldsReducer;
