import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import JobsForm from '../../PageBanners/BannerJobs/JobsForm';
import TextLink from '../../globals/link/TextLink';
import { Image, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { bannerLoaded } from '../../../../redux/actions/actions';
import { useDispatch } from 'react-redux';
import './HomeSplashBanner.scss';

/**
 *@description -  HomeSplashBanner standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const HomeSplashBanner = (props) => {
  const { fields, t } = props;
  // eslint-disable-next-line no-unused-vars
  const { params } = props;
  const dispatch = useDispatch();

  const {
    title,
    foregroundimage,
    backgroundimage,
    searchjobLabel,
    ctaText,
    ctaUrl,
  } = fields;

  const searchCareerTextPlaceHolder = t('job-title-industry-skill');
  const searchLocationPlaceholder = t('city-or-zip-code');

  /**
   * @description - Set a vertical top-offset for splash banner on sticky header state to prevent spalsh banner and header transparent content overlapping.
   * @returns {void}
   */
  const listener = () => {
    let lastScrollTop = 0;
    const splashBanner = document.querySelector('.home__splashbanner');
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      splashBanner.classList.remove('stickyHeader');
    }
    lastScrollTop = st <= 0 ? 0 : st;
  };

  const setStickySplashBanner = () => {
    window.addEventListener('scroll', listener);
  };

  useEffect(() => {
    setStickySplashBanner();
    dispatch(bannerLoaded('HomeSplashBanner', true));
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);
  return (
    <section className="home__splashbanner stickyHeader animate--in">
      <div className="gradient__overlay"></div>
      <div className="rectangle"></div>
      <div className="rectangle2"></div>
      <Image className="bknd__image" field={backgroundimage} rel="preload" />
      <div className="content__wrap container-xl">
        <div className="content">
          <h1 className="headline">
            <Text field={title} />
          </h1>
          <JobsForm
            ctaText={searchjobLabel?.value}
            searchCareerTextPlaceHolder={searchCareerTextPlaceHolder}
            searchLocationPlaceholder={searchLocationPlaceholder}
          />
          <TextLink
            ctaText={ctaText}
            ctaUrl={ctaUrl?.value}
            cssClass="reversed"
            underline
          />
        </div>
        <div className="person__imagewrap">
          <Image
            className="person__image"
            field={foregroundimage}
            fetchpriority="high"
          />
        </div>
      </div>
    </section>
  );
};

HomeSplashBanner.defaultProps = {
  params: {},
  fields: {},
  t: (val) => val,
};

HomeSplashBanner.propTypes = {
  params: PropTypes.shape({}),
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string }),
    backgroundimage: PropTypes.shape({
      value: {
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      },
    }),
    foregroundimage: PropTypes.shape({
      value: {
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      },
    }),
    searchjobLabel: PropTypes.shape({ value: PropTypes.string }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
        text: PropTypes.string,
        anchor: PropTypes.string,
        linktype: PropTypes.string,
        class: PropTypes.string,
        title: PropTypes.string,
        target: PropTypes.string,
        querystring: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  }),
  t: PropTypes.func.isRequired,
};

export default withSitecoreContext()(withTranslation()(HomeSplashBanner));
