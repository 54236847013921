/* eslint-disable */
import React, { useEffect, useState, forwardRef, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Button from '../../../core/globals/buttons/Button';
import DynamicForm from '../../../core/Forms/DynamicForm';
import Modal from '../../../core/Modal';
import { toast } from 'react-toastify';
import map from 'lodash/map';
import { flattenObject } from '../../../../utils/datafactory';
import { isObjNotEmpty } from '../../../../utils/helperUtils';
import {
  saveProfileInfo,
  saveProfileInfoForm,
  saveParse,
  saveFormActions,
  saveProfileInfoFormName,
  saveProfileInfoFormNameIfFormValid,
} from '../../../../redux/actions/actions';
import {
  resumeParseApi,
  transformCandidateDetails,
} from '../../../../services/apiServices/candidateService';
import CustomOverlayLoader from '../../../core/ErrorBoundary/CustomOverlayLoader';
import { formObjIteration } from '../../Utils/helper';
import { buildProfileItems } from '../../../../utils/enums';
import './upload_resume.scss';

/**
 * @description - Upload Resume Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const UploadResume = ({ inputRef, t, ...props }) => {
  const {
    fields,
    profileInfo,
    dispatch,
    profileInfoForm,
    profileInfoFormData,
    formData,
    componentName,
    triggerArray,
    invalidArray,
  } = props;
  const { subTitle, items = [], apiKeyMapper, parseApiEndPoint } = fields;
  const [data, setData] = useState({});
  const [fileUpload, setFileUpload] = useState({});
  const [candidateId, setCandidateId] = useState('');
  const [fieldList, setFieldList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isUpload, setUploadStatus] = useState(false);
  const {
    handleSubmit,
    register,
    getValues,
    errors,
    setValue,
    control,
    formState,
    setError,
    clearErrors,
    trigger,
    unregister,
  } = useForm({
    mode: 'all',
    revalidate: 'onChange',
  });
  const [showModal, setShowModal] = useState(false);
  const [isDelete, setUploadDelete] = useState(false);
  const [formError, showFormError] = useState(false);
  const formSubmitRef = useRef();
  const { isValid, isDirty, touched, isValidating } = formState;
  const apiMapper = apiKeyMapper?.value
    ? apiKeyMapper?.value
    : buildProfileItems[componentName];

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        saveFormActions({
          [componentName]: {
            isDirty,
            isValid,
            touched,
            isDelete,
            isUpload,
          },
        })
      );
    }, 20);
  }, [isValid, isValidating, isDirty, touched, isDelete, isUpload]);

  const triggerForm = () => {
    setTimeout(() => dispatch(saveProfileInfoFormName(componentName)), 50);
    let event;
    if (typeof Event === 'function') {
      event = new Event('submit', { cancelable: true });
    } else {
      event = document.createEvent('Event');
      event.initEvent('submit', false, { cancelable: true });
    }
    formSubmitRef &&
      formSubmitRef.current &&
      formSubmitRef.current.dispatchEvent(event);
  };

  useEffect(() => {
    if (triggerArray && triggerArray.includes(componentName)) {
      triggerForm();
    }
  }, [triggerArray]);

  useEffect(() => {
    if (invalidArray && invalidArray.includes(componentName)) {
      showFormError(true);
    }
  }, [invalidArray]);

  /**
   * @description to show file remove alert dialog
   * @returns {undefined} - nothing
   */
  const handleModal = () => {
    setShowModal(!showModal);
  };

  /**
   * @description - Remove file
   * @returns {undefined} - nothing
   */
  const handleYes = () => {
    handleModal();
  };

  /**
   * @description to show job alert dialog
   * @returns {undefined} - nothing
   */
  const handleNo = () => {
    handleModal();
  };

  useEffect(() => {
    if (profileInfo?.Id) {
      setData({
        data: profileInfo?.[apiMapper]?.data?.[0],
        attachment: profileInfo?.AttachmentDetails,
      });
      setCandidateId(profileInfo?.Id);
    }
  }, [profileInfo]);

  const parseFn = (data) => {
    const resumeName = Object.keys(data)?.[0];
    if (resumeName) {
      setLoading(true);
      if (data?.[resumeName]?.value === 'upload') {
        const resumeData = new FormData();
        Object.keys(fileUpload).forEach((item) => {
          for (let i = 0; i < fileUpload[item].length; i++) {
            if (!fileUpload[item][i].id && fileUpload[item][i]?.['file']) {
              resumeData.append(
                fileUpload[item][i]['type'],
                fileUpload[item][i]['file']
              );
              break;
            }
          }
        });
        const params = {
          candidateId,
        };
        resumeParseApi(
          parseApiEndPoint?.value,
          parseData,
          resumeData,
          params,
          parseFailed
        );
      } else {
        const params = {
          candidateId,
          fileId: data?.[resumeName]?.value,
        };
        resumeParseApi(parseApiEndPoint?.value, parseData, [], params, parseFailed);
      }
      handleModal();
    }
  };

  const parseData = (cvData) => {
    if (Object.keys(cvData).length > 0) {
      let cvTranformData = transformCandidateDetails(cvData);
      let formDataFlatternObject = flattenObject(
        formData?.PersonalDetails?.data?.[0]
      );
      let cvTranformFlatternObject = flattenObject(
        cvTranformData?.PersonalDetails?.data?.[0]
      );
      Object.keys(cvTranformFlatternObject).forEach((key) => {
        if (Array.isArray(formDataFlatternObject[key])) {
          formDataFlatternObject[key] = [
            ...new Set([
              ...formDataFlatternObject[key],
              ...cvTranformFlatternObject[key],
            ]),
          ];
        } else if (
          cvTranformFlatternObject[key] !== null &&
          cvTranformFlatternObject[key] !== '' &&
          cvTranformFlatternObject[key] !== undefined
        ) {
          formDataFlatternObject[key] = cvTranformFlatternObject[key];
        }
      });
      dispatch(saveParse(true));
      dispatch(
        saveProfileInfo({
          ...formData,
          PersonalDetails: {
            data: [
              {
                ...formDataFlatternObject,
              },
            ],
          },
          ExperienceDetails:
            cvTranformData?.ExperienceDetails?.formCount > 0
              ? cvTranformData.ExperienceDetails
              : formData?.ExperienceDetails,
          EducationDetails:
            cvTranformData?.EducationDetails?.formCount > 0
              ? cvTranformData.EducationDetails
              : formData?.EducationDetails,
        })
      );
      dispatch(
        saveProfileInfoForm({
          ...profileInfoForm?.data,
          DeletedExperienceDetails:
            cvTranformData?.ExperienceDetails?.formCount > 0
              ? map(formData?.ExperienceDetails?.data, 'id')
              : undefined,
          DeletedEducationDetails:
            cvTranformData?.EducationDetails?.formCount > 0
              ? map(formData?.EducationDetails?.data, 'id')
              : undefined,
        })
      );
      toast.success(t('parsing-success-message'));
    } else {
      toast.error(t('parsing-no-data-message'));
    }
    setLoading(false);
  };

  const parseFailed = () => {
    setLoading(false);
    toast.error(t('parsing-failed-message'));
  };

  const handleUpload = (fileData, name) => {
    const fileList = fileUpload;
    const obj = { [name]: fileData };
    if (fileUpload.hasOwnProperty(name)) {
      delete fileList[name];
    }
    setFileUpload({ ...fileList, ...obj });
    setUploadStatus(true);
  };

  const handleDelete = (id, name, index) => {
    if (fileUpload.hasOwnProperty(name)) {
      const temp = fileUpload[name];
      delete temp[index];
      const newtemp = temp?.filter((item) => item);
      setFileUpload({ ...fileUpload, [name]: newtemp });
    }
    if (id) {
      setFieldList([...fieldList, id]);
    }
    setUploadDelete(true);
  };

  const parseOnSubmit = (value) => {
    if (profileInfoFormData?.isFormSubmitting) {
      toast.error(t('ongoing-section-api-saving'));
      return true;
    }
    const resumeName = Object.keys(value);
    if (value[resumeName] !== null && value[resumeName]) {
      handleYes();
    } else {
      toast.error(t('resume-required'));
    }
  };

  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    onSubmitFunc(formattedData);
  };

  /**
   * @description - onsubmit hander.
   * @returns {undefined}
   */
  const onSubmit = (formData) => {
    dispatch(
      saveProfileInfoForm({
        ...profileInfoFormData?.data,
        [componentName]: isObjNotEmpty(formData) ? formData : undefined,
        files: isObjNotEmpty(profileInfoFormData?.data?.files)
          ? { ...profileInfoFormData?.data?.files, ...fileUpload }
          : fileUpload,
        DeletedFiles:
          profileInfoFormData?.data?.DeletedFiles?.length > 0
            ? [
                ...new Set([
                  ...profileInfoFormData?.data?.DeletedFiles,
                  ...fieldList,
                ]),
              ]
            : fieldList.length > 0
            ? fieldList
            : undefined,
      })
    );

    dispatch(saveProfileInfoFormNameIfFormValid(componentName));
  };

  return (
    <section className="resume-upload">
      {isLoading && (
        <CustomOverlayLoader
          showSpinner
          customLoaderMsg={t('parsing-in-progress')}
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#fff',
          }}
          customTextStyle={{ color: '#fff' }}
          customStyle={{
            backgroundColor: 'rgb(0, 0, 0, 0.5)',
            background: 'rgba(0, 0, 0, 0.5)',
            opacity: '1',
          }}
        />
      )}
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 border-line">
            <div className="cover-letter-text">{subTitle?.value}</div>
            <Fragment>
              <div className="uploadWrapper">
                <div className="upload-resume-form">
                  <form
                    onSubmit={handleSubmit(transform(onSubmit))}
                    ref={formSubmitRef}
                  >
                    <DynamicForm
                      formData={items}
                      register={register}
                      errors={formError || isObjNotEmpty(touched) ? errors : ''}
                      getValues={getValues}
                      values={data}
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                      handleUpload={handleUpload}
                      handleDelete={handleDelete}
                      APIQuerryParams={{ cid: candidateId }}
                      control={control}
                      validateOnLoad={true}
                      trigger={trigger}
                      unregister={unregister}
                    />
                  </form>
                </div>
                <div className="upload-button-container" role="button" tabIndex={0}>
                  <Button
                    text={t('parse-cv')}
                    handleButtonClick={handleSubmit(parseOnSubmit)}
                    cssClass="blue"
                  ></Button>
                </div>
              </div>
            </Fragment>
          </div>
        </div>
      </div>
      <Modal showModal={showModal} handleModal={handleModal} size="md">
        <div className="inner-modal-container" key="body">
          {t('parse-confirmation-message')}
          <div className="confirm-button-container">
            <Button
              cssClass="yes-button"
              handleButtonClick={handleSubmit(parseFn)}
              text={t('continue')}
            />
            <Button handleButtonClick={handleNo} text={t('cancel')} />
          </div>{' '}
        </div>
      </Modal>
    </section>
  );
};

UploadResume.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
  profileInfoForm: PropTypes.shape({}),
  formData: PropTypes.shape({}),
};

UploadResume.defaultProps = {
  fields: {},
  profileInfoForm: {},
  formData: {},
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    profileInfoForm: state.profileInfoFormReducer,
    formData: state.formDataReducer.data,
    profileInfoFormData: state.profileInfoFormReducer,
  };
};

const ConnectedComponent = connect(mapStateToProps)(UploadResume);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
