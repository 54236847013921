/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import Accordion from './SEOAccordion';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import TypeAheadDropDownAdvanced from '../globals/dropdown/TypeAheadDropDownAdvanced';
import { isObjNotEmpty } from '../../../utils/helperUtils';
import './seoHyperlinks.scss';

/**
 * @description - to show countrylinks
 * @param {*} param0
 * @returns {HTML} - countrylinks
 */
const CategoryLandingSearch = (props) => {
  const { fields, t } = props;
  const {
    items,
    accordionTitle,
    accordionStyling,
    accordionMode,
    accordionDesc,
    showSearchFilter,
  } = fields || {};
  // const [categories, setCategories] = useState([]);
  const [showAccordion, setShowAccordion] = useState(true);
  const stylesObj = {
    backgroundColor: accordionStyling?.backgroundColor,
    // color: accordionStyling?.fontColor,
  };
  // useEffect(() => {
  //   prepareLocationLinks();
  // }, [items]);

  // const prepareLocationLinks = () => {
  //   let categories = [];
  //   const itemList = [];
  //   Array.isArray(items?.items) &&
  //     items?.items?.forEach((item) => {
  //       categories = [...categories, ...item?.categories];
  //     });

  //   categories.forEach((category, index) => {
  //     const text = (
  //       <div key={index}>
  //         {/* <span>{category?.categoryName}</span> */}
  //         <a href={category?.categoryURL} key={index}>
  //           {category?.categoryName}
  //         </a>
  //       </div>
  //     );

  //     itemList.push({
  //       value: category?.categoryName,
  //       text: text,
  //     });
  //   });
  //   setCategories(itemList);
  // };

  let categoriesTemp = [];
  const itemList = [];
  Array.isArray(items) &&
    items?.forEach((item) => {
      categoriesTemp = [...categoriesTemp, ...item?.categories];
    });

  categoriesTemp.forEach((category, index) => {
    const text = (
      <div>
        <Link key={index} to={category?.categoryURL}>
          {category?.categoryName}
        </Link>
      </div>
    );

    itemList.push({
      value: category?.categoryName,
      text: text,
    });
  });
  // setCategories(itemList);

  const doShowAccordion = () => setShowAccordion(true);
  const doHideAccordion = () => setShowAccordion(false);

  return isObjNotEmpty(fields) ? (
    <section className={`category-search category-section-seo`} style={stylesObj}>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-12">
            <h2 className="search-title">{accordionTitle?.value}</h2>
            <RichText className="search-subtitle" field={accordionDesc} />
          </div>
          <div className="search-category-drodown">
            {showSearchFilter?.value && (
              <TypeAheadDropDownAdvanced
                items={itemList}
                textField="text"
                valueField="value"
                floatingLabel={t('search-category-jobs')}
                seletedItem={''}
                onItemSelect={() => {}}
                brandName={'manpower'}
                dropdown={true}
                onClear={doShowAccordion}
                onSearch={doHideAccordion}
                noDataMessage={t('category-no-result-found')}
              />
            )}
          </div>
        </div>
        <div className="location-links">
          <div key="body">
            <Accordion
              linkItems={items}
              showAccordion={showAccordion}
              doShowAccordion={doShowAccordion}
              cssClass={accordionStyling?.cssClass}
              accordionMode={accordionMode}
            />
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

CategoryLandingSearch.defaultProps = {
  fields: {},
  sitecoreContext: {},
};

CategoryLandingSearch.propTypes = {
  sitecoreContext: PropTypes.shape({}),
  fields: PropTypes.shape({}),
  sitecorecontext: PropTypes.func,
};

export default withSitecoreContext()(withTranslation()(CategoryLandingSearch));
