/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import Pagination from '../../globals/pagination/Pagination';
import { saveSEOSearchResults } from '../../../../redux/actions';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import SnackBar from '../../../core/ErrorBoundary/SnackBar';
import { socialShareMapper } from '../../../../factory/jobSearchFilterFactory';
import { isNullorEmpty } from '../../../../utils/helperUtils';
import SearchJobResultCard from '../../Search/SearchResultGlobal/SearchJobResultCard';

import '../../Search/search.scss';
import '../../Search/SearchResultGlobal/search_global.scss';
import '../seo-jobs.scss';
import ButtonLink from '../../globals/link/ButtonLink';
import Button from '../../globals/buttons/Button';
import { seojobResultsEvent } from '../../../../services/apiServices/gtmService';

/**
 * @description to show job search results
 * @param {object} fields - search results component params
 * @param {function} t - translate function
 * @returns {node} -html
 */
const SEOJobResults = ({ fields = {}, t = () => {}, sitecoreContext }) => {
  const {
    jobs,
    socialShares,
    mediaIcons,
    applyJobUrl,
    totalJobResults,
    filterResults,
    seeAllJobs,
    showingResults,
    searchLabels,
    zeroSearchResultsText,
    jobCategory,
    seoSearchResultCss,
    showJobAlert,
    frequency,
    alertSuccess,
    consentText,
    dashboardURL,
    searchCriteria,
    pageNo,
    jobItemPerPage,
    seoPageUrl,
    seoCanonicalUrlForPagination,
  } = fields;
  const [selectedJob, setSelectedJob] = useState(0);
  const [seoJobShareUrl, setSeoJobShareUrl] = useState('');
  const jobDetailsReducer = useSelector((state) => state.jobDetailsReducer);
  const loginReducer = useSelector((state) => state.loginReducer);
  const loginState = loginReducer?.state;
  const { isActive, message, openSnackBar } = useSnackbar();
  const dispatch = useDispatch();
  const socialItems = socialShares ? socialShareMapper(socialShares) : [];
  const history = useHistory();
  const isNewLayout = !isNullorEmpty(seoSearchResultCss);
  const [currentPage, setCurrentPage] = useState(1);
  const alertIcon =
    Array.isArray(mediaIcons) &&
    mediaIcons?.find((item) => item?.itemName === 'Alert');
  const iconAlert = alertIcon?.icon;
  const filterIcon =
    Array.isArray(mediaIcons) &&
    mediaIcons?.find((item) => item?.itemName === 'Filter');
  const iconFilter = filterIcon?.icon;
  useEffect(() => {
    setCurrentPage(pageNo);
    setSeoJobShareUrl(window.location.origin);
    seojobResultsEvent(
      jobs,
      sitecoreContext,
      loginState,
      jobCategory
    );
  }, [jobs]);

  useEffect(() => {
    if (jobs && jobs.length) {
      const companyUrl = jobs[selectedJob]?.backToSearchResultsUrl;
      if (typeof localStorage !== 'undefined')
        localStorage.setItem('companyUrl', companyUrl);
    }
  }, [selectedJob]);

  useEffect(() => {
    if (sessionStorage) {
      const savejobButtonClickedToLogin = sessionStorage.getItem(
        'savejobButtonClickedToLogin'
      );
      if (savejobButtonClickedToLogin === 'true') {
        const saveJobBtnClickDetails = JSON.parse(
          sessionStorage.getItem('saveJobBtnClickDetails')
        );
        if (jobs && jobs.length) {
          const jobIndex = jobs.findIndex(
            (job) => job.jobItemID === saveJobBtnClickDetails?.jobItemID
          );
          if (jobIndex >= 0) {
            setSelectedJob(jobIndex);
          }
        }
      }
    }
  }, [jobDetailsReducer?.data]);

  useEffect(() => {
    dispatch(
      saveSEOSearchResults({
        jobs,
        totalJobResults,
        filterResults,
        seeAllJobs,
        showingResults,
        searchLabels,
        showJobAlert,
        frequency,
        alertSuccess,
        consentText,
        dashboardURL,
        iconAlert,
        searchCriteria,
        seoSearchResultCss,
        iconFilter,
      })
    );
  }, [jobs]);

  /**
   * @description to see all jobs
   * @returns {undefined} - nothing
   */

  const handleSeeAllJobs = () => {
    history.push({
      pathname: sitecoreContext?.Country?.jobSearchURL || '/',
    });
  };

  const handlePageChange = (selectedPage) => {
    console.log('searchpage selectedpage currentpage', selectedPage, currentPage);
    if (selectedPage !== currentPage) {
      setPageParams(selectedPage);
    }
  };

  const setPageParams = (page) => {
    history.push({ pathname: `${seoPageUrl}/p${page}` });
  };

  /**
   * @description - to handle job item selection
   * @param {number} jobListIndex - selected job item index
   * @returns {undefined} - nothing
   */
  const handleJobSelection = (jobListIndex) => {
    setSelectedJob(jobListIndex);
  };

  return (
    <>
      <section className="search-job-results mb-0 seo-job-results">
        {jobs && jobs?.length ? (
          <div className="container">
            <div
              className={`${
                isNewLayout ? `row justify-content-md-center` : `col-md-8`
              } seo-cards`}
            >
              {jobs &&
                jobs?.length > 0 &&
                jobs?.map((item, index) => {
                  return (
                    <div
                      role="list"
                      className={`${
                        isNewLayout ? `${seoSearchResultCss} hybrid-layout` : ''
                      }`}
                      key={`job_${index}`}
                    >
                      <SearchJobResultCard
                        active={selectedJob === index ? true : false}
                        jobDetails={item}
                        company={item.companyName}
                        type={item.employmentType}
                        buttonLabel={t('preview')}
                        onJobClick={() => handleJobSelection(index)}
                        socialFields={{ items: socialItems }}
                        icons={mediaIcons}
                        lang={sitecoreContext?.language}
                        seoJobShareUrl={seoJobShareUrl}
                        jobCategory={jobCategory}
                        isCompanySearch={false}
                        index={index}
                        domain={item.domain}
                        showJobDesc={!isNewLayout}
                      />
                    </div>
                  );
                })}
            </div>
            {/* <div className="see-all-jobs-button">
              <ButtonLink
                ctaText={t('seo-all-job-results')}
                ctaUrl={seeAllJobs}
                arrow
              />
            </div> */}
          </div>
        ) : (
          <div className="container">
            <div className="no-jobs-ribbon col-md-12'">
              <div className="no-job-results">
                <div className="no-job-search-result-card ">
                  <p className="no-job-desc">
                    <RichText
                      // tag="p"
                      // className="no-job-desc"
                      field={{
                        value: zeroSearchResultsText,
                      }}
                    />
                  </p>
                </div>
                <Button
                  text={t('seo-search-jobs')}
                  handleButtonClick={handleSeeAllJobs}
                  cssClass="seo-see-all"
                />
              </div>
            </div>
          </div>
        )}
      </section>
      {isNewLayout && totalJobResults > jobItemPerPage && jobs && jobs?.length > 0 && (
        <section className="search-global-pagination search-global-pagination-job">
          <div className="search-pagination-wrap">
            <Pagination
              totalRecords={totalJobResults}
              pageLimit={jobItemPerPage}
              onPageChanged={handlePageChange}
              selectedPage={currentPage}
              showPrevNext={true}
              pageNeighbours={1}
              searchUrl={seoPageUrl}
              isSeoPage={true}
              canonical={seoCanonicalUrlForPagination}
            ></Pagination>
          </div>
          <SnackBar isActive={isActive} message={message} />
        </section>
      )}
    </>
  );
};

SEOJobResults.defaultProps = {
  items: [],
  fields: {},
  t: () => {},
  sitecoreContext: {},
};

SEOJobResults.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields: PropTypes.shape({}),
  t: PropTypes.func,
  sitecoreContext: PropTypes.shape({}),
};

export default withTranslation()(withSitecoreContext()(withRouter(SEOJobResults)));
