/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';

import Button from '../../globals/buttons/Button';
import Textbox from '../../globals/forms/Textbox';
import TextboxComplex from '../../globals/forms/TextboxComplex';
import LocationSearch from '../../globals/forms/LocationSearch';
import {
  isNullorEmpty,
  isObjNotEmpty,
  getSearchURLParamsUpdated,
} from '../../../../utils/helperUtils';
import { saveSelectedLocation } from '../../../../redux/actions';
import { STRING_VALUES } from '../../../../constants';
import Checkbox from '../../../core/globals/forms/Checkbox';

/**
 *
 * @param {*} props -props
 * @return {object} -action
 */
const FormElements = (props) => {
  const {
    register,
    control,
    locationSelected,
    countryCode,
    isMyPath,
    countryName,
  } = props;
  const { errors, searchLocation, onLocChange, t } = props;
  const { searchCareerTextPlaceHolder, searchLocationPlaceholder } = props;
  const { getValues, brandName, regexValidationPattern } = props;
  const isRemoteCheckAvail = t('check-remote');
  const isSweden = countryName?.toLowerCase().indexOf('sweden') > -1;
  let styleTextbox = 'col-lg-5';
  let styleController = 'col-lg-4';
  if (isMyPath === 'True') {
    styleTextbox = 'col-lg-6';
    styleController = 'col-lg-3';
  }

  /**
   *
   * @param {*} careerSearchLoc - career search location
   * @return {object} -action
   */
  const validateLocation = (careerSearchLoc) => {
    return !(
      careerSearchLoc &&
      (!isObjNotEmpty(searchLocation) ||
        (isObjNotEmpty(searchLocation) && searchLocation.place !== careerSearchLoc))
    );
  };

  return (
    <Fragment>
      <div className={`${styleTextbox}`}>
        {brandName === STRING_VALUES.MANPOWER ? (
          <TextboxComplex
            id="career-search-text"
            isSearchEnabled={false}
            name="careerSearchKey"
            register={register({
              pattern: regexValidationPattern,
            })}
            errorMsg={
              errors && errors.careerSearchKey && t('search-validation-message')
            }
            value={getValues('careerSearchKey')}
            showOrgVal
            label={searchCareerTextPlaceHolder}
          />
        ) : (
          <Textbox
            id="career-search-text"
            placeholder={searchCareerTextPlaceHolder}
            customProps={{
              ref: register({
                pattern: regexValidationPattern,
              }),
              name: 'careerSearchKey',
            }}
            errorMsg={
              errors && errors.careerSearchKey && t('search-validation-message')
            }
          />
        )}
      </div>

      <div className={`${styleController}`}>
        <Controller
          as={LocationSearch}
          control={control}
          onLocationSelect={locationSelected}
          region={countryCode}
          errorMessage={t('invalid-location')}
          {...(brandName === STRING_VALUES.MANPOWER
            ? { label: searchLocationPlaceholder }
            : { placeholder: searchLocationPlaceholder })}
          id="career-search-location"
          name="careerSearchLoc"
          disableEnterSubmit={true}
          rules={{ validate: validateLocation }}
          refVal={register}
          showError={errors?.careerSearchLoc}
          selectedLocation={searchLocation}
          changeHandler={onLocChange}
          showCurrentLocation={true}
        />
      </div>

      {isSweden &&
        !isNullorEmpty(isRemoteCheckAvail) &&
        isRemoteCheckAvail.indexOf('check-remote') == -1 && (
          <div className={`${styleController} remote-jobs`}>
            <Checkbox
              id="job_application_consent"
              name="remotejobConsent"
              text={isRemoteCheckAvail}
              required={false}
              register={register}
            />
          </div>
        )}
    </Fragment>
  );
};

FormElements.defaultProps = {
  isMyPath: '',
  searchCareerTextPlaceHolder: '',
  searchLocationPlaceholder: '',
  brandName: STRING_VALUES.MANPOWER,
};

FormElements.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  locationSelected: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  isMyPath: PropTypes.string,
  errors: PropTypes.shape({}).isRequired,
  searchLocation: PropTypes.shape({
    place: PropTypes.string,
  }).isRequired,
  onLocChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  searchCareerTextPlaceHolder: PropTypes.string,
  searchLocationPlaceholder: PropTypes.string,
  getValues: PropTypes.func.isRequired,
  brandName: PropTypes.string,
};

/**
 * @param {*}  - object
 * @return {object} -action
 */
const JobsForm = ({
  t,
  sitecoreContext,
  locationState,
  dispatch,
  ctaText,
  isMyPath,
  searchCareerTextPlaceHolder,
  searchLocationPlaceholder,
}) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const [searchLocation, setSrLocation] = useState({});
  const { jobSearchURL, brandName, regExPattern, name } =
    sitecoreContext?.Country || {};
  const url = jobSearchURL || '';
  const regexValidationPattern = new RegExp(regExPattern || '');
  const brandNameValue = brandName?.toLowerCase();
  const urlVal = url.split('?')[0];
  let buttonCssClass = 'reversed';
  if (isMyPath === 'True') {
    buttonCssClass = '';
  }
  if (!searchCareerTextPlaceHolder) {
    searchCareerTextPlaceHolder = t('job-title-industry-skill');
  }
  if (!searchLocationPlaceholder) {
    searchLocationPlaceholder = t('search-location-header');
  }

  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      careerSearchLoc: searchLocation?.place,
    },
  });

  useMemo(() => {
    if (isObjNotEmpty(locationState)) {
      setValue('careerSearchLoc', locationState.place);
      setSrLocation(locationState);
    }
  }, [locationState]);

  /**
   * @description function called followed by place selection
   * @param {object} locVal  - geometry of location
   * @returns {undefined}
   */
  const locationSelected = (locVal) => {
    if (locVal) {
      setSrLocation(locVal);
      setValue('careerSearchLoc', locVal.place);
      clearErrors('careerSearchLoc');
    } else {
      setSrLocation({});
    }
  };

  /**
   * @description - On location change handler.
   * @param {object} value - Form values.
   * @returns {undefined}
   */
  const onLocChange = (value) => {
    setValue('careerSearchLoc', value);
    if (value === '') {
      setSrLocation({});
      clearErrors('careerSearchLoc');
    }
  };

  /**
   * @description - On submit handler.
   * @param {object} values - Form values.
   * @returns {undefined}
   */
  const onSubmit = (values) => {
    let filterURLParam = '';
    const filterobj = {};
    const ids = {};
    const { remotejobConsent } = values;
    const { careerSearchKey } = values;
    const finalParams = getSearchURLParamsUpdated(
      params,
      careerSearchKey,
      searchLocation
    );
    const URlParams = decodeURIComponent(finalParams);
    if (isObjNotEmpty(searchLocation)) {
      dispatch(saveSelectedLocation(searchLocation));
    }

    if (remotejobConsent) {
      filterobj['remoteJobs'] = t('remote-filter');
      filterobj['sf'] = t('remote-filter').toLowerCase();
      ids['remoteJobs'] = [t('remote-filterkeyid')];
    }

    for (const key in filterobj) {
      if (filterURLParam != '') {
        filterURLParam += '&';
      }
      filterURLParam += key + '=' + encodeURIComponent(filterobj[key]);
    }
    const filterURLLink = filterURLParam + '&ids=' + JSON.stringify(ids);

    history.push({
      pathname: urlVal,
      search: remotejobConsent
        ? String(`${URlParams}&${decodeURIComponent(filterURLLink)}`)
        : String(decodeURIComponent(finalParams)),
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-fields">
        <div className="row">
          <FormElements
            searchCareerTextPlaceHolder={searchCareerTextPlaceHolder}
            searchLocationPlaceholder={searchLocationPlaceholder}
            register={register}
            control={control}
            locationSelected={locationSelected}
            countryCode={sitecoreContext?.Country?.code}
            errors={errors}
            searchLocation={searchLocation}
            onLocChange={onLocChange}
            t={t}
            isMyPath={isMyPath}
            getValues={getValues}
            brandName={brandNameValue}
            regexValidationPattern={regexValidationPattern}
            countryName={name}
          />
          <div className="col-lg-3">
            <div className="cta">
              <Button
                cssClass={`${buttonCssClass}`}
                text={ctaText}
                search
                handleButtonClick={handleSubmit(onSubmit)}
                typeProp={{ type: 'submit' }}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

JobsForm.defaultProps = {
  ctaText: 'Find jobs',
  isMyPath: '',
  t: (val) => val,
  searchCareerTextPlaceHolder: '',
  searchLocationPlaceholder: '',
};

JobsForm.propTypes = {
  searchCareerTextPlaceHolder: PropTypes.string,
  searchLocationPlaceholder: PropTypes.string,
  ctaText: PropTypes.string,
  isMyPath: PropTypes.string,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
  locationState: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    place: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.shape({}).isRequired,
  ctaText: PropTypes.shape({}),
};

/**
 * @description Mapping the state to props.
 * @param {object} state - state.
 * @returns {object} - Slice of state.
 */
const mapStateToProps = (state) => {
  return {
    locationState: state.jobLocationReducer.location,
  };
};

export default connect(mapStateToProps)(
  withSitecoreContext()(withTranslation()(JobsForm))
);
