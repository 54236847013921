import gql from 'graphql-tag';

export const GET_PARTNER_ARTICLES = gql`
  query GetPartnerArticleCardBlocks(
    $first: Int
    $after: String!
    $updateType: String!
  ) {
    cards: customSearch(
      first: $first
      after: $after
      sort: { field: "date", dir: DESC }
      fieldsEqual: [
        { name: "_template", value: "8405a564d5ae40eeb34554852fca854c" }
        { name: "updatetype_sm", value: $updateType }
      ]
    ) {
      results {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        items {
          item {
            ... on PartnerArticle {
              url
              date {
                jss
              }
              headline {
                value
              }
            }
          }
        }
      }
    }
  }
`;
