import React from 'react';
import PropTypes from 'prop-types';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import ConnectorButtonLink from '../../globals/buttons/ConnectorButtonLink';
import { setAltUrlForImg } from '../../../../utils/helperUtils';

/**
 * @description - Connector Card Component
 * @param {*} param0 - component props
 * @returns {noed} -  html
 */
const CardConnector = ({ title, text, img, altText, ctaText, ctaUrl }) => (
  <div
    className="card connector"
    role="article"
    aria-labelledby="title"
    aria-describedby="info"
  >
    <div className="card-image">
      <Image field={setAltUrlForImg(img, altText)} />
    </div>
    <div className="card-body">
      <h2 className="title" id="title">
        <Text field={title} />
      </h2>
      <div className="text" id="info">
        <p className="large">
          <Text field={text} />
        </p>
      </div>
      <div className="cta">
        <ConnectorButtonLink ctaText={ctaText} ctaUrl={ctaUrl} cssClass="blue" />
      </div>
    </div>
  </div>
);

CardConnector.defaultProps = {
  title: '',
  text: '',
  img: {},
  altText: '',
  ctaText: 'Read more',
  ctaUrl: '#',
};

CardConnector.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.shape({}),
  altText: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
};

export default CardConnector;
