/* eslint-disable */
import { GTM } from '../../../src/constants';

const enums = {
  AUTHENTICATED: 'Authenticated',
  UNAUTHENTICATED: 'Non-authenticated',
};

const hasProfile = (responseData, loginState) => {
  return (
    !responseData?.hasResume &&
    !responseData?.hasExperience &&
    !responseData?.hasEducation &&
    loginState === 'AUTHENTICATED'
  );
};

const getExpOrEdu = (responseData, t) => {
  return `${responseData?.hasExperience ? t('Experience') : t('No Experience')} | ${
    responseData?.hasEducation ? t('Education') : t('No Education')
  }`;
};

export const jobApplyEvent = (
  applicationId,
  context,
  data = {},
  loginState,
  responseData = {},
  t,
  isStandardJob
) => {
  const revenuePerApplication = context?.Country?.revenuePerApplication;
  const isNorway = context?.language?.toLowerCase() === 'nb';
  const isPublicJob = data?.jobOfferType?.toLowerCase() === 'public';
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GTM.EVENTS.JOB_APPLICATION_SUCCESS,
      ecommerce: {
        purchase: {
          actionField: {
            id: applicationId,
            revenue: revenuePerApplication || '',
          },
          products: [
            {
              name: data?.jobTitle ? data?.jobTitle : 'na',
              id: data?.jobId ? data?.jobId : 'na',
              price: revenuePerApplication || 'na',
              brand: data?.addtionalForDataLayer?.companyName
                ? data?.addtionalForDataLayer?.companyName
                : data?.companyName
                ? data?.companyName
                : 'na',
              category: data?.domain ? data?.domain : 'na',
              variant: data?.employmentType ? data?.employmentType : 'na',
              quantity: 1,
              dimension11: `Location: ${data?.jobLocation || 'na'} | Education: ${
                data?.addtionalForDataLayer?.education
                  ? data?.addtionalForDataLayer?.education.join(', ')
                  : data?.education
                  ? data?.education.join(', ')
                  : 'na'
              } | Salary: ${
                data?.salaryRate
                  ? data?.salaryRate
                  : data?.salaryUnit
                  ? data?.salaryUnit
                  : 'na'
              } | Hours: ${data?.JobType ? data?.JobType : 'na'}`,

              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: `${isNorway ?
                (isPublicJob && isStandardJob)
                  ? 'standard'
                  : (isPublicJob && !isStandardJob)
                    ? 'quick apply'
                    : (!isPublicJob && isStandardJob)
                      ? 'private job | standard'
                      : 'private job | quick apply'
                : data?.SpontaneousapplicationType
                  ? data?.SpontaneousapplicationType
                  : enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
                    ? 'quick apply'
                    : 'standard'
                }`,
              dimension14: hasProfile(responseData, loginState)
                ? t('Existing Profile')
                : responseData?.hasResume || responseData?.hasAttachments
                ? t('With resume')
                : t('Without resume'),
              dimension15: hasProfile(responseData, loginState)
                ? t('Existing Profile')
                : getExpOrEdu(responseData, t),
              dimension16: data?.clientID ? data.clientID : 'na',
              dimension17: data?.jobLocation ? data?.jobLocation : 'na',
              dimension18: data?.addtionalForDataLayer?.education
                ? data?.addtionalForDataLayer?.education.join(', ')
                : data?.education
                ? data?.education.join(', ')
                : 'na',
              dimension19: data?.salaryRate ? data?.salaryRate : 'na',
              dimension20: data?.JobType ? data?.JobType : 'na',
            },
          ],
        },
      },
    });
  }
};

export const jobResultsEvent = (jobs = [], context, isCompanySearch, loginState) => {
  const revenue = context?.Country?.revenuePerApplication;
  const isNorway = context?.language?.toLowerCase() === 'nb';
  if (window?.dataLayer) {
    const impressions = [];
    jobs?.forEach((job, index) => {
      impressions.push({
        name: job.jobTitle ? job.jobTitle : 'na',
        id: job.jobID || job.positionID || 'na',
        price: revenue || 'na',
        brand: job?.addtionalForDataLayer?.companyName
          ? job?.addtionalForDataLayer?.companyName
          : job?.companyName
          ? job?.companyName
          : 'na',
        category: job.domain ? job.domain : 'na',
        variant: job.employmentType ? job.employmentType : 'na',
        list: isCompanySearch ? 'Vacancies by employer' : 'Search Results',
        position: index + 1,
        dimension11: `Location: ${job?.jobLocation || 'na'} | Education: ${
          job?.addtionalForDataLayer?.education
            ? job?.addtionalForDataLayer?.education.join(', ')
            : job?.education
            ? job?.education.join(', ')
            : 'na'
        } | Salary: ${job?.salaryRate ? job?.salaryRate : 'na'} | Hours: ${
          job?.jobType ? job?.jobType : 'na'
        }`,
        dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
        dimension13: `${  
          isNorway ?
           (job?.jobOfferType) ?
              (job?.jobOfferType.toLowerCase()==='private')
                ?'private job | na'
              : 'na'
            : 'na'
          :enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
            ? 'quick apply'
            : 'standard'
        }`,
        dimension14: 'na',
        dimension15: 'na',
        dimension16: job?.clientID ? job?.clientID : 'na',
        dimension17: job?.jobLocation ? job?.jobLocation : 'na',
        dimension18: job?.addtionalForDataLayer?.education
          ? job?.addtionalForDataLayer?.education.join(', ')
          : job?.education
          ? job?.education.join(', ')
          : 'na',
        dimension19: job?.salaryRate ? job?.salaryRate : 'na',
        dimension20: job?.jobType ? job?.jobType : 'na',
      });
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: isCompanySearch
        ? GTM.EVENTS.SEO_JOB_SEARCH_RESULTS
        : GTM.EVENTS.JOB_SEARCH_RESULTS,
      ecommerce: {
        impressions: impressions,
      },
    });
  }
};

export const seojobResultsEvent = (jobs = [], context, loginState, jobCategory) => {
  const revenue = context?.Country?.revenuePerApplication;
  const isNorway = context?.language?.toLowerCase() === 'nb';
  if (window?.dataLayer) {
    const impressions = [];
    jobs?.forEach((job, index) => {
      impressions.push({
        name: job.jobTitle ? job.jobTitle : 'na',
        id: job.jobID || job.positionID || 'na',
        price: revenue || 'na',
        brand: job?.addtionalForDataLayer?.companyName
          ? job?.addtionalForDataLayer?.companyName
          : job?.companyName
          ? job?.companyName
          : 'na',
        category: job.domain ? job.domain : 'na',
        variant: job.employmentType ? job.employmentType : 'na',
        list: `${jobCategory ? `Vacancies by ${jobCategory}` : 'Vacancies'}`,
        position: index + 1,
        dimension11: `Location: ${job?.jobLocation || 'na'} | Education: ${
          job?.addtionalForDataLayer?.education
            ? job?.addtionalForDataLayer?.education.join(', ')
            : job?.education
            ? job?.education.join(', ')
            : 'na'
        } | Salary: ${job?.salaryRate ? job?.salaryRate : 'na'} | Hours: ${
          job?.jobType ? job?.jobType : 'na'
        }`,
        dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
        dimension13: `${  
          isNorway ?
           (job?.jobOfferType) ?
              (job?.jobOfferType.toLowerCase()==='private')
                ?'private job | na'
              : 'na'
            : 'na'
          :enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
            ? 'quick apply'
            : 'standard'
        }`,
        dimension14: 'na',
        dimension15: 'na',
        dimension16: job?.clientID ? job?.clientID : 'na',
        dimension17: job?.jobLocation ? job?.jobLocation : 'na',
        dimension18: job?.addtionalForDataLayer?.education
          ? job?.addtionalForDataLayer?.education.join(', ')
          : job?.education
          ? job?.education.join(', ')
          : 'na',
        dimension19: job?.salaryRate ? job?.salaryRate : 'na',
        dimension20: job?.jobType ? job?.jobType : 'na',
      });
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GTM.EVENTS.SEO_JOB_SEARCH_RESULTS,
      ecommerce: {
        impressions: impressions,
      },
    });
  }
};

export const jobVacancyEvent = (eventName, job, context, loginState) => {
  const revenue = context?.Country?.revenuePerApplication;
  const isNorway = context?.language?.toLowerCase() === 'nb';
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: eventName,
      pageVariant: job?.jobItemType ? job?.jobItemType : 'na',
      ecommerce: {
        detail: {
          products: [
            {
              name: job?.jobTitle ? job?.jobTitle : 'na',
              id: job?.jobID || job?.positionID || 'na',
              price: revenue || 'na',
              brand: job?.addtionalForDataLayer?.companyName
                ? job?.addtionalForDataLayer?.companyName
                : job?.companyName
                ? job?.companyName
                : 'na',
              category: job?.domain ? job?.domain : 'na',
              variant: job?.employmentType ? job?.employmentType : 'na',
              quantity: 1,
              dimension11: `Location: ${job?.jobLocation || 'na'} | Education: ${
                job?.addtionalForDataLayer?.education
                  ? job?.addtionalForDataLayer?.education.join(', ')
                  : job?.education
                  ? job?.education.join(', ')
                  : 'na'
              } | Salary: ${job?.salaryRate ? job?.salaryRate : 'na'} | Hours: ${
                job?.jobType ? job?.jobType : 'na'
              }`,
              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: `${  
                isNorway ?
                 (job?.jobOfferType) ?
                    (job?.jobOfferType.toLowerCase()==='private')
                      ?'private job | na'
                    : 'na'
                  : 'na'
                :enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
                  ? 'quick apply'
                  : 'standard'
              }`,
              dimension14: 'na',
              dimension15: 'na',
              dimension16: job?.clientID ? job?.clientID : 'na',
              dimension17: job?.jobLocation ? job?.jobLocation : 'na',
              dimension18: job?.addtionalForDataLayer?.education
                ? job?.addtionalForDataLayer?.education.join(', ')
                : job?.education
                ? job?.education.join(', ')
                : 'na',
              dimension19: job?.salaryRate ? job?.salaryRate : 'na',
              dimension20: job?.jobType ? job?.jobType : 'na',
            },
          ],
        },
      },
    });
  }
};

export const searchResultClickEvent = (
  eventName,
  job,
  context,
  loginState,
  jobCategory,
  isCompanySearch,
  index
) => {
  const revenue = context?.Country?.revenuePerApplication;
  const isNorway = context?.language?.toLowerCase() === 'nb';
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: eventName,
      ecommerce: {
        click: {
          actionField: {
            list: `${
              jobCategory
                ? `Vacancies by ${jobCategory}`
                : `${isCompanySearch ? `Vacancies by employer` : `Search Results`}`
            }`,
          },
          products: [
            {
              name: job?.jobTitle ? job?.jobTitle : 'na',
              id: job?.jobID || job?.positionID || 'na',
              price: revenue || 'na',
              brand: job?.addtionalForDataLayer?.companyName
                ? job?.addtionalForDataLayer?.companyName
                : job?.companyName
                ? job?.companyName
                : 'na',
              category: job?.domain ? job?.domain : 'na',
              variant: job?.employmentType ? job?.employmentType : 'na',
              quantity: 1,
              position: index + 1,
              dimension11: `Location: ${job?.jobLocation || 'na'} | Education: ${
                job?.addtionalForDataLayer?.education
                  ? job?.addtionalForDataLayer?.education.join(', ')
                  : job?.education
                  ? job?.education.join(', ')
                  : 'na'
              } | Salary: ${job?.salaryRate ? job?.salaryRate : 'na'} | Hours: ${
                job?.jobType ? job?.jobType : 'na'
              }`,
              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: `${  
                isNorway ?
                 (job?.jobOfferType) ?
                    (job?.jobOfferType.toLowerCase()==='private')
                      ?'private job | na'
                    : 'na'
                  : 'na'
                :enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
                  ? 'quick apply'
                  : 'standard'
              }`,
              dimension14: 'na',
              dimension15: 'na',
              dimension16: job?.clientID ? job?.clientID : 'na',
              dimension17: job?.jobLocation ? job?.jobLocation : 'na',
              dimension18: job?.addtionalForDataLayer?.education
                ? job?.addtionalForDataLayer?.education.join(', ')
                : job?.education
                ? job?.education.join(', ')
                : 'na',
              dimension19: job?.salaryRate ? job?.salaryRate : 'na',
              dimension20: job?.jobType ? job?.jobType : 'na',
            },
          ],
        },
      },
    });
  }
};

export const preJobApplyEvent = (jobStorageDetails, context, loginState, isStandardJob = false) => {
  const revenue = context?.Country?.revenuePerApplication;
  const isNorway = context?.language?.toLowerCase() === 'nb';
  const isPublicJob = jobStorageDetails?.jobOfferType?.toLowerCase() === 'public'
  if (window?.dataLayer) {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: GTM.EVENTS.JOB_APPLICATION,
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          products: [
            {
              name: jobStorageDetails?.jobTitle ? jobStorageDetails?.jobTitle : 'na',
              id: jobStorageDetails?.jobId ? jobStorageDetails?.jobId : 'na',
              price: revenue || 'na',
              brand: jobStorageDetails?.addtionalForDataLayer?.companyName
                ? jobStorageDetails?.addtionalForDataLayer?.companyName
                : jobStorageDetails?.companyName
                ? jobStorageDetails?.companyName
                : 'na',
              category: jobStorageDetails?.domain ? jobStorageDetails?.domain : 'na',
              variant: jobStorageDetails?.employmentType
                ? jobStorageDetails?.employmentType
                : 'na',
              quantity: 1,
              dimension11: `Location: ${
                jobStorageDetails?.jobLocation || 'na'
              } | Education: ${
                jobStorageDetails?.addtionalForDataLayer?.education
                  ? jobStorageDetails?.addtionalForDataLayer?.education.join(', ')
                  : jobStorageDetails?.education
                  ? jobStorageDetails?.education.join(', ')
                  : 'na'
              } | Salary: ${
                jobStorageDetails?.salaryRate
                  ? jobStorageDetails?.salaryRate
                  : jobStorageDetails?.salaryUnit
                  ? jobStorageDetails?.salaryUnit
                  : 'na'
              } | Hours: ${
                jobStorageDetails?.JobType ? jobStorageDetails?.JobType : 'na'
              }`,
              dimension12: `${enums?.[loginState || 'UNAUTHENTICATED']}`,
              dimension13: `${
                isNorway ?
                (isPublicJob && isStandardJob)
                  ? 'standard'
                  : (isPublicJob && !isStandardJob)
                    ? 'quick apply'
                    : (!isPublicJob && isStandardJob)
                      ? 'private job | standard'
                      : 'private job | quick apply'
                : jobStorageDetails?.SpontaneousapplicationType
                  ? jobStorageDetails?.SpontaneousapplicationType
                  : enums?.[loginState || 'UNAUTHENTICATED'] === 'Non-authenticated'
                    ? 'quick apply'
                    : 'standard'
                }`,
              dimension14: 'na',
              dimension15: 'na',
              dimension16: jobStorageDetails?.clientID
                ? jobStorageDetails?.clientID
                : 'na',
              dimension17: jobStorageDetails?.jobLocation
                ? jobStorageDetails?.jobLocation
                : 'na',
              dimension18: jobStorageDetails?.addtionalForDataLayer?.education
                ? jobStorageDetails?.addtionalForDataLayer?.education.join(', ')
                : jobStorageDetails?.education
                ? jobStorageDetails?.education.join(', ')
                : 'na',
              dimension19: jobStorageDetails?.salaryRate
                ? jobStorageDetails?.salaryRate
                : 'na',
              dimension20: jobStorageDetails?.JobType
                ? jobStorageDetails?.JobType
                : 'na',
            },
          ],
        },
      },
    });
  }
};