import React from 'react';
import PropTypes from 'prop-types';
import { isNullorEmpty } from '../../../../src/utils/helperUtils';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - Component.
 * @param {Object} props - Props passed.
 * @returns {Node} - Element.
 */
const YoutubePlayer = (props) => {
  const { playerid, videoUrl, videoPreviewImg, title, videoDuration, color } = props;
  const yt_url = `https://www.youtube.com/embed/${videoUrl}`;

  return (
    <>
      <a
        id={playerid}
        className="video__link fancybox fancybox.iframe"
        data-fancybox="myvid"
        data-options='{"smallBtn": true, "toolbar": false}'
        href={yt_url}
      >
        <Image className="video__preview" editable={true} field={videoPreviewImg} />
        {!isNullorEmpty(videoDuration) && videoDuration.value !== '' && (
          <span className="video__duration">
            <Text field={videoDuration} />
          </span>
        )}
      </a>
      {!isNullorEmpty(title) && title.value !== '' && (
        <span className="video__title" style={{ color: color }}>
          <Text field={title} />
        </span>
      )}
    </>
  );
};

YoutubePlayer.defaultProps = {
  title: '',
  playerid: '',
  videoDuration: '',
  videoUrl: '',
  videoPreviewImg: '',
  color: '',
};

YoutubePlayer.propTypes = {
  title: PropTypes.string.isRequired,
  playerid: PropTypes.string.isRequired,
  videoPreviewImg: PropTypes.string.isRequired,
  videoDuration: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default YoutubePlayer;
