import React from 'react';
import PropTypes from 'prop-types';
import CardEvent from '../../Cards/CardEvent';
import { withTranslation } from 'react-i18next';
import NoData from '../../ErrorBoundary/NoData';
import Slider from 'react-slick';
import { isDataExists, removeSlideTab } from '../../../../utils/helperUtils';
import '../cardscroll.scss';

/**
 * @description Event Card Scroll
 */
class EventsCardScroll extends React.Component {
  /**
   * @description constructor method
   * @param {object} props -inputprops
   */
  constructor(props) {
    super(props);
  }

  /**
   * @description - Remove tab index from slider once mounted.
   * @returns {undefined}
   */
  componentDidMount() {
    const { rendering } = this.props;
    removeSlideTab(rendering?.uid);
  }

  /**
   * @description - descrition
   * @param{object} - input
   * @returns{node}- html block
   */
  render() {
    const {
      fields,
      t,
      sitecoreContext,
      rendering: { uid },
    } = this.props;
    const { items = [], backgroundColor, labelColor } = fields;
    const isItemExists = isDataExists(items);
    const color_codes = backgroundColor?.value?.split(',');
    let card_background = '';
    let card_style = 'blue';
    const brandName =
      sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
    if (brandName === 'manpower') {
      card_style = 'generic';
      if (color_codes?.length === 1) {
        card_background = color_codes ? color_codes[0] : '';
      } else {
        card_background = `linear-gradient(267deg, ${
          color_codes ? color_codes[1] : ''
        }, ${color_codes ? color_codes[0] : ''})`;
      }
    }
    /**
     * @description Slide load hook.
     * @returns {undefined}
     */
    const slideLoadHook = () => {
      if (typeof window !== 'undefined') {
        document.querySelectorAll(`.scroll_${uid} .slick-slide`).forEach((node) => {
          if (node.className.includes('slick-active')) {
            node.setAttribute('aria-hidden', 'false');
          } else {
            node.setAttribute('aria-hidden', 'true');
          }
        });
      }
    };
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: false,
      lazyLoad: true,
      accessibility: true,
      arrows: true,
      onLazyLoad: () => slideLoadHook(),
      afterChange: () => slideLoadHook(),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 564,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section
        className={`card-scroll ${card_style} scroll_${uid}`}
        style={
          brandName === 'manpower'
            ? { background: `${card_background}` }
            : { background: 'none' }
        }
      >
        <div className="container">
          <div className="card-scroll-header">
            <h2
              className="title"
              style={
                brandName === 'manpower'
                  ? { color: `${labelColor ? labelColor.value : '#FFFFFF'}` }
                  : { color: 'none' }
              }
            >
              {t('more-events')}
            </h2>
          </div>

          {isItemExists ? (
            <Slider {...settings}>
              {items.map((item, ind) => {
                const itemVal = item?.fields || {};
                return (
                  <CardEvent
                    key={`cardEvent_${ind}`}
                    headline={itemVal?.headline}
                    startDate={itemVal?.startDate}
                    ctaText={t('lorem-ipsum')}
                    altText={`Card Event ${ind}`}
                    ctaUrl={itemVal?.url}
                  />
                );
              })}
            </Slider>
          ) : (
            <NoData message={'No Data'} />
          )}
        </div>
      </section>
    );
  }
}

EventsCardScroll.defaultProps = {
  fields: {
    items: [],
  },
  sitecoreContext: {},
  backgroundColor: {},
  labelColor: {
    value: '',
  },
};

EventsCardScroll.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    backgroundColor: PropTypes.shape({}),
    labelColor: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  sitecoreContext: PropTypes.shape({}),
  rendering: PropTypes.shape({ uid: PropTypes.string }).isRequired,
};

export default withTranslation()(EventsCardScroll);
