import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';

import TextLink from '../../globals/link/TextLink';

import '../locations.scss';

/**
 *
 * @param {string} title - office name
 * @param {string} address - office address
 * @param {string} hours - office hours
 * @param {string} phone - office phone
 * @param {string} hoursLabel - office hours label
 * @param {string} phoneLabel - office phone label
 * @param {string} ctaText - view details text
 * @param {object} ctaUrl - view details link
 * @returns {node} - html
 */
const LocationsNearbyItem = ({
  title,
  address,
  hours,
  phone,
  hoursLabel,
  phoneLabel,
  ctaText,
  ctaUrl,
}) => (
  <div className="near-location-item" role="listitem">
    <div className="location-title" aria-label={`location ${title.value}`}>
      {title && <Text field={title} />}
    </div>
    <div className="row">
      <div className="col-md-4" aria-label={`address ${address.value}`}>
        {address && <Text field={address} />}
      </div>
      <div className="col-md-4 col-6">
        <strong>{hoursLabel}</strong>
        <br aria-hidden={true} />
        {hours && <Text field={{ value: hours.value.split('\n')[0] }} />}
        <br aria-hidden={true} />
        {hours && <Text field={{ value: hours.value.split('\n')[1] }} />}
      </div>
      <div className="col-md-4 col-6 phone" aria-label="phone">
        <strong>{phoneLabel}</strong>
        <br aria-hidden={true} />
        <a
          href={`tel:${phone.value}`}
          aria-label="Phone number opens in a new window/tab"
        >
          {phone && <Text field={phone} />}
        </a>
      </div>
      <div className="cta">
        <TextLink ctaText={ctaText} ctaUrl={ctaUrl} arrow />
      </div>
    </div>
  </div>
);

LocationsNearbyItem.defaultProps = {
  title: '',
  address: '',
  phone: '',
  hours: '',
  hoursLabel: '',
  phoneLabel: '',
  ctaText: '',
  ctaUrl: {
    value: {
      href: '#',
    },
  },
};

LocationsNearbyItem.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
  hoursLabel: PropTypes.string.isRequired,
  phoneLabel: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.shape({
    value: PropTypes.shape({
      href: PropTypes.string.isRequired,
    }),
  }),
};

export default LocationsNearbyItem;
