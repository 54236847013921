import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Button from '../../core/globals/buttons/Button';
import ModalComponent from '../../core/Modal';
import { authstates } from '../../../utils/enums';
import { isNullorEmpty } from '../../../utils/helperUtils';
import { dataFetcher } from '../../../dataFetcher';
import { API_STATUS_CODES, CREATE_PROFILE } from '../../../constants';
import { toast } from 'react-toastify';
import { getCandidateDetailsApi } from '../../../services/apiServices/candidateService';
import { useSelector, useDispatch } from 'react-redux';
import { validateDNI } from '../../core/Forms/DynamicForm/helper/validationFactory';
import './nieNumberSpain.scss';

const NIENumberSpain = ({ t, sitecoreContext }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);
  const [nieNumberValue, setNieNumberValue] = useState('');
  const [isUserWithoutNIENumber, setIsUserWithoutNIENumber] = useState(false);
  const [loading, setLoading] = useState(false);
  const loginReducer = useSelector((state) => state.loginReducer);
  const loginState = loginReducer?.state;
  const authenticatedUser = loginState === authstates.AUTHENTICATED;
  const nieNumberCheck = isNullorEmpty(loginReducer?.user?.nie);
  const bhidNumberCheck = isNullorEmpty(loginReducer?.user?.bhid);
  const isSpain = sitecoreContext?.Country?.name?.toLowerCase() === 'spain';
  const sessionStorageExist =
    typeof sessionStorage !== 'undefined' &&
    sessionStorage.getItem &&
    sessionStorage.getItem('isNIENumberSaved') === null;

  useEffect(() => {
    if (
      sessionStorageExist &&
      authenticatedUser &&
      nieNumberCheck &&
      bhidNumberCheck &&
      isSpain
    ) {
      setIsUserWithoutNIENumber(true);
    }
  }, [loginReducer, sessionStorageExist]);

  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    revalidate: 'onBlur',
    shouldFocusError: true,
  });

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const onSubmit = (data) => {
    setLoading(true);
    const queryParams = {
      entity: 'all',
      forceUpdate: false,
      applicantNIE: data.nieNumber,
    };
    dataFetcher(
      `${CREATE_PROFILE.GET_CREATE_PROFILE.CANDIDATE_DETAILS}`,
      'GET',
      {},
      queryParams
    ).then(
      (response) => {
        if (response?.data?.status === API_STATUS_CODES.SUCCESS) {
          if (response?.data?.message === 'NIEAlreadyExist') {
            toast.error(t('nie-already-exist'), {
              position: 'top-center',
            });
            setLoading(false);
          } else {
            sessionStorage.setItem('isNIENumberSaved', JSON.stringify(true));
            toast.success(t('nie-saved-successfully'), {
              position: 'top-center',
            });
            getCandidateDetailsApi({}, dispatch);
            setLoading(false);
            setShowModal(false);
          }
        } else {
          toast.error(t('nie-save-error'), {
            position: 'top-center',
          });
          setLoading(false);
          setShowModal(false);
        }
      },
      () => {
        toast.error(t('nie-save-error'), {
          position: 'top-center',
        });
        setLoading(false);
        setShowModal(false);
      }
    );
  };

  const onInputChange = (e) => {
    setNieNumberValue(e?.target?.value);
    if (e?.target?.value !== '') {
      clearErrors('nieNumber');
    }
  };

  return (
    <>
      {isUserWithoutNIENumber && (
        <ModalComponent
          showModal={showModal}
          handleModal={handleModal}
          hideHeader={true}
          hideFooter={true}
          size="md"
          backdrop="static"
          keyboard={false}
        >
          <div className="inner-modal-container" key="body">
            <div className="nie-number container">
              <h2 className="title">{t('capture-nie-number-title')}</h2>
              <p className="sub-title">{t('capture-nie-additional-details')}</p>
              <div className="row">
                <div className="col-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div
                      className={`form-block complex ${
                        errors.nieNumber ? 'error withoutBg' : ''
                      }`}
                    >
                      <Controller
                        name="nieNumber"
                        control={control}
                        render={({ onChange, value }) => (
                          <>
                            <input
                              className={nieNumberValue ? 'filled' : ''}
                              id="nieNumber"
                              type="text"
                              value={value || null}
                              aria-label="NIE Number"
                              onChange={(e) => {
                                onInputChange(e);
                                onChange(e?.target?.value);
                              }}
                            />
                            <label htmlFor="nieNumber">
                              <div className="label-text">
                                {t('nie-number-placeholder')}
                              </div>
                            </label>
                          </>
                        )}
                        rules={{
                          required: t('nie-required-error'),
                          validate: {
                            dniValidationCheck: (value) =>
                              validateDNI(value) || t('nie-pattern-error'),
                          },
                        }}
                      />
                      {errors.nieNumber && (
                        <div className="error-msg">{errors.nieNumber.message}</div>
                      )}
                    </div>
                    <div className="save-btn text-center">
                      <Button
                        text={t('capture-nie-submit')}
                        type="submit"
                        isLoading={loading}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
    </>
  );
};

export default withSitecoreContext()(withTranslation()(NIENumberSpain));
