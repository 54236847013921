import React, { Fragment } from 'react';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import NavLink from '../../core/globals/NavLinks';
import { setAltUrlForImg, cookiePolicy } from '../../../utils/helperUtils';
import TextButton from '../globals/buttons/TextButton';

import './sitefooter.scss';
/**
 * @description - Site Footer Disclaimer section
 * @param {*} props - Input props.
 * @returns {Node} - HTML node.
 */
const SiteFooterDisclaimerSections = (props) => {
  const { fields } = props;
  const { items = [], copyrightText, text } = fields;
  let logoCount = 0;
  return (
    <div className="footer-disclaimer de-site-manpower">
      <div className="container">
        <div className="row">
          <div className="col">
            {items[0]?.fields?.items?.map((section, index) => {
              return (
                <div className="row icon-container" key={index}>
                  {section.fields?.items?.map((logoItem) => {
                    logoCount++;
                    return (
                      <div
                        className="col-lg-2 col-xl-2 col-md-3 col-sm-3"
                        key={logoItem?.id}
                      >
                        <span className="icon">
                          <NavLink
                            url={logoItem?.fields?.ctaUrl}
                            ariaLabel={
                              logoItem?.fields?.ctaUrl?.value?.target
                                ? `Open ${
                                    logoItem?.fields?.logo?.value?.alt ||
                                    `logo-${logoCount}`
                                  } in new tab`
                                : ''
                            }
                          >
                            <Image
                              field={setAltUrlForImg(
                                logoItem?.fields?.logo,
                                `logo-${logoCount}`
                              )}
                            />
                          </NavLink>
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <RichText tag="p" field={text} />
            <RichText tag="p" field={copyrightText} />
            <div className="footer-disclaimer-links" aria-label="Disclaimer Links">
              {items[1]?.fields?.items.map((linkItem, index) => (
                <Fragment key={index}>
                  {!linkItem?.fields?.isCookieSettingLink?.value ? (
                    <NavLink
                      url={linkItem?.fields?.ctaUrl}
                      ariaLabel={
                        linkItem?.fields?.ctaUrl?.value?.target
                          ? `Open ${linkItem?.fields?.ctaText?.value} in new window/tab`
                          : ''
                      }
                    >
                      <Text field={linkItem?.fields?.ctaText} />
                    </NavLink>
                  ) : (
                    <TextButton
                      handleTextClick={cookiePolicy}
                      text={linkItem?.fields?.ctaText?.value}
                    />
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SiteFooterDisclaimerSections.defaultProps = {
  fields: {},
};

SiteFooterDisclaimerSections.propTypes = {
  fields: PropTypes.shape({
    copyrightText: PropTypes.shape({}),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          items: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      })
    ),
    text: PropTypes.shape({}),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SiteFooterDisclaimerSections);
