export const COUNTRY_ISOS = new Map([
  ['US', { lat: 37.09024, lng: -95.712891 }],
  ['NO', { lat: 60.472024, lng: 8.468946 }],
  ['NL', { lat: 52.132633, lng: 5.291266 }],
  ['SE', { lat: 60.128161, lng: 18.643501 }],
  ['DE', { lat: 51.165691, lng: 10.451526 }],
  ['ES', { lat: 40.463667, lng: -3.74922 }],
  ['IN', { lat: 20.593684, lng: 78.96288 }],
  ['CA', { lat: 56.130366, lng: -106.346771 }],
  ['PL', { lat: 51.9194, lng: 19.1451 }],
  ['UK', { lat: 48.3794, lng: 31.1656 }],
]);
