/* eslint-disable */
import { JOB_DETAILS } from '../actions/actionTypes';

const initialState = {
  data: {},
  isRedirect: false,
};

const jobDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOB_DETAILS.SAVE:
      return {
        ...state,
        data: action.payload,
      };
    case JOB_DETAILS.CLEAR:
      return {
        ...state,
        data: {},
      };
    case JOB_DETAILS.PAGEREDIRECT:
      return {
        ...state,
        isRedirect: true,
      };
    default:
      return state;
  }
};
export default jobDetailsReducer;
