/*eslint-disable*/
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * @description - File upload Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const OneDriveComponent = ({ inputRef, ...props }) => {
  const { openFile } = props;

  const fileUpload = useRef();

  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      fileUpload.current.click();
    },
  }));
  return (
    <input
      className="odfp-button button"
      type="submit"
      onClick={openFile}
      ref={fileUpload}
    />
  );
};

OneDriveComponent.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired,
};

OneDriveComponent.defaultProps = {
  onSuccess: () => {},
  img: '',
};

export default forwardRef((props, ref) => {
  return <OneDriveComponent {...props} inputRef={ref} />;
});
