/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { getValidationDatabyType } from '../../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../../business/Utils/helper';
import {
  maximumAgeValidationCheck,
  minimumAgeValidationCheck,
  ageRangeValidationCheck,
  yearValidationCheck,
  validDate,
  createDateObject,
  currentDateValidator,
} from '../../DynamicForm/helper/validationFactory';

/**
 * @description - Dynamic DateInputField component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const DateInputField = ({
  id,
  name,
  label,
  requiredValidation = [],
  icon,
  customStyle,
  readOnly,
  customError,
  hintText,
  dataValidations,
  getValues,
  setValue,
  watch,
  showBasedOnFieldName,
  hideBasedOnFieldName,
  isHideField,
  value,
  validateOnLoad,
  formName,
  t,
  control,
  format,
  register,
}) => {
  const fieldError = get(customError, `${name}_hidden`);
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  const dateValidationData = getValidationDatabyType(dataValidations, 'date');
  const minimumAgeValidationData = getValidationDatabyType(
    dataValidations,
    'minimumAge'
  );
  const maximumAgeValidationData = getValidationDatabyType(
    dataValidations,
    'maximumAge'
  );
  const ageRangeValidationData = getValidationDatabyType(dataValidations, 'range');
  const yearValidationData = getValidationDatabyType(dataValidations, 'year');
  const currentDateValidationData = getValidationDatabyType(
    dataValidations,
    'currentDate'
  );
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;

  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    let newValue = null;
    if (value && validDate(value)) {
      newValue = moment(value).format(format);
    }
    setValue(`${name}_hidden`, newValue, param);
    setFormValue(newValue);
  }, [value]);

  const setFormValue = (val) => {
    val = createDateObject(val);
    let result = moment(val).format('YYYY-MM-DDTHH:mm:ss');
    setValue(name, result);
  };

  return (
    <div
      className={`form-block complex ${fieldError ? 'error withoutBg' : ''} ${
        !showField || hideField ? 'hide' : ''
      }`}
      style={customStyle}
    >
      <input
        type="hidden"
        name={name}
        id={`${id}_hidden`}
        ref={register ? register : () => {}}
      />
      <Controller
        name={`${name}_hidden`}
        control={control}
        render={({ onChange, onBlur, value }) => (
          <InputMask
            mask="99/99/9999"
            maskChar={null}
            alwaysShowMask={true}
            value={value || ''}
            onChange={(e) => {
              setFormValue(e.target.value);
              onChange(e.target.value);
            }}
            onBlur={onBlur}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                type="tel"
                className={`input ${
                  getValues && getValues(`${name}_hidden`) ? 'filled' : ''
                }`}
                id={id}
              />
            )}
          </InputMask>
        )}
        rules={{
          required:
            showField && requiredData?.fields?.value?.value ? !hideField : false,
          validate: {
            validDateCheck: (value) =>
              (dateValidationData?.id ? validDate(value) : true) ||
              dateValidationData?.fields?.message?.value ||
              t('date-validation-failed'),
            maximumAgeValidationCheck: (value) =>
              (maximumAgeValidationData?.id
                ? maximumAgeValidationCheck(value, maximumAgeValidationData, format)
                : true) ||
              maximumAgeValidationData?.fields?.message?.value ||
              t('age-minimum-validation-failed'),
            minimumAgeValidationCheck: (value) =>
              (minimumAgeValidationData?.id
                ? minimumAgeValidationCheck(value, minimumAgeValidationData, format)
                : true) ||
              minimumAgeValidationData?.fields?.message?.value ||
              t('age-maximum-validation-failed'),
            ageRangeValidationCheck: (value) =>
              (ageRangeValidationData?.id
                ? ageRangeValidationCheck(value, ageRangeValidationData, format)
                : true) ||
              ageRangeValidationData?.fields?.message?.value ||
              t('age-range-validation-failed'),
            yearValidationCheck: (value) =>
              (yearValidationData?.id
                ? yearValidationCheck(value, yearValidationData, format)
                : true) ||
              yearValidationData?.fields?.message?.value ||
              t('year-validation-failed'),
            currentDateValidator: (value) =>
              (currentDateValidationData?.id
                ? currentDateValidator(value, currentDateValidationData, format)
                : true) ||
              currentDateValidationData?.fields?.message?.value ||
              t('current-date-validation-failed'),
          },
        }}
      />
      {label && (
        <label htmlFor={id}>
          <div className="label-text">
            {icon && (
              <div className="input-icon">
                <span className="input-icon"></span>
              </div>
            )}
            {getValues && getValues(name) ? label : label}
          </div>
        </label>
      )}
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
      {hintText && <div className="hintText">{hintText}</div>}
    </div>
  );
};

DateInputField.defaultProps = {
  id: '',
  name: '',
  label: '',
  placeholder: '',
  type: 'text',
  icon: '',
  readOnly: false,
  setValue: () => {},
  t: () => {},
  trigger: () => {},
  format: 'DD/MM/YYYY',
  control: () => {},
};

DateInputField.propTypes = {
  id: PropTypes.string.isRequired,
  t: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  format: PropTypes.string,
  control: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};

export default DateInputField;
