/* eslint-disable valid-jsdoc */
/* eslint-disable max-lines-per-function */
/* eslint-disable require-jsdoc */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { getImg } from '../../../utils/helperUtils';
import {
  mediaApi,
  withSitecoreContext,
  Text,
} from '@sitecore-jss/sitecore-jss-react';
import TextLink from '../globals/link/TextLink';
import VidyardPlayer from '../../business/VidyardPlayer';
import WistiaPlayer from '../../business/WistiaPlayer';
import YoutubePlayer from '../../business/YoutubePlayer';
import VimeoPlayer from '../../business/VimeoPlayer';
import { GET_ITEM_DETAILS } from '../../../constants';
import { dataFetcher } from '../../../dataFetcher';
import './VideoComponent.scss';

/**
 * @description - Component.
 * @param {Object} props - Props passed.
 * @returns {Node} - Element.
 */
const VideoComponent = (props) => {
  const { fields, sitecoreContext, rendering } = props;
  const { title, text, backgroundImage } = fields;

  const [componentSetting, setComponentSetting] = useState({});
  const itemId = rendering?.params?.componentColor;
  const textAlign =
    rendering?.params?.textAlignment === 'Left' ? 'text-to-left' : '';
  const compId = rendering?.params?.anchorLinkId || 'video-component';
  const class_name = backgroundImage !== '' ? 'bknd' : '';
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';

  /**
   * @description - Get Item Details By Item Id.
   * @returns {undefined}
   */
  const getItemDetails = (id) => {
    dataFetcher(`${GET_ITEM_DETAILS}?itemID=${id}`).then((response) => {
      setComponentSetting(response);
    });
  };
  useEffect(() => {
    itemId && getItemDetails(itemId);
  }, [itemId]);

  const fontColor = componentSetting?.data || {};
  const backgroundColor = componentSetting?.data?.BackgroundColor || '';

  let bg_color_code = [];
  const updatebackgroundColor = () => {
    bg_color_code = backgroundColor ? backgroundColor : '';
    bg_color_code =
      bg_color_code?.indexOf(',') > -1 ? bg_color_code?.split(',') : bg_color_code;
    if (backgroundColor && backgroundColor.indexOf(',') > -1) {
      bg_color_code = `linear-gradient(${bg_color_code[0]}, ${bg_color_code[1]})`;
    } else {
      bg_color_code = bg_color_code;
    }
    return bg_color_code;
  };

  const backgroundImg = backgroundImage
    ? mediaApi.updateImageUrl(getImg(backgroundImage))
    : '';

  useEffect(() => {
    /**
     * @description - loads the fancybox library.
     * @returns {*} - Returns nothing.
     */
    async function loadFancy() {
      await require('@fancyapps/fancybox');
      await require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
    }

    $(function () {
      if (!window.jQuery) window.jQuery = $;
      loadFancy();
    });
  }, []);

  return (
    <section
      className={`video-block ${class_name}`}
      id={`${compId}`}
      style={{
        backgroundImage: backgroundImg ? `url(${backgroundImg})` : 'none',
        background: backgroundColor ? updatebackgroundColor() : 'none',
      }}
    >
      <div className="container">
        {fields?.items?.length > 1 && (
          <div className="video-text-content">
            <h2 className="headline" style={{ color: fontColor?.FontColor }}>
              <Text field={title} />
            </h2>
            <p className="text" style={{ color: fontColor?.FontColor }}>
              <Text field={text} />
            </p>
          </div>
        )}
        <div className={`row ${textAlign}`}>
          <div className="player-wrapper"></div>
          {fields?.items?.map((item, index) => (
            <div
              className={`video-cont ${
                fields?.items?.length === 1 ? 'col-md-5' : 'col-md-6'
              }`}
              key={index}
            >
              {item?.fields?.source?.value === 'Youtube' && (
                <YoutubePlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title}
                  color={fontColor?.FontColor}
                />
              )}
              {item?.fields?.source?.value === 'Wistia' && (
                <WistiaPlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title}
                  color={fontColor?.FontColor}
                />
              )}
              {item?.fields?.source?.value === 'Vidyard' && (
                <VidyardPlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title}
                  color={fontColor?.FontColor}
                />
              )}
              {item?.fields?.source?.value === 'Vimeo' && (
                <VimeoPlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title}
                  color={fontColor?.FontColor}
                />
              )}
            </div>
          ))}
          {fields?.items?.length === 1 && (
            <div
              className={`col-md-6 ${
                textAlign === 'text-to-left' ? '' : 'offset-md-1'
              }`}
            >
              <div className="video-text-content single-vid">
                <h2 className="headline" style={{ color: fontColor?.FontColor }}>
                  <Text field={title} />
                </h2>
                <p className="text" style={{ color: fontColor?.FontColor }}>
                  <Text field={text} />
                </p>
                <div className={`cta_link ${brandName}`}>
                  <TextLink ctaText={fields?.ctaText} ctaUrl={fields?.ctaUrl} />
                </div>
              </div>
            </div>
          )}
        </div>
        {fields?.items?.length > 1 && (
          <div className={`cta__wrap ${brandName}`}>
            <TextLink
              ctaText={fields?.ctaText}
              ctaUrl={fields?.ctaUrl}
              cssClass="button-text--large"
            />
          </div>
        )}
      </div>
    </section>
  );
};

VideoComponent.defaultProps = {
  fields: {
    title: '',
    items: [],
    text: '',
    backgroundImage: '',
  },
  sitecoreContext: {},
  rendering: {},
};

VideoComponent.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    text: PropTypes.string,
    backgroundImage: PropTypes.string,
  }),
  sitecoreContext: PropTypes.shape({}),
  rendering: PropTypes.shape({}),
};

export default withSitecoreContext()(VideoComponent);
