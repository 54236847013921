import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { createUseStyles } from 'react-jss';
import './textBlock.scss';

/**
 * @description - TextBlock component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const TextBlock = (props) => {
  const { fields = {} } = props;
  const { text, componentBackgroundColor = [] } = fields;
  const colorValues = componentBackgroundColor[0]?.fields || {};

  useEffect(() => {
    const ot_policy_list = document.getElementById('ot-sdk-cookie-policy');
    if (window?.Optanon && ot_policy_list) {
      window.Optanon.initializeCookiePolicyHtml();
    }
  }, [text]);

  const themeColors = createUseStyles({
    bgColor: {
      '&.rich-text-block': {
        background:
          colorValues?.colorCode?.value?.indexOf(',') > -1
            ? `linear-gradient(${colorValues?.colorCode?.value})`
            : colorValues?.colorCode?.value,
      },
    },
    richTextFont: {
      color: colorValues?.fontColor?.value || 'inherit',
      '& *': {
        color: colorValues?.fontColor?.value || 'inherit',
      },
    },
  });
  const classes = themeColors();
  useEffect(() => {
    if (
      document &&
      document.location &&
      document.location.hash !== '' &&
      document.querySelector(document.location.hash) !== null
    ) {
      document
        .querySelector(document.location.hash)
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  return (
    <div className={`rich-text-block ${classes.bgColor}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <RichText field={text} className={`content ${classes.richTextFont}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

TextBlock.defaultProps = {
  fields: {},
};

TextBlock.propTypes = {
  fields: PropTypes.shape({
    text: PropTypes.shape({ value: PropTypes.string }),
    componentBackgroundColor: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default TextBlock;
