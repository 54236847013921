import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './cards.scss';
import ActionBarJobSave from './../ActionBars/ActionBarJobSave';
/**
 *
 * @param {*} param0 - site params
 * @returns {*} - data
 */
const CardGrid = ({
  title,
  location,
  subTitle,
  date,
  ctaUrl,
  isSaved,
  jobItemId,
  isExpired,
  icons,
  jobDetails,
}) => {
  return (
    <div className="card job grid">
      <div className="card-link">
        <div className="card-body">
          <div className="card-body__left">
            <Link to={ctaUrl}>
              <div className="title reversed">{title}</div>
            </Link>
            <div className="location">{location}</div>
            <div className="sub-title">{subTitle}</div>
          </div>
          <div className="card-body__right">
            <div className="flag">
              <ActionBarJobSave
                isSaved={isSaved}
                icons={icons}
                jobItemID={jobItemId}
                isExpired={isExpired}
                jobDetails={jobDetails}
              />
            </div>
            <div className="date">{moment(date).fromNow()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

CardGrid.defaultProps = {
  subTitle: '',
  title: '',
  location: '',
  date: 'January 1, 1001',
  img: '',
  isSaved: false,
  jobItemId: '',
  isExpired: false,
  icons: [],
  ctaUrl: '#',
  jobDetails: [],
};

CardGrid.propTypes = {
  subTitle: PropTypes.oneOf(['permanent', 'contract']),
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  img: PropTypes.string,
  isSaved: PropTypes.bool,
  jobItemId: PropTypes.string,
  isExpired: PropTypes.bool,
  ctaUrl: PropTypes.string,
  icons: PropTypes.arrayOf(PropTypes.shape({})),
  jobDetails: PropTypes.arrayOf(PropTypes.shape({})),
};
export default withSitecoreContext()(withTranslation()(CardGrid));
