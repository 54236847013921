/*eslint-disable*/
import React, { useEffect, useState, useReducer } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import get from 'lodash/get';
import { dataFetcher } from '../../../../dataFetcher';
import { dropDownFormat } from '../DynamicForm/helper/dataFactory';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { API } from '../../../../constants';
import { symbolValidationCheck } from '../DynamicForm/helper/validationFactory';
import {
  checkValueTypeAndGetTheCount,
  isTrue,
} from '../../../business/Utils/helper';
import { skillMasterDate } from './mockData';
import Loader from '../../ErrorBoundary/Loader';
const { ValueContainer, Placeholder } = components;

const NewValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder
        {...props}
        isFocused={props.selectProps.menuIsOpen || props.hasValue}
      >
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const StyleObject = (error) => {
  return {
    menu: (styles) => ({ ...styles, zIndex: 999 }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      zIndex: 999999,
    }),
    menuList: (provided, state) => ({
      ...provided,
      overflowX: 'hidden',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      color: 'rgba(103, 105, 111, 0.5)',
      top:
        state.isFocused || state.isSelected || state.selectProps.inputValue
          ? 15
          : '50%',
      transition: 'top 0.1s, font-size 0.1s',
      whiteSpace: 'nowrap',
      fontSize:
        state.isFocused || state.isSelected || state.selectProps.inputValue
          ? 12
          : 16,
    }),
    control: (base) => ({
      ...base,
      height: 49,
      minHeight: 49,
      ...(error
        ? {
            border: '1px solid  #9d323d',
            '&:hover': { borderColor: '#9d323d' },
            boxShadow: 'none',
          }
        : { boxShadow: 'none' }),
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 49,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      top: 35,
    }),
  };
};

const SelectComplex = (props) => {
  const {
    className,
    name,
    label,
    options,
    isClearable,
    isSearchable,
    isMulti,
    customStyle,
    type,
    isApi,
    apiEndPoint,
    dataValidations,
    requiredValidation = [],
    customError,
    control,
    value,
    connectorField,
    connectorType,
    connectorMessage,
    getValues,
    setValue,
    formName,
    readOnly,
    validateOnLoad,
    t,
    dateFormat,
    id,
    showBasedOnFieldName,
    hideBasedOnFieldName,
    watch,
    isHideField,
    hasApiCollection,
    apiData,
    handleChange,
    contentLoading,
  } = props;
  const fieldError = get(customError, name);
  const [loading, setLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);
  const [initialOptions, setInitialOptions] = useState(options);
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const optionSet = isApi ? dropDownData : options;
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  const connectorFormName =
    formName && connectorField ? `${formName}[${connectorField}]` : connectorField;
  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;

  useEffect(() => {
    contentLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      isFieldHasVal = isFieldHasVal ? isTrue(hideFieldValue?.value) : true;
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    let OptionObj = null;
    if (optionSet.length > 0) {
      OptionObj = optionSet.find(function (item) {
        return item.value === value || item.label === value;
      });
      param = OptionObj ? { ...param, shouldDirty: true } : param;
      setValue(name, OptionObj, param);
    }
  }, [value, dropDownData]);

  useEffect(() => {
    if (isApi) {
      setLoading(true);
      if (!hasApiCollection)
        dataFetcher(`${API.HOST}${apiEndPoint}`).then(
          (response) => {
            setLoading(false);
            setDropDownData(
              dropDownFormat(response?.data, getType(), 0, 0, '', dateFormat)
            );
          },
          (error) => {
            setLoading(false);
          }
        );
    }
  }, []);

  useEffect(() => {
    if (apiData) {
      setLoading(false);
      setDropDownData(dropDownFormat(apiData, getType(), 0, 0, '', dateFormat));
    }
  }, [apiData]);

  const getType = () =>
    type === 'DateDropdown' ? 'DateDropdownAPI' : 'DropdownAPI';

  const dataValidator = (value) => {
    let result = true;
    const connectoreFieldsValue = connectorFormName
      ? getValues(connectorFormName)
      : null;
    connectoreFieldsValue = connectoreFieldsValue?.value
      ? connectoreFieldsValue.value
      : connectoreFieldsValue;
    if (value && connectoreFieldsValue) {
      result = symbolValidationCheck(value, connectoreFieldsValue, connectorType);
    }
    return result;
  };

  const loadOptions = async (search, prevOptions) => {
    let filteredOptions;
    if (!search) {
      filteredOptions = optionSet;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = optionSet.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }
    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  return (
    <div
      className={`form-block select-complex ${fieldError ? 'error withoutBg' : ''} ${
        !showField || hideField ? 'hide' : ''
      }`}
      style={customStyle}
      role="combobox"
    >
      <div style={loading ? { display: 'none' } : {}}>
        <Controller
          control={control}
          name={name}
          render={({ onChange, onBlur, value }) => (
            <AsyncPaginate
              cacheOptions
              options={initialOptions}
              loadOptions={loadOptions}
              onChange={(selected) => {
                onChange(selected);
                handleChange();
              }}
              onBlur={onBlur}
              noOptionsMessage={() => t('no-options')}
              loadingMessage={() => t('loading')}
              styles={StyleObject(fieldError)}
              components={{ ValueContainer: NewValueContainer }}
              value={value || ''}
              className={className}
              isMulti={isMulti}
              isClearable={isClearable}
              isSearchable={isSearchable}
              placeholder={label}
              isLoading={loading}
              isDisabled={loading || readOnly}
            />
          )}
          rules={{
            required:
              showField && requiredData?.fields?.value?.value ? !hideField : false,
            dateValidator: (value) =>
              (connectorField ? dataValidator(value) : true) ||
              connectorMessage ||
              t('validation-failed'),
          }}
        />
      </div>
      {loading && (
        <div className="select-loading-placeholder">
          {label}
          <Loader
            loaderMsg=""
            loaderWithMsg
            customLoaderStyle={{
              width: '1rem',
              height: '1rem',
              color: '#665E66',
            }}
          />
        </div>
      )}
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
    </div>
  );
};

SelectComplex.defaultProps = {
  id: '',
  label: '',
  name: '',
  placeholder: '',
  customStyle: {},
  readOnly: false,
  value: '',
  setValue: () => {},
  handleChange: () => {},
  t: () => {},
  dateFormat: 'LL',
  trigger: () => {},
};

SelectComplex.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  customStyle: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  handleChange: PropTypes.func,
  t: PropTypes.func,
};

export default SelectComplex;
