/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

/**
 * @description - Modal Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const TooltipComponent = (props) => {
    const[text, setText] = React.useState(props.text)


React.useEffect(() => {
setText(props.text)
  },[props.text])

  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip id={`tooltip-right`}>
          {props.text}
        </Tooltip>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
};

TooltipComponent.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

TooltipComponent.defaultProps = {
text:''
};

export default TooltipComponent;
