import React from 'react';
import PropTypes from 'prop-types';
import ConnectorButtonLink from '../globals/buttons/ConnectorButtonLink';
import { Text, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { getImg } from '../../../utils/helperUtils';
import './calloutbar.scss';

/**
 * @description - callout bar footer action;
 * @param {object} props - input props;
 * @returns{Node} - html block;
 */
const CalloutBarFooter = (props) => {
  const { fields } = props;
  const { ctaUrl, ctaText, title, text, logo } = fields;
  return (
    <section className="calloutbar-section footer">
      <div
        className="calloutbar-x"
        style={{
          backgroundImage: `url(${mediaApi.updateImageUrl(getImg(logo))})`,
        }}
      ></div>
      <div className="skewed-rectangle"></div>
      <div className="calloutbar-body container">
        <div className="row">
          <div className="col-lg-6">
            <div className="title reversed">
              <Text field={title} />
            </div>

            <p className="reversed large">
              <Text field={text} />
            </p>
          </div>
          <div className="col-lg">
            <ConnectorButtonLink
              cssClass="orange"
              ctaText={ctaText}
              ctaUrl={ctaUrl}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

CalloutBarFooter.defaultProps = {
  fields: { title: {}, text: {}, ctaText: {}, ctaUrl: {}, logo: {} },
};

CalloutBarFooter.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({}),
    logo: PropTypes.shape({}),
    text: PropTypes.shape({}),
    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
  }),
};

export default CalloutBarFooter;
