/* eslint-disable */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  withSitecoreContext,
  mediaApi,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';
import { usePostData } from '../../../../hooks/http-client';
import SearchRibbon from './SearchRibbon';
import { saveJobSearchInfo } from '../../../../redux/actions';
import { SEARCH, DEVICE } from '../../../../constants';
import { socialShareMapper } from '../../../../factory/jobSearchFilterFactory';
import { clearSavedJobs } from '../../../../redux/actions/actions';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import SnackBar from '../../ErrorBoundary/SnackBar';
import { jobResultsEvent } from '../../../../services/apiServices/gtmService';
import usePrevious from '../../../../hooks/usePrevious';
import {
  pushSearchPageToDataLayer,
  parseFilterData,
  verifySearchJobPath,
} from '../../../../utils/helperUtils';
import { getCenterLocationRadius } from '../../../../utils/locationUtils';
import SearchJobResultCard from './SearchJobResultCard';
import '../search.scss';
import Error from '../../ErrorBoundary/Error';
import Loader from '../../ErrorBoundary/Loader';
import { Helmet } from 'react-helmet';
import Pagination from '../../globals/pagination/Pagination';
import FilterPill from './FilterPill';
import { CollapseFilterContext } from '../../../business/Contexts';

/**
 * @description - to prepare search filters
 * @param {number} currentPage - selected page
 * @param {string} searchKey - search key word
 * @param {object} filterValues - filters to be applied
 * @param {number} defaultRadius - Default radius value
 * @returns {object} - filters to be applied for search
 */
const prepareData = (
  currentPage,
  searchKey,
  filterValues = {},
  defaultRadius,
  lang,
  company = '',
  countryName = '',
  finalLocation = {}
) => {
  const sf = filterValues?.sf;
  delete filterValues.sf;
  const radiusVal =
    !filterValues?.radius && (filterValues?.latitude || filterValues?.longitude)
      ? { radius: Number(defaultRadius) }
      : {};
  if (company !== '') {
    return {
      sf: sf,
      filter: {
        ...filterValues,
        ...radiusVal,
        ...finalLocation,
        offset: currentPage - 1,
        totalCount: 0,
        limit: countryName === 'Italy' ? 20 : 10,
        searchkeyword: searchKey,
        haslocation:
          filterValues?.latitude && filterValues?.longitude ? true : false,
        language: lang,
        company: company,
      },
    };
  } else {
    return {
      sf: sf,
      filter: {
        ...filterValues,
        ...radiusVal,
        ...finalLocation,
        offset: currentPage - 1,
        totalCount: 0,
        limit: countryName === 'Italy' ? 20 : 10,
        searchkeyword: searchKey,
        haslocation:
          filterValues?.latitude && filterValues?.longitude ? true : false,
        language: lang,
      },
    };
  }
};

/**
 * @description to show job search results
 * @param {object} fields - search results component params
 * @param {function} t - translate function
 * @returns {node} -html
 */
const SearchJobResultsGlobal = ({
  fields,
  t = () => {},
  sitecoreContext,
  JobFilters,
}) => {
  const {
    jobItemPerPage,
    alertSuccess,
    showJobAlert,
    frequency,
    dashboardUrl,
    defaultRadius,
    applyJobUrl,
    consentText,
    isCompanyJobSearch,
    NoScriptMessage,
    searchCanonicalUrl,
    searchUrl,
  } = fields;
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const countryName = sitecoreContext?.Country?.name;
  const jobUrl = sitecoreContext?.Country?.jobSearchURL || '';
  const loginReducer = useSelector((state) => state.loginReducer);
  const loginState = loginReducer?.state;
  /*  const filterValues =
    JSON.parse(decodeURIComponent(urlParams.get('filters'))) || {}; */
  const filter = typeof window !== 'undefined' ? parseFilterData() : undefined;
  const filterValues = filter || {};
  const searchKey = urlParams.get('searchKeyword');
  const filters = urlParams.get('filters');
  const page = urlParams.get('page');
  const [place, setPlace] = useState('');
  const { isActive, message, openSnackBar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedJob, setSelectedJob] = useState(0);
  const [socialItems, setSocialItems] = useState([]);
  const [calculatedLocation, setCalculatedLocation] = useState({});
  const [response, setResponse] = useState();
  const { loading, error, write } = usePostData();
  const prevValue = usePrevious({ location });

  const searchDetailsSection = useRef();
  const jobResultRibbonSection = useRef();
  const focusResult = location.focusResult || false;

  const dispatch = useDispatch();
  const jobDetailsReducer = useSelector((state) => state.jobDetailsReducer);
  const mapInit = useSelector((state) => state.mapReducer?.data);

  let { iconFilter, collapseAll, iconClose } = {};
  const [isCollapse, setIsCollapse] = useState(true);
  const [collapseButton, setCollapseButton] = useState(true);
  const [expandedFilters, setExpandedFilters] = useState(0);
  const stateVal = {
    isCollapse,
    collapseButton,
    sortby: response?.data?.filters?.selectedSortOrder || '',
    isCompanyJobSearch: isCompanyJobSearch?.value,
    setExpandedFilters,
    mediaIcons: response?.data?.mediaIcons,
  };
  const [isMobView, setIsMobView] = useState(false);
  useEffect(() => {
    const isMobWidth = window.innerWidth <= DEVICE.TABLET.WIDTH;
    // initial rendering.
    if (isMobView !== isMobWidth) {
      setIsMobView(!isMobView);
    }
  }, []);

  useEffect(() => {
    if (
      (verifySearchJobPath(location?.pathname, jobUrl, sitecoreContext?.language) ||
        isCompanyJobSearch?.value) &&
      mapInit
    ) {
      dispatch(clearSavedJobs());
      page ? setCurrentPage(parseInt(page)) : setCurrentPage(1);
      page ? getData(parseInt(page)) : getData(1);
      if (window?.dataLayer) {
        window.dataLayer.push({
          event: 'JobSearch',
          eventCategory: 'Job Search',
          eventAction: searchKey,
          eventLabel: urlParams.get('place') || '',
        });
      }
    }
  }, [page, searchKey, filters, jobUrl, location.search, mapInit]);

  // useEffect(() => {
  //   pushSearchPageToDataLayer(prevValue?.location, location, t('search-jobsearch'));
  // }, [location]);

  useEffect(() => {
    dispatch(saveJobSearchInfo(response?.data, loading));
    if (focusResult && jobResultRibbonSection.current)
      jobResultRibbonSection.current.focus();
  }, [response, loading]);

  useEffect(() => {
    if (sessionStorage) {
      const savejobButtonClickedToLogin = sessionStorage.getItem(
        'savejobButtonClickedToLogin'
      );
      if (savejobButtonClickedToLogin === 'true') {
        const saveJobBtnClickDetails = JSON.parse(
          sessionStorage.getItem('saveJobBtnClickDetails')
        );
        if (
          response?.data &&
          response?.data?.jobsItems &&
          response?.data?.jobsItems.length
        ) {
          const jobIndex = response.data.jobsItems.findIndex(
            (jobs) => jobs.jobItemID === saveJobBtnClickDetails?.jobItemID
          );
          if (jobIndex >= 0) {
            setSelectedJob(jobIndex);
          }
        }
      }
    }
  }, [response, jobDetailsReducer?.data]);

  useEffect(() => {
    if (selectedJob && selectedJob > 0) {
      setTimeout(() => {
        const el = document.querySelector('.job-search-item.active');
        if (el) el.scrollIntoView();
      }, 100);
    }
  }, [selectedJob]);

  useEffect(() => {
    if (expandedFilters > 1) setIsCollapse(false);
    else {
      setIsCollapse(true);
    }
  }, [expandedFilters]);

  /**
   * @description to prepare social share data
   * @param {object} response - api response object
   * @returns {undefined} - no returns
   */
  const prepareSocialItems = (response) => {
    setResponse(response);
    setSocialItems(socialShareMapper(response?.data?.socialShares));
    jobResultsEvent(
      response?.data?.jobsItems,
      sitecoreContext,
      isCompanyJobSearch?.value,
      loginState
    );
  };

  /**
   * @description to set  icons
   * @returns {undefined} -  no returns
   */
  const setIcons = () => {
    if (Array.isArray(response?.data?.mediaIcons)) {
      response?.data?.mediaIcons.forEach((iconItem) => {
        switch (iconItem?.itemName) {
          case 'Filter':
            iconFilter = iconItem.icon;
            break;
          case 'Collapse All':
            collapseAll = iconItem.icon;
            break;
          case 'Close':
            iconClose = iconItem.icon;
          default:
            break;
        }
      });
    }
  };
  setIcons();

  /**
   * @description to fetch data from api
   * @param {number} currentPage - page selected
   * @returns {undefined}
   */
  const getData = (currentPage) => {
    setSelectedJob(0);
    const requestProps = [
      currentPage,
      searchKey,
      filterValues,
      defaultRadius?.value,
      sitecoreContext?.languageISO,
      sitecoreContext?.route?.fields?.company?.fields?.key?.value || '',
      countryName,
    ];
    if (filterValues?.place) {
      if (place && urlParams.get('place') === place) {
        const calculatedLoc = Object.assign({}, calculatedLocation);
        if (calculatedLocation?.radius) {
          calculatedLoc['radius'] =
            calculatedLocation?.radius +
            Number(
              filterValues?.selectedDistance?.[0]?.value || defaultRadius?.value
            );
          calculatedLoc['selectedDistance'] = filterValues?.selectedDistance?.[0]
            ? [filterValues?.selectedDistance?.[0]]
            : null;
        }
        write(
          `${SEARCH.JOB_SEARCH.SEARCH_API_NEW}`,
          prepareData(...requestProps, calculatedLoc),
          prepareSocialItems
        );
        window.scrollTo(0, 0);
      } else {
        Promise.resolve(getCenterLocationRadius(filterValues?.place)).then(
          (finalLocation) => {
            setCalculatedLocation(finalLocation);
            setPlace(urlParams.get('place'));
            const calculatedLoc = Object.assign({}, finalLocation);
            if (finalLocation?.radius) {
              calculatedLoc['radius'] =
                finalLocation?.radius +
                Number(
                  filterValues?.selectedDistance?.[0]?.value || defaultRadius?.value
                );
              calculatedLoc['selectedDistance'] = filterValues?.selectedDistance?.[0]
                ? [filterValues?.selectedDistance?.[0]]
                : null;
            }
            write(
              `${SEARCH.JOB_SEARCH.SEARCH_API_NEW}`,
              prepareData(...requestProps, calculatedLoc),
              prepareSocialItems
            );
            window.scrollTo(0, 0);
          }
        );
      }
    } else {
      write(
        `${SEARCH.JOB_SEARCH.SEARCH_API_NEW}`,
        prepareData(...requestProps),
        prepareSocialItems
      );
      window.scrollTo(0, 0);
    }
  };

  /**
   * @description to load selected page content
   * @param {number} selectedPage - selected page number
   * @returns {array} - page content
   */
  const handlePageChange = (selectedPage) => {
    if (selectedPage !== currentPage) {
      setPageParams(selectedPage);
    }
  };

  /**
   * @description to set page params
   * @param {number} page - selected page
   * @returns {undefined} - no returns
   */
  const setPageParams = (page) => {
    urlParams.set('page', page);
    history.push({
      pathname: location.pathname,
      search: String(decodeURIComponent(urlParams)),
    });
  };

  /**
   * @description - to handle job item selection
   * @param {number} jobListIndex - selected job item index
   * @returns {undefined} - nothing
   */
  const handleJobSelection = (jobListIndex) => {
    setSelectedJob(jobListIndex);
    searchDetailsSection.current.focus();
  };

  /**
   * @description - to handle accordian toggle
   * @param {*} event - click eventി
   * @returns {undefined} - nothing
   */
  const handleToggleClick = (event) => {
    if (event.keyCode === 13) {
      setCollapseButton((prevState) => !prevState);
      setIsCollapse((prevState) => !prevState);
    }
  };
  let canonicalPage = page && page > 1 ? `?page=${page}` : '';
  return (
    <>
      <Helmet>
        <link rel="canonical" href={searchCanonicalUrl?.value + canonicalPage} />
        <link
          rel="alternate"
          href={searchCanonicalUrl?.value + canonicalPage}
        ></link>
      </Helmet>
      <RichText field={NoScriptMessage} />
      <section
        className={`search-job-results ${
          error || response?.data?.jobsItems?.length < 10 ? 'no-cls' : ''
        }`}
      >
        <div className="container">
          <div className="row">
            <div
              className={`${
                response?.data?.filters?.hideFilter
                  ? 'filter-hide'
                  : 'col-lg-4 col-md-4 job-search-filters desktop'
              } `}
            >
              <div className="job-filters-global accordion accordion-section desktop">
                {!loading && response?.data?.jobsItems && (
                  <div className={`filter-block ${isCollapse ? 'active' : ''}`}>
                    <div className="filter-block-inner">
                      <div className="icon-filters">
                        <img
                          src={mediaApi.updateImageUrl(iconFilter)}
                          alt="filter icon"
                        />
                      </div>
                      <div className="text">{t('filter-result')}</div>
                    </div>
                    <div
                      className={`collapse-block-inner ${
                        isCollapse ? 'collapse' : ''
                      } ${
                        response?.data?.filters?.totalCount === 0
                          ? 'hide-collapse'
                          : ''
                      }`}
                      onClick={() => {
                        setCollapseButton((prevState) => !prevState);
                        setIsCollapse((prevState) => !prevState);
                      }}
                      onKeyDown={handleToggleClick}
                      role="button"
                      tabIndex="0"
                      aria-label={`toggle accordian  ${
                        isCollapse ? t('expand-all') : t('collapse-all')
                      }`}
                    >
                      <div className="text">
                        {isCollapse ? t('expand-all') : t('collapse-all')}
                      </div>
                      <div className="icon-collapse">
                        <img
                          src={mediaApi.updateImageUrl(collapseAll)}
                          alt="collapse all icon"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="job-filters-inner">
                  <CollapseFilterContext.Provider value={stateVal}>
                    {JobFilters && JobFilters.map((component) => component)}
                  </CollapseFilterContext.Provider>
                </div>
              </div>
              {/* <div className="mobile-only"> */}
              {/* <Button cssClass="blue" text="see 54 jobs" /> */}
              {/* </div> */}
            </div>

            <div
              className={`${
                response?.data?.filters?.hideFilter
                  ? 'col-md-12'
                  : 'col-lg-8 col-md-12'
              }`}
            >
              {response?.data && response?.data?.filters?.totalCount > 0 && (
                <section
                  ref={jobResultRibbonSection}
                  tabIndex="-1"
                  className={loading ? 'search-ribbon-show' : ''}
                >
                  <CollapseFilterContext.Provider value={stateVal}>
                    <SearchRibbon
                      searchResultCount={response?.data?.filters?.totalCount || 0}
                      searchQuery={searchKey}
                      searchLocation={filterValues?.place}
                      jobAlertOptions={frequency}
                      ctaText={t('create-alert-for-future-jobs')}
                      translate={t}
                      jobAlertSuccessMessage={alertSuccess}
                      showJobAlert={showJobAlert?.value}
                      dashboardUrl={dashboardUrl?.value}
                      consentText={consentText}
                      totalPositions={response?.data?.totalNumberOfPosition}
                      totalPositionsLabel={t('total-positions')}
                      icons={response?.data?.mediaIcons}
                      JobFilters={JobFilters}
                      jobctaText={t('see-search-jobs')}
                      jobctaUrl={sitecoreContext?.Country?.jobSearchURL}
                      searchLabel={response?.data?.searchLabel}
                      paginationCount={response?.data?.jobsItems?.length || 0}
                      selectedFilters={response?.data?.selectedFilters}
                      hideFilter={response?.data?.filters?.hideFilter}
                    />
                  </CollapseFilterContext.Provider>
                </section>
              )}
              {error && <Error errorMsg={t('search-errormessage')} />}

              {response?.data?.filters?.totalCount === 0 ? (
                <section>
                  <div className="no-ribbon-data"></div>
                </section>
              ) : null}

              {/* {loading && <Loader />} */}
              {!isMobView && response?.data && !loading && (
                <section>
                  <div className="job-search-pills">
                    <FilterPill
                      data={response?.data?.selectedFilters}
                      iconClose={iconClose}
                    />
                  </div>
                </section>
              )}
              {!loading &&
                response?.data?.jobsItems?.map((item, index) => {
                  return (
                    <div key={index} id={`job_${index}`}>
                      <SearchJobResultCard
                        active={selectedJob === index ? true : false}
                        jobDetails={item}
                        company={item.companyName}
                        type={
                          item.jobAndEmploymentType !== null
                            ? item.jobAndEmploymentType
                            : item.employmentType
                        }
                        buttonLabel={t('JobDetailsCTAText')}
                        onJobClick={() => handleJobSelection(index)}
                        socialFields={{ items: socialItems }}
                        icons={response?.data?.mediaIcons}
                        lang={sitecoreContext?.language}
                        index={index}
                        domain={item.domain}
                        brandLabel={item?.brandLabel}
                        jobCategory={null}
                        isCompanySearch={isCompanyJobSearch?.value}
                      />
                    </div>
                  );
                })}
              {response?.data?.filters?.totalCount === 0 ? (
                <>
                  <div className="no-jobs-ribbon">
                    <SearchRibbon
                      showJobAlert={false}
                      searchResultCount={response?.data?.filters?.totalCount || 0}
                      searchQuery={searchKey}
                      searchLocation={filterValues?.place}
                      jobAlertOptions={frequency}
                      translate={t}
                      jobAlertSuccessMessage={alertSuccess}
                      dashboardUrl={dashboardUrl?.value}
                      consentText={consentText}
                      totalPositions={response?.data?.totalNumberOfPosition}
                      totalPositionsLabel={t('total-positions')}
                      icons={response?.data?.mediaIcons}
                      JobFilters={JobFilters}
                      jobctaText={t('see-search-jobs')}
                      jobctaUrl={sitecoreContext?.Country?.jobSearchURL}
                      searchLabel={response?.data?.searchLabel}
                      paginationCount={response?.data?.jobsItems?.length || 0}
                      selectedFilters={response?.data?.selectedFilters}
                      hideFilter={response?.data?.filters?.hideFilter}
                      zeroSearchResultsText={response?.data?.zeroSearchResultsText}
                      ctaText={t('create-alert-for-future-jobs')}
                      showJobAlertOnError={showJobAlert?.value}
                      SearchRibbonError={true}
                    />
                  </div>
                </>
              ) : null}
              {!loading && (
                <section className="search-global-pagination search-global-pagination-job">
                  <div className="search-pagination-wrap">
                    {response?.data?.filters?.totalCount > 10 &&
                      response?.data?.jobsItems?.length > 0 && (
                        <Pagination
                          totalRecords={response?.data?.filters?.totalCount}
                          pageLimit={
                            countryName === 'Italy'
                              ? '20'
                              : parseInt(jobItemPerPage?.value)
                          }
                          onPageChanged={handlePageChange}
                          selectedPage={currentPage}
                          showPrevNext={true}
                          pageNeighbours={1}
                          canonical={searchCanonicalUrl?.value}
                          searchUrl={searchUrl?.value}
                        ></Pagination>
                      )}
                  </div>

                  <SnackBar isActive={isActive} message={message} />
                </section>
              )}
            </div>
            {loading && (
              <div className="show-loader">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </section>

      {/* {response?.data && (
        <section ref={jobResultRibbonSection} tabIndex="-1">
          <SearchRibbon
            searchResultCount={response?.data?.filters?.totalCount}
            searchQuery={searchKey}
            searchLocation={filterValues?.place}
            jobAlertOptions={frequency}
            ctaText={t('create-alert-for-future-jobs')}
            translate={t}
            jobAlertSuccessMessage={alertSuccess}
            showJobAlert={showJobAlert?.value}
            dashboardUrl={dashboardUrl?.value}
            consentText={consentText}
            totalPositions={response?.data?.totalNumberOfPosition}
            totalPositionsLabel={t('total-positions')}
          />
          {response?.data?.filters?.totalCount === 0 ? (
            <SearchRibbon
              ctaText={t('create-alert-for-future-jobs')}
              mode={'no-results'}
              translate={t}
              totalPositions={response?.data?.totalNumberOfPosition}
              totalPositionsLabel={t('total-positions')}
            />
          ) : null}
        </section>
      )}

      {error && <Error errorMsg={t('search-errormessage')} />}

      {loading && <Loader />}

      <section className="search-job-results">
        <div className="search-job-results-items" id="job-search-results-list">
          {!loading &&
            response?.data?.jobsItems?.map((item, index) => {
              return (
                <div key={index} id={`job_${index}`}>
                  <SearchJobItem
                    active={selectedJob === index ? true : false}
                    jobDetails={item}
                    company={item.companyName}
                    type={item.employmentType}
                    buttonLabel={t('preview')}
                    onJobClick={() => handleJobSelection(index)}
                    socialFields={{ items: socialItems }}
                    icons={response?.data?.mediaIcons}
                    lang={sitecoreContext?.language}
                    index={index}
                  />
                </div>
              );
            })}
        </div>
        <div className="search-job-results-details">
          {!loading && response && response?.data?.jobsItems?.length > 0 && (
            <>
              <span ref={searchDetailsSection} tabIndex={-1} className="sr-only">
                {response?.data?.jobsItems[selectedJob]?.jobTitle}
              </span>
              <SearchJobItemDetails
                key={selectedJob}
                type="permanent"
                ctaText="apply now"
                ctaUrl="#"
                matchItems={[]}
                readMore={true}
                jobDetails={response?.data?.jobsItems[selectedJob]}
                socialFields={{ items: socialItems }}
                icons={response?.data?.mediaIcons}
                applyJobUrl={applyJobUrl?.value}
                lang={sitecoreContext?.language}
              />
            </>
          )}
        </div>
      </section>
      <section className="search-pagination search-pagination-job">
        <div className="search-pagination-wrap">
          {response?.data?.jobsItems?.length > 0 && (
            <Pagination
              totalRecords={response?.data?.filters?.totalCount}
              pageLimit={parseInt(jobItemPerPage?.value)}
              onPageChanged={handlePageChange}
              selectedPage={currentPage}
              label={t('search-page')}
            ></Pagination>
          )}
        </div>
        <SnackBar isActive={isActive} message={message} />
      </section> */}
    </>
  );
};

SearchJobResultsGlobal.defaultProps = {
  items: [],
  fields: {
    cssClass: {
      value: '',
    },
    itemPerPage: {
      value: '10',
    },
    alertSuccess: {
      value: '',
    },
    showJobAlert: {
      value: null,
    },
    dashboardUrl: {
      value: '',
    },
    defaultRadius: {
      value: '',
    },
    applyJobUrl: {
      value: '',
    },
    isCompanyJobSearch: {
      value: false,
    },
  },
  t: () => {},
  showSkills: false,
  readMore: '',
  sitecoreContext: {},
};

SearchJobResultsGlobal.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields: PropTypes.shape({
    cssClass: PropTypes.shape({
      value: PropTypes.string,
    }),
    jobItemPerPage: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    alertSuccess: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    showJobAlert: PropTypes.shape({
      value: PropTypes.bool.isRequired,
    }),
    dashboardUrl: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    defaultRadius: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    applyJobUrl: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    isCompanyJobSearch: PropTypes.shape({
      value: PropTypes.bool,
    }),
  }),
  t: PropTypes.func,
  showSkills: PropTypes.bool,
  readMore: PropTypes.string,
  sitecoreContext: PropTypes.shape({}),
};

export default withRouter(withSitecoreContext()(SearchJobResultsGlobal));
