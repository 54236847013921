import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import AccordionItem from './AccordionItem';

import '../Accordion/accordion.scss';
import './accordion.scss';

/**
 * @description - to render accordion
 * @param {*} param0 -Input props.
 * @returns {HTML} -  accordion html
 */
const Accordion = ({
  cssClass,
  offset,
  linkItems,
  showAccordion,
  doShowAccordion = () => {},
  t,
}) => {
  const { items } = linkItems;
  const [openAccordion, setOpenAccordion] = useState();

  /**
   * @description - to handle accordion open
   * @param {*} index -index
   * @return {undefined}
   */
  const handleAccordion = (index) => {
    setOpenAccordion(index);
  };

  return (
    <section className={`accordion-section-advanced ${cssClass}`}>
      <div className="container">
        <div className="row">
          <div className={offset ? 'col-lg-10 offset-lg-1' : 'col-lg-12'}>
            <div className="row">
              <div className="col">
                {!showAccordion ? (
                  <div onClick={() => doShowAccordion()} role="presentation">
                    <AccordionItem title={t('choose-by-region')} />
                  </div>
                ) : (
                  Array.isArray(items) &&
                  items.map((item, index) => {
                    return (
                      <AccordionItem
                        title={item?.regionName}
                        region={item}
                        active={index === openAccordion ? true : false}
                        onAccordionClick={handleAccordion}
                        key={index}
                        index={index}
                      />
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Accordion.defaultProps = {
  cssClass: '',
  offset: '',
  linkItems: {
    items: [],
  },
  showAccordion: false,
  doShowAccordion: () => {},
  t: () => {},
};

Accordion.propTypes = {
  cssClass: PropTypes.string,
  offset: PropTypes.string,
  linkItems: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  showAccordion: PropTypes.bool,
  doShowAccordion: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(Accordion);
