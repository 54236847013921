import { CHATBOT } from '../actions/actionTypes';

const initialState = {
  showChat: false,
};

/**
 * @description - Hubspot form state reducer.
 * @param {object} state - State object.
 * @param {object} action - Action object.
 * @returns {object} - showForm.
 */
const chatbotReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHATBOT.SHOW:
      return {
        showChat: true,
      };
    default:
      return state;
  }
};

export default chatbotReducer;
