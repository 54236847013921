import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './CalloutBarSteps.scss';
import CalloutBarStepsLogin from './CalloutBarStepsLogin';
import { isDataExists, isNullorEmpty } from '../../../../utils/helperUtils';

/**
 * @description CalloutBarSteps component
 * @param {object} props - parameters get from sitecore
 * @returns {node} - html node
 */
const CalloutSteps = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { fields, params } = props;
  const { title, items, componentBackgroundColor, logInLable } = fields;

  const loginBtnFields = { ...fields };
  loginBtnFields.title = logInLable;
  const loginBtnProps = { ...props };
  loginBtnProps.fields = loginBtnFields;

  const [isMobView, setIsMobView] = useState(false);

  const steps =
    !isNullorEmpty(items) && isDataExists(items)
      ? items.map((item) => ({
          id: item?.id,
          displayName: item?.displayName,
          isActive: item?.fields?.isActive?.value,
          text: item?.fields?.title?.value,
        }))
      : [];
  const numSteps = steps.length;
  const lengthOfActiveSteps = steps.filter((item) => item.isActive === true).length;

  /**
   * @description CalloutBarSteps resizes
   * @returns {*} - Null.
   */
  const resizeEvent = useCallback(() => {
    const isMobWidth = window.innerWidth <= 992;
    if (isMobView !== isMobWidth) {
      setIsMobView(!isMobView);
    }
  }, [isMobView]);

  useEffect(() => {
    const isMobWidth = window.innerWidth <= 992;
    // initial rendering.
    if (isMobView !== isMobWidth) {
      setIsMobView(!isMobView);
    }
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, [isMobView, resizeEvent]);

  /**
   * @description - Progress Width calculator.
   * @returns {*} - Null.
   */
  const getProgressWidth = () => {
    let progressWidth = 0;
    if (numSteps > 1) {
      progressWidth = +(100 * ((lengthOfActiveSteps - 1) / (numSteps - 1)));
      if (numSteps === 4 && isMobView) {
        progressWidth = progressWidth.toFixed(2) === '33.33' ? 36.33 : progressWidth;
      }
    }
    const styles = isMobView
      ? { height: `${progressWidth.toFixed(2)}%` }
      : { width: `${progressWidth.toFixed(2)}%` };
    return styles;
  };

  const bgColorCode = componentBackgroundColor?.[0]?.fields?.colorCode?.value?.split(
    ','
  );
  let bgColor = bgColorCode?.[0]?.toString();
  if (bgColorCode?.length > 1) {
    bgColor = `linear-gradient(267deg, ${bgColorCode ? bgColorCode[1] : ''}, ${
      bgColorCode ? bgColorCode[0] : ''
    })`;
  }

  /**
   * @description - to return window width
   * @returns {number} - width
   */
  const getScreenWidth = () => {
    try {
      return window.innerWidth;
    } catch {
      return 0;
    }
  };

  return (
    <section className="callout-steps" style={{ backgroundImage: bgColor }}>
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="title">{title?.value}</h2>
            <div className="steps-container">
              <div className="steps-wrap">
                <div
                  className={'steps-progress-bar'}
                  style={
                    !isMobView
                      ? numSteps === 2 || numSteps === 3
                        ? {
                            left: getScreenWidth() < 1400 ? '16.5' : '12.5%',
                            right: getScreenWidth() < 1400 ? '16.5' : '12.5%',
                          }
                        : {
                            left: 100 / (numSteps * 2) + '%',
                            right: 100 / (numSteps * 2) + '%',
                          }
                      : {}
                  }
                >
                  <div className="steps-progress">
                    <div
                      style={{ ...getProgressWidth(), left: '0px' }}
                      className="steps-progress-fill"
                    ></div>
                  </div>
                </div>
                <ul className="steps-progress">
                  {steps.map((step, index) => (
                    <li className="step" key={step.id + index.toString()}>
                      <span className="step-number">{index + 1}</span>
                      <span className="step-name">{step.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="cta-wrap">
              <CalloutBarStepsLogin {...loginBtnProps} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

CalloutSteps.defaultProps = {
  fields: {
    title: {},
    items: [],
    componentBackgroundColor: {},
    logInLable: {},
  },
  params: {},
};

CalloutSteps.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({}),
    items: PropTypes.shape([]),
    componentBackgroundColor: PropTypes.shape({}),
    logInLable: PropTypes.shape({}),
  }).isRequired,
  params: PropTypes.shape({}),
};

export default CalloutSteps;
