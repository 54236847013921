import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ButtonLink from '../globals/link/ButtonLink';
import { getImg } from '../../../utils/helperUtils';
import {
  Text,
  mediaApi,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import './page-banners.scss';

/**
 * @description - Location banner.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const LocationPageBanner = ({ fields, t, sitecoreContext }) => {
  const {
    title,
    address,
    hours,
    phone,
    ctaUrl,
    ctaText,
    backgroundimage,
    latitude,
    longtitude,
  } = fields;

  /**
   * @description - Google map direction url.
   * @returns {Object} - Link field object.
   */
  const getDirectionUrl = () => {
    const { value } = ctaUrl;

    return {
      ...value,
      href: !ctaUrl?.value?.href
        ? `https://www.google.com/maps/dir/?api=1&origin=&destination=${latitude.value},${longtitude.value}`
        : ctaUrl.value.href,
      linktype: !ctaUrl?.value?.href ? 'external' : ctaUrl?.value?.linktype,
      target:
        ctaUrl?.value.href && ctaUrl?.value?.linktype === 'internal' ? '' : '_blank',
    };
  };
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  return (
    <section className={`banner-section location ${brandName}`}>
      <div
        className="banner-image"
        style={{
          backgroundImage: `url(${mediaApi.updateImageUrl(
            getImg(backgroundimage)
          )})`,
        }}
      ></div>
      <div className={`skewed-rectangle ${brandName}`}></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-5 col-md-6 col-8">
            <div className="sub-title">{t('locations')}</div>
            <h1 className="title">
              <Text field={title} />
            </h1>
            <div className="address" aria-describedby="address">
              <Text field={address} id="address" tag="p" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-7 col">
            <div className="hours-phone-container">
              <div className="hours">
                <div className="block-title">{t('hours')}</div>
                <div className="hours-content">
                  <Text field={hours} />
                </div>
              </div>
              <div className="phone">
                <div className="block-title">{t('phone')}</div>
                <a
                  href={`tel: ${phone?.value}`}
                  aria-label="Phone number opens in a new window/tab"
                >
                  <Text field={phone} />
                </a>
              </div>
            </div>
            <div className="getDirectionsBtn">
              {latitude &&
                latitude.value &&
                longtitude &&
                longtitude.value &&
                (brandName === 'manpower' ? (
                  <ButtonLink
                    cssClass="reversed"
                    ctaText={ctaText}
                    ctaUrl={getDirectionUrl()}
                    externalLink={true}
                    arrow
                  />
                ) : (
                  <ButtonLink
                    cssClass="reversed"
                    ctaText={ctaText}
                    ctaUrl={getDirectionUrl()}
                    externalLink={true}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

LocationPageBanner.defaultProps = {
  fields: {
    title: { value: '' },
    address: { value: '' },
    hours: { value: '' },
    phone: { value: '' },
    ctaText: { value: '' },
    ctaUrl: { value: null },
    latitude: { value: '' },
    longtitude: { value: '' },
    backgroundimage: {
      value: { src: '' },
    },
  },
};

LocationPageBanner.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    address: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    }),
    hours: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    }),
    phone: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({ value: PropTypes.string }),
    latitude: PropTypes.shape({ value: PropTypes.string }),
    longtitude: PropTypes.shape({ value: PropTypes.string }),
    backgroundimage: PropTypes.shape({ value: PropTypes.string.isRequired }),
  }),
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
    Country: PropTypes.shape({
      brandName: PropTypes.string,
    }),
  }).isRequired,
};
export default withSitecoreContext()(withTranslation()(LocationPageBanner));
