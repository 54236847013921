/* eslint-disable */
import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import AccordionSection from './AccordionSection';
import TextButton from '../../core/globals/buttons/TextButton';
import './accordion.scss';

/**
 * @description - Accordion Block component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const AccordionBlock = ({ inputRef, ...props }) => {
  const {
    allowMultipleOpen,
    data,
    showExpandButton,
    t,
    triggerArray,
    invalidArray,
  } = props;
  const [mode, setAccordionMode] = useState(1);
  const [openSections, setOpenSession] = useState({});

  useEffect(() => {
    if (data.length === Object.keys(openSections).length) {
      if (
        Object.keys(openSections).filter((key) => openSections[key]).length ===
        data.length
      ) {
        setAccordionMode(2);
      }
      if (
        Object.keys(openSections).filter((key) => !openSections[key]).length ===
        data.length
      ) {
        setAccordionMode(1);
      }
    }
  }, [openSections]);

  const handleClick = (id, isToggle = true) => {
    const isOpen = !!openSections[id];

    if (allowMultipleOpen) {
      const newSection = {
        [id]: isToggle ? !isOpen : true,
      };
      setOpenSession({
        ...openSections,
        ...newSection,
      });
    } else {
      if (isToggle)
        setOpenSession({
          [id]: !isOpen,
        });
      else
        setOpenSession({
          [id]: true,
        });
    }
    const formId = document.getElementById(`form${id}`);
    setTimeout(() => {
      id && formId?.scrollIntoView({ behavior: 'smooth' });
    }, 10);
  };

  const changeMode = () => {
    const newMode = mode === 1 ? 2 : 1;
    const filterObj = {};
    data.forEach((child, index) => {
      filterObj[index] = newMode === 2 ? true : false;
    });
    setAccordionMode(newMode);
    setOpenSession(filterObj);
  };

  const refArray = {};
  data.map((d) => {
    refArray[d.componentName] = useRef(null);
  });

  useEffect(() => {
    if (sessionStorage && sessionStorage.getItem('setAccordionOpen')) {
      const accId = parseInt(sessionStorage.getItem('setAccordionOpen'));
      setOpenSession({ [accId]: true });
      sessionStorage.removeItem('setAccordionOpen');
    }
  }, []);
  useImperativeHandle(inputRef, () => ({
    handleRefTrigger() {
      Object.keys(refArray).forEach((d) => {
        if (refArray[d].current !== null && triggerArray.includes(d)) {
          setTimeout(() => {
            refArray[d].current.trigger();
          }, 10);
        }
      });
    },
    handleInvalidRefTrigger() {
      Object.keys(refArray).forEach((d) => {
        if (refArray[d].current !== null && invalidArray.includes(d)) {
          setTimeout(() => {
            refArray[d].current.invalidTrigger();
          }, 10);
        }
      });
    },
  }));
  return (
    <>
      {showExpandButton && (
        <div className="expandOrCollapse">
          <TextButton
            cssClass="blue custom"
            ariaExpanded={mode === 1 ? false : true}
            text={mode === 1 ? t('expand-all-sections') : t('collapse-all-sections')}
            handleTextClick={changeMode}
          />
        </div>
      )}
      <div role="list">
        {data.map((child, index) => (
          <AccordionSection
            isOpen={!!openSections[index]}
            key={index}
            id={index}
            lastAccordionIndex={data.length - 1}
            componentName={child.componentName}
            fields={child.fields}
            inputRef={refArray[child.componentName]}
            onClick={handleClick}
            t={t}
          />
        ))}
      </div>
    </>
  );
};

AccordionBlock.defaultProps = {
  allowMultipleOpen: false,
  data: [],
  t: (val) => val,
  showExpandButton: false,
  triggerArray: [],
  invalidArray: [],
};

AccordionBlock.propTypes = {
  allowMultipleOpen: PropTypes.bool,
  data: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  showExpandButton: PropTypes.bool,
  triggerArray: PropTypes.arrayOf(PropTypes.shape({})),
  invalidArray: PropTypes.arrayOf(PropTypes.shape({})),
};

const ConnectedComponent = AccordionBlock;
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
