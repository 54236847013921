/* eslint-disable max-lines-per-function */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Text,
  withSitecoreContext,
  mediaApi,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import ButtonLink from '../globals/link/ButtonLink';
import { checkDataExists, getImg } from '../../../utils/helperUtils';
import JobSearchForm from '../../core/Search/SearchHeader/JobSearchForm';
import './ExpiredJobBanner.scss';

/**
 *@description -  ExpiredJobBanner standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const ExpiredJobBanner = (props) => {
  const { fields, t, sitecoreContext } = props;
  const {
    mode,
    title,
    text,
    ctaText,
    ctaUrl,
    backgroundImage,
    icon,
    componentBackgroundColor,
  } = fields;

  const useLoc = useLocation();
  const searchPage = sitecoreContext?.Country?.expiredJobSearchURL || '';
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
  const [jodDetails, setJobdetails] = useState();
  const [jobLabelList, setjobLabelList] = useState([]);
  const checkCondition = checkDataExists(mode);
  const direction =
    checkCondition?.toLowerCase() === 'simple'
      ? '267deg'
      : 'to bottom' || 'to bottom';

  let color_code = [];
  const updatebackgroundColor =
    componentBackgroundColor &&
    componentBackgroundColor?.map((item) => {
      const { colorCode } = item?.fields;
      color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
      color_code = color_code.split(',');
      if (color_code.length > 1) {
        color_code = `linear-gradient(${direction}, ${color_code[0]}, ${color_code[1]})`;
      } else {
        color_code = ` ${color_code}`;
      }
      return color_code;
    });
  const styleObj = {
    backgroundImage: backgroundImage
      ? `url(${mediaApi.updateImageUrl(
          getImg(backgroundImage)
        )}), url(${mediaApi.updateImageUrl(getImg(icon))})`
      : null,
  };

  const location = {
    hash: '',
    pathname: `${sitecoreContext?.Country?.expiredJobSearchURL}`,
    search: `${useLoc?.search}`,
    state: undefined,
  };

  useEffect(() => {
    const jobdetalsParams = new URLSearchParams(location?.search) || {};
    setJobdetails(jobdetalsParams);
    setjobLabelList(
      jobdetalsParams?.get('jobDetails')?.replaceAll('_', '|')?.split('|')
    );
  }, [useLoc]);

  // Adding Dynamic Span tag for the Job details text //
  useEffect(() => {
    setTimeout(() => {
      const element =
        document.getElementsByClassName('delimiter-space-wrapper') || [];
      element.forEach(function (item, i) {
        const div = document.createElement('span');
        div.className = 'delimiter-space';
        div.textContent = i < element.length - 1 ? ' | ' : '';
        item.appendChild(div);
      });
    }, 100);
  }, []);

  return (
    <div>
      <div
        className={`expired-job calloutbar-section  ${checkCondition ? checkCondition : ''} ${brandName}`}
        style={{ background: `${updatebackgroundColor}` }}
      >
        {checkDataExists(mode) === 'withlogo' && (
          <>
            <div className="calloutbar-x" style={{ ...styleObj }}></div>
            <div
              className="calloutbar-x mobile"
              style={{
                backgroundImage: backgroundImage
                  ? `url(${mediaApi.updateImageUrl(getImg(icon))})`
                  : null,
              }}
            ></div>
          </>
        )}
        <div className="calloutbar-body container">
          <div className="row">
            <div className="col-md-12">
              <h2
                className={`title ${
                  checkCondition === 'overview' ? '' : 'reversed'
                }`}
              >
                <Text field={title} />
              </h2>
              <p className="reversed large">
                <Text field={text} />
              </p>
              <p className="job-title-x">
                <Text field={{ value: jodDetails?.get('searchKeyword') }} />
              </p>
              <p className="job-title-x">
                {jobLabelList &&
                  jobLabelList?.length > 0 &&
                  jobLabelList.map((list, i) => (
                    <span className="delimiter-space-wrapper" key={i}>
                      {list}
                    </span>
                  ))}
              </p>
              {/* <p>{jobdetalsParams?.get('jobDetails')?.replaceAll('_', '|')}</p> */}
              {checkCondition !== 'overview' ? (
                <ButtonLink cssClass="reversed" ctaText={ctaText} ctaUrl={ctaUrl} />
              ) : (
                <ButtonLink ctaText={ctaText} ctaUrl={ctaUrl} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="job-search">
        <div className="col-md-10 offset-md-1">
          {isExperienceEditorActive() ? (
            <div>Open preview to see the job search form</div>
          ) : (
            <JobSearchForm
              mode=""
              t={t}
              location={location}
              urlVal={searchPage}
              isFilterExists={0}
              defaultRange={20}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ExpiredJobBanner.defaultProps = {
  fields: {
    title: {},
    text: {},
    mode: {},
    ctaText: '',
    ctaUrl: '',
    backgroundImage: '',
    icon: '',
    componentBackgroundColor: [],
    t: (val) => val,
  },
  sitecoreContext: {},
};

ExpiredJobBanner.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    text: {
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    },
    mode: {
      value: PropTypes.oneOf(['withlogo', 'simple', 'overview']),
    },
    ctaText: {
      value: PropTypes.string,
    },
    ctaUrl: {
      value: PropTypes.shape({}),
    },

    backgroundImage: {
      value: PropTypes.string,
    },
    icon: {
      value: PropTypes.string,
    },
    componentBackgroundColor: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  sitecoreContext: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withSitecoreContext()(ExpiredJobBanner));
