/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ProfileItemTemplate from '../Template/ProfileItem';
import { missingInfo } from '../Template/MissingInfoTemplate';
import { dashboardItems } from '../../../../../../utils/enums';
import Loader from '../../../../../core/ErrorBoundary/Loader';

/**
 * @description - SingleView component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const SingleView = ({ fields, profileInfo, t, getEmptyFields, componentName }) => {
  const { items, apiKeyMapper } = fields;
  const [data, setData] = useState({});
  const [candidateId, setCandidateId] = useState('');
  const [noData, showNoData] = useState(false);
  const [loading, setLoading] = useState(true);
  const compName = apiKeyMapper?.value || dashboardItems?.[componentName];

  useEffect(() => {
    if (profileInfo?.Id) {
      setLoading(false);
      const personalDetailsData = {
        data: profileInfo?.[compName]?.data?.[0],
        attachment: profileInfo?.AttachmentDetails,
      };
      setData(personalDetailsData);
      setCandidateId(profileInfo?.Id);
      let emptyCount = 0;
      const emptyObj = {};
      let temp = 0;
      const array = [];
      items &&
        items.map((item) => {
          array.push(missingInfo(item, personalDetailsData));
        });
      array.map((item) => {
        Object.keys(item).map((k) => {
          if (k === 'emptyCount') {
            emptyCount = emptyCount + item[k];
          } else if (k === 'temp') {
            temp = temp + item[k];
          }
        });
      });
      emptyObj[componentName] = {};
      emptyObj[componentName].isValid = emptyCount;
      getEmptyFields(emptyObj);
      if (temp === 0) {
        showNoData(true);
      }
    }
  }, [profileInfo]);

  return (
    <div className="accordion-container-block">
      {data?.data && Object.keys(data?.data).length && !noData > 0 ? (
        items?.map((block, index) => (
          <ProfileItemTemplate
            data={data}
            candidateId={candidateId}
            block={block}
            t={t}
            itemNumber={index}
            id={null}
          />
        ))
      ) : loading ? (
        <Loader
          loaderMsg={t('fetching-your-data')}
          loaderWithMsg
          customLoaderStyle={{
            width: '2rem',
            height: '2rem',
            color: '#C25700',
          }}
          customTextStyle={{ color: '#C25700' }}
        />
      ) : (
        <div className="no-content-data">{t(`no-${componentName}-data`)}</div>
      )}
    </div>
  );
};

SingleView.defaultProps = {
  fields: {},
  t: (val) => val,
  getEmptyFields: () => {},
};

SingleView.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  getEmptyFields: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(SingleView));
