import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { showChatbot } from '../../../redux/actions';
/**
 * @description - Chatbot component.
 * @param {Object} props - Initialize object from sitecore.
 * @returns {Node} - null.
 */
const Chatbot = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const onChatBotReload = () => {
      dispatch(showChatbot());
    };
    window.addEventListener('chatbot-reload', onChatBotReload);
    return () => {
      window.removeEventListener('chatbot-reload', onChatBotReload);
    };
  }, []);

  useEffect(() => {
    if (props?.fields?.config?.value !== '' && props?.showChat) {
      const chatObj = JSON.parse(props?.fields?.config?.value);
      const script = document.createElement('script');
      script.type = 'application/javascript';
      script.async = 'true';
      script.src = 'https://wchat.freshchat.com/js/widget.js';

      script.onload = function () {
        if (typeof window.fcWidget !== 'undefined') {
          const today_hour = new Date().getHours();
          const today_day = new Date().getDay();

          if (
            today_day === 1 ||
            today_day === 2 ||
            today_day === 3 ||
            today_day === 4 ||
            today_day === 5
          ) {
            if (today_hour >= 8 && today_hour <= 17) {
              window.fcWidget.init(chatObj);
            }
          }
        }
      };
      if (typeof window.fcWidget === 'undefined') document.body.appendChild(script);
    }
  }, [props]);

  return null;
};

Chatbot.propTypes = {
  fields: PropTypes.shape({}).isRequired,
};

Chatbot.defaultProps = {
  fields: {},
};
const mapStateToProps = (state) => {
  return {
    showChat: state.chatbotReducer.showChat,
  };
};

export default connect(mapStateToProps)(Chatbot);
