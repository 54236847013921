import { checkColor as toUpperCase } from '../utils/helperUtils';

/**
 * @description - Get filter list.
 * @param {object} options - Search object.
 * @param {string} noneLabel - None string.
 * @returns {Array} - Filtered Array.
 */
export const getFilterArray = (options, noneLabel) => {
  const items = options ? options[0]?.values || [] : [];
  const filterArray = [];
  items.map((itemVal, index) => {
    const val = {
      title: itemVal?.item?.field?.value,
      value: itemVal?.item?.field?.value,
      id: itemVal?.value,
      pos: index + 1,
    };
    if (val?.value && val?.id) {
      filterArray.push(val);
    }
    return val;
  });
  if (filterArray && filterArray.length) {
    filterArray.unshift({
      title: noneLabel,
      value: noneLabel,
      id: null,
      pos: 0,
    });
  }
  return filterArray;
};

/**
 * @description - Get filtered list of sub topics.
 * @param {object} options - Search object.
 * @param {string} noneLabel - None string.
 * @param {string} topic - Parent topic.
 * @returns {Array} - Filtered Array.
 */
export const getSubTopicFilterArray = (options, noneLabel, topic) => {
  const items = options ? options[0]?.values || [] : [];
  const filterArray = [];
  items.map((itemVal, index) => {
    const val = {
      title: itemVal?.item?.name,
      value: itemVal?.item?.name,
      id: itemVal?.value,
      pos: index + 1,
    };
    if (
      val?.value &&
      val?.id &&
      toUpperCase(itemVal?.item?.parent.id) === toUpperCase(topic)
    ) {
      filterArray.push(val);
    }
    return val;
  });
  if (filterArray && filterArray.length) {
    filterArray.unshift({
      title: noneLabel,
      value: noneLabel,
      id: null,
      pos: 0,
    });
  }
  return filterArray;
};
