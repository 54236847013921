const gplVariables = {
  INSIGHTS_BLOCKS: {
    // datasource:
    //   '/sitecore/content/Manpowergroup/Experis/Experis-Norway/Home/Insights/Articles',
    first: 10,
    after: '0',
    contentType: '*',
    topic: '*',
    subTopic: '*',
  },
  PARTNER_ARTICLE: {
    first: 9,
    after: '0',
    updateType: '*',
  },
};

export default gplVariables;
