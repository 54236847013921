import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CardSubpage from '../Cards/CardSubpage';
import CardSubpageDiamond from '../Cards/CardSubpageDiamond';
import { checkDataExists } from '../../../utils/helperUtils';

import './cardblock.scss';

/**
 * @description - subpage card block
 * @param {props} props - input props
 * @returns{node} - HTMl card
 *
 */
const CardBlockSubpage = (props) => {
  const { fields, params } = props;
  const { items = [], componentBackgroundColor } = fields;
  const cssClass = params?.Type ? params?.Type : 'square';
  const updatebackgroundColor =
    componentBackgroundColor &&
    componentBackgroundColor?.map((item) => {
      const { colorCode } = item?.fields;
      let color_code = '';
      color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
      return color_code;
    });

  /**
   * @description-  CardBlockInfo standard component
   * @param {string} cssClass - Input props;
   * @returns{Node} - html block;
   */
  const getTheme = (cssClass) => {
    if (cssClass === 'diamond') {
      return 'gradient_green';
    } else if (cssClass === 'square') {
      return 'gradient_blue';
    }
    return '';
  };
  const theme = getTheme(cssClass.toLowerCase());
  return (
    <section
      className={`card-block subpage ${theme} ${cssClass}`}
      style={{
        background: `${updatebackgroundColor}`,
      }}
    >
      <div className="container">
        <div className="row">
          {items.map((x, index) => (
            <Fragment key={index}>
              <div className="col-lg-6 row-div subblock">
                {cssClass === 'diamond' ? (
                  <CardSubpageDiamond
                    cssClass={cssClass}
                    key={x.key}
                    title={x.fields?.title}
                    text={x.fields?.text}
                    ctaText={x.fields?.ctaText}
                    ctaUrl={x.fields?.ctaUrl}
                    img={x.fields?.image}
                  />
                ) : (
                  <CardSubpage
                    cssClass={cssClass}
                    key={x.key}
                    title={x.fields?.title}
                    text={x.fields?.text}
                    ctaText={x.fields?.ctaText}
                    ctaUrl={x.fields?.ctaUrl}
                    img={x.fields?.image}
                  />
                )}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

CardBlockSubpage.defaultProps = {
  fields: { items: [], componentBackgroundColor: [] },
  params: PropTypes.shape({
    Type: 'manpower',
  }),
};

CardBlockSubpage.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        cssClass: PropTypes.string,
        title: PropTypes.string,
        text: PropTypes.string,
        img: PropTypes.string,
        altText: PropTypes.string,
        ctaText: PropTypes.string,
        ctaUrl: PropTypes.string,
      })
    ).isRequired,
    componentBackgroundColor: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  params: PropTypes.shape({
    Type: PropTypes.string,
  }),
};

export default CardBlockSubpage;
