/* eslint-disable*/
import React, { forwardRef } from 'react';
import { withTranslation } from 'react-i18next';
import SingleForm from './SingleForm';
import MultiForm from './MultiForm';

const NormalForm = ({ inputRef, ...props }) => {
  const { section, componentName, t, hideForm = false } = props;
  const { multipleForms, formsToShow } = section?.fields;
  return (
    <div>
      {multipleForms?.value ? (
        <MultiForm
          hideForm={hideForm}
          componentName={componentName}
          section={section}
          ref={inputRef}
        />
      ) : (
        <SingleForm
          hideForm={hideForm}
          componentName={componentName}
          section={section}
          ref={inputRef}
        />
      )}
    </div>
  );
};

NormalForm.propTypes = {};

const ConnectedComponent = withTranslation()(NormalForm);

export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
