import React from 'react';
import PropTypes from 'prop-types';
import JobDetailsList from '../JobDetailsList/JobDetailsList';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';
import { withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react';

import './JobHeader.scss';

const JobHeader = ({ t, sitecoreContext, ...props }) => {
  const { icons, headerDetails } = props;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  const isStegmanorStegplus = brandName?.indexOf('steg') > -1;

  return (
    <section
      className={`job-header ${isStegmanorStegplus ? 'reversetheme' : brandName}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-10 offset-xl-1">
            <div className="row">
              <div className="col">
                <div className="article-header-container">
                  <Breadcrumb fields={props?.bcItems} />
                  <h1 className="title reversed">{props?.title}</h1>

                  {!props.isApplied ? (
                    <div className="brand-label">{props?.brandLabel}</div>
                  ) : (
                    <div className="applied-job">
                      {<RichText field={{ value: t('dashboardURL') }} tag="div" />}
                    </div>
                  )}
                  <JobDetailsList
                    icons={icons}
                    headerDetails={headerDetails}
                    isApplyASAP={headerDetails?.items?.isApplyASAP}
                    hourPerWeek={headerDetails?.items?.hourPerWeek}
                    education={headerDetails?.items?.education}
                    noOfPositions={headerDetails?.items?.noOfPositions}
                    domain={headerDetails?.items?.domain}
                    remoteJobs={headerDetails?.items?.remoteJobs}
                    jobType={headerDetails?.items?.jobType}
                    salaryRate={headerDetails?.items?.salaryRate}
                    referenceNumber={headerDetails?.items?.jobID}
                    datePosted={headerDetails?.items?.publishfromDate}
                    companyLogo={headerDetails?.items?.customerLogoUrl}
                    applyBy={headerDetails?.items?.applicationDeadlineDate}
                    formOfEmployment={headerDetails?.items?.employmentType}
                    openingParagraph={headerDetails?.items?.openingParagraph}
                    publicDescription={headerDetails?.items?.publicDescription}
                    companyDescription={headerDetails?.items?.companyDescription}
                    jobAdvertisementTeaser={
                      headerDetails?.items?.jobAdvertisementTeaser
                    }
                    experience={headerDetails?.items?.experience}
                    specialisation={headerDetails?.items?.specialisations}
                    applicationType={headerDetails?.items?.applicationType}
                    scheduleType={headerDetails?.items?.scheduleType}
                  />
                  {props?.actionBar && props.actionBar}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

JobHeader.defaultProps = {
  title: '',
  t: () => {},
};

JobHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withSitecoreContext()(JobHeader);
