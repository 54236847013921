import React from 'react';
import PropTypes from 'prop-types';
import CardAd from '../../core/Cards/CardAd';

/**
 * @description - Job profile card.
 * @param {object} param0 - Input props.
 * @returns {Node} - Card Ad component.
 */
const JobProfile = ({ fields }) => {
  const { title, profileStatus, link } = fields;
  return <CardAd title={title} ctaText={profileStatus} ctaUrl={link} />;
};

export default JobProfile;

JobProfile.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    profileStatus: PropTypes.shape({
      value: PropTypes.string,
    }),
    link: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
  }).isRequired,
};

JobProfile.defaultProps = {
  fields: {},
};
