import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import { Text, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { getImg } from '../../../utils/helperUtils';
import './page-banners.scss';
//import mockBannerData from './mockBannerData';

/**
 * @description overlay page banner component
 * @param {*} fields - component content fields
 * @returns {node} - html
 */
const OverlayPageBanner = ({ fields }) => {
  //fields = Object.entries(fields).length ? fields : mockBannerData;
  const { title, subTitle, text, ctaText, ctaUrl, backgroundImage } = fields;

  return (
    <section
      className="banner-section stats overlay-banner"
      style={{
        backgroundImage: `url(${mediaApi.updateImageUrl(getImg(backgroundImage))})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 white">
            <div className="title">{subTitle && <Text field={subTitle} />}</div>
            <h1>{title && <Text field={title} />}</h1>

            <p className="large" id="section-description">
              {text && <Text field={text} />}
            </p>
            {ctaUrl && <ButtonLink ctaText={ctaText} ctaUrl={ctaUrl} />}
          </div>
        </div>
      </div>
    </section>

    /*
    <section class="banner-section stats overlay" style="background-image: url({{ module.hero_banner.src }});">
      <div class="container">
        <div class="row">
          <div class="col-xl-5 col-lg-6 white">
            <div class="title">{{ module.sub_title }}</div>
            <h1>{{ module.title }}</h1>
            <p class="large">{{ module.description }}</p>
            {% if module.cta_url.href %}
            <a href="{{ module.cta_url.href }}" class="primary-button" aria-label="">{{ module.cta_text }}<div class="arrow-icon"></div>
              {% endif %}
            </a>
          </div>
        </div>
      </div>
    </section>
    */
  );
};

OverlayPageBanner.defaultProps = {
  fields: {
    title: { value: '' },
    subTitle: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: {
      value: null,
    },
    backgroundImage: {
      value: {
        src: 'http://via.placeholder.com/1440x583/FEFEFE/EEEEEE',
      },
    },
  },
};

OverlayPageBanner.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default OverlayPageBanner;
