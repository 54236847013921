import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import CardLeader from '../../Cards/CardLeader';
import Slider from 'react-slick';
import { getImg, isDataExists, removeSlideTab } from '../../../../utils/helperUtils';

import '../cardscroll.scss';

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  arrows: false,
  accessibility: true,
  lazyLoad: 'ondemand',
  // eslint-disable-next-line react/display-name
  appendDots: (dots) => {
    return (
      <ul style={{ margin: '0px' }} className={'slick-dots'}>
        {dots.map((dot, index) => (
          <li key={index} className={dot.props.className}>
            <button
              onClick={dot.props.children.props.onClick}
              aria-label={`Slide${dot.props.children.props.children}`}
              aria-Selected={dot.props.className ? 'true' : 'false'}
              aria-current={dot.props.className ? 'true' : 'false'}
            >
              {dot.props.children.props.children}
            </button>
          </li>
        ))}
      </ul>
    );
  },
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

/**
 * @description - Card Scroll Leadership component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML section.
 */
const LeadershipCardScroll = (props) => {
  const {
    fields,
    rendering: { uid },
  } = props;
  const { title, items = [] } = fields;
  const isItemExists = isDataExists(items);

  useEffect(() => {
    removeSlideTab(uid);
  }, []);

  return fields?.items && isItemExists ? (
    <section
      className={`card-scroll scroll_${uid} ${isItemExists ? '' : 'no-padding'}`}
    >
      <div className="container">
        <div className="card-scroll-header">
          <h2 className="title">
            <Text field={title} />
          </h2>
        </div>
        {isItemExists && (
          <Slider {...settings}>
            {items.map((item, idx) => {
              const itemVal = item?.fields || {};
              return (
                <CardLeader
                  key={`card_${idx}`}
                  title={itemVal?.title}
                  text={itemVal?.text}
                  img={itemVal.image?.value || getImg(itemVal.image)}
                  altText={itemVal.image?.altText || `Card Image ${idx}`}
                  ctaUrl={itemVal?.ctaUrl?.value}
                />
              );
            })}
          </Slider>
        )}
      </div>
    </section>
  ) : null;
};

LeadershipCardScroll.defaultProps = {
  fields: {
    title: '',
    items: [],
  },
  rendering: {},
};

LeadershipCardScroll.propTypes = {
  fields: PropTypes.shape({
    /** Header title */
    title: PropTypes.shape({
      value: PropTypes.string,
    }),

    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  rendering: PropTypes.shape({ uid: PropTypes.string }),
};

export default withTranslation()(withSitecoreContext()(LeadershipCardScroll));
