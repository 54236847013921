import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import TextLink from '../globals/link/TextLink';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { setAltUrlForImg, checkDataExists } from '../../../utils/helperUtils';
import './cards.scss';

/**
 * @description - subpage card block
 * @param {props} props - input props
 * @returns{node} - HTMl card
 *
 */
const CardSubpage = ({ title, text, img, altText, ctaText, ctaUrl, cssClass }) => {
  const externalLinkType = ctaUrl?.value?.linktype === 'internal' ? false : true;
  return (
    <div className={`card article-simple ${cssClass}`}>
      <div className="subpage-image">
        <Image field={setAltUrlForImg(img, altText)} />
      </div>
      <div className="card-body subpage-subcard">
        <div>
          <h2 className="title">
            <Text field={title} />
          </h2>
          <p className="large">
            <Text field={text} />
          </p>
        </div>
        {checkDataExists(ctaUrl) && checkDataExists(ctaText) && (
          <div className="subpage-button">
            {cssClass === 'manpower' ? (
              <div className="cta">
                <TextLink ctaText={ctaText} ctaUrl={ctaUrl} />
              </div>
            ) : (
              <ButtonLink
                ctaText={ctaText}
                ctaUrl={ctaUrl}
                ctaLabel={`${title} ${ctaText}`}
                externalLink={externalLinkType}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

CardSubpage.defaultProps = {
  cssClass: '',
  title: '',
  text: '',
  img: 'http://via.placeholder.com/600x300',
  altText: '',
  ctaText: '',
  ctaUrl: '#',
};

CardSubpage.propTypes = {
  cssClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  altText: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
};

export default CardSubpage;
