import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - HomeIconCard component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const HomeIconCard = ({
  cssClass,
  title,
  cardUrl,
  img,
  cardBodyClass = 'col-lg-4 col-6',
}) => (
  <div className={cardBodyClass}>
    <div className={`icon__card ${cssClass}`}>
      <a className="card__link" href={cardUrl}>
        <Image className="icon" field={img} />
        <span className="title">{title}</span>
      </a>
    </div>
  </div>
);

HomeIconCard.defaultProps = {
  cssClass: '',
  title: '',
  cardUrl: '#',
  img: 'http://via.placeholder.com/500',
  cardBodyClass: 'col-lg-4 col-6',
};

HomeIconCard.propTypes = {
  cssClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cardUrl: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  cardBodyClass: PropTypes.string,
};

export default HomeIconCard;
