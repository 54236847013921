import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import MetaData from '../../core/MetaData';

/**
 * @description - MetaData from header.
 * @param {object} props - Input props.
 * @returns {Node}.
 */
const MetaDataComp = (props) => {
  const helmet = Helmet.peek();
  if (!helmet.title) {
    return <MetaData {...props} />;
  }
  return null;
};

MetaDataComp.propTypes = {
  fields: PropTypes.shape({}).isRequired,
};

MetaDataComp.defaultProps = {
  fields: {},
};

export default MetaDataComp;
