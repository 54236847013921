/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@apollo/react-hooks';
import gqlQueries from './graphql';
import gplVariables from './variables';

/**
 * @description - Gets article and case study list
 * @param {number} pageCount - Pagination count.
 * @param {string} contentType - Content Type id.
 * @param {string} topic - Topic id
 * @param {string} subTopic - SubTopic id
 * @returns {async} - Query object.
 */
export const getInsightsList = (
  pageCount,
  contentType = '*',
  topic = '*',
  subTopic = '*'
) => {
  return useQuery(gqlQueries.GET_INSIGHT_ARTICELS, {
    variables: {
      first: pageCount || gplVariables.INSIGHTS_BLOCKS.first,
      after: gplVariables.INSIGHTS_BLOCKS.after,
      contentType,
      topic,
      subTopic,
    },
    fetchPolicy: 'cache-and-network',
    ssr: false,
  });
};

/**
 * @description - Gets topic list for insights filter
 * @param {string} contentType - Content Type id
 * @returns {async} - Query object.
 */
export const getTopicsList = (contentType) => {
  if (contentType) {
    return useQuery(gqlQueries.GET_TOPICS, {
      variables: {
        contentType,
      },
      fetchPolicy: 'cache-and-network',
    });
  }
  return {};
};

/**
 * @description - Gets topic list for insights filter
 * @param {string} contentType - Content Type id.
 * @param {string} topic - Topic id
 * @returns {async} - Query object.
 */
export const getSubTopicsList = (contentType = '*', topic) => {
  if (topic) {
    return useQuery(gqlQueries.GET_SUB_TOPICS, {
      variables: {
        contentType,
        topic,
      },
      fetchPolicy: 'cache-and-network',
    });
  }
  return {};
};

/**
 * @description - Gets article and case study list
 * @param {number} pageCount - Pagination count.
 * @param {string} updateType - Type id.
 * @returns {async} - Query object.
 */
export const getPartnerArticleList = (pageCount, updateType = '*') => {
  return useQuery(gqlQueries.GET_PARTNER_ARTICLES, {
    variables: {
      first: pageCount || gplVariables.PARTNER_ARTICLE.first,
      after: gplVariables.PARTNER_ARTICLE.after,
      updateType,
    },
    fetchPolicy: 'cache-and-network',
    ssr: false,
  });
};

/**
 * @description - Get site search results
 * @param {number} pageCount - Pagination count.
 * @param {string} contentType - Type id.
 * @returns {async} - Query object.
 */
export const getSiteSearch = (pageCount, contentType = '*') => {
  return useQuery(gqlQueries.GET_SITE_SEARCH_RESULT, {
    variables: {
      first: pageCount || gplVariables.PARTNER_ARTICLE.first,
      after: gplVariables.PARTNER_ARTICLE.after,
      contentType,
    },
    fetchPolicy: 'cache-and-network',
    ssr: false,
  });
};
