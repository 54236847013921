import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import Checkbox from '../../../core/globals/forms/Checkbox';

/**
 * @description - CreateProfile Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const ConsentBlock = ({ fields, register, errors }) => {
  const { Description, Abstract, consent1, consent2 } = fields;
  return (
    <section className="consent-block">
      <div>
        <Text field={Abstract} />
      </div>
      <div className="consent-text">
        <RichText field={Description} />
      </div>
      <Checkbox
        id="job_application_consent"
        name="jobAppConsent"
        text={consent1?.value}
        required={true}
        register={register}
        errors={errors}
      />
      <Checkbox id="promotional_communication_consent" text={consent2?.value} />
    </section>
  );
};

ConsentBlock.propTypes = {
  fields: PropTypes.shape({
    Description: PropTypes.string.isRequired,
    Abstract: PropTypes.string.isRequired,
    consent2: PropTypes.string.isRequired,
    consent1: PropTypes.string.isRequired,
  }).isRequired,
};

ConsentBlock.defaultProps = {
  fields: {},
};

export default ConsentBlock;
