import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { showString } from '../../../../utils/helperUtils';
import './links.scss';
import './buttons.scss';

/**
 * @description - StaticLink Component.
 * @param {Object} Params - Input Parameters.
 * @returns {Node} - HTML Template.
 */
const StaticLink = ({
  cssClass,
  ctaText,
  ctaLabel,
  ctaIcon,
  arrow,
  additionalProps,
}) => {
  return (
    <div className="textLink">
      <div className={`${cssClass} link-div`} aria-label={ctaLabel}>
        <div className={`cta-text ${cssClass}`} {...additionalProps}>
          {ctaText?.value ? <Text field={ctaText} /> : showString(ctaText)}
          {arrow && <div className="arrow-icon" aria-hidden="true"></div>}
        </div>
        {ctaIcon && (
          <div className="cta-icon">
            <img src={ctaIcon} alt="icon" />
          </div>
        )}
      </div>
    </div>
  );
};

StaticLink.defaultProps = {
  cssClass: '',
  ctaText: '',
  ctaLabel: undefined,
  ctaIcon: '',
  arrow: false,
  additionalProps: {},
};

StaticLink.propTypes = {
  cssClass: PropTypes.string,
  ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ctaLabel: PropTypes.string,
  ctaIcon: PropTypes.string,
  arrow: PropTypes.bool,
  additionalProps: PropTypes.shape({}),
};

export default StaticLink;
