/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { Controller } from 'react-hook-form';
import get from 'lodash/get';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';

/**
 * @description - Dynamic CheckboxBoolean component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const CheckboxBoolean = ({
  id,
  name,
  text,
  label,
  isHideField,
  showBasedOnFieldName,
  hideBasedOnFieldName,
  customError,
  dataValidations,
  watch,
  customStyle,
  value,
  t,
  readOnly,
  setValue,
  control,
  validateOnLoad,
  requiredValidation = [],
  formName,
}) => {
  const fieldError = get(customError, name);
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;

  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    setValue(name, value, param);
  }, [value]);

  return (
    <div
      className={`form-block chkBox ${fieldError ? 'error withoutBg' : ''} ${
        !showField || hideField ? 'hide' : ''
      }`}
      style={customStyle}
    >
      <div className="chkBox">
        <Controller
          control={control}
          name={name}
          render={({ onChange, onBlur, value }) => (
            <input
              id={id}
              type="checkbox"
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              checked={value}
              aria-checked={value}
              disabled={readOnly}
            />
          )}
          rules={{
            required:
              showField && requiredData?.fields?.value?.value ? !hideField : false,
          }}
        />
        <label htmlFor={`${id}`} className="checkmark"></label>
        <label htmlFor={`${id}`} className="labelText">
          {text?.value ? (
            <RichText field={text} editable={false} className="checkBoxLabel" />
          ) : (
            label
          )}
          {!text && <span className="sr-only">Checkbox Label</span>}
        </label>
      </div>
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
    </div>
  );
};

CheckboxBoolean.defaultProps = {
  id: 'id',
  setValue: () => {},
  t: () => {},
};

CheckboxBoolean.propTypes = {
  id: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  t: PropTypes.func,
};

export default CheckboxBoolean;
