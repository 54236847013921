/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SocialMediaSignIn from '../SocialMediaSignIn';
import Button from '../../core/globals/buttons/Button';
import TextLink from '../../core/globals/link/TextLink';
import DynamicForm from '../../core/Forms/DynamicForm';
import { ROMA_INTEGRATION, API_STATUS_CODES, SEARCH } from '../../../constants';
import { usePostData } from '../../../hooks/http-client';
import SnackBar from '../../core/ErrorBoundary/SnackBar';
import { useSnackbar } from '../../../hooks/useSnackbar';

import './createAccSweden.scss';

/**
 * @description - ROMA SignUp component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML to render.
 */
const RomaSignUp = ({ fields, t, sitecoreContext }) => {
  const { items = [], Title } = fields;
  const { isActive, message, openSnackBar } = useSnackbar();
  const [param, setParam] = useState(new URLSearchParams());
  const [jobTitle, setJobTitle] = useState('');
  const location = useLocation();
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    trigger,
    control,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    revalidate: 'onBlur',
    shouldFocusError: false,
  });
  const { write, error, loading } = usePostData();
  const ifItemExists = items?.length > 0;

  useEffect(() => {
    if (error) {
      openSnackBar(t('http-error'));
    }
  }, [error]);

  useEffect(() => {
    setParam(new URLSearchParams(location.search));
  }, [location]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const posId = urlParams.get('positionID');
    const data = {
      filter: {
        page: '1',
        searchKeyword: posId,
        offset: 0,
        totalCount: 0,
        limit: 10,
        searchkeyword: posId,
        haslocation: false,
        language: '',
      },
    };
    if (posId) {
      write(`${SEARCH.JOB_SEARCH.SEARCH_API_NEW}`, data, prepareJobTitle);
    }
  }, []);

  /**
   * @description - Callback for setting the job title.
   * @param {object} response - Response object.
   * @returns {undefined}
   */
  const prepareJobTitle = (response) => {
    const data = response?.data;
    const { jobsItems } = data;
    setJobTitle(jobsItems[0]?.jobTitle);
  };

  /**
   * @description - On submit handler.
   * @param {object} values - Form values.
   * @returns {undefined}
   */
  const onSubmit = (values) => {
    write(
      `${ROMA_INTEGRATION.ROMA_AUTHENTICATE}`,
      {
        Request: {
          PositionID: param.get('positionID') || null,
          Source: param.get('source') || null,
        },
        Application: {
          UserName: values.emailAddress,
          Password: btoa(values.password),
          email: values.emailAddress,
          LegalNoticeSignedDate:
            values.consent1 && values.consent1 === 'true'
              ? String(values.consent1)
              : '',
          MarketingConsentSignedDate:
            values.consent2 && values.consent2 === 'true'
              ? String(values.consent2)
              : '',
          Action: 'signup',
          SSOID: '',
        },
      },
      handleCreateResponse,
      {
        PositionID: param.get('positionID'),
        method: 'email',
      }
    );
  };

  /**
   *
   * @param {*} paramStr
   */
  const setParamOnError = (paramStr) => {
    const paramObj = paramStr ? JSON.parse(paramStr) : {};
    const paramVal = new URLSearchParams(location.search);
    paramVal.set('positionID', paramObj.PositionId || '');
    paramVal.set('source', paramObj?.Source || '');
    setParam(paramVal);
  };

  /**
   * @description - Handle signup success/failure response.
   * @param {object} response - Response object.
   * @returns {undefined}
   */
  const handleCreateResponse = (response, params) => {
    if (response.data.Status === API_STATUS_CODES.SUCCESS) {
      if (!response.data.Data) {
        openSnackBar(t('roma-already-applied'));
      } else {
        if (window?.dataLayer) {
          window.dataLayer.push({
            event: 'createAccount',
            type: params?.PositionID ? 'withJobAd' : 'noJobAd',
            eventCategory: 'Create an account',
            eventAction: `Create account with ${params?.method}`,
            eventLabel: params?.PositionID
              ? 'Apply > Create account'
              : 'Create account',
          });
        }
        window.location.assign(response.data.Data);
      }
    } else {
      setParamOnError(response.data.Message);
      if (response.data.Status === API_STATUS_CODES.NOT_FOUND) {
        openSnackBar(t('roma-wrong-username-or-password'));
      } else if (response.data.Status === API_STATUS_CODES.DATA_EXIST) {
        openSnackBar(t('roma-username-already-exists'));
      } else if (response.data.Status === API_STATUS_CODES.LIMIT_EXCEEDS) {
        openSnackBar(t('roma-position-deleted'));
      } else {
        openSnackBar(t('roma-error'));
      }
    }
  };

  /**
   * @description - Social sign Up handler.
   * @param {object} values - User values.
   * @returns {undefined}
   */
  const onSocialSignUp = (values = {}) => {
    const tokenParams = {};
    let consent1 = true;
    let consent2 = false;
    let PositionID = param.get('positionID') || null;
    let Source = param.get('source') || null;
    if (values.SSOID === 'Facebook') {
      const formValues = getValues();
      consent1 = formValues.consent1;
      consent2 = formValues.consent2;
    }
    if (values.SSOID === 'LinkedIn') {
      const { code, state, RedirectUri } = values;
      tokenParams.code = code;
      tokenParams.state = state;
      tokenParams.RedirectUri = RedirectUri;
      consent1 = values.consent1;
      consent2 = values.consent2;
      PositionID = values.PositionID || null;
      Source = values.Source || null;
    }

    write(
      `${ROMA_INTEGRATION.ROMA_AUTHENTICATE}`,
      {
        Request: {
          PositionID: PositionID,
          Source: Source,
        },
        Application: {
          UserName: values.email,
          Password: values.accessToken,
          email: values.email,
          LegalNoticeSignedDate:
            consent1 && consent1 === 'true' ? String(`${consent1}`) : '',
          MarketingConsentSignedDate:
            consent2 && consent2 === 'true' ? String(`${consent2}`) : '',

          Action: 'signup',
          SSOID: values.SSOID,
        },
        ...tokenParams,
      },
      handleCreateResponse,
      {
        PositionID: PositionID,
        method: values.SSOID,
      }
    );
  };

  /**
   * @description - Social sign Up error handler.
   * @returns {undefined}
   */
  const onSocialSignUpError = (error) => {
    if (error.toString().indexOf('Facebook is not initialized.') > -1) {
      openSnackBar(t('onetrust-error'));
    } else {
      openSnackBar(t('facebook-sign-in-error'));
    }
  };

  return (
    <section className="create-acc-sw container">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {jobTitle && (
          <div>
            <p className="apply-text">{t('you-are-applying-to')}</p>
            <p className="job-title-roma">{jobTitle}</p>
          </div>
        )}
        <h2>
          <Text field={Title} />
        </h2>
        <div className="row form-section justify-content-md-center">
          <div className="social-media-signin col-lg-5 col-xl-4">
            <SocialMediaSignIn
              onError={onSocialSignUpError}
              onSuccess={onSocialSignUp}
              redirectTo={location.pathname?.replace(
                `/${sitecoreContext?.language}/`,
                '/'
              )}
              facebookText={t('roma-sign-up-with-facebook')}
              linkedInText={t('roma-sign-up-with-linkedin')}
              trigger={trigger}
              getValues={getValues}
              param={param}
              clearErrors={clearErrors}
            />
          </div>
          <div className="wrapper col-lg-1">
            <div className="line"></div>
            <div className="wordwrapper">
              <div className="word">{t('roma-or')}</div>
            </div>
          </div>

          <div className="manual-signin col-lg-5 col-xl-4">
            <DynamicForm
              formData={ifItemExists ? items[0]?.fields?.items : []}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              control={control}
            />
          </div>
        </div>
        <div className="consent-section row justify-content-md-center">
          <div className="col col-lg-11 col-xl-9">
            <DynamicForm
              formData={ifItemExists ? items[1]?.fields?.items : []}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              control={control}
            />
          </div>
        </div>

        <div className="footer-section">
          <Button
            text={t('roma-create-account')}
            handleButtonClick={handleSubmit(onSubmit)}
            type="submit"
            isLoading={loading}
          />
          <div className="sign-in">
            <span>{t('roma-already-have-an-account')}</span> &nbsp;
            <TextLink
              ctaText={ifItemExists ? items[2]?.fields?.ctaText : t('roma-sign-in')}
              ctaUrl={ifItemExists ? items[2]?.fields?.ctaUrl : ''}
            />
          </div>
        </div>
        <SnackBar isActive={isActive} message={message} />
      </form>
    </section>
  );
};

RomaSignUp.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

RomaSignUp.defaultProps = {
  fields: {},
  sitecoreContext: {},
};

export default withSitecoreContext()(withTranslation()(RomaSignUp));
