import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CreateNewAccount from './CreateNewAccount';
import VerifyYourIdentitySelection from './VerifyYourIdentitySelection';
import VerifyYourIdentity from './VerifyYourIdentity';
import CreateAccountSuccess from './CreateAccountSuccess';

/**
 * @description - CreateProfile Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const CreateAccount = ({ fields }) => {
  const [step, setStep] = useState({ activeStep: 0 });
  const stepRef = useRef(null);

  const navigateToCreateAccount = () => {
    setStep({ activeStep: 1 });
  };

  const handleEmailVerification = () => {
    setStep({ activeStep: 2 });
  };

  const handleVerfication = () => {
    setStep({ activeStep: 3 });
  };
  const handleProfile = () => {
    setStep({ activeStep: 0 });
  };

  return (
    <React.Fragment>
      {step.activeStep === 0 && (
        <CreateNewAccount
          fields={fields}
          handleCreateAccount={navigateToCreateAccount}
          ref={stepRef}
        />
      )}
      {step.activeStep === 1 && (
        <VerifyYourIdentitySelection
          handleEmailVerification={handleEmailVerification}
        />
      )}
      {step.activeStep === 2 && (
        <VerifyYourIdentity
          verifyIdentityMessage="A 6-digit validation code was sent to the email address associated with your account.Please enter the code to verify your account."
          handleVerfication={handleVerfication}
        />
      )}
      {step.activeStep === 3 && (
        <CreateAccountSuccess handleProfile={handleProfile} />
      )}
    </React.Fragment>
  );
};

CreateAccount.propTypes = {
  fields: PropTypes.shape({}).isRequired,
};

CreateAccount.defaultProps = {
  fields: {},
};

export default CreateAccount;
