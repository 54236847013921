/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import SubNavBox from '../SubNavBox/SubNavBox';
import Button from '../../globals/buttons/Button';

import '../subnav.scss';

/**
 * @description - sub navigation component
 * @param {*} fields - sub navigation component fields
 * @returns {Node} - HTML component
 */
const SubNav = ({ fields, params, t }) => {
  const { title, items } = fields;
  const [showMore, setShowMore] = useState(
    items && items.length > Number(params?.noOfItems) ? true : false
  );
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    setNavItems(
      showMore
        ? items && Array.isArray(items) && items.slice(0, parseInt(params.noOfItems))
        : items
    );
  }, [showMore]);

  return (
    <section className="navbox-section" id="watch-navbox">
      <div className="container">
        {title && <h2 className="title">{title?.value}</h2>}
        <div className="navbox-items">
          {navItems &&
            Array.isArray(navItems) &&
            navItems.map((item) => (
              <SubNavBox
                key={item?.title}
                icon={item?.fields?.image}
                altText={item?.fields?.altText}
                title={item?.fields?.title}
                url={item?.fields?.url}
              />
            ))}
        </div>
        {showMore && (
          <Button
            cssClass="orange"
            text={t('load-more')}
            handleButtonClick={() => setShowMore(false)}
          />
        )}
      </div>
    </section>
  );
};

SubNav.defaultProps = {
  fields: {
    title: {
      value: 'IT Focus Areas',
    },
    items: [
      {
        name: 'Business',
        displayName: 'Business',
        fields: {
          title: {
            value: 'Business',
          },
          url: {
            value: {
              href: '',
              linktype: 'internal',
            },
          },
          image: {
            src: '',
          },
        },
      },
    ],
  },
  params: {
    noOfItems: '',
  },
};

SubNav.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          title: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
          url: PropTypes.shape({
            value: PropTypes.shape({
              href: PropTypes.string.isRequired,
              linktype: PropTypes.string,
            }),
          }),
          image: PropTypes.shape({
            src: PropTypes.string.isRequired,
          }),
        }),
      })
    ),
    btnText: PropTypes.string,
  }),
  params: PropTypes.shape({
    noOfItems: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(SubNav);
