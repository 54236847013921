import { GET_INSIGHT_ARTICELS, GET_TOPICS, GET_SUB_TOPICS } from './insightsQueries';
import { GET_PARTNER_ARTICLES } from './partnerArticleQueries';
import { GET_SITE_SEARCH_RESULT } from './siteSearchQueries';

const gqlQueries = {
  GET_INSIGHT_ARTICELS,
  GET_TOPICS,
  GET_SUB_TOPICS,
  GET_PARTNER_ARTICLES,
  GET_SITE_SEARCH_RESULT,
};

export default gqlQueries;
