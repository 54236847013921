/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, withRouter } from 'react-router-dom';

import SearchEverythingItem from './SearchEverythingItem';
import SearchPagination from './SearchPagination';
import Loader from '../../../core/ErrorBoundary/Loader';
import Error from '../../../core/ErrorBoundary/Error';
import { usePostData } from '../../../../hooks/http-client';
import { SEARCH } from '../../../../constants';
import NoData from '../../ErrorBoundary/NoData';
import usePrevious from '../../../../hooks/usePrevious';
import { pushSearchPageToDataLayer } from '../../../../utils/helperUtils';

import '../search.scss';

/**
 * @description to display search results
 * @param {string} cssClass - to change style properties
 * @returns {node} - html
 */
const SearchEverythingResults = ({ fields, t = () => {} }) => {
  const { cssClass, itemPerPage } = fields;
  const location = useLocation();
  const searchKey = new URLSearchParams(useLocation().search).get('searchText');
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, error, write } = usePostData();
  const [result, setResult] = useState();
  const prevValue = usePrevious({ location });

  useEffect(() => {
    if (searchKey) {
      setCurrentPage(1);
      getData(1);
    }
  }, [searchKey]);

  useEffect(() => {
    if (window?.dataLayer && searchKey && result?.data) {
      window.dataLayer.push({
        event: 'WebsiteSearch',
        eventCategory: 'Website Search',
        eventAction: searchKey,
        eventLabel:
          result?.data?.searchResult && result?.data?.searchResult?.length > 0
            ? result?.data?.searchResult[0]?.title
            : '',
      });
    }
  }, [result]);

  useEffect(() => {
    pushSearchPageToDataLayer(prevValue?.location, location, t('search-searchsite'));
  }, [location]);

  /**
   * @description to fetch data from api
   * @param {number} currentPage - page selected
   * @returns {undefined}
   */
  const getData = (currentPage) => {
    write(
      `${SEARCH.SITE_SEARCH.SEARCH_API}`,
      {
        limit: itemPerPage?.value,
        offset: currentPage - 1,
        searchKeyword: searchKey,
      },
      setResult
    );
  };

  /**
   * @description to load selected page content
   * @param {number} selectedPage - selected page number
   * @returns {array} - page content
   */
  const handlePageChange = (selectedPage) => {
    if (selectedPage !== currentPage) {
      setCurrentPage(selectedPage);
      getData(selectedPage);
    }
    const elmnt = document.getElementsByClassName('search-everything-results');
    setTimeout(() => {
      elmnt[0].scrollIntoView(
        {
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        },
        500
      );
    });
  };

  return (
    <>
      <section className={`search-everything-results ${cssClass}`}>
        <div>
          <h1 className="sr-only">{t('site-search-results')}</h1>
          {loading && <Loader />}
          {error && <Error errorMsg={t('search-errormessage')} />}
          {result?.data?.searchResult?.length === 0 && (
            <NoData message={t('search-emptyresults')} />
          )}
          {!loading &&
            result?.data?.searchResult?.map((item, index) => {
              return (
                <SearchEverythingItem
                  key={index}
                  title={item.title}
                  text={item.description || t('search-defaultdescription')}
                  ctaUrl={item.url}
                />
              );
            })}
        </div>
      </section>
      {result?.data?.searchResult?.length > 0 && (
        <SearchPagination
          showJobAlert={false}
          handlePageChange={handlePageChange}
          totalRecords={result?.data?.totalCount}
          pageLimit={parseInt(itemPerPage.value)}
          selectedPage={currentPage}
          label={t('search-page')}
        ></SearchPagination>
      )}
    </>
  );
};

SearchEverythingResults.defaultProps = {
  items: [],
  fields: {
    cssClass: {
      value: '',
    },
    itemPerPage: {
      value: '10',
    },
  },
  t: () => {},
};

SearchEverythingResults.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields: PropTypes.shape({
    cssClass: PropTypes.shape({
      value: PropTypes.string,
    }),
    itemPerPage: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func,
};

export default withRouter(SearchEverythingResults);
