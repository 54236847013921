import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/vidyard';
import { isNullorEmpty } from '../../../../src/utils/helperUtils';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import './vidyardplayer.scss';

/**
 * @description - Component.
 * @param {Object} props - Props passed.
 * @returns {Node} - Element.
 */
const VidyardPlayer = (props) => {
  const { playerid, videoUrl, videoPreviewImg, title, videoDuration, color } = props;
  const wistia_url = 'https://video.vidyard.com/watch/' + videoUrl;

  return (
    <>
      <a
        className="video__link"
        data-fancybox="myvid"
        data-src={`#${playerid}`}
        href="#!"
      >
        <Image className="video__preview" editable={true} field={videoPreviewImg} />
        {!isNullorEmpty(videoDuration) && videoDuration.value !== '' && (
          <span className="video__duration">
            <Text field={videoDuration} />
          </span>
        )}
      </a>
      {!isNullorEmpty(title) && title.value !== '' && (
        <span className="video__title" style={{ color: color }}>
          <Text field={title} />
        </span>
      )}

      <div style={{ display: 'none' }} id={playerid} className="vidyard-content">
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={wistia_url}
            width="100%"
            height="100%"
            config={{
              vidyard: {
                options: { autoPlay: 0 },
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

VidyardPlayer.defaultProps = {
  title: '',
  videoDuration: '',
  videoUrl: '',
  videoPreviewImg: '',
  color: '',
};

VidyardPlayer.propTypes = {
  title: PropTypes.string.isRequired,
  playerid: PropTypes.string.isRequired,
  videoPreviewImg: PropTypes.string.isRequired,
  videoDuration: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default VidyardPlayer;
