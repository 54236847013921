import React, { Fragment, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './apsisForm.scss';

/**
 * @description - Apsis Form for news letter.
 * @param {object} props - Input props.
 * @returns {Node} - Apsis Form
 */
const ApsisForm = ({ params, t }) => {
  const [showForm, setShowForm] = useState(false);
  const formId = params?.formId;

  useEffect(() => {
    setShowForm(true);
  }, []);

  return formId ? (
    <Fragment>
      <Helmet>
        {showForm && (
          <script>
            {(function () {
              if (window && window.ApsisForms) {
                const form = new window.ApsisForms.FormbuilderInstance();
                form.init({
                  formId: formId,
                });
              }
            })()}
          </script>
        )}
      </Helmet>
      <section className="apsis-form newsletter">
        <div className="title">{t('newsletter-title')}</div>
        <p className="text">{t('newsletter-subtitle')}</p>
        <div className={`afp-${formId}`}></div>
      </section>
    </Fragment>
  ) : null;
};

ApsisForm.propTypes = {
  params: PropTypes.shape({
    formId: PropTypes.string.isRequired,
  }),
};

ApsisForm.defaultProps = {
  params: {},
};

export default withTranslation()(ApsisForm);
