import React from 'react';
import PropTypes from 'prop-types';
import { Text, mediaApi } from '@sitecore-jss/sitecore-jss-react';

import TextLink from '../globals/link/TextLink';
import StaticLink from '../globals/link/StaticLink';
import { showString } from '../../../utils/helperUtils';
import './cards.scss';

/**
 * @description - On enter key press for accessibility.
 * @param {object} e - Event object.
 * @param {Function} clickHandler - Event handler function.
 * @returns {undefined}
 */
const onEnter = (e, clickHandler) => {
  if (e && e.keyCode === 13) {
    clickHandler(e);
  }
};

/**
 * @description - Card component for card scrolls.
 * @param {*} param0 - Input props.
 * @returns {Node} - HTML card.
 */
const Card = ({
  title,
  text,
  img,
  altText,
  ctaText,
  ctaUrl,
  clickHandler,
  brandName,
}) => {
  const imgVal = mediaApi.updateImageUrl(img);
  return (
    <div
      className={`card standard card--wipe ${brandName} `}
      onClick={clickHandler}
      onKeyUp={(e) => onEnter(e, clickHandler)}
      role="presentation"
    >
      <span className="card--wipe--apollo" />
      <figure
        className="article-image"
        style={{ backgroundImage: `url(${imgVal})` }}
        aria-hidden="true"
      >
        <img className="sr-only" alt={altText} src={imgVal} />
      </figure>
      <div className="card-body">
        <h3 className="title">
          {title?.value ? <Text field={title} /> : showString(title)}
        </h3>
        <p>{text?.value ? <Text field={text} /> : showString(text)}</p>
      </div>
      {ctaText?.value && (
        <div
          className={`cta__wrap ${
            brandName === 'manpower' ? 'card-footer__manpower' : 'card-footer'
          }`}
        >
          {clickHandler ? (
            <StaticLink
              ctaText={ctaText}
              arrow
              additionalProps={{
                tabIndex: 0,
                role: 'link',
              }}
            />
          ) : (
            <TextLink
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              ctaLabel={`${title?.value}_${ctaText?.value}`}
              tabIndex={ctaUrl === '' ? -1 : 0}
              arrow
            />
          )}
        </div>
      )}
    </div>
  );
};

Card.defaultProps = {
  title: '',
  text: '',
  img: {},
  altText: '',
  ctaText: 'Read more',
  ctaUrl: '',
  brandName: '',
  clickHandler: () => {},
};

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  text: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  img: PropTypes.shape({}),
  altText: PropTypes.string,
  ctaText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  ctaUrl: PropTypes.string,
  brandName: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  clickHandler: PropTypes.func,
};

export default Card;
