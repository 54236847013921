import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './boundaries.scss';

/**
 * @description - overlay Loader.
 * @param {*} param0 - Props.
 * @returns {Node} - HTML.
 */
const CustomOverlayLoader = ({
  loaderMsg,
  customLoaderMsg,
  showSpinner = false,
  customLoaderStyle,
  customTextStyle,
  customStyle,
}) => (
  <div className="overlayCustom text-center" style={showSpinner ? customStyle : {}}>
    {showSpinner ? (
      <div className="container-fluid customLoader">
        <div className="row justify-content-center">
          <div className="spinner-border" style={customLoaderStyle} role="status">
            <span className="sr-only">{customLoaderMsg}</span>
          </div>
        </div>
        <div className="row justify-content-center" style={customTextStyle}>
          <strong>{customLoaderMsg}</strong>
        </div>
      </div>
    ) : (
      <Fragment>
        {customLoaderMsg && <span className="loadingText">{customLoaderMsg}</span>}
        <div
          className="spinner-grow"
          style={{ width: '0.5rem', height: '0.5rem' }}
          role="status"
        />
        <div
          className="spinner-grow"
          style={{ width: '0.5rem', height: '0.5rem' }}
          role="status"
        />
        <div
          className="spinner-grow"
          style={{ width: '0.5rem', height: '0.5rem' }}
          role="status"
        />
        <span className="sr-only">{loaderMsg}</span>
      </Fragment>
    )}
  </div>
);

CustomOverlayLoader.propTypes = {
  loaderMsg: PropTypes.string,
  customLoaderMsg: PropTypes.string,
  showSpinner: PropTypes.bool,
  customLoaderStyle: PropTypes.shape({}),
  customTextStyle: PropTypes.shape({}),
  customStyle: PropTypes.shape({}),
};

CustomOverlayLoader.defaultProps = {
  loaderMsg: 'Loading...',
  customLoaderMsg: '',
  showSpinner: false,
  customLoaderStyle: {},
  customTextStyle: {},
  customStyle: { opacity: 1, background: 'transparent' },
};

export default CustomOverlayLoader;
