import React, { useState, useEffect, Fragment, useRef } from 'react';
import { isNullorEmpty } from './../../../../utils/helperUtils';
import PropTypes from 'prop-types';
import './forms.scss';

/**
 *
 * @param {*} props - props
 * @returns {*} - action
 */
const RadioToggle = (props) => {
  const { options, name, selectedValue, selectHandler } = props;
  const { cssClass, inputRef, notRequired, showOrgVal } = props;
  const [selected, setSelected] = useState(selectedValue);
  const focusId = props?.focusId ? props?.focusId : null;
  const radioRef = useRef(null);

  useEffect(() => {
    setSelected(selectedValue);
    if (!isNullorEmpty(radioRef) && !isNullorEmpty(radioRef?.current))
      radioRef.current.focus();
    window.scrollTo(0, 0);
  }, [selectedValue]);

  /**
   *@param {*} event - event
   *@param {*} option - option
   *@returns {*} - action
   */
  const handleSelect = (event, option) => {
    if (event && event.target) {
      setSelected(event.target.value);
      if (selectHandler) {
        selectHandler(event, option);
      }
    }
  };

  /**
   *
   * @param {*} event - event
   * @param {*} value - value
   * @returns {*} - action
   */
  const unSelectRadio = (event, value) => {
    if (notRequired && selected === value) {
      setSelected('');
      if (selectHandler) {
        selectHandler(event, '');
      }
    }
  };

  return (
    <Fragment>
      <ul className={`form-radio-group checklist ${cssClass}`} role="radiogroup">
        {options.map((option) => {
          const { id, text, value, disabled = false, count = '' } = option || {};
          const val = value || text;
          const checkedVal = showOrgVal
            ? {}
            : {
                checked:
                  decodeURIComponent(val).replace(/,/g, '') ===
                  decodeURIComponent(selected),
              };
          return (
            <li className="checklist__item" key={id}>
              <div className="form-check radio-toggle">
                <input
                  className="form-check-input"
                  type="radio"
                  ref={
                    !isNullorEmpty(focusId) && focusId?.current === id
                      ? radioRef
                      : inputRef
                  }
                  name={name}
                  id={id}
                  value={val}
                  disabled={disabled}
                  {...checkedVal}
                  onChange={(event) => handleSelect(event, option)}
                  onClick={(event) => unSelectRadio(event, val)}
                  onKeyUp={(e) => {
                    if (e && e.keyCode === 13) unSelectRadio(e, val);
                  }}
                />
                <label className="form-check-label" htmlFor={id}>
                  {text}
                </label>
                {count && count !== '' ? <span>{count}</span> : null}
              </div>
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
};

RadioToggle.defaultProps = {
  options: [],
  selectedValue: '',
  selectHandler: () => {},
  cssClass: '',
  notRequired: false,
  showOrgVal: false,
  inputRef: () => {},
};

RadioToggle.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Field identifier  */
      id: PropTypes.string.isRequired,

      /** Label text */
      text: PropTypes.string.isRequired,
      value: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  name: PropTypes.string.isRequired,
  selectedValue: PropTypes.string,
  selectHandler: PropTypes.func,
  cssClass: PropTypes.string,
  notRequired: PropTypes.bool,
  showOrgVal: PropTypes.bool,
  inputRef: PropTypes.func,
};

export default RadioToggle;
