/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  isObjNotEmpty,
  isDataExists,
  parseFilterData,
  setIdsParamAtLast,
  getTitle,
} from '../../../../utils/helperUtils';
import { withTranslation } from 'react-i18next';
import { SEARCH } from '../../../../constants';
import TextButton from '../../../core/globals/buttons/TextButton';
import Icon from '../../../../assets/images/icon__close_pills_grey.svg';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { toJson } from 'really-relaxed-json';
/**
 *
 * @param {*} props - props
 * @returns {*} - action
 */
const FilterPill = (props) => {
  const { data, t, iconClose } = props;
  const history = useHistory();
  const [filterpills, setFilterpills] = useState();
  const updateArticle = (e, filterData, name, id) => {
    const params = new URLSearchParams(location?.search);
    const filter = typeof window !== 'undefined' ? parseFilterData() : undefined;
    const filterObj = filter || {};
    const fieldName = name;
    params.delete(fieldName);
    const finalVal = filterData;
    if (fieldName === 'radius' || fieldName === 'salaryRange') {
      filterObj[fieldName] = finalVal;
    } else if (isDataExists(finalVal)) {
      filterObj[fieldName] = finalVal;
    } else {
      delete filterObj[fieldName];
    }
    filterObj['sf'] = fieldName;
    let newfilterObj = [];
    newfilterObj = filterObj[fieldName].map((item, index) => {
      if (item.key === id) {
        delete filterObj[fieldName][index];
        !isObjNotEmpty(filterObj[fieldName])
          ? delete filterObj[fieldName]
          : filterObj;
      }
    });
    let newobj = {};
    newobj = { ...newobj, ...filterObj };

    if (isObjNotEmpty(newobj)) {
      let ids = {};
      Object.keys(newobj).forEach((filter) => {
        let temp = [];
        if (filter === 'salaryRange') {
          params.set('salaryMin', newobj[filter].min);
          params.set('salaryMax', newobj[filter].max);
        } else if (Array.isArray(newobj[filter])) {
          ids[filter] = [];
          newobj[filter].filter((type) => {
            temp.push(type['value']?.replace(/,/g, ''));
            ids[filter].push(type['key']);
          });
          params.set(filter, temp.join(','));
        } else params.set(filter, newobj[filter]);
      });
      if (isObjNotEmpty(ids))
        params.set('ids', JSON.stringify(ids).split('"').join(''));
    }
    params.set('page', 1);
    setIdsParamAtLast(params);
    history.replace({
      pathname: location?.pathname,
      search: String(decodeURIComponent(params)),
    });
  };

  const getData = (filter, item) => {
    if (filter === 'selectedDistance') {
      return item.key;
    }

    return item.value;
  };

  const clearAll = (e) => {
    const params = new URLSearchParams(location?.search);

    const filter = typeof window !== 'undefined' ? parseFilterData() : undefined;
    const filterObj = filter || {};

    const filterIds =
      JSON.parse(toJson(decodeURIComponent(params.get('ids')))) || {};
    filterpills.data.map((item, index) => {
      params.delete(item);
      delete filterIds[item];
    });
    if (isObjNotEmpty(filterIds))
      params.set('ids', JSON.stringify(filterIds).split('"').join(''));
    history.replace({
      pathname: location?.pathname,
      search: String(decodeURIComponent(params)),
    });
  };

  useEffect(() => {
    if (data) {
      let filterpills = Object.keys(data)?.filter(
        (filter, index) => data[filter] && data[filter] !== null
      );
      let count = 0;
      filterpills.map((item) => {
        count = count + data[item].length;
      });
      setFilterpills({ data: filterpills, count });
    }
  }, [location.search, data]);
  return (
    <>
      {filterpills?.data
        ? filterpills?.data?.map(
            (filter, index) =>
              data[filter] !== null &&
              data[filter].map((item) => (
                <div className={`${item?.id} primary-button search-pill`}>
                  <span key={`filteritem_${index}`} className="closebutton">
                    <span className="">{`${t(getTitle(filter))}: ${getData(
                      filter,
                      item
                    )}`}</span>
                  </span>
                  <span
                    className="closeDetail"
                    role="button"
                    onClick={(e) => {
                      updateArticle(e, data[filter], filter, item.key);
                    }}
                    tabIndex={0}
                    onKeyUp={null}
                    aria-label="Close button"
                  >
                    x
                  </span>
                </div>
              ))
          )
        : null}
      {filterpills?.count > 1 && (
        <TextButton
          text={t('clear-all')}
          handleTextClick={clearAll}
          cssClass="clear-filters"
        />
      )}
    </>
  );
};

FilterPill.defaultProps = {
  id: '',
  text: 'Button',
  dataAttr: '',
  cssClass: '',
};

FilterPill.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  dataAttr: PropTypes.string,

  /** Color class  */
  cssClass: PropTypes.string.isRequired,
};

export default withTranslation()(FilterPill);
