/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
/*eslint-disable*/
import PropTypes from 'prop-types';

/**
 * @description - File upload Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const NormalUpload = ({ inputRef, ...props }) => {
  const { onSuccess, id, name } = props;
  const fileUpload = useRef();

  const handleChange = (e) => {
    onSuccess(e.target.files[0]);
  };

  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      fileUpload.current.click();
    },
  }));
  const handleClick = (event) => {
    event.target.value = '';
  };

  return (
    <input
      type="file"
      id={id}
      name={`${name}_file_upload`}
      accept=".doc, .docx, .html, .pdf"
      value={''}
      ref={fileUpload}
      onChange={handleChange}
      onClick={handleClick}
    />
  );
};

NormalUpload.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired,
};

NormalUpload.defaultProps = {
  onSuccess: () => {},
  img: '',
};

export default forwardRef((props, ref) => {
  return <NormalUpload {...props} inputRef={ref} />;
});
