/* eslint-diable */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import '../../apply_job_flow.scss';
import './statusText.scss';

/**
 * @description - Success component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const SpontaneousJobApplyStatusText = ({ fields, t }) => {
  const { text = {} } = fields;
  return (
    <Fragment>
      <div className="col-lg-6 offset-lg-3 job-title">
        <span>{t('Spontaneous-Job-Title')}&nbsp;</span>
      </div>
      <div className="create-profile-success-flex col-lg-6 offset-lg-3">
        <div className="container greet-container">
          {text && <RichText field={text} className="congrats-section" />}
        </div>
      </div>
    </Fragment>
  );
};

SpontaneousJobApplyStatusText.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    text: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
};

SpontaneousJobApplyStatusText.defaultProps = {
  fields: {},
  sitecoreContext: {},
  t: (val) => val,
};
export default withTranslation()(SpontaneousJobApplyStatusText);
