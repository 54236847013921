/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import ButtonLink from '../../../../core/globals/link/ButtonLink';
import { showString } from '../../../../../utils/helperUtils';
import { DATE_FORMATS } from '../../../../../utils/enums';
import moment from 'moment';
import ProgressBar from '../../../../core/ProgressBar';

/**
 * @description - DashboardProgressBarBlock component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const DashboardProgressBarBlock = ({ fields, t, sitecoreContext, profileInfo }) => {
  const { text, title, link, buttonText, type, percentage } = fields;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';
  const layoutType = type ? type : '1';
  const [updatedDate, setUpdatedDate] = useState('');

  useEffect(() => {
    if (profileInfo?.Id) {
      const theDate = new Date(profileInfo?.LastModifiedDate);
      const dateString = theDate.toGMTString();
      const date = dateString;
      setUpdatedDate(date);
    }
  }, [profileInfo]);

  const template1 = (
    <div className={`row dashboard-summary dashboard-block ${brandName} normal`}>
      <div className={`dashboard-progress ${buttonText ? 'col-xl-4' : 'col-xl-6'}`}>
        {title ? title?.replace('{0}', percentage) : showString(title)}
        <br aria-hidden={true} />
        {updatedDate && (
          <div className="dashboard-last-updated only-mobile ">
            {t('last-updated')}{' '}
            {moment(updatedDate).format(DATE_FORMATS.LOCALE_SMALL)}
          </div>
        )}
        {percentage > 0 && percentage < 100 && (
          <ProgressBar
            completed={percentage}
            primaryColor={'#345F9A'}
            secondaryColor={'#1C3E6B'}
            gradient
          />
        )}
      </div>
      <div
        className={`dashboard-tip ${brandName} ${
          buttonText ? 'col-xl-4' : 'col-xl-6'
        }`}
      >
        <strong>{t('tip')} </strong>
        {text ? text?.replace('{0}', percentage) : showString(text)}
      </div>
      {buttonText && (
        <div className={'dashboard-continue text-center col-xl-4'}>
          <ButtonLink
            cssArrowClass="styleArrow"
            ctaText={buttonText}
            ctaUrl={{ href: link || '/', linktype: 'internal' }}
            arrow
          />
        </div>
      )}
    </div>
  );

  const template2 = (
    <div className={`row dashboard-summary dashboard-block ${brandName}`}>
      <div className="dashboard-text col-xl-12">
        {title ? title?.replace('{0}', percentage) : showString(title)}
        {updatedDate && (
          <div className="dashboard-last-updated only-mobile ">
            {t('last-updated')}{' '}
            {moment(updatedDate).format(DATE_FORMATS.LOCALE_SMALL)}
          </div>
        )}
      </div>
      <div className="dashboard-progress col-xl-6 col-sm-8 col-md-6">
        {percentage > 0 && percentage < 100 && (
          <ProgressBar
            completed={percentage}
            primaryColor={'#1c3e6b'}
            secondaryColor={'#30588f'}
            gradient
          />
        )}
      </div>
      <div className="dashboard-tip col-xl-6 offset-xl-3">
        <strong>{t('tip')} </strong>
        {text ? text?.replace('{0}', percentage) : showString(text)}
      </div>
      {buttonText && (
        <div className="dashboard-continue text-center col-xl-6 offset-xl-3">
          <ButtonLink
            cssClass="orange"
            cssArrowClass="styleArrow"
            ctaText={buttonText}
            ctaUrl={{ href: link || '/', linktype: 'internal' }}
          />
        </div>
      )}
    </div>
  );

  return (
    <>
      {updatedDate && (
        <div className="row only-desktop">
          <div className="col">
            <div className="dashboard-last-updated">
              {t('last-updated')}{' '}
              {moment(updatedDate).format(DATE_FORMATS.LOCALE_SMALL)}
            </div>
          </div>
        </div>
      )}
      {title && (layoutType === '1' ? template1 : template2)}
    </>
  );
};

DashboardProgressBarBlock.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}),
};

DashboardProgressBarBlock.defaultProps = {
  fields: {},
  t: (val) => val,
  sitecoreContext: {},
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default connect(mapStateToProps)(
  withSitecoreContext()(withTranslation()(DashboardProgressBarBlock))
);
