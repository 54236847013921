import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import ButtonLink from '../../../../core/globals/link/ButtonLink';
import { showString } from '../../../../../utils/helperUtils';
import { DATE_FORMATS } from '../../../../../utils/enums';
import moment from 'moment';
import ProgressBar from '../../../../core/ProgressBar';

/**
 * @description - DashboardProgressBarBlock component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const ProgressBarBlock = ({ fields, t, sitecoreContext, profileInfo }) => {
  const { items } = fields;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';
  const [percentage, setPercentage] = useState(0);
  const [updatedDate, setUpdatedDate] = useState('');

  useEffect(() => {
    if (profileInfo?.Id) {
      const theDate = new Date(profileInfo?.LastModifiedDate);
      const dateString = theDate.toGMTString();
      const date = dateString;
      setUpdatedDate(date);
      const percentValue = parseInt(profileInfo?.ProfilePercentage);
      setPercentage(percentValue);
    }
  }, [profileInfo]);

  const template1 = (layout) => {
    return (
      <div className={`row dashboard-summary dashboard-block ${brandName} normal`}>
        <div
          className={`dashboard-progress ${
            layout?.ctaText?.value ? 'col-xl-4' : 'col-xl-6'
          }`}
        >
          {layout?.title?.value
            ? layout?.title?.value?.replace('{0}', percentage)
            : showString(layout?.title?.value)}
          <br aria-hidden={true} />
          {updatedDate && (
            <div className="dashboard-last-updated only-mobile ">
              {t('last-updated')}{' '}
              {moment(updatedDate).format(DATE_FORMATS.LOCALE_SMALL)}
            </div>
          )}
          {percentage < 100 && (
            <ProgressBar
              completed={percentage}
              primaryColor={'#345F9A'}
              secondaryColor={'#1C3E6B'}
              gradient
            />
          )}
        </div>
        <div
          className={`dashboard-tip ${brandName} ${
            layout?.ctaText?.value ? 'col-xl-4' : 'col-xl-6'
          }`}
        >
          <strong>{t('tip')} </strong>
          {layout?.text?.value
            ? layout?.text?.value?.replace('{0}', percentage)
            : showString(layout?.text?.value)}
        </div>
        {layout?.ctaText && (
          <div className={'dashboard-continue text-center col-xl-4'}>
            <ButtonLink
              cssArrowClass="styleArrow"
              ctaText={layout?.ctaText}
              ctaUrl={layout?.ctaUrl}
              externalLink={
                layout?.ctaUrl?.value?.linktype === 'internal' ? false : true
              }
              arrow
            />
          </div>
        )}
      </div>
    );
  };

  const template2 = (layout) => {
    return (
      <div className={`row dashboard-summary dashboard-block ${brandName}`}>
        <div className="dashboard-text col-xl-12" role="heading" aria-level="1">
          {layout?.title?.value
            ? layout?.title?.value?.replace('{0}', percentage)
            : showString(layout?.title?.value)}
          {updatedDate && (
            <div className="dashboard-last-updated only-mobile ">
              {t('last-updated')}{' '}
              {moment(updatedDate).format(DATE_FORMATS.LOCALE_SMALL)}
            </div>
          )}
        </div>
        <div className="dashboard-progress col-xl-6 col-sm-8 col-md-6">
          {percentage < 100 && (
            <ProgressBar
              completed={percentage}
              primaryColor={'#1c3e6b'}
              secondaryColor={'#30588f'}
              gradient
            />
          )}
        </div>
        <div className="dashboard-tip col-xl-6 offset-xl-3">
          <strong>{t('tip')} </strong>
          {layout?.text?.value
            ? layout?.text?.value?.replace('{0}', percentage)
            : showString(layout?.text?.value)}
        </div>
        {layout?.ctaText && (
          <div className="dashboard-continue text-center col-xl-6 offset-xl-3">
            <ButtonLink
              cssClass="orange"
              cssArrowClass="styleArrow"
              ctaText={layout?.ctaText}
              ctaUrl={layout?.ctaUrl}
              externalLink={
                layout?.ctaUrl?.value?.linktype === 'internal' ? false : true
              }
            />
          </div>
        )}
      </div>
    );
  };

  const getTemplate = () => {
    const layout = items?.filter(function (el) {
      const layoutVar =
        percentage < 100 ? el.name === 'Partial' : el.name === 'Complete';
      return layoutVar;
    })?.[0]?.fields;
    const template =
      layout?.type?.value === '1' ? template1(layout) : template2(layout);
    return template;
  };

  return (
    <>
      {updatedDate && (
        <div className="row only-desktop">
          <div className="col">
            <div className="dashboard-last-updated">
              {t('last-updated')}{' '}
              {moment(updatedDate).format(DATE_FORMATS.LOCALE_SMALL)}
            </div>
          </div>
        </div>
      )}
      {percentage > 0 && getTemplate()}
    </>
  );
};

ProgressBarBlock.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}),
};

ProgressBarBlock.defaultProps = {
  fields: {},
  t: (val) => val,
  sitecoreContext: {},
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default connect(mapStateToProps)(
  withSitecoreContext()(withTranslation()(ProgressBarBlock))
);
