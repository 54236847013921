/* eslint-disable*/
import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { jobApply } from '../../../../utils/enums';
import NormalForm from './NormalForm';

const ToggleForm = ({ inputRef, ...props }) => {
  const { section, t, updateSelectedTab } = props;
  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);
  const [currentActiveTabIndex, setCurrentActiveTabIndex] = useState(0);
  const [sectionComponentDetails, setSectionComponentDetails] = useState({});

  useEffect(() => {
    const tabFields = section?.fields?.items?.[0]?.fields;
    getTabItemClicked(0, tabFields?.items);
  }, []);

  const getTabItemClicked = (selectedIndex, tabSections) => {
    let mandatorySections = [];
    tabSections?.map((item) => {
      if (item?.fields?.isMandatory?.value)
        mandatorySections.push(item?.fields?.sectionName?.value);
    });
    const sectionNames = mandatorySections.join(',');
    tabItemClicked(selectedIndex, sectionNames);
  };

  /**
   * @description - switches the nav tabs.
   * @param {number} selectedIndex - Nav index which need to be activated
   * @returns {void} - void.
   */
  const tabItemClicked = (selectedIndex, sections) => {
    setCurrentActiveTabIndex(selectedIndex);
    updateSelectedTab({ key: selectedIndex, sections });
  };

  const getData = (sectionFields) => {
    const { hideIfDataExist, sectionName, apiKeyMapper } = sectionFields;
    const apiMapper = apiKeyMapper?.value
      ? apiKeyMapper?.value
      : jobApply?.[sectionName?.value];
    let res = false;
    if (hideIfDataExist?.value) {
      if (profileInfo?.[apiMapper]?.data?.length > 0) {
        res = true;
      }
    }
    return res;
  };

  useEffect(() => {
    let componentPerSection = {};
    let componentDetails = {};
    section?.fields?.items?.map((sectionItem, index) => {
      let componentCount = 0;
      let formsWithData = [];
      sectionItem?.fields?.items?.map((item, ind) => {
        componentPerSection[index] = ++componentCount;
        const { hideIfDataExist, sectionName, apiKeyMapper } = item?.fields;
        const apiMapper = apiKeyMapper?.value
          ? apiKeyMapper?.value
          : jobApply?.[sectionName?.value];
        if (hideIfDataExist?.value) {
          if (profileInfo?.[apiMapper]?.data?.length > 0) {
            formsWithData.push(sectionName?.value);
          }
        }
      });
      componentDetails[index] = {
        componentCount,
        formsWithData,
      };
    });

    setSectionComponentDetails(componentDetails);
  }, [profileInfo]);

  return (
    <>
      <nav className="toggle-style">
        <div className="toggle toggle__tabs" role="tablist">
          {section?.fields?.items?.map((item, navTabIndex) => (
            <button
              style={{ background: 'transparent', border: 0 }}
              key={'tabbedNavItemNo_' + navTabIndex}
              className={`toggle__item toggle__link ${
                currentActiveTabIndex === navTabIndex ? 'active' : ''
              }`}
              id={'nav-tab' + navTabIndex}
              data-toggle="tab"
              role="tab"
              aria-controls={'navTabGroupNo_' + navTabIndex}
              aria-selected="true"
              onClick={() => getTabItemClicked(navTabIndex, item?.fields?.items)}
            >
              {item?.fields?.title?.value}
            </button>
          ))}
          <span className="toggle-slider"></span>
        </div>
      </nav>
      <div className="tab__content">
        {section?.fields?.items?.map((sectionItem, index) => {
          return (
            <div
              className={`${
                index === currentActiveTabIndex ? 'active_tab' : 'non_active_tab'
              }`}
            >
              <>
                {sectionComponentDetails?.[index]?.componentCount ===
                sectionComponentDetails?.[index]?.formsWithData?.length
                  ? sectionItem?.fields?.abstract?.value && (
                      <RichText
                        tag="div"
                        className="sub-title-text"
                        field={sectionItem?.fields?.abstract}
                      />
                    )
                  : sectionItem?.fields?.subTitle?.value && (
                      <RichText
                        tag="div"
                        className="sub-title-text"
                        field={sectionItem?.fields?.subTitle}
                      />
                    )}
                {sectionItem?.fields?.items?.map((item) => {
                  return (
                    <NormalForm
                      hideForm={getData(item?.fields)}
                      componentName={item?.fields?.sectionName?.value}
                      section={item}
                      ref={inputRef[item?.fields?.sectionName?.value]}
                      formIndex={index}
                    />
                  );
                })}
              </>
            </div>
          );
        })}
      </div>
    </>
  );
};
ToggleForm.defaultProps = {
  section: {},
  t: () => {},
  updateSelectedTab: () => {},
};

ToggleForm.propTypes = {
  section: PropTypes.shape({}),
  t: PropTypes.func,
  updateSelectedTab: PropTypes.func,
};

const ConnectedComponent = withTranslation()(ToggleForm);

export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
