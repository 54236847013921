import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import MSALView from './MSALView';

/**
 * @description _ Login Component.
 * @param {object} props - Props object.
 * @returns {Node} - HTML node.
 */
const Login = (props) => {
  const LoginView = MSALView;
  return <LoginView {...props} />;
};

Login.propTypes = {
  isMobile: PropTypes.bool,
};

Login.defaultProps = {
  isMobile: false,
};

export default withTranslation()(Login);
