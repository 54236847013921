import React from 'react';
import PropTypes from 'prop-types';
import { Text, Image, Link as JssLink } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

import '../subnav.scss';

/**
 * @description - sub navigation tile component
 * @param {string} title - title to be displayed
 * @param {string} icon - icon to be displayed
 * @param {string} url - url to be redirected on click
 * @returns {Node} - HTML
 */
const SubNavBox = ({ title, icon, url }) =>
  url?.value?.linktype === 'external' ? (
    <JssLink field={url?.value} className="navbox image--wipe">
      <figure role="presentation">
        {icon ? (
          <>
            <Image className="icon" media={icon} />
            <figcaption className="caption" role="presentation">
              <Text field={title} editable={false} />
            </figcaption>
          </>
        ) : (
          <figcaption className="caption" role="presentation">
            <Text field={title} editable={false} />
          </figcaption>
        )}
      </figure>
      <span className="wipe--apollo" />
    </JssLink>
  ) : (
    <Link to={url?.value?.href} className="navbox image--wipe">
      <figure role="presentation">
        {icon ? (
          <>
            <Image className="icon" media={icon} />
            <figcaption className="caption" role="presentation">
              <Text field={title} editable={false} />
            </figcaption>
          </>
        ) : (
          <figcaption className="caption" role="presentation">
            <Text field={title} editable={false} />
          </figcaption>
        )}
      </figure>
      <span className="wipe--apollo" />
    </Link>
  );

SubNavBox.defaultProps = {
  title: '',
  icon: '',
  url: {
    value: {
      href: '',
      linktype: 'internal',
    },
  },
};

SubNavBox.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.shape({}),
};

export default SubNavBox;
