import { JOBSEARCHINFO } from '../actions/actionTypes';

const initialState = {
  data: {},
  loading: false,
};

/**
 *
 * @param {*} state - state
 * @param {*} action - action
 * @returns {*} - action
 */
const jobSearchInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOBSEARCHINFO.SAVE:
      if (action.payload?.loading) {
        return {
          data: {},
          loading: true,
        };
      }
      return {
        ...state,
        data: action.payload?.data,
        loading: action.payload?.loading,
      };
    case JOBSEARCHINFO.CLEAR:
      return {
        ...state,
        data: {},
        loading: false,
      };
    default:
      return state;
  }
};
export default jobSearchInfoReducer;
