/* eslint-disable require-jsdoc */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import TextButton from '../globals/buttons/TextButton';
import './applyprocess.scss';

/**
 * @description - TextBlock component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const ApplicationProcessBlock = ({ fields, t }) => {
  const loadMore = () => {
    setHideMore(false);
  };
  const [hideMore, setHideMore] = useState(fields?.items?.length < 4 ? false : true);
  if (fields?.items)
    return (
      <div className="apply-process-progess-outer">
        <div className="container">
          <div className={`apply-process-progess-inner`}>
            <div className="container">
              <div className="row">
                <div className="col">
                  <h2 className="title">{t('application-process')}</h2>
                </div>
              </div>
              <div className="row">
                {fields?.items?.map((item, index) => (
                  <div
                    className={`col-lg-3 col-md-4 col-sm-12 ${
                      hideMore && index > 3 ? 'hideon-mob' : ''
                    }`}
                    key={index.toString()}
                  >
                    <div className="steps-container">
                      <div className="img-container">
                        <Image className="infoIcon" field={item?.image} />
                      </div>
                      <div className="main-text">{`${index + 1}. ${
                        item?.title
                      }`}</div>
                      <div className="sub-text">{item?.subTitle}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={`row `}>
                <div className="col">
                  {hideMore && (
                    <div aria-label={t('load-more')} className="load-more-btn">
                      <TextButton text={t('load-more')} handleTextClick={loadMore} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  return null;
};

ApplicationProcessBlock.defaultProps = {
  fields: {},
};

ApplicationProcessBlock.propTypes = {
  fields: PropTypes.shape({}),
  t: PropTypes.shape({}).isRequired,
};

export default withTranslation()(ApplicationProcessBlock);
