import React from 'react';
import './verify_your_identity.scss';
import { withTranslation } from 'react-i18next';
import Button from '../../../core/globals/buttons/Button';
import Checkbox from '../../../core/globals/forms/Checkbox';
import TextButton from '../../../core/globals/buttons/TextButton';
import PropTypes from 'prop-types';

/**
 * @description - CreateProfile Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const VerifyYourIdentity = ({ verifyIdentityMessage, handleVerfication, t }) => {
  return (
    <section className="verify-identity">
      <div className="container flex-container">
        <div className="title-text-account">{t('verification-title')}</div>
        <div className="message-text-verify-identity">
          <p className="verify-identity-text">{verifyIdentityMessage}</p>
        </div>
        <div className="input-verify-identity">
          <input
            type="text"
            placeholder="Enter the 6-digit code"
            autoComplete="off"
            aria-label="Enter the 6-digit code"
          />
        </div>
        <div className="difficult-text-verify-identity">
          {t('having-difficulty')}
          <a className="another-way-verify-identity" href="/">
            {t('try-another-way')}
          </a>
        </div>
        <div className="button-verify-identity">
          <Button text={t('verify-account')} handleButtonClick={handleVerfication} />
        </div>
        <Checkbox id="remember_device" text={t('remember-me')} />
        <div className="text-button-verify-identity">
          <TextButton cssClass="dark-gray" text="RETURN TO JOB RESULTS" />
        </div>
      </div>
    </section>
  );
};

VerifyYourIdentity.propTypes = {
  t: PropTypes.func.isRequired,
  handleVerfication: PropTypes.func.isRequired,
  verifyIdentityMessage: PropTypes.string,
};

VerifyYourIdentity.defaultProps = {
  verifyIdentityMessage: '',
};

export default withTranslation()(VerifyYourIdentity);
