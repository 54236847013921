import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import '../../business/EmbeddedScript/embedded-script.scss';

/**
 * @description - EmbeddedScriptBlock component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const EmbeddedScriptBlock = (props) => {
  const { fields = {} } = props;
  const { widgetType, widgetContent, anchorId } = fields;
  const compId =
    anchorId !== 'undefined' && anchorId?.value !== ''
      ? anchorId?.value
      : 'supporting-data';
  return (
    <>
      <Helmet>
        {widgetType?.value === 'Turtl' && (
          <script
            async
            type="text/javascript"
            data-turtl-script="embed"
            data-turtl-assets-hostname="https://assets.turtl.co"
            src="https://app-static.turtl.co/embed/turtl.embed.v1.js"
          ></script>
        )}
        {widgetType?.value === 'Infogram' && (
          <script>
            {`!(function (e, i, n, s) {var t = 'InfogramEmbeds', d = e.getElementsByTagName('script')[0]; if (window[t] && window[t].initialized)window[t].process && window[t].process(); else if (!e.getElementById(n)) {var o = e.createElement('script');(o.async = 1),(o.id = n), (o.src = 'https://e.infogram.com/js/dist/embed-loader-min.js'), d.parentNode.insertBefore(o, d); } })(document, 0, 'infogram-async')`}
          </script>
        )}
        {widgetType?.value === 'Ceros' && (
          <script
            type="text/javascript"
            src="https://view.ceros.com/scroll-proxy.min.js"
            data-ceros-origin-domains="view.ceros.com"
          ></script>
        )}
      </Helmet>
      <div className="embedded-script-block" id={`${compId}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <RichText field={widgetContent} className="content" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EmbeddedScriptBlock.defaultProps = {
  fields: {},
};

EmbeddedScriptBlock.propTypes = {
  fields: PropTypes.shape({
    widgetType: PropTypes.shape({ value: PropTypes.string }),
    widgetContent: PropTypes.shape({ value: PropTypes.string }),
    anchorId: PropTypes.shape({ value: PropTypes.string }),
  }),
};

export default EmbeddedScriptBlock;
