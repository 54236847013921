import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ButtonLink from '../../../../core/globals/link/ButtonLink';
import '../../apply_job_flow.scss';
import ProgressBar from '../../../../core/ProgressBar';
import Loader from '../../../../core/ErrorBoundary/Loader';
import Error from '../../../../core/ErrorBoundary/Error';

/**
 * @description - Success component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const SuccessBlock = ({ fields, t, profileInfo }) => {
  const { items } = fields;
  const [percentage, setPercentage] = useState(0);
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (profileInfo?.Id) {
      setprofileDataLoading(false);
      const percentValue = parseInt(profileInfo?.ProfilePercentage);
      setPercentage(percentValue);
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);

  /**
   * @description - Template for partially complete profile.
   * @param {Object} layout - Partial layout.
   * @returns {Node} - HTML Template.
   */
  const partialTemplateWithProgressBar = (layout) => {
    const titleText = layout?.title?.value?.replace('{0}', percentage);
    return (
      <div className="build-profile">
        <div className="titleText">{titleText}</div>
        <div className="col-lg-6 col-xl-6 col-md-6 col-sm-8 customStyle">
          <ProgressBar
            completed={percentage}
            primaryColor={'#1c3e6b'}
            secondaryColor={'#30588f'}
            gradient
          />
        </div>
        {layout?.text?.value && (
          <div className="tipText">
            <strong>{t('tip')}&nbsp;</strong>
            <span>{layout?.text?.value}</span>
          </div>
        )}
        {layout?.ctaText?.value && (
          <div className="btnContainer">
            <ButtonLink
              ctaText={layout?.ctaText}
              ctaUrl={layout?.ctaUrl}
              externalLink={
                layout?.ctaUrl?.value?.linktype === 'internal' ? false : true
              }
            />
          </div>
        )}
      </div>
    );
  };

  /**
   * @description - Template for Fully complete profile.
   * @param {Object} layout - Partial layout.
   * @returns {Node} - HTML Template.
   */
  const completedProfileTemplate = (layout) => {
    return (
      <div className="build-profile">
        <div className="titleText">{layout?.title?.value}</div>
        <div className="sub-text">{layout?.text?.value}</div>
        {layout?.ctaText?.value && (
          <div className="btnContainer">
            <ButtonLink
              ctaText={layout?.ctaText}
              ctaUrl={layout?.ctaUrl}
              externalLink={
                layout?.ctaUrl?.value?.linktype === 'internal' ? false : true
              }
            />
          </div>
        )}
      </div>
    );
  };

  /**
   * @description - Function to get the respective template based on percentage.
   * @returns {Node} - HTML Template.
   */
  const getTemplate = () => {
    const layout = items?.filter(function (el) {
      const layoutVar =
        percentage < 100 ? el.name === 'Partial' : el.name === 'Complete';
      return layoutVar;
    })?.[0]?.fields;
    const template =
      percentage < 100
        ? layout?.showProgressBar?.value === true
          ? partialTemplateWithProgressBar(layout)
          : completedProfileTemplate(layout)
        : completedProfileTemplate(layout);
    return template;
  };

  return (
    <div className="create-profile-success-block col-lg-6 offset-lg-3">
      {profileDataLoading ? (
        <Loader
          loaderMsg={t('fetching-your-data')}
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#C25700',
          }}
          customTextStyle={{ color: '#C25700' }}
        />
      ) : error ? (
        <div>
          <Error errorMsg={t('error-message')} t={t} />
        </div>
      ) : (
        <>{Object.keys(fields).length > 0 && getTemplate()}</>
      )}
    </div>
  );
};

SuccessBlock.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  profileInfo: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

SuccessBlock.defaultProps = {
  fields: {},
  profileInfo: {},
  t: (val) => val,
};

/**
 * @description - Function to map reducer data to props.
 * @param {Object} state - Store value.
 * @returns {Object} Reducer data as props.
 */
const mapStateToProps = (state) => {
  return { profileInfo: state.profileInfoReducer.data };
};

export default connect(mapStateToProps)(withTranslation()(SuccessBlock));
