import React from 'react';
import PropTypes from 'prop-types';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';

import { checkString } from '../../../../utils/helperUtils';
import '../page-banners.scss';

/**
 * @description - Standard Page Banner component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const SEOPageBanner = ({ fields }) => {
  const { description, image, fontColor, backgroundColor } = fields;
  const bgColor = checkString(backgroundColor).toLowerCase();

  return (
    <section
      className={`banner-section seo-standard`}
      style={{ backgroundColor: bgColor }}
    >
      <div className="container">
        <div className="row align-items-md-end justify-content-center">
          {image?.value?.src ? (
            <>
              <div className="col-lg-2">
                <div className="d-flex justify-content-center justify-content-lg-end">
                  <h1 className="headingLogo">
                    <Image field={image} />
                  </h1>
                </div>
              </div>
              <div className="col-lg-6">
                <p style={{ color: `${fontColor?.value}` }} className="text m-0">
                  <RichText field={description} />
                </p>
              </div>{' '}
            </>
          ) : (
            <div className="col-lg-10">
              <p style={{ color: `${fontColor?.value}` }} className="text m-0">
                <RichText field={description} />
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

SEOPageBanner.defaultProps = {
  fields: {
    description: { value: '' },
    image: {
      value: { src: '' },
    },
    backgroundColor: {
      value: '',
    },
    fontColor: {
      value: '',
    },
  },
};

SEOPageBanner.propTypes = {
  fields: PropTypes.shape({
    description: PropTypes.shape({ value: PropTypes.string.isRequired }),
    image: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    backgroundColor: PropTypes.shape({ value: PropTypes.string }),
    fontColor: PropTypes.shape({ value: PropTypes.string }),
  }).isRequired,
};

export default SEOPageBanner;
