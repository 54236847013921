/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ModalComponent from '../../Modal';
import JobAlert from './JobAlert/JobAlert';
import JobAlertByEmail from './JobAlert/JobAlertByEmail';
import TextButton from '../../globals/buttons/TextButton';
import AuthWrapper from '../../../core/LoginMSAL/AuthWrapper';
import { authstates } from '../../../../utils/enums';

/**
 * @description to show search result count and related information
 * @param {number} searchResultCount - no of results
 * @param {string} searchQuery - query used to generate the result
 * @param {string} searchLocation -location used for search
 * @param {array} jobAlertOptions - job alert frequency options
 * @param {string} ctaText - job alert button label
 * @returns {node} html
 */
const SaveJobAlert = ({
  mode,
  translate,
  sitecoreContext,
  jobAlertOptions,
  ctaText,
  jobAlertSuccessMessage,
  showJobAlert,
  dashboardUrl,
  consentText,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [jobSuccessMessage, setJobSuccessMessage] = useState({});
  const loginReducer = useSelector((state) => state.loginReducer);

  const alertTextMssg = (alertText) => {
    const startIndx = alertText?.value?.indexOf('~');
    const endIndx = alertText?.value?.lastIndexOf('">');
    const stringToReplace = alertText?.value?.substring(startIndx, endIndx);
    setJobSuccessMessage({
      value: alertText?.value?.replace(
        stringToReplace,
        '/candidate/account-settings'
      ),
    });
  };

  useEffect(() => {
    alertTextMssg(jobAlertSuccessMessage);
  }, []);

  /**
   * @description to show job alert dialog
   * @returns {undefined} - nothing
   */
  const handleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (
      loginReducer.initialized &&
      loginReducer.state === authstates.AUTHENTICATED
    ) {
      const createJobAlertButtonClickedToLogin = sessionStorage.getItem(
        'createJobAlertButtonClickedToLogin'
      );
      if (createJobAlertButtonClickedToLogin === 'true') {
        handleModal();
        sessionStorage.removeItem('createJobAlertButtonClickedToLogin');
      }
    }
  }, []);

  /**
   * @description - login user and create alert
   * @param {*} login - to login
   * @returns {undefined} -
   */
  const loginUserAndCreateJobAlert = (login) => {
    if (login !== null && typeof window !== 'undefined') {
      sessionStorage.setItem('createJobAlertButtonClickedToLogin', true);
      localStorage.setItem(
        'authenticationType',
        'Apply > Create Account / Apply > Sign in'
      );
      login(null, 'createAlert');
    }
  };

  return (
    <section className="search-ribbon">
      <div
        className={`search-ribbon-wrap ${mode === 'show-results' ? 'active' : ''}`}
      >
        <div className="">
          <div className="">
            <div className="">
              <div className="search-ribbon-inner">
                <div className="filters-list">
                  {showJobAlert && sitecoreContext?.LoginType === 'ADB2C' && (
                    <AuthWrapper>
                      {({ login, authenticationState }) => {
                        return (
                          <TextButton
                            text={ctaText}
                            handleTextClick={
                              authenticationState === authstates.AUTHENTICATED
                                ? handleModal
                                : () => loginUserAndCreateJobAlert(login)
                            }
                          />
                        );
                      }}
                    </AuthWrapper>
                  )}
                  {showJobAlert && sitecoreContext?.LoginType === 'ROMA' && (
                    <TextButton
                      text={ctaText}
                      handleTextClick={() => {
                        window?.dataLayer.push({
                          event: 'jobAlertClick',
                        });
                        handleModal();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`search-ribbon-wrap ${
          mode === 'no-results' ? 'active alert' : ''
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="search-ribbon-inner">
                <div className="results">
                  <h2 className="text">{translate('empty-job-results')}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent showModal={showModal} handleModal={handleModal}>
        {sitecoreContext?.LoginType === 'ADB2C' && (
          <JobAlert
            key="body"
            jobAlertOptions={jobAlertOptions}
            jobAlertSuccessMessage={jobSuccessMessage}
            dashboardUrl={dashboardUrl}
            handleModal={handleModal}
            showModal={showModal}
          />
        )}
        {sitecoreContext?.LoginType === 'ROMA' && (
          <JobAlertByEmail
            key="body"
            jobAlertOptions={jobAlertOptions}
            jobAlertSuccessMessage={jobSuccessMessage}
            dashboardUrl={dashboardUrl}
            handleModal={handleModal}
            consentText={consentText}
            showModal={showModal}
          />
        )}
      </ModalComponent>
    </section>
  );
};

SaveJobAlert.defaultProps = {
  searchResultCount: '',
  searchQuery: '',
  searchLocation: '',
  frequency: [],
  ctaText: '',
  ctaUrl: '',
  mode: 'show-results',
  translate: () => {},
  totalPositions: '',
  totalPositionsLabel: '',
};

SaveJobAlert.propTypes = {
  t: PropTypes.func,
  showSkills: PropTypes.bool,
  readMore: PropTypes.string,
  sitecoreContext: PropTypes.shape({}),
  searchResultCount: PropTypes.number.isRequired,
  searchQuery: PropTypes.string,
  searchLocation: PropTypes.string.isRequired,
  frequency: PropTypes.arrayOf(),
  ctaText: PropTypes.string,
  mode: PropTypes.oneOf(['show-results', 'no-results']),
  translate: PropTypes.func,
  consentText: PropTypes.shape({}).isRequired,
  totalPositions: PropTypes.number,
  totalPositionsLabel: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withSitecoreContext()(SaveJobAlert);
