/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  symbolValidationCheck,
  patternRegexCheck,
} from '../../Forms/DynamicForm/helper/validationFactory';
import { getValidationDatabyType } from '../../Forms/DynamicForm/helper/filter';

class TextboxComplex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filled: false,
      inputValue: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.value && this.props.value !== '')
      this.setState({
        filled: this.props.value && this.props.value !== '',
        inputValue: this.props.value,
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        filled: this.props.value && this.props.value !== '',
        inputValue: this.props.value,
      });
    }
  }

  handleChange(event) {
    if (event.target.value) {
      this.setState({
        filled: true,
        inputValue: event.target.value,
      });
    } else {
      this.setState({
        filled: false,
        inputValue: event.target.value,
      });
    }
  }

  handleEnter = (e) => {
    const { handleEnter } = this.props;
    if (handleEnter && e.keyCode === 13) {
      handleEnter(this.state.inputValue);
    }
  };

  render() {
    const { isSearchEnabled, hintText, showOrgVal, value, register } = this.props;
    const { onBlurValidation, focusHandler } = this.props;
    const patternData = getValidationDatabyType(
      this.props.dataValidations,
      'pattern'
    );
    const requiredData = getValidationDatabyType(
      this.props.dataValidations,
      'required'
    );
    const maximumData = getValidationDatabyType(
      this.props.dataValidations,
      'maximum'
    );
    const minimumData = getValidationDatabyType(
      this.props.dataValidations,
      'minimum'
    );
    const connectorData = {
      connectorField: this.props.connectorField,
      connectorType: this.props.connectorType,
      getValues: this.props.getValues,
    };
    const valProp = showOrgVal ? {} : { defaultValue: this.state.inputValue };
    const showField = this.props.watch
      ? this.props.watch(this.props.showBasedOnFieldName)
      : true;
    return (
      <div
        className={`form-block complex ${this.props.cssClass} ${
          this.props.isHideField && !showField ? 'hide' : ''
        }`}
        style={this.props.customStyle}
      >
        <input
          className={this.state.filled ? 'filled' : ''}
          name={this.props.name}
          id={this.props.id}
          ref={this.props.ref}
          type={this.props.type}
          placeholder={this.props.placeholder}
          autoComplete="autoComplete_off"
          onChange={this.handleChange}
          {...valProp}
          readOnly={this.props.readOnly}
          onKeyUp={this.props.onEnter ? this.handleEnter : () => {}}
          aria-label={this.props.label || this.props.placeholder}
          aria-invalid={
            this.props.errorMsg ||
            (this.props.customError && this.props.customError[this.props.name])
              ? true
              : false
          }
          aria-describedby={`error_${this.props.id}`}
          onBlur={onBlurValidation}
          onFocus={focusHandler}
          ref={
            showOrgVal
              ? register
              : this.props.register
              ? this.props.register({
                  required:
                    ((!this.props.isHideField || showField) &&
                      requiredData?.fields?.value?.value) ||
                    false,
                  validate: {
                    symbolValidationCheck: (value) =>
                      (connectorData.connectorField
                        ? symbolValidationCheck(value, connectorData)
                        : true) ||
                      this.props.connectorMessage ||
                      'Invalid Values',
                  },
                  maxLength: {
                    value: maximumData?.fields?.value?.value || null,
                    message:
                      maximumData?.fields?.message?.value || 'Exceeded the limits',
                  },
                  minLength: {
                    value: minimumData?.fields?.value?.value || null,
                    message:
                      minimumData?.fields?.message?.value ||
                      'Minimum characters not reached',
                  },
                  pattern: {
                    value: patternData?.fields?.value?.value
                      ? patternRegexCheck(patternData.fields.value.value)
                      : '',
                    message:
                      patternData?.fields?.message?.value ||
                      'Pattern validation failed',
                  },
                })
              : () => {}
          }
        />

        {this.props.label && (
          <label htmlFor={this.props.id}>
            <div className="active">
              {this.props.icon && (
                <div className="input-pin-icon-wrap">
                  <span className="input-pin-icon"></span>
                </div>
              )}
              {this.props.label}
            </div>
            <div className="inactive">
              {this.props.icon && (
                <div className="input-pin-icon-wrap">
                  <span className="input-pin-icon"></span>
                </div>
              )}
              {this.props.label2 && this.props.label2 !== ''
                ? this.props.label2
                : this.props.label}
            </div>
          </label>
        )}
        {isSearchEnabled && (
          <button
            className="search-icon"
            onClick={() => this.props.handleEnter(this.state.inputValue)}
          ></button>
        )}
        {this.props.errorMsg && (
          <div className="error-msg" role="alert" id={`error_${this.props.id}`}>
            {this.props.errorMsg}
          </div>
        )}
        {this.props.errorItems && <div className="error-msg">Required Item</div>}
        {this.props.customError && this.props.customError[this.props.name] && (
          <div className="error-msg" role="alert" id={`error_${this.props.id}`}>
            {this.props.customError[this.props.name].type === 'required'
              ? requiredData?.fields?.message?.value
                ? requiredData.fields.message.value.replace(
                    '{0}',
                    this.props.label.replace('*', '')
                  )
                : 'This Field is Required'
              : this.props.customError[this.props.name].message.replace(
                  '{0}',
                  this.props.label.replace('*', '')
                )}
          </div>
        )}
        {hintText && <div className="hintText">{hintText}</div>}
      </div>
    );
  }
}

TextboxComplex.defaultProps = {
  id: '',
  name: '',
  label: '',
  name: '',
  label2: '',
  placeholder: '',
  type: 'text',
  cssClass: '',
  icon: '',
  errorMsg: '',
  isSearchEnabled: true,
  onEnter: true,
  customStyle: {},
  handleValidations: () => {},
  value: '',
  readOnly: false,
  validationText: {},
  customError: {},
  hintText: '',
  showOrgVal: false,
};

TextboxComplex.propTypes = {
  /** Field identifier  */
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  /** Label text */
  label: PropTypes.string.isRequired,

  /** Name text */
  name: PropTypes.string.isRequired,

  /** Secondary Label text */
  label2: PropTypes.string.isRequired,

  /** Placeholder label text */
  placeholder: PropTypes.string.isRequired,

  /** Type, like text, date, password, email, or tel */
  type: PropTypes.string,

  /** CSS class */
  cssClass: PropTypes.string,

  /** Icon, anchored left as a background */
  icon: PropTypes.string,

  /** Error message if validation occurs */
  errorMsg: PropTypes.string,
  isSearchEnabled: PropTypes.bool,
  onEnter: PropTypes.bool,
  hintText: PropTypes.string,
  customStyle: PropTypes.shape({}),
  handleValidations: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  validationText: PropTypes.shape({}),
  customError: PropTypes.shape({}),
  showOrgVal: PropTypes.bool,
  handleEnter: PropTypes.func,
};

export default TextboxComplex;
