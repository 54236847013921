/* eslint-disable */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * @description - to list accordion items
 * @param {*} props - props
 * @returns {HTML} - accordion html
 */
const AccordionItem = (props) => {
  const [countryChunks, setCountryChunks] = useState([]);
  const { region, title, active, onAccordionClick, index } = props;
  const chunk_size = Math.ceil(region?.countries?.length / 3);
  const [origin, setOrigin] = useState();

  useEffect(() => {
    let index = 0;
    const arrayLength = region?.countries?.length;
    const tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      const myChunk = region?.countries?.slice(index, index + chunk_size);
      tempArray.push(myChunk);
    }

    setCountryChunks(tempArray);
    setOrigin(window.location.hostname);
  }, []);

  return (
    <div className={`accordion-block ${active ? 'active' : ''}`}>
      <button
        className="icon"
        onClick={() => (active ? onAccordionClick() : onAccordionClick(index))}
        aria-label={`toggle accordion ${title}`}
        aria-expanded={active}
      ></button>
      <div
        className="accordion-title"
        onClick={() => (active ? onAccordionClick() : onAccordionClick(index))}
        role="presentation"
      >
        {title}
      </div>
      <div className="accordion-content clearfix">
        <div className="row">
          {countryChunks.map((chunk, index) => {
            if (chunk.length === 0) return;
            return (
              <div className="col-md-4 country-list" key={index}>
                {Array.isArray(chunk) &&
                  chunk.map((country, index) => {
                    if (country?.languages?.length === 0) {
                      return;
                    }
                    return (
                      <ul className="countries">
                        <span>{`${country?.countryName}` + ' '}</span>
                        {country &&
                          Array.isArray(country.languages) &&
                          country.languages.map((language) =>
                            language?.redirectURL ? (
                              <li key={index}>
                                {
                                  <>
                                    {'|'}
                                    <a
                                      href={language?.redirectURL}
                                      target={`${
                                        language?.redirectURL?.includes(origin)
                                          ? ''
                                          : '_blank'
                                      }`}
                                    >
                                      {language?.name}
                                    </a>
                                  </>
                                }
                              </li>
                            ) : null
                          )}
                      </ul>
                    );
                  })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

AccordionItem.defaultProps = {
  region: {},
  title: '',
  onAccordionClick: () => {},
  active: false,
  index: 0,
};

AccordionItem.propTypes = {
  region: PropTypes.shape({}),
  title: PropTypes.string,
  onAccordionClick: PropTypes.func,
  active: PropTypes.bool,
  index: PropTypes.number,
};

export default AccordionItem;
