/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
/**
 * @description - Dynamic ErrorBlock component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const ErrorBlock = ({ id, error, label, requiredData }) => {
  return (
    <>
      {error && Object.keys(error).length > 0 && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {error.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : error?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
    </>
  );
};

ErrorBlock.defaultProps = {
  id: '',
  error: {},
  label: '',
  requiredData: {},
};

ErrorBlock.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  requiredData: PropTypes.shape({}),
};

export default withTranslation()(ErrorBlock);
