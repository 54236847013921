import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { getImg, checkDataExists } from '../../../utils/helperUtils';
import './page-banners.scss';
import { useDispatch } from 'react-redux';
import { bannerLoaded } from '../../../redux/actions/actions';

/**
 * @description - Banner Insights.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const InsightsPageBannerWithLogo = ({ fields }, sitecoreContext) => {
  const { title, subTitle, text, image, componentBackgroundColor } = fields;
  const dispatch = useDispatch();
  // const theme = color?.value ? color.value.toLowerCase() : 'dark_green';
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
  let color_code = '';
  const updatebackgroundColor = componentBackgroundColor?.map((item) => {
    const { colorCode } = item?.fields;
    color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
    return color_code;
  });
  useEffect(() => {
    dispatch(bannerLoaded('InsightsPageBannerWithLogo', true));
  });
  return (
    <section
      className={`banner-section insights with-logo ${brandName}`}
      style={{ background: `${updatebackgroundColor}` }}
    >
      {brandName === 'manpower' ? (
        <div className="banner-section__overlay"></div>
      ) : (
        ''
      )}
      <div
        className={`insights-x ${brandName}`}
        style={{
          backgroundImage: `url(${mediaApi.updateImageUrl(getImg(image))})`,
        }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-lg-1 order-2">
            <span className="headline__eyebrow">
              {/*changed classname and tag as suggested by Sarah in bug#14497*/}
              <Text field={title} />
            </span>
            <h1 className="title">
              <Text field={subTitle} />
            </h1>
            <p className="large reversed" id="insights-page-description">
              <Text field={text} />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

InsightsPageBannerWithLogo.defaultProps = {
  fields: {
    title: { value: '' },
    subTitle: { value: '' },
    text: { value: '' },
  },
};

InsightsPageBannerWithLogo.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
  }).isRequired,
};

export default InsightsPageBannerWithLogo;
