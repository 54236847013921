import { useState, useEffect } from 'react';

/**
 * @description - Get previous route location.
 * @param {object} location - location.
 * @returns {string} - Previous route value.
 */
const usePreviousLocation = (location) => {
  const [currentPath, setCurrentPath] = useState(null);
  const [previousPath, setPreviousPath] = useState(null);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
    }
  }, [location.pathname]);

  return previousPath;
};

export default usePreviousLocation;
