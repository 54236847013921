import { FORM_DATA } from '../actions/actionTypes';

const initialState = {
  data: {},
};

/**
 * @description - Saves and clears the data stored in the form.
 * @param {object} state - Input props
 * @param {object} action - Input props
 * @returns {object} - Object Structure.
 */
const formDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORM_DATA.SAVE:
      return {
        ...state,
        data: action.payload,
      };
    case FORM_DATA.CLEAR:
      return initialState;
    default:
      return state;
  }
};
export default formDataReducer;
