/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import Textbox from '../globals/forms/Textbox';
import TextboxComplex from '../globals/forms/TextboxComplex';
import LocationSearch from '../globals/forms/LocationSearch';
import Button from '../globals/buttons/Button';
import TextButton from '../globals/buttons/TextButton';
import {
  isObjNotEmpty,
  getSearchURLParamsUpdated,
  getWidth,
  checkArray,
  checkRouteField,
} from '../../../utils/helperUtils';
import NavLink from '../globals/NavLinks';
import { STRING_VALUES, DEVICE } from '../../../constants';
import './headerJobs.scss';
import { saveSelectedLocation } from '../../../redux/actions';
import { removeFocus } from '../../../redux/actions/actions';

/**
 *
 * @param {*} props
 */
const FormElements = (props) => {
  const { register, control, locationSelected, countryCode } = props;
  const { errors, searchLocation, onInputChange, t, setSearchOpen } = props;
  const { setTextFocused, brandName, getValues, regexValidationPattern } = props;

  /**
   * @description - Hack to fix menu close on google autocomplete selection
   * @returns {undefined}
   */
  const handleBlur = () => {
    setTimeout(() => {
      if (
        document &&
        document.getElementById('sr-location-hdr') !== document.activeElement
      ) {
        setSearchOpen(false);
      }
    }, 500);
  };

  /**
   *
   * @param {*} searchLoc
   */
  const validateLocation = (searchLoc) => {
    return !(
      searchLoc &&
      (!isObjNotEmpty(searchLocation) ||
        (isObjNotEmpty(searchLocation) && searchLocation.place !== searchLoc))
    );
  };

  return (
    <Fragment>
      {brandName === STRING_VALUES.MANPOWER ? (
        <TextboxComplex
          id="jobsearchtext"
          isSearchEnabled={false}
          name="headerSearchKey"
          register={register({
            pattern: regexValidationPattern,
          })}
          errorMsg={
            errors && errors.headerSearchKey && t('search-validation-message')
          }
          value={getValues('headerSearchKey')}
          showOrgVal
          label={t('job-title-industry-skill')}
          focusHandler={() => setTextFocused(true)}
          onBlurValidation={() => setTextFocused(false)}
        />
      ) : (
        <Textbox
          id="jobsearchtext"
          placeholder={t('job-title-industry-skill')}
          customProps={{
            autoComplete: 'off',
            ref: register({
              pattern: regexValidationPattern,
            }),
            name: 'headerSearchKey',
          }}
          errorMsg={
            errors && errors.headerSearchKey && t('search-validation-message')
          }
          focusHandler={() => setTextFocused(true)}
          onBlurValidation={() => setTextFocused(false)}
        />
      )}

      <Controller
        as={LocationSearch}
        control={control}
        onLocationSelect={locationSelected}
        region={countryCode}
        errorMessage={t('invalid-location')}
        {...(brandName === STRING_VALUES.MANPOWER
          ? { label: t('search-location-header') }
          : { placeholder: t('search-location-header') })}
        id="sr-location-hdr"
        name="headerSearchLoc"
        disableEnterSubmit={true}
        rules={{ validate: validateLocation }}
        showError={errors?.headerSearchLoc}
        selectedLocation={searchLocation}
        changeHandler={onInputChange}
        focusHandler={() => setSearchOpen(true)}
        focusOutHandler={handleBlur}
        defaultValue={searchLocation?.place}
        showCurrentLocation={true}
      />
    </Fragment>
  );
};

FormElements.defaultProps = {
  brandName: STRING_VALUES.MANPOWER,
};

/**
 * @description - Get full route items.
 * @param {Array} routes - Array of routes.
 * @returns {Array} - Final route array.
 */
const getFullRouteData = (routes) => {
  let finalRoute = [];
  checkArray(routes).map((r) => {
    finalRoute = finalRoute.concat(...checkRouteField(r));
  });
  return finalRoute;
};

/**
 *
 * @param {*} props
 */
const HeaderSearchJobs = (props) => {
  const { fields, t, sitecoreContext, history, location } = props;
  const { dispatch, locationState } = props;
  const {
    header,
    subTitle,
    navigationText,
    navigationTextMobile,
    linkText,
  } = fields;
  const { partnerText, partnerLink } = fields;
  const [focused, setFocused] = useState(false);
  const [textFocused, setTextFocused] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchLocation, setSrLocation] = useState({});
  const { jobSearchURL, brandName, regExPattern } = sitecoreContext?.Country || {};
  const url = jobSearchURL || '';
  const brandNameValue = brandName?.toLowerCase();
  const regexValidationPattern = new RegExp(regExPattern || '');
  const urlVal = url.split('?')[0];
  const menu = useRef(null);
  const { route } = sitecoreContext;
  const placeholders = route?.placeholders;
  const common = placeholders && placeholders['jss-common'];
  const { items = [] } = (common && common[0]?.fields) || {};
  const rightNavItems = items[1]?.fields?.items || [];
  const rightFullRoutes = getFullRouteData(rightNavItems);
  const mob_Search_text = navigationTextMobile?.value?.length;
  const mobileNavText =
    mob_Search_text !== 0
      ? mob_Search_text && mob_Search_text > 0 && mob_Search_text > 5
        ? 'max-search-text'
        : 'med-search-text'
      : 'no-search-text';

  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    errors,
    control,
    getValues,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      headerSearchLoc: searchLocation?.place,
      headerSearchKey: '',
    },
  });

  useMemo(() => {
    if (isObjNotEmpty(locationState)) {
      setValue('headerSearchLoc', locationState.place);
      setSrLocation(locationState);
    }
  }, [locationState]);

  useEffect(() => {
    clearErrors('headerSearchLoc');
    setValue('headerSearchKey', '');
  }, [location]);

  /**
   *
   */
  const onSubmit = (values) => {
    dispatch(removeFocus(true));
    if (menu?.current) menu.current.style.display = 'none';
    const paramsVal = new URLSearchParams();
    const finalParams = getSearchURLParamsUpdated(
      paramsVal,
      values?.headerSearchKey,
      values?.headerSearchLoc ? searchLocation : null
    );
    if (isObjNotEmpty(searchLocation)) {
      dispatch(saveSelectedLocation(searchLocation));
    }
    if (location?.pathname === urlVal) {
      history.replace({
        pathname: urlVal,
        search: String(decodeURIComponent(finalParams)),
      });
    } else {
      history.push({
        pathname: urlVal,
        search: String(decodeURIComponent(finalParams)),
      });
    }
    setValue('headerSearchKey', '');
  };

  /**
   * @description function called followed by place selection
   * @param {object} locVal  - geometry of location
   * @returns {undefined} - no return
   */
  const locationSelected = (locVal) => {
    if (locVal) {
      setSrLocation(locVal);
      setValue('headerSearchLoc', locVal.place);
      clearErrors('headerSearchLoc');
    } else {
      setSrLocation({});
    }
  };

  /**
   *
   */
  const onInputChange = (value) => {
    setValue('headerSearchLoc', value);
    if (value === '') {
      setSrLocation({});
      clearErrors('headerSearchLoc');
    }
  };

  return (
    <div
      id="job-search-oevrlay-menu"
      className={`main-nav-item search-mobl search--mobile ${
        rightFullRoutes.length ? '' : 'right-section'
      } ${focused || searchOpen || textFocused ? 'link-focused' : ''}`}
      onMouseOver={() => {
        if (menu?.current) menu.current.style.display = 'block';
        if (getWidth() > DEVICE.MOBILE.WIDTH) setFocused(true);
      }}
      onMouseLeave={() => {
        if (getWidth() > DEVICE.MOBILE.WIDTH) setFocused(false);
      }}
      onFocus={() => {
        if (document && !document.getElementsByClassName('hide_outline').length) {
          if (menu?.current) menu.current.style.display = 'block';
          setFocused(true);
        }
      }}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setFocused(false);
        }
      }}
      role="presentation"
    >
      <Link
        to={url}
        aria-haspopup={true}
        aria-expanded={focused}
        onClick={() => {
          menu.current.style.display = focused ? 'none' : 'block';
          setFocused((focused) => !focused);
        }}
        onKeyPress={() => setFocused(!focused)}
      >
        <span>
          <div className="icon-search" />{' '}
          <span className="search__text">
            <Text field={navigationText} />
          </span>
          <span className="search__text-mobl">
            <Text field={navigationTextMobile} />
          </span>
        </span>
      </Link>
      <div
        className={`main-nav-dropdown ${mobileNavText}`}
        id="job-header-menu"
        ref={menu}
        aria-hidden={!focused}
      >
        <div className="main-nav-dropdown-inside">
          <div className="triangle"></div>
          <div className="find-career-block">
            <div>
              <div className="title">
                <Text field={header} />
              </div>
              <p className="large">
                <Text field={subTitle} />
              </p>
              <FormElements
                register={register}
                control={control}
                locationSelected={locationSelected}
                countryCode={sitecoreContext?.Country?.code}
                errors={errors}
                searchLocation={searchLocation}
                onInputChange={onInputChange}
                t={t}
                setSearchOpen={setSearchOpen}
                setTextFocused={setTextFocused}
                brandName={brandNameValue}
                getValues={getValues}
                regexValidationPattern={regexValidationPattern}
              />
              <div className="row btns-container">
                <div className="col-auto">
                  <Button
                    text={t('search')}
                    handleButtonClick={handleSubmit(onSubmit)}
                  />
                </div>
                <div className="col">
                  <TextButton
                    text={linkText?.value}
                    cssClass="seo-blue"
                    handleTextClick={() => {
                      if (menu?.current) menu.current.style.display = 'none';
                      clearErrors('headerSearchKey');
                      clearErrors('headerSearchLoc');
                      setSrLocation({});
                      history.push(urlVal);
                      dispatch(removeFocus(true));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="footer-section">
            <NavLink url={partnerLink} cssClass="footer-link">
              <Text field={partnerText} />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderSearchJobs.propTypes = {
  fields: PropTypes.shape({
    header: PropTypes.shape({}),
    subTitle: PropTypes.shape({}),
    navigationText: PropTypes.shape({}),
    linkText: PropTypes.shape({}),
  }),
  t: PropTypes.func.isRequired,
};

HeaderSearchJobs.defaultProps = {
  fields: {},
  t: (val) => val,
};

/**
 *
 * @param {*} state
 */
const mapStateToProps = (state) => {
  return {
    locationState: state.jobLocationReducer.location,
  };
};

export default connect(mapStateToProps)(
  withSitecoreContext()(withRouter(withTranslation()(HeaderSearchJobs)))
);
