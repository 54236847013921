import { CANDIDATE_ID } from '../actions/actionTypes';

const initialState = {
  data: '',
};
/**
 * @description - Candidate Id Reducer function
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */
const candidateIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANDIDATE_ID.SAVE:
      return {
        ...state,
        data: action.payload,
      };
    case CANDIDATE_ID.CLEAR:
      return {
        ...state,
        data: '',
      };
    default:
      return state;
  }
};
export default candidateIdReducer;
