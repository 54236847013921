import { SAVED_JOBS } from '../actions/actionTypes';

const initialState = {
  data: [],
};

/**
 * @description - to add or remove saved jobs
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */
const savedJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVED_JOBS.ADD:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case SAVED_JOBS.REMOVE:
      return {
        ...state,
        data: [...state.data, state.data.filter((item) => action.payload !== item)],
      };
    case SAVED_JOBS.CLEAR:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};

export default savedJobsReducer;
