import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../../globals/link/ButtonLink';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import './CalloutBarWide.scss';
/**
 *@description -  CalloutBarWide standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const CalloutBarWide = (props) => {
  const { fields } = props;
  const { title, text, ctaText, imageForMobile, imageForDesktop, ctaUrl } = fields;
  return (
    <section className={`calloutbar calloutbar--wide blue`}>
      <div className="row">
        <div className="col-lg-6">
          <div className="calloutbar__image">
            <img
              className="image"
              src={imageForDesktop?.value?.src}
              srcSet={` ${imageForDesktop?.value?.src} 1920w, ${imageForMobile?.value?.src} 720w  `}
              width="617"
              height="382"
              sizes="(max-width: 991px) 50vw, 100vw"
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="calloutbar__content">
            <h2 className="calloutbar__title calloutbar__title--reversed">
              <Text field={title} />
            </h2>
            <p className="calloutbar__text calloutbar__text--reversed">
              <Text field={text} />
            </p>
            <ButtonLink
              cssClass="reversed"
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              arrow
            />
          </div>
        </div>
      </div>
    </section>
  );
};

CalloutBarWide.defaultProps = {
  fields: {
    title: {
      value: '',
    },
    text: {
      value: '',
    },
    ctaText: {
      value: 'Learn More',
    },
    ctaUrl: {
      value: {
        href: '',
        text: '',
        anchor: '',
        linktype: '',
        class: '',
        title: '',
        target: '',
        querystring: '',
        id: '',
      },
    },
    imageForMobile: { value: { src: '', alt: '', width: '', height: '' } },
    imageForDesktop: { value: { src: '', alt: '', width: '', height: '' } },
  },
};

CalloutBarWide.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),
    text: PropTypes.shape({
      value: PropTypes.string,
    }),
    ctaUrl: PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      anchor: PropTypes.string,
      linktype: PropTypes.string,
      class: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string,
      querystring: PropTypes.string,
      id: PropTypes.string,
    }),
    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),
    imageForMobile: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    imageForDesktop: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
    }),
  }),
};

export default CalloutBarWide;
