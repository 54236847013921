/* eslint-disable */
import React, { useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AccordionBlock from '../../Accordion';
import './buildProfileAccordion.scss';

/**
 * @description - BuildProfileAccordion component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const BuildProfileAccordion = ({ inputRef, ...props }) => {
  const { fields, data, t, triggerArray, invalidArray } = props;
  const { text, allowMultipleOpen } = fields;
  const childRef = useRef(null);

  useEffect(() => {
    if (triggerArray) {
      childRef?.current?.handleRefTrigger();
    }
  }, [triggerArray]);

  useEffect(() => {
    if (invalidArray) {
      childRef?.current?.handleInvalidRefTrigger();
    }
  }, [invalidArray]);

  return (
    <section
      className="profile-accordion-section buildProfileAccordion"
      aria-labelledby="title"
      aria-describedby="info"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            {text?.value && (
              <div className="row">
                <div className="col-md-12">
                  <Text field={text} tag="h1" className="text" id="info" />
                </div>
              </div>
            )}
            {data && data.length && (
              <div id="profile-form">
                <div className="row">
                  <div className="col">
                    <AccordionBlock
                      allowMultipleOpen={
                        allowMultipleOpen?.value === 'true' ? true : false
                      }
                      showNextButton
                      data={data}
                      showExpandButton={false}
                      ref={childRef}
                      t={t}
                      triggerArray={triggerArray}
                      invalidArray={invalidArray}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

BuildProfileAccordion.defaultProps = {
  t: (val) => val,
  fields: {},
};

BuildProfileAccordion.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
};

const ConnectedComponent = withTranslation()(BuildProfileAccordion);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
