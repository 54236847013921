/* eslint-disable*/
import { isNullorEmpty } from '../../../../../../utils/helperUtils';
import { checkValueTypeAndGetTheCount } from '../../../../Utils/helper';
import get from 'lodash/get';

/**
 * @description - Function to generate missing info.
 * @param {Object} item - Data item.
 * @param {Object} data - Candidate details.
 * @returns {Object} - Missing info template.
 */
export const missingInfo = (item, data) => {
  const obj = {
    emptyCount: 0,
    temp: 0,
  };
  item &&
    item.fields &&
    item.fields.items &&
    item.fields.items.map((subItem) => {
      const field = subItem?.fields?.name?.value.split('/');
      const requiredFields = subItem?.fields?.multipleRequired?.value
        ? subItem?.fields?.multipleRequired?.value?.split('/')
        : [subItem?.fields?.required?.value];
      if (subItem?.fields?.type?.value === 'file') {
        let attachmentDetails = data?.attachment || [];
        if (
          attachmentDetails &&
          (requiredFields?.[0] === 'true' || requiredFields?.[0] === true) &&
          !checkValueTypeAndGetTheCount(attachmentDetails) &&
          isNullorEmpty(subItem?.fields?.value?.value)
        ) {
          obj.emptyCount = obj.emptyCount + 1;
        }
        if (
          checkValueTypeAndGetTheCount(attachmentDetails) ||
          !isNullorEmpty(subItem?.fields?.value?.value)
        ) {
          obj.temp = obj.temp + 1;
        }
      } else {
        const fieldLength = field?.length;
        let formDetails = data?.data || {};
        for (let i = 0; i < fieldLength; i++) {
          if (
            formDetails &&
            (requiredFields?.[i] === 'true' || requiredFields?.[i] === true) &&
            !checkValueTypeAndGetTheCount(get(formDetails, field?.[i])) &&
            isNullorEmpty(subItem?.fields?.value?.value)
          ) {
            obj.emptyCount = obj.emptyCount + 1;
            break;
          }
        }
        for (let i = 0; i < fieldLength; i++) {
          if (
            checkValueTypeAndGetTheCount(get(formDetails, field?.[i])) ||
            !isNullorEmpty(subItem?.fields?.value?.value)
          ) {
            obj.temp = obj.temp + 1;
            break;
          }
        }
      }
    });
  return obj;
};
