import React, { useState, useContext, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  withPlaceholder,
  withSitecoreContext,
  RichText,
  Image,
} from '@sitecore-jss/sitecore-jss-react';
import CompanyJobSearchForm from './CompanyJobSearchForm';
import { HeaderContext } from '../../SiteHeader/HamburgerMenu/HeaderContext';
import { SEARCH } from '../../../../constants';
import { isDataExists, parseFilterData } from '../../../../utils/helperUtils';
import TextLink from '../../globals/link/TextLink';
import '../search.scss';
import '../search-filters.scss';

/**
 * @description - Search Header tabs for site and job search.
 * @param {object} props - Input props.
 * @returns {Node} - Search header tabs and forms.
 */
const CompanySearchHeader = (props) => {
  const {
    fields,
    location,
    t,
    filterComp,
    brandName,
    sitecoreContext,
    BreadCrumb,
  } = props;
  const { isInHeader } = useContext(HeaderContext) || {};
  const {
    description,
    backgroundColor,
    fontColor,
    image,
    hideSearch,
    searchUrl,
    ctaText,
    ctaUrl,
  } = fields;
  const params = new URLSearchParams(location?.search);
  const paramMode = params.get('mode') || '';
  const modeVal = isInHeader ? '' : paramMode;
  const [mode, setMode] = useState(modeVal);
  const isFilterExists = isDataExists(filterComp);
  //const [textDesc, setTextDesc] = useState();
  let buttonCssClass = 'reversed';

  if (backgroundColor?.value === '#FFFFFF') {
    buttonCssClass = ' ';
  }

  // useEffect(() => {
  //   setTextDesc(description);
  // }, [description]);
  useEffect(() => {
    setMode(paramMode);
  }, [paramMode]);

  useEffect(() => {
    if (sitecoreContext?.route?.fields?.company?.fields?.key?.value !== '') {
      const params = new URLSearchParams(location?.search);
      if (!isInHeader && params.get('mode') !== 'site') {
        const filterObj = parseFilterData() || {};
        if (
          sitecoreContext?.route?.fields?.company?.fields?.key?.value &&
          sitecoreContext?.route?.fields?.company?.fields?.key?.value !== ''
        ) {
          filterObj.company =
            sitecoreContext?.route?.fields?.company?.fields?.key?.value;
          localStorage && localStorage.setItem('companyUrl', location?.pathname);
        }

        if (localStorage) {
          const params = new URLSearchParams(location?.search);
          hideSearch?.value
            ? localStorage.setItem(
                SEARCH.JOB_SEARCH.SAVED_SEARCH_KEY,
                JSON.stringify({
                  searchKeyword: null,
                  filters: filterObj,
                })
              )
            : localStorage.setItem(
                SEARCH.JOB_SEARCH.SAVED_SEARCH_KEY,
                JSON.stringify({
                  searchKeyword: params.get('searchKeyword'),
                  filters: filterObj,
                })
              );
          localStorage.setItem(
            SEARCH.JOB_SEARCH.SAVED_SEARCH_PARAMS,
            location?.search
          );
        }
      }
    }
  }, [isInHeader]);

  return (
    <section
      style={{ 'background-color': `${backgroundColor?.value}` }}
      className={`search-header ${brandName} branded`}
    >
      <div className="container">
        {isDataExists(BreadCrumb) ? (
          <div className="row">
            <div className="col-lg-10 header-container-branded">
              {BreadCrumb?.map((component, index) => {
                if (component.props && component.props.type === 'text/sitecore')
                  return component;
                return (
                  <Fragment key={index}>
                    <div className={`themeColor ${brandName}`}>{component}</div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="branded-content">
              <h1 className="headingLogo">
                <Image className="logo" field={image} />
              </h1>
              {/* <img className="logo" src={logo} alt={altText} /> */}
              <p style={{ color: `${fontColor?.value}` }} className="text">
                {/* {textDesc?.value &&  */}
                <RichText field={description} />
                {/* } */}
              </p>
            </div>
            {!hideSearch?.value && (
              <div>
                <CompanyJobSearchForm
                  mode={mode}
                  t={t}
                  location={location}
                  urlVal={searchUrl?.value}
                  isFilterExists={isFilterExists}
                  defaultRange={20}
                  buttonCssClass={buttonCssClass}
                />
              </div>
            )}
            {ctaText?.value && (
              <div
                className="branded-text-btn"
                style={{ color: `${fontColor?.value}` }}
              >
                <TextLink ctaText={ctaText} ctaUrl={ctaUrl} cssClass="branded-cta" />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {!hideSearch?.value &&
        (isDataExists(filterComp) && mode !== 'site'
          ? filterComp.map((component) => component)
          : null)} */}
    </section>
  );
};

CompanySearchHeader.defaultProps = {
  jobFormSubmitUrl: '/',
  fields: {
    description: {
      value:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Learn more about KLM',
    },
    backgroundColor: {
      value: '#00A1E4',
    },
    fontColor: {
      value: '#FFFFFF',
    },
    image: {
      value: {
        src: '',
        alt: '',
        width: '1600',
        height: '550',
      },
    },
    hideSearch: {
      value: false,
    },
    jobsCountToHideSearch: {
      value: '10',
    },
    itemPerPage: {
      value: '',
    },
    hideSearchSite: {
      value: false,
    },
    ctaText: {
      value: '',
    },
    ctaUrl: {
      value: {
        href: '#',
      },
    },
  },
  filterComp: [],
  t: (val) => val,
  brandName: '',
  BreadCrumb: [],
  ctaText: '',
  ctaUrl: '',
};

CompanySearchHeader.propTypes = {
  jobFormSubmitUrl: PropTypes.string,
  fields: PropTypes.shape({
    searchUrl: PropTypes.shape({}),
    description: PropTypes.shape({}),
    backgroundColor: PropTypes.shape({}),
    fontColor: PropTypes.shape({}),
    image: PropTypes.shape({}),
    hideSearch: PropTypes.shape({}),
    jobsCountToHideSearch: PropTypes.shape({}),
    hideSearchSite: PropTypes.shape({}),
    itemPerPage: PropTypes.shape({}),
    ctaText: PropTypes.shape({}),
    ctaUrl: PropTypes.shape({}),
  }).isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  filterComp: PropTypes.arrayOf(PropTypes.node),
  brandName: PropTypes.string,
  sitecoreContext: PropTypes.shape({}).isRequired,
  BreadCrumb: PropTypes.arrayOf(PropTypes.shape()),
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
};

export default withRouter(
  withPlaceholder([
    { placeholder: 'jss-content', prop: 'filterComp' },
    { placeholder: 'jss-breadcrumb', prop: 'BreadCrumb' },
  ])(withTranslation()(withSitecoreContext()(CompanySearchHeader)))
);
