import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @param {*} param0 - site params
 * @returns {*} - any
 */
const Button = ({
  cssClass,
  text,
  handleButtonClick,
  actionParams,
  isLoading,
  isDisabled,
  type,
  typeProp,
  ctaLabel,
  search,
}) => (
  <button
    type={type}
    className={`primary-button ${cssClass}`}
    onClick={handleButtonClick ? (e) => handleButtonClick(e, actionParams) : null}
    disabled={isDisabled}
    {...typeProp}
    title={ctaLabel}
  >
    {isLoading && (
      <Fragment>
        <span
          className={` ${cssClass} spinner-border spinner-border-sm`}
          role="status"
        ></span>
      </Fragment>
    )}
    {search && <div className="btn-job-search-icon"></div>}
    {text}
  </button>
);

Button.defaultProps = {
  cssClass: '',
  text: 'Button',
  handleButtonClick: null,
  actionParams: null,
  isLoading: false,
  isDisabled: false,
  type: 'button',
  typeProp: {},
  search: false,
};

Button.propTypes = {
  /** Color class  */
  cssClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func,
  actionParams: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  typeProp: PropTypes.shape({}),
  search: PropTypes.bool,
};

export default Button;
