import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import './create_account.scss';
import ConsentBlock from './ConsentBlock';
import CreateAccountNav from './CreateAccountNav';
import DynamicForm from '../../../core/Forms/DynamicForm';

/**
 * @description - CreateProfile Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const CreateNewAccount = forwardRef((props, ref) => {
  const { register, handleSubmit, getValues, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const buttonRef = useRef();
  const onSubmit = () => {
    props.handleCreateAccount();
  };

  useImperativeHandle(ref, () => ({
    trigger: () => {
      buttonRef.current.click();
    },
  }));

  return (
    <section className="create-account">
      <div className="container flex-container">
        <div className="title-text-account">{props?.fields?.title?.value}</div>
        <div className="create-account-container">
          <div className="account-form-container forms">
            <DynamicForm
              formData={
                props?.fields?.items?.length > 0
                  ? props?.fields?.items[0]?.fields?.items
                  : []
              }
              register={register}
              errors={errors}
              getValues={getValues}
            />
            <button
              ref={buttonRef}
              className="hideButton"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
          <div className="seperator" />
          <ConsentBlock
            fields={
              props?.fields?.items?.length > 1 ? props?.fields?.items[1]?.fields : {}
            }
            register={register}
            errors={errors}
          />
        </div>
        <CreateAccountNav handleCreateAccount={handleSubmit(onSubmit)} />
      </div>
    </section>
  );
});

CreateNewAccount.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  handleCreateAccount: PropTypes.func.isRequired,
};

CreateNewAccount.defaultProps = {
  fields: {},
  handleCreateAccount: () => {},
};

export default CreateNewAccount;
