/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useCallback } from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import AuthWrapper from '../../LoginMSAL/AuthWrapper';
import { authstates } from '../../../../utils/enums';

/**
 * @description - Push authentication type to storage.
 * @returns {undefined}
 */
const pushToStorage = () => {
  localStorage.setItem('authenticationType', 'Create an account/Sign in');
};

/**
 * @description- user links after login
 * @param {Object} - component props
 * @returns {HTML} - html
 */
const Links = ({
  role,
  to,
  handleClick,
  character,
  focus,
  index,
  setFocus,
  ctaText,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [focus]);

  const handleSelect = useCallback(() => {
    setFocus(index);
  }, [character, index, setFocus]);

  return (
    <li role="none" key={index}>
      <Link
        role={role}
        to={to}
        onClick={() => handleClick()}
        tabIndex={0}
        onKeyPress={handleSelect}
        ref={ref}
      >
        <Text field={ctaText} />
      </Link>
    </li>
  );
};

Links.propTypes = {
  role: PropTypes.string,
  to: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  character: PropTypes.shape({}),
  focus: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  setFocus: PropTypes.func.isRequired,
  ctaText: PropTypes.string.isRequired,
};

Links.defaultProps = {
  role: '',
  to: '',
  handleClick: () => {},
  character: {},
  focus: false,
  index: 0,
  setFocus: () => {},
  ctaText: '',
};

/**
 * @description _ StepsLogin Component.
 * @param {object} props - Props passed.
 * @returns {Node} - HTML node.
 */
const StepsLogin = ({ fields, sitecoreContext }) => {
  const { title, associateText } = fields;
  return (
    <AuthWrapper>
      {({ authenticationState, login, showLogin }) => {
        if (!showLogin) return;
        switch (authenticationState) {
          case authstates.AUTHENTICATED:
            return null;
          case authstates.PROGRESS:
            return (
              <div
                className={`loginD ${
                  sitecoreContext?.Country?.brandName === 'Manpower'
                    ? 'login--mobile'
                    : ''
                }`}
              >
                <p className="text">
                  <Text field={associateText} />
                </p>
                <button
                  style={{ background: 'transparent', border: 0 }}
                  aria-label="Progress"
                >
                  <span>
                    <div className="login__text">
                      <Text field={title} />
                    </div>
                  </span>
                </button>
              </div>
            );
          default:
            return (
              <div
                className={`loginD ${
                  sitecoreContext?.Country?.brandName === 'Manpower'
                    ? 'login--mobile'
                    : ''
                }`}
                role="menu"
              >
                <p className="text">
                  <Text field={associateText} />
                </p>
                <button
                  style={{ background: 'transparent', border: 0 }}
                  onClick={() => {
                    pushToStorage();
                    login();
                  }}
                  onKeyDown={(e) => {
                    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
                      pushToStorage();
                      login();
                    }
                  }}
                  tabIndex="0"
                >
                  <span>
                    <div className="login__text">
                      <Text field={title} />
                    </div>
                  </span>
                </button>
              </div>
            );
        }
      }}
    </AuthWrapper>
  );
};

StepsLogin.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.shape({}),
    associateText: PropTypes.shape({}),
  }),
  sitecoreContext: PropTypes.shape({}),
};

StepsLogin.defaultProps = {
  fields: {
    items: [],
    title: '',
  },
  sitecoreContext: {},
};

export default withSitecoreContext()(withTranslation()(StepsLogin));
