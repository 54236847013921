/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as QueryString from 'query-string';
import { useSelector } from 'react-redux';

import ButtonWithIcon from './ButtonWithIcon';
import { LOGIN } from '../../../../constants';

/**
 *
 */
const clearStorage = () => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(LOGIN.LINKEDIN_CONSENT);
    localStorage.removeItem(LOGIN.POSITION_ID);
    localStorage.removeItem(LOGIN.SOURCE);
  }
};

/**
 * @description - LinkedIn Login Button.
 * @returns {Node} - Button node.
 */
const LinkedInButton = ({
  text,
  appId,
  redirectTo,
  onLogged,
  onError,
  trigger,
  getValues,
  param,
  clearErrors,
}) => {
  const statecsrf =
    typeof window !== 'undefined'
      ? btoa(text.replace(/\s/g, ''))
      : text.replace(/\s/g, '');
  const [codeLn, setCodeLn] = useState(null);
  const loginReducer = useSelector((state) => state.loginReducer);

  /**
   * @description - Gets CODE from LinkedIn.
   * @returns {undefined}
   */
  useEffect(() => {
    if (typeof window !== 'undefined' && loginReducer.location && !codeLn) {
      const { query } = QueryString.parseUrl(loginReducer.location.href);
      const { code, state, error } = query;
      const { consent1, consent2 } = !!localStorage.getItem(LOGIN.LINKEDIN_CONSENT)
        ? JSON.parse(localStorage.getItem(LOGIN.LINKEDIN_CONSENT))
        : {};
      const PositionID = localStorage.getItem(LOGIN.POSITION_ID);
      const Source = localStorage.getItem(LOGIN.SOURCE);
      if (!error && statecsrf === state && code) {
        onLogged({
          code,
          state,
          email: '',
          accessToken: '',
          SSOID: 'LinkedIn',
          consent1,
          consent2,
          PositionID,
          Source,
          RedirectUri: `${loginReducer.location.origin}${redirectTo}`,
        });
        setCodeLn(code);
        clearStorage();
        window.history.pushState({}, document.title, redirectTo);
      } else if (error && statecsrf === state) {
        onError(error);
        window.history.pushState({}, document.title, redirectTo);
      }
    }
  }, [codeLn, statecsrf, onLogged, onError, redirectTo]);

  /**
   * @description - Request profile redirect function.
   * @param {Object} response - Response.
   * @returns {undefined}
   */
  const requestProfile = () => {
    const { consent1, consent2 } = getValues();
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      localStorage.setItem(
        LOGIN.LINKEDIN_CONSENT,
        JSON.stringify({ consent1: consent1 || '', consent2: consent2 || '' })
      );
      localStorage.setItem(LOGIN.POSITION_ID, param.get('positionID') || '');
      localStorage.setItem(LOGIN.SOURCE, param.get('source') || '');
    }
    const scope = ['r_liteprofile', 'r_emailaddress'];
    const uriRedirect = `${loginReducer.location.origin}${redirectTo}`;
    const fullScope = scope.join(' ');
    // Redirects to LinkedIn for user Login.
    const url = encodeURI(
      `${LOGIN.LINKEDIN}?response_type=code&client_id=${appId}&redirect_uri=${uriRedirect}&state=${statecsrf}&scope=${fullScope}`
    );
    window.location.href = url;
  };

  return (
    <ButtonWithIcon
      btnIcon="linked-in"
      cssClass="linked-in-blue"
      text={text}
      arrow={false}
      handleButtonClick={
        trigger
          ? async () => {
              clearErrors();
              const result = await trigger('consent1_hidden');
              if (result) {
                requestProfile();
              }
            }
          : requestProfile
      }
    />
  );
};

LinkedInButton.defaultProps = {
  getValues: () => {
    return { consent1: true, consent2: false };
  },
};

LinkedInButton.propTypes = {
  text: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onLogged: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  trigger: PropTypes.func.isRequired,
  redirectTo: PropTypes.string.isRequired,
  getValues: PropTypes.func,
  clearErrors: PropTypes.func,
};

export default LinkedInButton;
