import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Link as JssLink } from '@sitecore-jss/sitecore-jss-react';
import './cards.scss';

/**
 *
 * @param {*} param0 - site params
 * @returns {*} - any
 */
const CardIcon = ({ img, title, ctaUrl, altText }) => {
  const cardBody = (
    <div className="card-body">
      <div className="icon">
        <img src={img} className="icon__img" alt={altText} />
      </div>
      <div className="title">{title}</div>
    </div>
  );

  return (
    <div className="card industry">
      {ctaUrl?.value?.linktype === 'external' ? (
        <JssLink field={ctaUrl} className="card-link">
          {cardBody}
        </JssLink>
      ) : (
        <>
          {ctaUrl?.value?.querystring ? (
            <Link
              to={{
                pathname: ctaUrl?.value?.href,
                search: ctaUrl?.value?.querystring,
              }}
              target={ctaUrl?.value?.target}
            >
              {cardBody}
            </Link>
          ) : (
            <Link to={ctaUrl?.value?.href} target={ctaUrl?.value?.target}>
              {cardBody}
            </Link>
          )}
        </>
      )}
    </div>
  );
};

CardIcon.defaultProps = {
  img: '',
  title: '',
  ctaText: '',
  ctaUrl: {},
  altText: '',
};

CardIcon.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.shape({}).isRequired,
  altText: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
};

export default CardIcon;
