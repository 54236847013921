/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import JobFilters from '../../core/Filters/JobFilters';
import Sort from './Sort';
import './dropdowns.scss';

/**
 * @description - Dropdown options.
 * @param {object} props - Input props.
 * @returns {Node} - HTML block.
 */
const Dropdown = (props) => {
  const {
    dropdownSize,
    mode,
    dropdown,
    dropdownOptions,
    headerClickData,
    cancelFilters,
    getSelectedFilterValues,
    getArticleFilters,
    optionsList,
    t,
  } = props;
  const {
    clickHandler,
    actionParams,
    selectedOption,
    setFiltervals,
    setSelectedLabel,
    active,
  } = props;

  const [activestate, setActivestate] = useState(false);
  useEffect(() => {
    setActivestate(active);
  }, [active]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`dropdown-block ${
        dropdownSize ? dropdownSize : ''
      } ${mode} ${activestate}`}
      id="filtersubmenu"
    >
      <div className="dropdown-block-inside">
        <div className="dropdown-content">
          {dropdown === 'job-filters' && (
            <JobFilters
              title={t('filter- search-results')}
              ctaText={t('update')}
              cancelText={t('cancel')}
              options={dropdownOptions}
              optionsList={optionsList}
              headerClickData={headerClickData}
              selectedOption={selectedOption}
              setFiltervals={setFiltervals}
              getSelectedFilterValues={getSelectedFilterValues}
              getArticleFilters={getArticleFilters}
              cancelFilters={cancelFilters}
              setSelectedLabel={setSelectedLabel}
            />
          )}
          {dropdown === 'sort' && (
            <Sort
              options={dropdownOptions}
              selectedOption={selectedOption}
              clickHandler={clickHandler}
              actionParams={actionParams}
              setSelectedLabel={setSelectedLabel}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  dropdown: 'sort',
  dropdownSize: '',
  cancelFilters: () => {},
  t: () => {},
  optionsList: [],
  headerClickData: [],
  dropdownOptions: [],
  mode: '',
  setSelectedOption: null,
  getSelectedFilterValues: () => {},
  getArticleFilters: () => {},
};

Dropdown.propTypes = {
  dropdown: PropTypes.oneOf(['job-filters', 'sort', 'job-alert']),
  dropdownSize: PropTypes.oneOf(['', 'small']),
  dropdownOptions: PropTypes.arrayOf(),
  cancelFilters: PropTypes.func,
  t: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['right']),
  setSelectedOption: PropTypes.func,
  getSelectedFilterValues: PropTypes.func,
  getArticleFilters: PropTypes.func,
  optionsList: PropTypes.arrayOf(PropTypes.shape({})),
  headerClickData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withTranslation()(Dropdown);
