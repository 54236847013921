/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  patternRegexCheck,
  validateDNI,
} from '../DynamicForm/helper/validationFactory';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';
import { isNullorEmpty } from '../../../../utils/helperUtils';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { authstates } from '../../../../utils/enums';

/**
 * @description - Dynamic Textbox component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const Textbox = ({
  id,
  name,
  label,
  requiredValidation = [],
  type,
  icon,
  customStyle,
  readOnly,
  customError,
  hintText,
  dataValidations,
  getValues,
  setValue,
  watch,
  showBasedOnFieldName,
  hideBasedOnFieldName,
  isHideField,
  value,
  validateOnLoad,
  formName,
  t,
  control,
  disableIfDataExist,
  sitecoreContext,
}) => {
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  const [emailFieldType, setEmailFieldType] = useState('text');

  const loginReducer = useSelector((state) => state);

  const countryName = sitecoreContext?.Country?.name;
  const email = watch('email');
  const verfiyEmail = watch('reEnterEmail');
  const fieldEmail = name === 'email';
  const fieldVerifyEmail = name === 'reEnterEmail';
  const fieldError = get(customError, name);
  const patternData = getValidationDatabyType(dataValidations, 'pattern');
  const sameEmail = getValidationDatabyType(dataValidations, 'isEmailSame');
  const loginState = loginReducer?.state;
  const authenticatedUser = loginState === authstates.AUTHENTICATED;
  const isMaskrequired = fieldEmail && !authenticatedUser && countryName === 'Italy';
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const maximumData = getValidationDatabyType(dataValidations, 'maximum');
  const minimumData = getValidationDatabyType(dataValidations, 'minimum');
  const dniValidator = getValidationDatabyType(dataValidations, 'dni');

  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;
  const readOnlyBasedOnValue = (disabled) =>
    disableIfDataExist && !isNullorEmpty(value) ? true : disabled;

  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    param = value ? { ...param, shouldDirty: true } : param;
    setValue(name, value, param);
  }, [value]);

  return (
    <div
      className={`form-block complex ${fieldError ? 'error withoutBg' : ''} ${
        !showField || hideField ? 'hide' : ''
      }`}
      style={customStyle}
    >
      <Controller
        name={name}
        control={control}
        render={({ onChange, onBlur, value }) => (
          <input
            className={getValues && getValues(name) ? 'filled' : ''}
            value={value || ''}
            id={id}
            type={isMaskrequired ? emailFieldType : type}
            autoComplete="off"
            readOnly={readOnlyBasedOnValue(readOnly)}
            aria-required={
              showField && requiredData?.fields?.value?.value ? !hideField : false
            }
            {...(fieldError
              ? { 'aria-invalid': true, 'aria-describedby': `err_${id}` }
              : '')}
            {...((getValues && getValues(name)) || value
              ? {}
              : {
                  'aria-label': requiredData?.fields?.value?.value
                    ? ''
                    : `${label} edit blank`,
                })}
            onBlur={(e) => {
              isMaskrequired && setEmailFieldType('password');
              onBlur(e);
            }}
            onChange={(selected) => {
              let selectedVal = selected?.target?.value
                ? selected?.target?.value
                : selected;
              isMaskrequired &&
                setValue('reEnterEmail', '', {
                  shouldValidate: true,
                });
              onChange(selectedVal);
            }}
            onPaste={
              fieldVerifyEmail
                ? (e) => {
                    e.preventDefault();
                    return false;
                  }
                : true
            }
            onFocus={() => {
              isMaskrequired && setEmailFieldType('text');
            }}
          />
        )}
        rules={{
          required:
            showField && requiredData?.fields?.value?.value ? !hideField : false,
          validate: {
            dniValidationCheck: (value) =>
              (dniValidator?.fields?.value?.value === 'true' ||
              dniValidator?.fields?.value?.value === true
                ? validateDNI(value)
                : true) ||
              dniValidator?.fields?.message?.value ||
              t('validation-failed'),
            isEmailSame: () => {
              return email !== verfiyEmail
                ? sameEmail?.fields?.message?.value
                : null;
            },
          },
          maxLength: {
            value: maximumData?.fields?.value?.value || null,
            message: maximumData?.fields?.message?.value || t('validation-failed'),
          },
          minLength: {
            value: minimumData?.fields?.value?.value || null,
            message: minimumData?.fields?.message?.value || t('validation-failed'),
          },
          pattern: {
            value: patternData?.fields?.value?.value
              ? patternRegexCheck(patternData.fields.value.value)
              : '',
            message: patternData?.fields?.message?.value || t('validation-failed'),
          },
        }}
      />

      {label && (
        <label htmlFor={id}>
          <div className="label-text">
            {icon && (
              <div className="input-icon">
                <span className="input-icon"></span>
              </div>
            )}
            {label}
          </div>
        </label>
      )}
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                  '{0}',
                  label?.replace('*', '')
                )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
      {hintText && (
        <div>
          {disableIfDataExist ? (
            <div className={`hintText ${value ? '' : 'hide'}`}>
              {<RichText field={{ value: hintText }} />}
            </div>
          ) : (
            <div className="hintText">{hintText}</div>
          )}
        </div>
      )}
    </div>
  );
};

Textbox.defaultProps = {
  id: '',
  name: '',
  label: '',
  placeholder: '',
  type: 'text',
  icon: '',
  readOnly: false,
  setValue: () => {},
  t: () => {},
  trigger: () => {},
  handleChange: () => {},
};

Textbox.propTypes = {
  id: PropTypes.string.isRequired,
  t: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  handleChange: PropTypes.func,
};

export default Textbox;
