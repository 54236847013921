import { REMOVE_FOCUS } from '../actions/actionTypes';

const initialState = {
  removeFocus: false,
};

/**
 *
 * @param {*} state
 * @param {*} action
 */
const checkboxFocusReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_FOCUS.FOCUS:
      return {
        removeFocus: action.payload,
      };
    default:
      return state;
  }
};
export default checkboxFocusReducer;
