import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import VidyardPlayer from '../../business/VidyardPlayer';
import WistiaPlayer from '../../business/WistiaPlayer';
import YoutubePlayer from '../../business/YoutubePlayer';
import VimeoPlayer from '../../business/VimeoPlayer';
import './featurette.scss';

/**
 * @description Featurette Video component
 * @param {object} props - parameters get from sitecore
 * @returns {node} - html node
 */
const FeaturetteVideo = (props) => {
  const { fields } = props;
  const { title, text } = fields;
  useEffect(() => {
    /**
     * @description - loads the fancybox library.
     * @returns {*} - Returns nothing.
     */
    async function loadFancy() {
      await require('@fancyapps/fancybox');
      await require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
    }

    $(function () {
      if (!window.jQuery) window.jQuery = $;
      loadFancy();
    });
  }, []);
  return (
    <section className="videofeat featurette-section video">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 offset-lg-1">
            <div className="featurette-body">
              <h2 className="title">
                <Text field={title} />
              </h2>
              <p className="large">
                <Text field={text} />
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="player-wrapper"></div>
            {Array.isArray(fields.items) &&
              fields.items.map((item, index) => (
                <div className="" key={index}>
                  {item?.fields?.source?.value === 'Youtube' && (
                    <YoutubePlayer
                      playerid={item?.id}
                      videoUrl={item?.fields?.videoCode?.value}
                      videoPreviewImg={item?.fields?.thumbnail}
                      videoDuration={item?.fields?.duration}
                      title={item?.fields?.title}
                    />
                  )}
                  {item?.fields?.source?.value === 'Wistia' && (
                    <WistiaPlayer
                      playerid={item?.id}
                      videoUrl={item?.fields?.videoCode?.value}
                      videoPreviewImg={item?.fields?.thumbnail}
                      videoDuration={item?.fields?.duration}
                      title={item?.fields?.title}
                    />
                  )}
                  {item?.fields?.source?.value === 'Vidyard' && (
                    <VidyardPlayer
                      playerid={item?.id}
                      videoUrl={item?.fields?.videoCode?.value}
                      videoPreviewImg={item?.fields?.thumbnail}
                      videoDuration={item?.fields?.duration}
                      title={item?.fields?.title}
                    />
                  )}
                  {item?.fields?.source?.value === 'Vimeo' && (
                    <VimeoPlayer
                      playerid={item?.id}
                      videoUrl={item?.fields?.videoCode?.value}
                      videoPreviewImg={item?.fields?.thumbnail}
                      videoDuration={item?.fields?.duration}
                      title={item?.fields?.title}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturetteVideo.defaultProps = {
  fields: {
    title: '',
    items: [],
    text: '',
  },
};

FeaturetteVideo.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    text: PropTypes.string,
  }),
};

export default FeaturetteVideo;
