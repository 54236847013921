/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { authstates } from '../../../utils/enums';
import Loader from '../ErrorBoundary/Loader';
import RedirectToLogin from './RedirectToLogin';
/**
 * @description - PrivateRoute component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for PrivateRoute.
 */
const PrivateRoute = ({ children }) => {
  const loginReducer = useSelector((state) => state.loginReducer);

  return (
    <>
      {loginReducer && loginReducer.state === authstates.AUTHENTICATED ? (
        children
      ) : loginReducer && loginReducer.state !== authstates.AUTHENTICATED ? (
        <RedirectToLogin />
      ) : (
        <Loader
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#C25700',
          }}
        />
      )}
    </>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
