import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {
  setAltUrlForImg,
  checkDataExists,
  isObjNotEmpty,
} from '../../../utils/helperUtils';
import { withSitecoreContext, Image } from '@sitecore-jss/sitecore-jss-react';
import ButtonLink from '../globals/link/ButtonLink';
import VidyardPlayer from '../../business/VidyardPlayer';
import WistiaPlayer from '../../business/WistiaPlayer';
import YoutubePlayer from '../../business/YoutubePlayer';
import VimeoPlayer from '../../business/VimeoPlayer';
import './SuccessStories.scss';

/**
 * @description - Component.
 * @param {Object} props - Props passed.
 * @returns {Node} - Element.
 */
const CompanySuccessStories = (props) => {
  const { fields, sitecoreContext } = props;
  const { text, backgroundImage, componentBackgroundColor, buttonColor } = fields;

  const updatebackgroundColor = componentBackgroundColor?.map((item) => {
    const { colorCode } = item?.fields;
    let color_code = '';
    color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
    return color_code;
  });
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  useEffect(() => {
    /**
     * @description - loads the fancybox library.
     * @returns {*} - Returns nothing.
     */
    async function loadFancy() {
      await require('@fancyapps/fancybox');
      await require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
    }

    $(function () {
      if (!window.jQuery) window.jQuery = $;
      loadFancy();
    });
  }, []);

  return (
    <section
      className="ss__block"
      id="companysuccessstories"
      style={{
        background: `${updatebackgroundColor}`,
        display: !isObjNotEmpty(fields) ? 'none' : 'block',
      }}
    >
      <div className="container">
        {/* <h1 className="headline">{title?.value}</h1> */}

        <h2 className="img-container">
          <Image className="infoIcon" field={setAltUrlForImg(backgroundImage, '')} />
        </h2>
        <p className="text-company">{text?.value}</p>
        <div className="row">
          <div className="player-wrapper"></div>
          {fields?.items?.map((item, index) => (
            <div className="col-md-6 video-cont" key={index}>
              {item?.fields?.source?.value === 'Youtube' && (
                <YoutubePlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title}
                />
              )}
              {item?.fields?.source?.value === 'Wistia' && (
                <WistiaPlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title}
                />
              )}
              {item?.fields?.source?.value === 'Vidyard' && (
                <VidyardPlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title}
                />
              )}
              {item?.fields?.source?.value === 'Vimeo' && (
                <VimeoPlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title}
                />
              )}
              {item?.fields?.source?.value === '' &&
                item?.fields?.videoCode?.value === '' &&
                item?.fields?.thumbnail?.value?.src !== '' && (
                  <div className="video__link">
                    <Image
                      className="video__preview"
                      editable={true}
                      field={item?.fields?.thumbnail}
                    />
                  </div>
                )}
            </div>
          ))}
        </div>
        <div className={`btn__wrap ${brandName}`}>
          <ButtonLink
            ctaText={fields?.ctaText}
            ctaUrl={fields?.ctaUrl}
            cssClass={buttonColor?.value}
          />
        </div>
      </div>
    </section>
  );
};

CompanySuccessStories.defaultProps = {
  fields: {
    items: [],
    text: '',
    backgroundImage: '',
    buttonColor: {
      value: 'blue',
    },
    componentBackgroundColor: [
      {
        fields: {
          colorCode: {
            value: '#fffff',
          },
        },
      },
    ],
  },
  sitecoreContext: {},
};

CompanySuccessStories.propTypes = {
  fields: PropTypes.shape({
    buttonColor: PropTypes.shape({}),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    text: PropTypes.shape({}),
    backgroundImage: PropTypes.shape({}),
    componentBackgroundColor: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(CompanySuccessStories);
