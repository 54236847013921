/* eslint-disable */
import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import CardGrid from '../Cards/CardGrid';
import { geolocated, geoPropTypes } from 'react-geolocated';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import TextLink from './../globals/link/TextLink';
import { usePostData } from '../../../hooks/http-client';
import { JOBS_CARD_SCROLL, SEARCH } from '../../../constants';
import Loader from '../ErrorBoundary/Loader';
import { isNullorEmpty } from '../../../utils/helperUtils';
import usePrevious from '../../../hooks/usePrevious';
import './cardblock.scss';
/**
 * @description-  CardBlockJob grid component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */

const queryParams = {
  JobFilters: {
    searchkeyword: '',
    latitude: null,
    longitude: null,
    company: '',
  },
  PageType: '',
  UserLatitude: null,
  UserLongitude: null,
  HasLocation: false,
};

/**
 * @description to show job card grid
 * @param {object} props - card grid properties
 * @returns {node} -html
 */

const JobCardsBlock = (props) => {
  const location = useLocation();
  const { response, loading, write } = usePostData();
  const { coords, sitecoreContext, t, fields, params } = props;
  const { items = [] } = fields;
  const [searchPath, setSearchpath] = useState('');
  queryParams.PageType = params?.pageType;
  const prevValue = usePrevious({ sitecoreContext, params, coords }) || {};

  /**
   * @description - call to card scroll api
   * @param {object} queryParams - request body
   * @returns {undefined}
   */
  const getJobsCard = (queryParams) => {
    write(`${JOBS_CARD_SCROLL.GET_JOBS_CARD_BLOCK.URL}`, queryParams);
  };

  const getPathname = () => {
    const searchFilters = JSON.parse(
      localStorage.getItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_KEY)
    );
    return searchFilters?.filters?.company
      ? sitecoreContext.Country?.jobSearchURL
      : sitecoreContext.Country?.jobSearchURL;
  };

  useEffect(() => {
    // url and props.params.pageType === 'Job', company url navigate else default job search
    const path =
      !isNullorEmpty(response?.data?.url) && props.params.pageType === 'Job'
        ? response?.data?.url
        : sitecoreContext.Country?.jobSearchURL;
    setSearchpath({
      pathname: path,
      search: localStorage.getItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_PARAMS),
    });
  }, [response]);

  const getJobCardData = () => {
    if (window) {
      const jobSearchFilters = JSON.parse(
        localStorage.getItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_KEY)
      );
      queryParams.JobFilters = jobSearchFilters ? jobSearchFilters?.filters : {};
      if (queryParams?.JobFilters?.latitude && queryParams?.JobFilters?.longitude) {
        queryParams.JobFilters.HasLocation = true;
      } else if (queryParams.JobFilters?.HasLocation) {
        queryParams.JobFilters.HasLocation = false;
      }
      if (jobSearchFilters?.filters?.company)
        queryParams.JobFilters.company = jobSearchFilters?.filters?.company;
      else delete queryParams?.JobFilters?.company;
    }
    if (props.params.pageType === 'Location') {
      queryParams.PageItemId = sitecoreContext?.itemId;
    }
    if (props.params.pageType === 'Job') {
      queryParams.PageItemId = sitecoreContext?.Country?.jobItemId;
    }
    if (props.params.pageType === 'App Route') {
      if (coords) {
        queryParams.UserLatitude = coords.latitude;
        queryParams.UserLongitude = coords.longitude;
        queryParams.HasLocation = true;
      }
    }
    getJobsCard(queryParams);
  };

  useEffect(() => {
    if (
      coords &&
      (prevValue.coords?.latitude !== coords.latitude ||
        prevValue.coords?.longitude !== coords.longitude)
    ) {
      getJobCardData();
    } else if (params?.pageType && prevValue.params?.pageType !== params?.pageType) {
      getJobCardData();
    } else if (
      sitecoreContext?.itemId &&
      prevValue.sitecoreContext?.itemId !== sitecoreContext?.itemId
    ) {
      getJobCardData();
    } else if (
      sitecoreContext?.Country?.jobItemId &&
      prevValue.sitecoreContext?.Country?.jobItemId !==
        sitecoreContext?.Country?.jobItemId
    ) {
      getJobCardData();
    } else if (
      prevValue?.location &&
      prevValue.location?.pathname !== location?.pathname
    ) {
      getJobCardData();
    }
  }, [location, coords, sitecoreContext, params]);

  return (
    <Fragment>
      {loading && <Loader />}
      {response?.data?.jobCards?.length > 0 && (
        <section className="card-grid ">
          <div className="container">
            <div className="card-grid__header">
              <h2 className="title">{`${
                response?.data?.title
                  ? props?.params?.cardScrollTitle?.replace(
                      '{0}',
                      response?.data?.title
                    )
                  : props?.params?.cardScrollTitle
              }`}</h2>
              <div className="cta">
                <TextLink cssClass="" ctaText={t('see-all')} ctaUrl={searchPath} />
              </div>
            </div>
            <div className="cards__wrap">
              <div className="row">
                {response?.data?.jobCards?.map((job) => {
                  return (
                    <div className="col-lg-6">
                      <CardGrid
                        title={job?.jobTitle}
                        location={job?.jobLocation}
                        subTitle={job?.employmentType}
                        img={job.image}
                        date={job?.publishfromDate}
                        ctaUrl={job?.jobURL}
                        isSaved={job?.isSaved}
                        icons={items}
                        jobItemId={job?.jobItemId}
                        isExpired={job?.isExpired}
                        jobDetails={job}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

JobCardsBlock.defaultProps = {
  coords: {},
  sitecoreContext: {},
  params: {},
  backgroundColor: '',
  t: () => {},
  fields: {},
};

JobCardsBlock.propTypes = {
  coords: PropTypes.shape({}),
  sitecoreContext: PropTypes.shape({}),
  params: PropTypes.shape({}),
  backgroundColor: PropTypes.string,
  t: PropTypes.func,
  ...geoPropTypes,
  fields: PropTypes.shape({}),
};

export default geolocated()(withTranslation()(withSitecoreContext()(JobCardsBlock)));
