import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  withSitecoreContext,
  mediaApi,
} from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import 'moment/min/locales';

/**
 * @description - Article Header component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const ArticleHeader = ({
  title,
  subTitle,
  company,
  companyLogo,
  time,
  skill,
  cssClass,
  brandName,
  lang,
  sitecoreContext,
  brandLabel,
  icons,
  jobDetails,
}) => {
  let { iconAccessibility } = {};
  /**
   * @description to set social share icons
   * @returns {undefined} -  no returns
   */
  const setIcons = () => {
    if (Array.isArray(icons)) {
      icons.forEach((iconItem) => {
        switch (iconItem?.itemName) {
          case 'Accessibility Icon':
            iconAccessibility = iconItem.icon;
          default:
            break;
        }
      });
    }
  };
  setIcons();

  moment.locale(lang);
  const country_name = sitecoreContext?.Country?.name;
  return (
    <div className={`article-header ${cssClass ? cssClass : ''}`}>
      <h1 className={`title ${brandName}`}>
        <Text field={title} />
      </h1>
      {cssClass !== 'job' && (
        <div className="sub-title">
          <Text field={subTitle} />
        </div>
      )}
      {cssClass === 'job' &&
        country_name.toLowerCase() !== 'sweden' &&
        country_name.toLowerCase() !== 'manpower-germany' && (
          <div className="company">
            {company ? subTitle + ' ' : ''}
            {/* <strong> {company}</strong> */}
            {company}
            {companyLogo && companyLogo.value && companyLogo.src && (
              <img src={companyLogo} alt="logo" />
            )}
          </div>
        )}

      {brandLabel && <div className="brandLabel">{brandLabel}</div>}
      {(time || skill) && (
        <div className="time-skill-block">
          {time && <div className="time">{moment(time).fromNow()}</div>}
          {skill && <div className="skill">{skill}</div>}
          {jobDetails?.showDisabilityIcon && (
            <div className="accessibility">
              <img
                src={mediaApi.updateImageUrl(iconAccessibility)}
                alt="accessibility icon"
              />
              <span>{jobDetails?.showDisabilityIcon}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ArticleHeader.defaultProps = {
  title: { value: '' },
  subTitle: { value: '' },
  companyLogo: {
    value: { src: '' },
    src: '',
  },
  company: '',
  time: '',
  skill: '',
  cssClass: '',
  brandName: '',
  brandLabel: '',
  lang: '',
  sitecoreContext: '',
};

ArticleHeader.propTypes = {
  title: PropTypes.shape({ value: PropTypes.string }),
  subTitle: PropTypes.shape({ value: PropTypes.string }),
  time: PropTypes.string,
  company: PropTypes.string,
  skill: PropTypes.string,
  cssClass: PropTypes.string,
  companyLogo: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    src: PropTypes.string,
  }),
  brandName: PropTypes.string,
  brandLabel: PropTypes.string,
  lang: PropTypes.string,
  sitecoreContext: PropTypes.string,
};
export default withSitecoreContext()(ArticleHeader);
