/*eslint-disable*/
import forEach from 'lodash/forEach';
import DashboardProfile from '../Dashboard/LeftBarContent/DashboardProfile';
import DashboardNav from '../Dashboard/LeftBarContent/DashboardNav';
import DashboardProfileMobile from '../Dashboard/LeftBarContent/DashboardProfileMobile';

import DashboardProgressBarBlock from '../Dashboard/MainContent/DashboardProgressBarBlock';
import ProgressBarBlock from '../Dashboard/MainContent/ProgressBarBlock';

import DashboardApplications from '../Dashboard/MainContent/DashboardApplications';
import DashboardSuggestedJobs from '../Dashboard/MainContent/DashboardSuggestedJobs';
import DashboardSavedJobs from '../Dashboard/MainContent/DashboardSavedJobs';

import DashboardAccountAccordion from '../Dashboard/MainContent/DashboardAccountAccordion';
import DashboardProfileAccordion from '../Dashboard/MainContent/DashboardProfileAccordion';

import AccountDetails from '../Dashboard/MainContent/DashboardAccountAccordion/AccountDetails';
import CommunicationPreference from '../Dashboard/MainContent/DashboardAccountAccordion/CommunicationPreference';
import ConsentForm from '../Dashboard/MainContent/DashboardAccountAccordion/ConsentForm';
import DeleteAccount from '../Dashboard/MainContent/DashboardAccountAccordion/DeleteAccount';

import SingleView from '../Dashboard/MainContent/DashboardProfileAccordion/Layout/SingleView';
import MultiView from '../Dashboard/MainContent/DashboardProfileAccordion/Layout/MultiView';

import BuildProfileAccordion from '../BuildProfile/BuildProfileAccordion';
import UploadCandidateResume from '../BuildProfile/UploadResume';
import SingleForm from '../BuildProfile/BuildProfileAccordion/Layout/SingleForm';
import MultiForm from '../BuildProfile/BuildProfileAccordion/Layout/MultiForm';
import CareerResources from '../Dashboard/MainContent/CareerResources';

const buildProfileDefault = {
  EditPersonalInfo: SingleForm,
  EditBasicInfo: SingleForm,
  EditExperience: MultiForm,
  EditEducation: MultiForm,
  EditExpertiseAndSkills: SingleForm,
  EditGamingDetails: SingleForm,
  EditCompetencies: SingleForm,
  EditCertifications: SingleForm,
  EditCoverLetters: SingleForm,
};

const dashboardProfileDefault = {
  PersonalInfo: SingleView,
  Experience: MultiView,
  Education: MultiView,
  ExpertiseAndSkills: SingleView,
  Gaming: SingleView,
  Competencies: SingleView,
  Certifications: SingleView,
  MyDocuments: SingleView,
  Resume: SingleView,
  CoverLetters: SingleView,
};

export const Components = {
  DashboardProfile: DashboardProfile,
  DashboardNav: DashboardNav,
  DashboardProfileMobile: DashboardProfileMobile,
  DashboardProgressBarBlock: DashboardProgressBarBlock,
  ProgressBarBlock: ProgressBarBlock,
  DashboardSavedJobs: DashboardSavedJobs,
  DashboardSuggestedJobs: DashboardSuggestedJobs,
  DashboardApplications: DashboardApplications,
  AccountAccordion: DashboardAccountAccordion,
  ProfileAccordion: DashboardProfileAccordion,
  AccountDetails: AccountDetails,
  ConsentForm: ConsentForm,
  CommunicationPreference: CommunicationPreference,
  DeleteAccount: DeleteAccount,
  DashboardCareerResources: CareerResources,
  CareerResources: CareerResources,
  BuildProfileAccordion: BuildProfileAccordion,
  UploadCandidateResume: UploadCandidateResume,
  SingleForm: SingleForm,
  MultiForm: MultiForm,
  SingleView: SingleView,
  MultiView: MultiView,
  ...dashboardProfileDefault,
  ...buildProfileDefault,
};

export const colorSelection = {
  green: '#5C7D70',
  brown: '#9D323D',
  black: '#000000',
  default: '#00000',
};

export const formObjIteration = (obj, val = 'value') => {
  Object.keys(obj).forEach((key) => {
    const objValue = obj[key];
    if (!objValue || typeof objValue !== 'object') {
      if (/_hidden$/.test(key) || /_picker$/.test(key) || /_upload$/.test(key)) {
        delete obj[key];
      } else {
        obj[key] = objValue;
      }
    } else {
      if (Object.keys(objValue).includes('label')) {
        if (/_hidden$/.test(key) || /_picker$/.test(key) || /_upload$/.test(key)) {
          delete obj[key];
        } else {
          obj[key] = objValue[val];
        }
      } else {
        obj[key] = formObjIteration(objValue, val);
      }
    }
  });
  return obj;
};

export const recursiveWithConstantCallback = (obj, constObj) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') {
      recursiveWithConstantCallback(val, constObj);
    } else {
      obj[key] =
        constObj[val] !== '' && constObj[val] !== undefined && constObj[val] !== null
          ? constObj[val]
          : val;
    }
  });
  return obj;
};

export const YesNo = {
  true: 'Yes',
  false: 'No',
};

export const yesno = {
  Yes: 'yes',
  No: 'no',
};

export const booleanRes = {
  true_bool: true,
  false_bool: false,
};

export const defineKeyValueObjFromObjectArray = (array, result = {}) => {
  let temp = result;
  array.forEach((item, index) => {
    let key = item?.fields?.key?.value ? item?.fields?.key?.value : index;
    temp[key] = item?.fields?.label?.value;
  });
  return temp;
};

export const checkProperties = (obj) => {
  for (var key in obj) {
    if (!obj[key]) return false;
  }
  return true;
};

export const replaceTextWithValues = (template, replacements, missingText = '') => {
  let resultStr = template;
  if (template) {
    resultStr = template.replace(/{([^{^}]+)}/g, (match, key) => {
      return replacements?.[key] !== undefined && replacements?.[key] !== null
        ? replacements?.[key]
        : missingText;
    });
  }
  return resultStr;
};

export const isTrue = (value) => {
  if (typeof value === 'string') {
    value = value.trim().toLowerCase();
  }
  switch (value) {
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
    case 'y':
    case 'Y':
    case 'Yes':
    case 'YES':
      return true;
    case 'false':
    case 0:
    case '0':
    case 'off':
    case 'no':
    case 'n':
    case 'N':
    case 'No':
    case 'NO':
      return false;
    default:
      return null;
  }
};

export const checkValueTypeAndGetTheCount = (value) => {
  let res = false;
  if (value) {
    if (typeof value === 'string') {
      let booleanVal = isTrue(value);
      if (booleanVal === null) {
        res = value?.length > 0;
      } else {
        res = booleanVal;
      }
    } else if (Object.keys(value).length > 0) {
      res = Object.keys(value).length > 0;
    } else if (typeof value === 'boolean') {
      res = value;
    } else if (Array.isArray(value)) {
      res = value?.length > 0;
    }
  }
  return res;
};

export const findArrayElementByObjectVal = (array, objectVal, val) => {
  return array.find((element) => {
    return element[objectVal] === val;
  });
};

export const fillInAddress = (addressComponents, postalCode = '') => {
  let addr = {};
  let address1 = '';
  forEach(addressComponents, function (component, index) {
    let types = component?.types.join(',');
    if (types == 'street_number') {
      addr.streetNumber = component?.long_name;
    }
    if (types == 'route' || types == 'point_of_interest,establishment') {
      addr.route = component?.long_name;
    }
    if (
      types == 'sublocality,political' ||
      types == 'locality,political' ||
      types == 'neighborhood,political' ||
      types == 'administrative_area_level_3,political' ||
      types == 'postal_town'
    ) {
      addr.city = addr.city ? addr.city : component.long_name;
    }
    if (types == 'administrative_area_level_2,political' && !addr?.city) {
      addr.city = component?.long_name;
    }
    if (types == 'administrative_area_level_1,political') {
      addr.state = component?.long_name;
    }
    if (types == 'postal_code' || types == 'postal_code_prefix,postal_code') {
      addr.zip = component?.long_name;
    }
    if (types == 'country,political') {
      if (!addr.zip) addr.zip = postalCode;
      addr.country = component?.long_name;
      addr.countryCode = component?.short_name;
    }
    if (types == 'street_number') {
      address1 += `${component.long_name} ${address1}`;
    }
    if (types == 'route') {
      address1 += component?.short_name;
    }
    if (types == 'administrative_area_level_2,political') {
      addr.province = component?.long_name;
    }
  });
  addr.address1 = address1;
  return addr;
};
