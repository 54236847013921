import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import ArticleHeader from '../ArticleHeader';
import SingleEvent from './SingleEvent';

/**
 * @description - Event details block.
 * @param {Object} props - Input Props.
 * @returns {Node} - HTML template.
 */
const EventBlock = (props) => {
  const { SocialSharePlatforms, RelatedArticles, sitecoreContext } = props;
  const { BreadCrumb, EventRegistration } = props;
  const { route = {} } = sitecoreContext;
  const { fields = {} } = route;
  const { headline, subTitle, image, description } = fields;
  const { startDate, endDate } = fields;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  return (
    <Fragment>
      <section className={`article-block ${brandName}`}>
        <div className="container">
          <div className="row">
            <div className="col-xl-10 ">
              <div className="row">
                <div className="col">
                  <div className="article-header-container">
                    {BreadCrumb.map((component, index) => {
                      if (
                        component.props &&
                        component.props.type === 'text/sitecore'
                      )
                        return component;
                      return (
                        <Fragment key={index}>
                          <div className={`themeColor ${brandName}`}>
                            {component}
                          </div>
                        </Fragment>
                      );
                    })}
                    <ArticleHeader
                      title={headline}
                      subTitle={subTitle}
                      brandName={brandName}
                    />
                    {SocialSharePlatforms.map((component, index) => {
                      if (
                        component.props &&
                        component.props.type === 'text/sitecore'
                      )
                        return component;
                      return <Fragment key={index}>{component}</Fragment>;
                    })}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <SingleEvent
                    image={image}
                    description={description}
                    EventRegistration={EventRegistration}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </div>
                <div className="col-lg-4">
                  {RelatedArticles.map((component, index) => {
                    if (component.props && component.props.type === 'text/sitecore')
                      return component;
                    return <Fragment key={index}>{component}</Fragment>;
                  })}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {SocialSharePlatforms.map((component, index) => {
                    if (component.props && component.props.type === 'text/sitecore')
                      return component;
                    return <Fragment key={index}>{component}</Fragment>;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

EventBlock.defaultProps = {
  SocialSharePlatforms: [],
  RelatedArticles: [],
  EventRegistration: [],
  fields: {
    abstract: { value: '' },
    image: {
      value: { src: 'http://via.placeholder.com/1440x583/EEEEEE/CCCCCC' },
    },
    body: { value: '' },
    headline: { value: '' },
    subTitle: { value: '' },
  },
  BreadCrumb: [],
};

EventBlock.propTypes = {
  SocialSharePlatforms: PropTypes.arrayOf(PropTypes.shape()),
  RelatedArticles: PropTypes.arrayOf(PropTypes.shape()),
  EventRegistration: PropTypes.arrayOf(PropTypes.shape()),
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
    Country: PropTypes.shape({
      brandName: PropTypes.string,
    }),
  }).isRequired,
  fields: PropTypes.shape({
    abstract: PropTypes.shape({ value: PropTypes.string }),
    body: PropTypes.shape({ value: PropTypes.string }),
    headline: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    image: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    }),
  }),
  BreadCrumb: PropTypes.arrayOf(PropTypes.shape()),
};

const eventContainer = withSitecoreContext()(
  withPlaceholder([
    { placeholder: 'jss-content', prop: 'SocialSharePlatforms' },
    { placeholder: 'jss-left', prop: 'EventRegistration' },
    { placeholder: 'jss-right', prop: 'RelatedArticles' },
    { placeholder: 'jss-breadcrumb', prop: 'BreadCrumb' },
  ])(EventBlock)
);

export default eventContainer;
