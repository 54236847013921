import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './sidebarcards.scss';

/**
 * @description web support details
 * @param {object} props - Input Props
 * @param {function} t - translate function
 * @returns {node} - html
 */
const WebSupportDetails = (props) => {
  const { fields } = props;
  return (
    <div className="websupport-sidebar">
      <div className="sidebar-title">{fields?.title?.value}</div>
      <div className="multitext">
        <RichText tag="div" field={fields?.text} />
      </div>
    </div>
  );
};

WebSupportDetails.defaultProps = {
  fields: {
    title: {},
    text: {},
  },
};

WebSupportDetails.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    text: {
      value: PropTypes.string,
    },
  }),
};

export default WebSupportDetails;
