import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import './sidebarcards.scss';

/**
 * @description to show location details
 * @param {object} fields - card data object
 * @param {function} t - translate function
 * @returns {node} - html
 */
const SidebarCardLocation = ({ fields, t, sitecoreContext }) => {
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase();
  const jobLocation = fields?.items?.hasOwnProperty('jobLocation')
    ? fields?.items?.jobLocation
    : '';
  let locationElement;
  if (jobLocation) {
    if (fields?.items?.hasOwnProperty('isConfidential')) {
      const confidential = fields?.items?.isConfidential;
      locationElement =
        confidential === true ? (
          <div className="address">{jobLocation}</div>
        ) : (
          <a
            className="address"
            href={fields?.items?.jobLocationHref}
            target="_blank"
            rel="noopener noreferrer"
          >
            {jobLocation}
          </a>
        );
    } else locationElement = <div className="address">{jobLocation}</div>;
  } else locationElement = null;
  return (
    <div className="location-sidebar">
      <h2 className={`sidebar-title ${brandName}`}>{t('location')}</h2>

      <div className="address-container">{locationElement}</div>
    </div>
  );
};

SidebarCardLocation.defaultProps = {
  fields: {},
  t: () => {},
  sitecoreContext: {},
};

SidebarCardLocation.propTypes = {
  fields: PropTypes.shape({}),
  t: PropTypes.func,
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(withTranslation()(SidebarCardLocation));
