import React from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import FeaturetteEvent from '../../Featurette/FeaturetteEvent';
/**
 * @description - FeaturetteEvent component;
 * @param {object}  props - Input props;
 * @returns{Node} - return html block;
 */
const EventCardScroll = (props) => {
  return <FeaturetteEvent {...props} />;
};

export default withSitecoreContext()(withTranslation()(EventCardScroll));
