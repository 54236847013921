import gql from 'graphql-tag';

export const GET_SITE_SEARCH_RESULT = gql`
  query GetResults($first: Int, $after: String!, $contentType: String!) {
    customSearch(
      index: "sitecore_web_index"
      first: $first
      after: $after
      fieldsEqual: [
        { name: "_template", value: "731156987037453b9a054ea42ff6a0c7" }
        { name: "contenttype_sm", value: $contentType }
      ]
    ) {
      results {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        items {
          item {
            ... on Article {
              url
              image {
                src
                alt
              }
              contentType {
                value
                targetId
                targetItem {
                  displayName
                }
              }
              date {
                value
                jss
              }
              headline {
                value
              }
            }
          }
        }
      }
    }
  }
`;
