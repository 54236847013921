import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @param {*} param0 - site params
 * @returns {*} - any
 */
const ButtonWithIcon = ({
  cssClass,
  text,
  handleButtonClick,
  actionParams,
  btnIcon,
  arrow,
  type,
}) => (
  <button
    className={`primary-button btn-with-icon ${cssClass}`}
    onClick={handleButtonClick ? (e) => handleButtonClick(e, actionParams) : null}
    type={type}
  >
    {btnIcon && <div className={`button-icon ${btnIcon}`} />}
    {btnIcon ? <div>{text}</div> : text}
    {arrow ? <div className="arrow-icon" aria-hidden="true" /> : null}
  </button>
);

ButtonWithIcon.defaultProps = {
  cssClass: '',
  text: 'Button',
  handleButtonClick: null,
  actionParams: null,
  btnIcon: null,
  arrow: true,
  type: 'button',
};

ButtonWithIcon.propTypes = {
  /** Color class  */
  cssClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func,
  actionParams: PropTypes.shape({}),
  btnIcon: PropTypes.string,
  arrow: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

export default ButtonWithIcon;
