import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

/**
 * @description - Modal Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const ModalComponent = (props) => {
  const {
    showModal,
    handleModal,
    size,
    className,
    backdrop,
    removeIndex = false,
    hideHeader = false,
    hideFooter = false,
  } = props;

  useEffect(() => {
    if (showModal && removeIndex) {
      document
        .getElementsByClassName('fade modal ')[0]
        .removeAttribute('role', 'tabIndex');
    }
  }, [showModal]);
  /**
   *
   * @param {*}  -handle close
   * @return {object} -action
   */
  const handleClose = () => {
    handleModal();
  };
  /**
   *
   * @param {*} key -key
   * @return {object} -action
   */
  const getComponent = (key) => {
    return Array.isArray(props.children)
      ? props.children.filter((comp) => {
          return comp.key === key;
        })
      : props.children.key === key && props.children;
  };

  return (
    <Modal
      {...props}
      show={showModal}
      onHide={handleClose}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={className}
      backdrop={backdrop}
    >
      {!hideHeader && (
        <Modal.Header closeButton style={{ border: 'none' }}>
          {getComponent('header')}
        </Modal.Header>
      )}

      <Modal.Body closeButton style={{ border: 'none' }}>
        {getComponent('body')}
      </Modal.Body>
      {!hideFooter && (
        <Modal.Footer closeButton style={{ border: 'none' }}>
          {getComponent('footer')}
        </Modal.Footer>
      )}
    </Modal>
  );
};

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  showModal: PropTypes.bool.isRequired,
  size: PropTypes.string,
  handleModal: PropTypes.func,
  className: PropTypes.string,
  backdrop: PropTypes.bool,
};

ModalComponent.defaultProps = {
  children: null,
  PropTypes: false,
  size: 'lg',
  className: '',
  backdrop: true,
  handleModal: () => {},
};

export default ModalComponent;
