import React from 'react';
import '../VerifyYourIdentity/verify_your_identity.scss';
import ButtonWithIcon from '../../../core/globals/buttons/ButtonWithIcon';
import TextButton from '../../../core/globals/buttons/TextButton';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

/**
 * @description - CreateProfile Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const VerifyYourIdentitySelection = ({ handleEmailVerification, t }) => {
  return (
    <section className="verify-identity">
      <div className="container flex-container">
        <div className="title-text-account">{t('verification-title')}</div>
        <div className="message-text-verify-identity">
          <p className="verify-identity-text">{t('verification-method-subtitle')}</p>
        </div>
        <div className="btn-area">
          <div className="button-verify-identity">
            <ButtonWithIcon
              text={t('email-verification-button')}
              btnIcon="email-icon"
              handleButtonClick={handleEmailVerification}
            />
          </div>
          <div className="button-verify-identity">
            <ButtonWithIcon
              text={t('text-verification-button')}
              btnIcon="text-icon"
            />
          </div>
        </div>
        <div className="difficult-text-verify-identity">{t('conditions-apply')}</div>
        <div className="diff-login">
          <a className="another-way-verify-identity" href="/">
            {t('login-different-account')}
          </a>
        </div>
        <div className="text-button-verify-identity">
          <TextButton cssClass="dark-gray" text="RETURN TO JOB RESULTS" />
        </div>
      </div>
    </section>
  );
};

VerifyYourIdentitySelection.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(VerifyYourIdentitySelection);
