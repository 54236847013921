import { BUILD_PROFILE_FORM } from '../actions/actionTypes';

const initialState = {};

/**
 *
 * @param {*} state - state
 * @param {*} action - action
 * @returns {*} - action
 */
const buildProfileFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUILD_PROFILE_FORM.PERSONAL_SAVE:
      return {
        ...state,
        ...action.payload,
      };
    case BUILD_PROFILE_FORM.RESUME_SAVE:
      return {
        ...state,
        ...action.payload,
      };
    case BUILD_PROFILE_FORM.CLEAR:
      return initialState;
    default:
      return state;
  }
};
export default buildProfileFormReducer;
