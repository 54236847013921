/* eslint-disable */
import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { geolocated, geoPropTypes } from 'react-geolocated';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';

import CardJob from '../../Cards/CardJob';
import TextLink from '../../globals/link/TextLink';
import { usePostData } from '../../../../hooks/http-client';
import { JOBS_CARD_SCROLL, SEARCH } from '../../../../constants';
import { removeSlideTab } from '../../../../utils/helperUtils';
import Loader from '../../../core/ErrorBoundary/Loader';

import '../cardscroll.scss';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  arrows: false,
  accessibility: true,
  lazyLoad: 'ondemand',
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const queryParams = {
  JobFilters: {
    searchkeyword: '',
    latitude: null,
    longitude: null,
  },
  PageType: '',
  UserLatitude: null,
  UserLongitude: null,
  HasLocation: false,
  company: '',
};

/**
 * @description to show job card scroll
 * @param {object} props - card scroll properties
 * @returns {node} -html
 */
const JobCardScroll = (props) => {
  const location = useLocation();
  const { response, loading, write } = usePostData();
  const {
    coords,
    sitecoreContext,
    t,
    params,
    rendering: { uid },
  } = props;

  const [searchPath, setSearchpath] = useState('');
  queryParams.PageType = params?.pageType;

  /**
   * @description - call to card scroll api
   * @param {object} queryParams - request body
   * @returns {undefined}
   */
  const getJobsCard = (queryParams) => {
    write(`${JOBS_CARD_SCROLL.GET_JOBS_CARD_SCROLL.URL}`, queryParams);
  };

  useEffect(() => {
    removeSlideTab(uid);
    setSearchpath({
      pathname: sitecoreContext.Country?.jobSearchURL,
      search: localStorage.getItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_PARAMS),
    });
  }, []);

  useEffect(() => {
    if (window) {
      const jobSearchFilters = JSON.parse(
        localStorage.getItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_KEY)
      );
      queryParams.JobFilters = jobSearchFilters ? jobSearchFilters?.filters : {};
      if (queryParams.JobFilters?.latitude && queryParams.JobFilters?.longitude) {
        queryParams.JobFilters.HasLocation = true;
      } else if (queryParams.JobFilters?.HasLocation) {
        queryParams.JobFilters.HasLocation = false;
      }
      if (jobSearchFilters?.JobFilters?.company)
        queryParams.company = jobSearchFilters?.JobFilters?.company;
      else delete queryParams.company;
    }
    if (props.params.pageType === 'Location') {
      queryParams.PageItemId = sitecoreContext?.itemId;
    }
    if (props.params.pageType === 'Job') {
      queryParams.PageItemId = sitecoreContext?.Country?.jobItemId;
    }
    if (props.params.pageType === 'App Route') {
      if (coords) {
        queryParams.UserLatitude = coords.latitude;
        queryParams.UserLongitude = coords.longitude;
        queryParams.HasLocation = true;
      }
    }
    getJobsCard(queryParams);
  }, [coords, location, sitecoreContext]);

  return (
    <Fragment>
      {loading && <Loader />}
      {response?.data?.jobCards?.length > 0 && (
        <section
          className={`card-scroll scroll_${uid}`}
          style={{ backgroundColor: `${props?.backgroundColor}` }}
        >
          <div className="container">
            <div className="card-scroll-header jobs">
              <div className="title">
                {`${
                  response?.data?.title
                    ? response?.data?.title + ' ' + props?.params?.cardScrollTitle
                    : props?.params?.cardScrollTitle
                }`}
              </div>
              <div className="cta">
                <TextLink
                  cssClass="blue"
                  ctaText={t('see-all')}
                  ctaUrl={searchPath}
                />
              </div>
            </div>
            <Slider {...settings}>
              {response?.data?.jobCards?.map((job) => {
                return (
                  <CardJob
                    title={job?.jobTitle}
                    subTitle={job?.employmentType}
                    location={job?.jobLocation}
                    date={job?.publishfromDate}
                    ctaText={t('view-job')}
                    ctaUrl={job?.jobURL}
                  />
                );
              })}
            </Slider>
          </div>
        </section>
      )}
    </Fragment>
  );
};

JobCardScroll.defaultProps = {
  coords: {},
  sitecoreContext: {},
  params: {},
  backgroundColor: '',
  t: () => {},
  rendering: {},
};

JobCardScroll.propTypes = {
  coords: PropTypes.shape({}),
  sitecoreContext: PropTypes.shape({}),
  params: PropTypes.shape({}),
  backgroundColor: PropTypes.string,
  t: PropTypes.func,
  ...geoPropTypes,
  rendering: PropTypes.shape({ uid: PropTypes.string }),
};

export default geolocated()(withTranslation()(withSitecoreContext()(JobCardScroll)));
