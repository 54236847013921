/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import ArticleHeader from './ArticleHeader';
import SingleArticle from './SingleArticle';
import './articleblock.scss';

/**
 *
 * @param {*} topics - list of topics
 * @returns {*} - data
 */
const getTopicsArray = (topics) => {
  if (topics && Array.isArray(topics)) {
    return topics.map((topic) => topic?.fields?.name?.value);
  }
  return [];
};

/**
 * @description - Article Block component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const ArticleBlock = (props) => {
  const {
    SocialSharePlatforms,
    RelatedArticles,
    sitecoreContext,
    BreadCrumb,
    HubspotForm,
  } = props;
  const { route = {} } = sitecoreContext;
  const { fields = {} } = route;
  const { abstract, headline, subTitle, body, image } = fields;
  const { contentType, topic, subTopic } = fields;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  useEffect(() => {
    if (window?.dataLayer) {
      const topics = getTopicsArray(topic);
      const subTopics = getTopicsArray(subTopic);
      window.dataLayer.push({
        event: 'InsightsArticlePage',
        eventCategory: 'Article loaded',
        eventAction: {
          contentType: contentType?.fields?.name?.value,
          topic: topics ? topics.join(', ') : '',
          subTopic: subTopics ? subTopics.join(', ') : '',
        },
        eventLabel: headline?.value,
      });
    }
  }, []);
  return (
    <Fragment>
      <section className={`article-block ${brandName}`}>
        <div className="container">
          <div className="row">
            <div className="col-xl-10">
              <div className="row">
                <div className="col">
                  <div className="article-header-container">
                    {BreadCrumb.map((component, index) => {
                      if (
                        component.props &&
                        component.props.type === 'text/sitecore'
                      )
                        return component;
                      return (
                        <Fragment key={index}>
                          <div className={`themeColor ${brandName}`}>
                            {component}
                          </div>
                        </Fragment>
                      );
                    })}
                    <ArticleHeader
                      title={headline}
                      subTitle={subTitle}
                      brandName={brandName}
                    />
                    {SocialSharePlatforms.map((component, index) => {
                      if (
                        component.props &&
                        component.props.type === 'text/sitecore'
                      )
                        return component;
                      return <Fragment key={index}>{component}</Fragment>;
                    })}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <SingleArticle
                    abstract={abstract}
                    body={body}
                    image={image}
                    fields={fields}
                  />
                  {HubspotForm.map((component, index) => {
                    if (component.props && component.props.type === 'text/sitecore')
                      return component;
                    return (
                      <Fragment key={index}>
                        <div className={`themeColor ${brandName}`}>{component}</div>
                      </Fragment>
                    );
                  })}
                </div>
                <div className="col-lg-4">
                  {RelatedArticles.map((component, index) => {
                    if (component.props && component.props.type === 'text/sitecore')
                      return component;
                    return <Fragment key={index}>{component}</Fragment>;
                  })}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {SocialSharePlatforms.map((component, index) => {
                    if (component.props && component.props.type === 'text/sitecore')
                      return component;
                    return <Fragment key={index}>{component}</Fragment>;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

ArticleBlock.defaultProps = {
  SocialSharePlatforms: [],
  RelatedArticles: [],
  fields: {
    abstract: { value: '' },
    image: {
      value: { src: 'http://via.placeholder.com/1440x583/EEEEEE/CCCCCC' },
    },
    body: { value: '' },
    headline: { value: '' },
    subTitle: { value: '' },
  },
  BreadCrumb: [],
  HubspotForm: [],
};

ArticleBlock.propTypes = {
  SocialSharePlatforms: PropTypes.arrayOf(PropTypes.shape()),
  RelatedArticles: PropTypes.arrayOf(PropTypes.shape()),
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
    Country: PropTypes.shape({
      brandName: PropTypes.string,
    }),
  }).isRequired,
  fields: PropTypes.shape({
    abstract: PropTypes.shape({ value: PropTypes.string }),
    body: PropTypes.shape({ value: PropTypes.string }),
    headline: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    image: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    }),
  }),
  BreadCrumb: PropTypes.arrayOf(PropTypes.shape()),
  HubspotForm: PropTypes.arrayOf(PropTypes.shape()),
};

const articleContainer = withSitecoreContext()(
  withPlaceholder([
    { placeholder: 'jss-content', prop: 'SocialSharePlatforms' },
    { placeholder: 'jss-right', prop: 'RelatedArticles' },
    { placeholder: 'jss-breadcrumb', prop: 'BreadCrumb' },
    { placeholder: 'jss-left', prop: 'HubspotForm' },
  ])(ArticleBlock)
);

export default articleContainer;
