import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Text, Link as JssLink } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - Header main links
 * @param {object} props - Input props
 * @returns {Node} - Links for route
 */
const MenuLink = (props) => {
  const { url, routeName, activeLinkClass, noSpan, navIcon } = props;
  const { clickHandler, actionParams, callback, ariaProps } = props;
  return (
    <Fragment>
      {url.value.linktype === 'external' ? (
        <JssLink
          field={url}
          className={activeLinkClass}
          onClick={
            clickHandler
              ? () => {
                  if (callback) {
                    callback();
                  }
                  clickHandler(...actionParams);
                }
              : null
          }
          {...ariaProps}
        >
          <Text tag={!noSpan ? 'span' : null} field={routeName} />
        </JssLink>
      ) : (
        <Link
          to={{
            pathname: url.value.href,
            search: url.value?.querystring?.replace(' & ', '$'),
          }}
          className={activeLinkClass}
          onClick={
            clickHandler
              ? () => {
                  if (callback) {
                    callback();
                  }
                  clickHandler(...actionParams);
                }
              : null
          }
          {...ariaProps}
        >
          {navIcon?.value === 'SearchIcon' && (
            <span className="search__icon">
              <div className="icon-search" />
            </span>
          )}
          <Text
            tag={!noSpan ? 'span' : null}
            field={routeName}
            className={navIcon?.value === 'SearchIcon' ? 'search__text' : ''}
          />
        </Link>
      )}
    </Fragment>
  );
};

MenuLink.propTypes = {
  url: PropTypes.shape({
    value: PropTypes.shape({
      linktype: PropTypes.string,
      href: PropTypes.string,
      querystring: PropTypes.string,
    }),
  }).isRequired,
  routeName: PropTypes.shape({
    value: PropTypes.string,
  }),
  activeLinkClass: PropTypes.string,
  noSpan: PropTypes.bool,
  clickHandler: PropTypes.func,
  actionParams: PropTypes.shape({}),
  callback: PropTypes.func,
  ariaProps: PropTypes.shape({}),
  navIcon: PropTypes.string,
};

MenuLink.defaultProps = {
  url: {
    value: {
      linktype: '',
      href: '#',
    },
  },
  routeName: {
    value: '',
  },
  activeLinkClass: '',
  noSpan: false,
  clickHandler: null,
  actionParams: {},
  callback: null,
  ariaProps: {},
  navIcon: '',
};

export default MenuLink;
