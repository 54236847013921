import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import VidyardPlayer from '../../business/VidyardPlayer';
import WistiaPlayer from '../../business/WistiaPlayer';
import YoutubePlayer from '../../business/YoutubePlayer';
import VimeoPlayer from '../../business/VimeoPlayer';
import { generateID } from '../../../utils/helperUtils';
import $ from 'jquery';

/**
 * @description - Single Article component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const SingleArticle = ({ abstract, body, image, fields }) => {
  useEffect(() => {
    /**
     * @description - loads the fancybox library.
     * @returns {*} - Returns nothing.
     */
    async function loadFancy() {
      await require('@fancyapps/fancybox');
      await require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
    }

    $(function () {
      if (!window.jQuery) window.jQuery = $;
      loadFancy();
    });
  }, []);
  return (
    <div className="single-article">
      <Text field={abstract} tag="p" className="large" />
      {fields?.source?.value !== '' && fields?.videoCode?.value !== '' ? (
        <div className="row">
          <div className="col">
            <div className="player-wrapper"></div>
            <div className="video-cont">
              {fields?.source?.value === 'Youtube' && (
                <YoutubePlayer
                  playerid={generateID(5)}
                  videoUrl={fields?.videoCode?.value}
                  videoPreviewImg={fields?.thumbnail}
                  videoDuration={fields?.duration}
                  title={fields?.title}
                  color=""
                />
              )}
              {fields?.source?.value === 'Wistia' && (
                <WistiaPlayer
                  playerid={generateID(5)}
                  videoUrl={fields?.videoCode?.value}
                  videoPreviewImg={fields?.thumbnail}
                  videoDuration={fields?.duration}
                  title={fields?.title}
                  color=""
                />
              )}
              {fields?.source?.value === 'Vidyard' && (
                <VidyardPlayer
                  playerid={generateID(5)}
                  videoUrl={fields?.videoCode?.value}
                  videoPreviewImg={fields?.thumbnail}
                  videoDuration={fields?.duration}
                  title={fields?.title}
                  color=""
                />
              )}
              {fields?.source?.value === 'Vimeo' && (
                <VimeoPlayer
                  playerid={generateID(5)}
                  videoUrl={fields?.videoCode?.value}
                  videoPreviewImg={fields?.thumbnail}
                  videoDuration={fields?.duration}
                  title={fields?.title}
                  color=""
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <Image className="responsive" editable={true} field={image} />
      )}
      <RichText field={body} className="content" />
    </div>
  );
};

SingleArticle.defaultProps = {
  abstract: { value: '' },
  body: { value: '' },
  image: { value: { src: '' } },
  fields: {},
};

SingleArticle.propTypes = {
  abstract: PropTypes.shape({ value: PropTypes.string.isRequired }),
  body: PropTypes.shape({ value: PropTypes.string.isRequired }),
  image: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SingleArticle;
