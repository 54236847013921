import { JOBAPPLY_FORM_STATE } from '../actions/actionTypes';

const initialState = {};

/**
 * @description - Reducer to store the form state for job apply form.
 * @param {*} state - Initial state.
 * @param {*} action - Dispatched action.
 * @returns {undefined} - Save data in store.
 */
const jobApplyFormStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOBAPPLY_FORM_STATE.SAVE:
      return {
        ...state,
        ...action.payload,
      };
    case JOBAPPLY_FORM_STATE.CLEAR:
      return initialState;
    default:
      return state;
  }
};
export default jobApplyFormStateReducer;
