import React from 'react';
import SiteHeaderLanguage from './SiteHeaderLanguage';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';

/** contrylinks */

/** @description - to display countryliknks
 * @param {Object} props - component props
 * @returns {HTML} - html
 */
const CountryLinkAdvanced = (props) => {
  const { sitecoreContext } = props;
  const { route } = sitecoreContext;
  const placeholders = route?.placeholders;
  const countryLinks = placeholders && placeholders['jss-common'];
  const linkItems = (countryLinks && countryLinks[1]?.fields) || {};

  const manpowerLink = placeholders && placeholders['jss-header'];
  const linkManpower =
    manpowerLink &&
    manpowerLink[0]?.placeholders &&
    manpowerLink[0]?.placeholders['jss-right'];
  const linkItemsManpower = (linkManpower && linkManpower[0]?.fields?.items) || {};
  return (
    <SiteHeaderLanguage
      linkItems={linkItems}
      linkItemsManpower={linkItemsManpower}
      sitecoreContext={sitecoreContext}
    />
  );
};

CountryLinkAdvanced.defaultProps = {
  sitecoreContext: {
    route: {},
  },
};

CountryLinkAdvanced.propTypes = {
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
  }),
};

export default withSitecoreContext()(CountryLinkAdvanced);
