import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './toggle.scss';

const ToggleSwitch = ({ selectedValue, selectHandler, inputName, ariaLabel }) => {
  const isTrueSet = /^true$/i.test(selectedValue);
  const [selected, setSelected] = useState(isTrueSet);

  const onToggle = (event) => {
    const isSelected = !selected;
    setSelected(isSelected);
    if (selectHandler) {
      selectHandler(event, isSelected);
    }
  };

  return (
    <div>
      <label className="toggle-switch">
        <input
          type="checkbox"
          checked={selected}
          onChange={onToggle}
          name={inputName}
          aria-label={ariaLabel}
          aria-checked={selected}
          tabIndex="0"
        />
        <span className="switch" />
      </label>
    </div>
  );
};

ToggleSwitch.propTypes = {
  selectedValue: PropTypes.string,
  selectHandler: PropTypes.func,
  inputName: PropTypes.string,
  ariaLabel: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  selectedValue: 'false',
  inputName: '',
  ariaLabel: 'toggle button',
};
export default ToggleSwitch;
