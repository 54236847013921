import React from 'react';
import PropTypes from 'prop-types';
import { setAltUrlForImg } from '../../../../utils/helperUtils';
import { Image } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - Social Icons Component.
 * @param {Object} param0 - Input Props.
 * @returns {Node} - Html template.
 */
const FollowUs = ({ img, altText, tooltip, ctaUrl, arialabel }) => {
  return (
    <a
      href={ctaUrl?.value}
      className="social-icon"
      target="_blank"
      rel="noopener noreferrer"
      title={tooltip}
      aria-label={tooltip + '-' + arialabel}
    >
      <Image field={setAltUrlForImg(img, altText)} />
    </a>
  );
};

FollowUs.defaultProps = {
  img: '',
  altText: '',
  tooltip: '',
  ctaUrl: '#',
  arialabel: '',
};

FollowUs.propTypes = {
  img: PropTypes.string.isRequired,
  altText: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  arialabel: PropTypes.string,
};

export default FollowUs;
