import React from 'react';
import PropTypes from 'prop-types';
import CardStat from '../Cards/CardStat';

import './cardblock.scss';

/**
 * @description-  CardBlockStat standard component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const CardBlockStat = (props) => {
  const { fields, params } = props;
  const { cssClass = '' } = params;
  const { items = [], noTopPadding } = fields;
  return (
    <section
      className={`card-block stat statblock ${
        noTopPadding.value === true ? 'no-top-pad' : ''
      } ${cssClass}`}
    >
      <div className="container">
        {fields?.title?.value && (
          <h2 hidden className="title" aria-hidden={true}>
            {fields?.title?.value}
          </h2>
        )}
        <div className="row">
          {items.map((statInfo, index) => (
            <div className="col-md-4" key={index}>
              {/* {cssClass === 'gradient' ? (
                <CardStat
                  stat={statInfo.fields?.stat}
                  text={statInfo.fields?.text}
                  icon={statInfo.fields?.icon || ''}
                  altText={statInfo.fields?.icon?.value?.alt || ''}
                />
              ) : cssClass === 'manpower' ? (
                <CardStat
                  stat={statInfo.fields?.stat}
                  text={statInfo.fields?.text}
                  icon={statInfo.fields?.icon || ''}
                  altText={statInfo.fields?.icon?.value?.alt || ''}
                />
              ) : (
                <CardStat
                  stat={statInfo.fields?.stat}
                  text={statInfo.fields?.text}
                  icon={statInfo.fields?.icon || ''}
                  altText={statInfo.fields?.icon?.value?.alt || ''}
                />
              )} */}
              <CardStat
                stat={statInfo.fields?.stat}
                text={statInfo.fields?.text}
                icon={statInfo.fields?.icon || ''}
                altText={statInfo.fields?.icon?.value?.alt || ''}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

CardBlockStat.defaultProps = {
  fields: {
    noTopPadding: { value: false },
    items: [],
    title: { value: '' },
  },
  params: PropTypes.shape({
    cssClass: {
      value: '',
    },
  }),
};

CardBlockStat.propTypes = {
  fields: PropTypes.shape({
    noTopPadding: PropTypes.shape({ value: PropTypes.bool }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.shape({}),
  }),
  params: PropTypes.shape({
    cssClass: {
      value: PropTypes.string,
    },
  }),
};

export default CardBlockStat;
