import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../globals/buttons/Button';
import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import AccordionBlockWithRTL from './AccordionBlockWithRTL';
import mockDataWithRTL from './mockDataWithRTL';
import './accordionRTL.scss';

/**
 * @description - AccordionWithRTL component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const AccordionWithRTL = ({ fields, t, params }) => {
  fields = Object.entries(fields).length ? fields : mockDataWithRTL;
  const { title, text, mode, allowMultipleOpen, items = [], count } = fields;
  const { type = '' } = params;
  const limit = count && count.value ? parseInt(count.value) : 5;
  const isMultipleItemOpen =
    allowMultipleOpen && allowMultipleOpen.value ? allowMultipleOpen.value : false;
  const [n, setN] = useState(limit);
  const [focusId, setFocus] = useState(-1);
  /**
   * @description - load more.
   * @param {Number} n - Number.
   * @returns {Number} - number.
   */
  function loadMore(n) {
    setFocus(n);
    return parseInt(n) + limit;
  }

  /**
   * @description - delay setter.
   * @param {Function} scroll - function.
   * @param {String} id -String
   * @returns {undefined}
   */
  const delaySetter = (scroll = () => {}, id) => {
    setTimeout(() => {
      id && scroll();
    }, 10);
  };

  /**
   * @description - handle accordion click.
   * @param {number} id -id.
   * @returns {undefined}
   */
  const handleAccordionClick = (id) => {
    delaySetter(
      () => document?.getElementById(id)?.scrollIntoView({ behavior: 'smooth' }),
      id
    );
  };
  return (
    <section
      className={`accordion-section rtl core ${
        type
          ? mode?.value
            ? `gradient ${type.toLowerCase()}`
            : type.toLowerCase()
          : mode?.value
          ? 'gradient'
          : ''
      }`}
      aria-labelledby="title"
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <Text
                  field={title}
                  className="title"
                  tag="h2"
                  id="title"
                  role="heading"
                />
                <Text field={text} tag="p" className="text" id="info" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                {items && items.length > 0 && (
                  <AccordionBlockWithRTL
                    allowMultipleOpen={isMultipleItemOpen}
                    data={items.slice(0, n)}
                    handleAccordionClick={handleAccordionClick}
                    focusId={focusId}
                  />
                )}
              </div>
            </div>
            {items && n < items.length && (
              <div className="row">
                <div className="col">
                  <Button
                    text={t('read-more')}
                    handleButtonClick={() => setN(loadMore)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

AccordionWithRTL.defaultProps = {
  fields: {
    title: { value: '' },
    text: { value: '' },
    mode: { value: false },
    allowMultipleOpen: { value: false },
    count: { value: '3' },
    items: [
      {
        name: '',
        displayName: '',
        fields: {
          title: { value: '' },
          content: { value: '' },
        },
      },
    ],
  },
  params: {
    type: '',
  },
};

AccordionWithRTL.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    text: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    mode: PropTypes.shape({
      value: PropTypes.bool.isRequired,
    }),
    allowMultipleOpen: PropTypes.shape({
      value: PropTypes.bool.isRequired,
    }),
    count: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          title: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
          content: PropTypes.shape({
            value: PropTypes.string.isRequired,
          }),
        }),
      })
    ),
  }),
  params: PropTypes.shape({
    type: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AccordionWithRTL);
