import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Card from '../../Cards/Card';
import NoData from '../../ErrorBoundary/NoData';
import Slider from 'react-slick';
import TextLink from '../../globals/link/TextLink';
import {
  getImg,
  isDataExists,
  checkDataExists,
  removeSlideTab,
} from '../../../../utils/helperUtils';
import '../cardscroll.scss';

const SLIDES_TO_SHOW = 3;
const BREAK_POINT = 992;

/**
 * @description Slide load hook.
 * @returns {undefined}
 */
const slideLoadHook = (uid) => {
  if (typeof window !== 'undefined') {
    document.querySelectorAll(`.scroll_${uid} .slick-slide`).forEach((node) => {
      if (node.className.includes('slick-active')) {
        if (node.querySelector('.cta__wrap .cta-text')) {
          node.querySelector('.cta__wrap .cta-text').setAttribute('tabindex', '0');
        }
      } else {
        if (node.querySelector('.cta__wrap .cta-text')) {
          node.querySelector('.cta__wrap .cta-text').setAttribute('tabindex', '-1');
        }
      }
    });
  }
};

/**
 *
 * @param {*} uid
 * @returns
 */
const getSettings = (uid) => {
  return {
    accessibility: true,
    dots: true,
    speed: 500,
    slidesToShow: SLIDES_TO_SHOW,
    slidesToScroll: SLIDES_TO_SHOW,
    infinite: false,
    arrows: false,
    lazyLoad: 'ondemand',
    afterChange: () => slideLoadHook(uid),
    // eslint-disable-next-line react/display-name
    appendDots: (dots) => {
      return (
        <ul style={{ margin: '0px' }} className={'slick-dots'}>
          {dots.map((dot, index) => (
            <li key={index} className={dot.props.className}>
              <button
                onClick={dot.props.children.props.onClick}
                aria-label={`Slide${dot.props.children.props.children}`}
                aria-current={dot.props.className ? 'true' : 'false'}
              >
                {dot.props.children.props.children}
              </button>
            </li>
          ))}
        </ul>
      );
    },
    responsive: [
      {
        breakpoint: BREAK_POINT,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
};

const getUpdatebackgroundColor = (componentBackgroundColor) => {
  componentBackgroundColor &&
    componentBackgroundColor.map((item) => {
      const { colorCode } = item?.fields;
      let color_code = '';
      color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
      return color_code;
    });
};
/**
 * @description - Card Scroll case studies component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML section.
 */
const CardScrollCaseStudies = (props) => {
  const {
    cssClass,
    fields,
    t,
    sitecoreContext,
    page,
    rendering: { uid },
  } = props;
  const {
    title,
    ctaUrl,
    ctaText,
    placeholderImage,
    items = [],
    linkText,
    componentBackgroundColor,
  } = fields;
  const { contentType, topic, subTopic } = fields;
  const history = useHistory();
  const isItemExists = isDataExists(items);
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  let ctaUrlVal = '';
  if (page === 'partner') {
    ctaUrlVal = ctaUrl;
  } else {
    const urlVal = ctaUrl?.value?.href ? ctaUrl?.value?.href.split('?') : [];
    let id = '';
    let cn_name = '';
    if (contentType && contentType[0]) {
      id = contentType[0]?.id;
      cn_name = contentType[0]?.fields?.name?.value;
    }
    const queryVal = isItemExists
      ? {
          contentType: {
            id: id,
            name: cn_name,
          },
          topic: {
            id: topic?.id,
            name: topic?.fields?.name?.value,
          },
          subTopic: {
            id: subTopic?.id,
            name: subTopic?.fields?.name?.value,
          },
        }
      : {};
    ctaUrlVal = {
      pathname: urlVal[0],
      search: urlVal[1],
      query: queryVal,
    };
  }
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
  const updatebackgroundColor = getUpdatebackgroundColor(componentBackgroundColor);
  const settings = getSettings(uid);

  useEffect(() => {
    removeSlideTab(uid);
  }, []);

  return isItemExists || isEditing ? (
    <section
      className={`card-scroll scroll_${uid} ${brandName} ${cssClass} ${
        isItemExists ? '' : 'no-padding'
      }`}
      style={{
        background: updatebackgroundColor ? `${updatebackgroundColor}` : 'none',
      }}
    >
      <div className="container">
        <div className="card-scroll-header">
          <h2 className="title">
            <Text field={title} />
          </h2>
          {ctaUrl?.value && ctaText?.value && (
            <div className="cta">
              {brandName === 'manpower' ? (
                <TextLink ctaText={ctaText} ctaUrl={ctaUrlVal} />
              ) : (
                <TextLink cssClass="blue" ctaText={ctaText} ctaUrl={ctaUrlVal} />
              )}
            </div>
          )}
        </div>
        {isItemExists ? (
          <Slider {...settings}>
            {items.map((item, idx) => {
              const itemVal = item?.fields || {};
              const url = itemVal?.url?.value || itemVal?.url;
              return (
                <Card
                  key={`card_${idx}`}
                  title={itemVal?.headline}
                  text={itemVal?.text}
                  img={
                    itemVal.image?.src ||
                    getImg(itemVal.image) ||
                    getImg(placeholderImage)
                  }
                  altText=" "
                  ctaText={linkText}
                  brandName={brandName}
                  clickHandler={() => history.push(url)}
                />
              );
            })}
          </Slider>
        ) : (
          <NoData message={t('no-data-exists')} />
        )}
      </div>
    </section>
  ) : null;
};

CardScrollCaseStudies.defaultProps = {
  fields: {
    items: [],
    contentType: {},
    topic: {},
    subTopic: {},
  },
  cssClass: '',
  page: 'placeholder',
  rendering: {},
};

CardScrollCaseStudies.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({
      value: PropTypes.string,
    }),

    ctaText: PropTypes.shape({
      value: PropTypes.string,
    }),

    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
    componentBackgroundColor: PropTypes.shape({
      value: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    placeholderImage: PropTypes.shape({
      value: PropTypes.shape({}),
    }),
    linkText: PropTypes.shape({
      value: PropTypes.string,
    }),
    contentType: PropTypes.shape({}),
    topic: PropTypes.shape({}),
    subTopic: PropTypes.shape({}),
  }),
  cssClass: PropTypes.string,
  page: PropTypes.string,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({
    pageEditing: PropTypes.bool,
  }).isRequired,
  rendering: PropTypes.shape({ uid: PropTypes.string }),
};

export default withTranslation()(withSitecoreContext()(CardScrollCaseStudies));
