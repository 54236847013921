import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import ArticleHeader from './ArticleHeader';
import SingleArticle from './SingleArticle';
import './partnerarticledetailsblock.scss';

/**
 * @description - Article Block component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const PartnerArticleDetailsBlock = (props) => {
  const { SocialSharePlatforms, sitecoreContext, BreadCrumb } = props;
  const { route = {} } = sitecoreContext;
  const { fields = {} } = route;
  const { abstract, headline, subTitle, body, image } = fields;

  return (
    <Fragment>
      <section className="article-block partner-article-block">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1">
              <div className="row">
                <div className="col">
                  <div className="article-header-container">
                    {BreadCrumb.map((component, index) => {
                      if (
                        component.props &&
                        component.props.type === 'text/sitecore'
                      )
                        return component;
                      return <Fragment key={index}>{component}</Fragment>;
                    })}
                    <ArticleHeader title={headline} subTitle={subTitle} />
                    {SocialSharePlatforms.map((component, index) => {
                      if (
                        component.props &&
                        component.props.type === 'text/sitecore'
                      )
                        return component;
                      return <Fragment key={index}>{component}</Fragment>;
                    })}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <SingleArticle abstract={abstract} body={body} image={image} />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {SocialSharePlatforms.map((component, index) => {
                    if (component.props && component.props.type === 'text/sitecore')
                      return component;
                    return <Fragment key={index}>{component}</Fragment>;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

PartnerArticleDetailsBlock.defaultProps = {
  SocialSharePlatforms: [],
  fields: {
    abstract: { value: '' },
    image: {
      value: { src: 'http://via.placeholder.com/1440x583/EEEEEE/CCCCCC' },
    },
    body: { value: '' },
    headline: { value: '' },
    subTitle: { value: '' },
  },
  BreadCrumb: [],
};

PartnerArticleDetailsBlock.propTypes = {
  SocialSharePlatforms: PropTypes.arrayOf(PropTypes.shape()),
  sitecoreContext: PropTypes.shape({
    route: PropTypes.shape({}),
  }).isRequired,
  fields: PropTypes.shape({
    abstract: PropTypes.shape({ value: PropTypes.string }),
    body: PropTypes.shape({ value: PropTypes.string }),
    headline: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    image: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    }),
  }),
  BreadCrumb: PropTypes.arrayOf(PropTypes.shape()),
};

const articleContainer = withSitecoreContext()(
  withPlaceholder([
    { placeholder: 'jss-content', prop: 'SocialSharePlatforms' },
    { placeholder: 'jss-breadcrumb', prop: 'BreadCrumb' },
  ])(PartnerArticleDetailsBlock)
);

export default articleContainer;
