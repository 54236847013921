import React from 'react';
import PropTypes from 'prop-types';
import { RichText, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import ModalComponent from '../../Modal';
import Button from '../../globals/buttons/Button';
import FilterPill from './FilterPill';

const ModalFilter = ({
  visibility,
  handleModal,
  JobFilters,
  jobctaText,
  searchLabel,
  totalPositions,
  totalPositionsLabel,
  translate,
  iconFilter,
  selectedFilters,
  iconClose,
}) => {
  return (
    <ModalComponent
      showModal={visibility}
      className="fullscreen-filter"
      handleModal={handleModal}
      size="lg"
    >
      <div key="body">
        <div className="overlay-body">
          <div className="search-overlay-label">
            <h2 className="text">
              <RichText
                tag="div"
                className="FoundLabel"
                field={{ value: searchLabel }}
              />
              {totalPositions ? (
                <>
                  {', '}
                  <div className="FoundLabel">
                    {totalPositionsLabel} <span>{totalPositions}</span>
                  </div>
                </>
              ) : (
                ''
              )}
            </h2>
          </div>

          {selectedFilters && (
            <section>
              <div className="job-search-pills overlay-pill">
                <FilterPill data={selectedFilters} iconClose={iconClose} />
              </div>
            </section>
          )}
          <div className="job-filters-global accordion accordion-section mobile">
            <div className="filter-block">
              <div className="filter-block-inner">
                <div className="icon-filters">
                  <img src={mediaApi.updateImageUrl(iconFilter)} alt="filter icon" />
                </div>
                <div className="text">{translate('filter-result')}</div>
              </div>
            </div>
            <div className="job-filters-inner">
              {JobFilters && JobFilters.map((component) => component)}
            </div>
            {jobctaText && (
              <div className="cta-btn-container">
                <Button
                  cssClass="blue"
                  handleButtonClick={() => {
                    handleModal();
                  }}
                  text={jobctaText}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

ModalFilter.propTypes = {
  visibility: PropTypes.bool,
};

ModalFilter.defaultProps = {
  visibility: false,
  handleModal: () => {},
};

export default ModalFilter;
