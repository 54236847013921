import React from 'react';
import PropTypes from 'prop-types';
import StandardCardScroll from '../../core/CardScrolls/StandardCardScroll';

/**
 * @description - Job profile card.
 * @param {object} props - Input props.
 * @returns {Node} - Card Ad component.
 */
const PartnerStandardCardScroll = (props) => {
  return <StandardCardScroll {...props} page="partner" />;
};

export default PartnerStandardCardScroll;

PartnerStandardCardScroll.propTypes = {
  fields: PropTypes.shape({}).isRequired,
};

PartnerStandardCardScroll.defaultProps = {
  fields: {},
};
