import { PUBLIC_POSITION } from '../actions/actionTypes';

const initialState = {
  data: {},
};
/**
 * @description - public Position Reducer function
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */
const publicPositionReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUBLIC_POSITION.SAVE:
      return {
        ...state,
        data: action.payload,
      };
    case PUBLIC_POSITION.CLEAR:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
};
export default publicPositionReducer;
