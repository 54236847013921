import { BANNER_LOADED } from '../actions/actionTypes';

const initialState = {
  EmployersSplashBanner: false,
  HomeSplashBanner: false,
  InsightsPageBannerWithLogo: false,
  TallBanner: false,
  MyPathBanner: false,
  SearchHeader: false,
};

/**
 *
 * @param {*} state
 * @param {*} action
 */
const bannerLoadedReducer = (state = initialState, action) => {
  switch (action.type) {
    case BANNER_LOADED.LOADED:
      return { ...state, [action.banner]: action.value };
    default:
      return state;
  }
};
export default bannerLoadedReducer;
