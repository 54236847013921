import React from 'react';
import PropTypes from 'prop-types';
import { Text, mediaApi } from '@sitecore-jss/sitecore-jss-react';

import ButtonLink from '../globals/link/ButtonLink';
import { getImg, checkString } from '../../../utils/helperUtils';

import './page-banners.scss';

/**
 * @description - Standard Page Banner component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const Banner = ({ fields }) => {
  const { title, text, ctaText, ctaUrl, backgroundImage, color } = fields;
  const colorVal = checkString(color).toLowerCase();

  return (
    <section className={`banner-section standard ${colorVal}`}>
      <div
        className="banner-image"
        style={{
          backgroundImage: `url(${mediaApi.updateImageUrl(
            getImg(backgroundImage)
          )})`,
        }}
      />
      <div className="container">
        <div className="row in-left">
          <div className="col-xl-4 col-lg-6">
            <h1 className="title" aria-describedby="banner-description">
              <Text field={title} />
            </h1>
            <p id="banner-description">
              <Text field={text} />
            </p>

            <ButtonLink ctaText={ctaText} ctaUrl={ctaUrl} />
          </div>
        </div>
      </div>
    </section>
  );
};

Banner.defaultProps = {
  fields: {
    title: { value: '' },
    text: {
      value: '',
    },
    ctaText: { value: '' },
    ctaUrl: { value: null },
    backgroundImage: {
      value: { src: '' },
    },
    color: {
      value: '',
    },
  },
};

Banner.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    color: PropTypes.shape({ value: PropTypes.string }),
  }).isRequired,
};

export default Banner;
