/* eslint-disable */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthWrapper from '../../../core/LoginMSAL/AuthWrapper';
import { authstates } from '../../../../utils/enums';
import JobAlert from '../../Search/SearchJob/JobAlert/JobAlert';
import JobAlertByEmail from '../../Search/SearchJob/JobAlert/JobAlertByEmail';
import { isNullorEmpty } from '../../../../utils/helperUtils';
import ModalComponent from '../../Modal';
import {
  withSitecoreContext,
  RichText,
  mediaApi,
} from '@sitecore-jss/sitecore-jss-react';
import './SEOJobResultsRibbon.scss';

/**
 * @description to show search result count and related information
 * @param {number} searchResultCount - no of results
 * @returns {node} html
 */
const SEOJobResultsRibbon = ({ t, sitecoreContext }) => {
  const {
    totalJobResults,
    showingResults,
    seeAllJobs,
    searchLabels,
    showJobAlert,
    frequency,
    alertSuccess,
    consentText,
    dashboardURL,
    iconAlert,
    searchCriteria,
    seoSearchResultCss,
    iconFilter,
  } = useSelector((state) => state.seoSearchResultsReducer);
  const newlayout = !isNullorEmpty(seoSearchResultCss);
  const [showModal, setShowModal] = useState(false);
  const loginReducer = useSelector((state) => state.loginReducer);

  const getReplacedLabel = (label) => {
    const mapObj = {
      count: showingResults,
      totalcount: totalJobResults,
    };
    const regexKeys = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    let newSearchLabel = label?.replace(regexKeys, function (matched) {
      return mapObj[matched];
    });
    newSearchLabel = newSearchLabel?.replace(/[()]/g, '');
    return newSearchLabel;
  };

  useEffect(() => {
    if (
      loginReducer.initialized &&
      loginReducer.state === authstates.AUTHENTICATED
    ) {
      const createJobAlertButtonClickedToLogin = sessionStorage.getItem(
        'createJobAlertButtonClickedToLogin'
      );
      if (createJobAlertButtonClickedToLogin === 'true') {
        handleModal();
        sessionStorage.removeItem('createJobAlertButtonClickedToLogin');
      }
    }
  }, []);

  /**
   * @description - login user and create alert
   * @param {*} login - to login
   * @returns {undefined} -
   */
  const loginUserAndCreateJobAlert = (login) => {
    if (login !== null && typeof window !== 'undefined') {
      sessionStorage.setItem('createJobAlertButtonClickedToLogin', true);
      localStorage.setItem(
        'authenticationType',
        'Apply > Create Account / Apply > Sign in'
      );
      login(null, 'createAlert');
    }
  };

  /**
   * @description to show job alert dialog
   * @returns {undefined} - nothing
   */
  const handleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <section className="search-ribbon-global container">
      <div
        className={`${
          newlayout ? 'col-md-12 seo-ribbon-filter' : 'col-md-8'
        } card-ribbon`}
      >
        <div className="search-ribbon-wrap active">
          <div className="search-ribbon-inner">
            {newlayout ? (
              <>
                <div className="see-all-results">
                  {seeAllJobs ? (
                    <div className="icon-new-filter">
                      <div className="icon-filters">
                        <img
                          src={mediaApi.updateImageUrl(iconFilter)}
                          className="filter_icon"
                          alt=""
                        />
                      </div>
                      <Link to={seeAllJobs}>
                        <div className="text">{t('seo-all-job-results')}</div>
                      </Link>
                    </div>
                  ) : null}
                </div>
                <div className="results">
                  {showingResults && totalJobResults ? (
                    <h2 className="text">
                      <RichText
                        tag="div"
                        className="searchLabel"
                        field={{
                          value: getReplacedLabel(searchLabels?.labelForDesktop),
                        }}
                      />
                    </h2>
                  ) : null}
                </div>
                <div className="create-seo-alert">
                  {showJobAlert && sitecoreContext?.LoginType === 'ADB2C' && (
                    <AuthWrapper>
                      {({ login, authenticationState }) => {
                        return (
                          <>
                            <button
                              className="create-alert-btn"
                              onClick={() => {
                                window?.dataLayer.push({
                                  event: 'jobAlertClick',
                                });
                                authenticationState === authstates.AUTHENTICATED
                                  ? handleModal()
                                  : loginUserAndCreateJobAlert(login);
                              }}
                            >
                              {t('create-alert-for-future-jobs')}
                              <div className="icon-alert">
                                <img
                                  src={mediaApi.updateImageUrl(iconAlert)}
                                  className="filter_icon"
                                  alt=""
                                />
                              </div>
                            </button>
                          </>
                        );
                      }}
                    </AuthWrapper>
                  )}
                  {showJobAlert && sitecoreContext?.LoginType === 'ROMA' && (
                    <button
                      className="create-alert-btn"
                      onClick={() => {
                        window?.dataLayer.push({
                          event: 'jobAlertClick',
                        });
                        handleModal();
                      }}
                    >
                      {t('create-alert-for-future-jobs')}
                      <div className="icon-alert">
                        <img
                          src={mediaApi.updateImageUrl(iconAlert)}
                          className="filter_icon"
                          alt=""
                        />
                      </div>
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="results">
                  {showingResults && totalJobResults ? (
                    <h2 className="text">
                      <RichText
                        tag="div"
                        className="searchLabel"
                        field={{
                          value: getReplacedLabel(searchLabels?.labelForDesktop),
                        }}
                      />
                    </h2>
                  ) : null}
                </div>
                <div className="see-all-results">
                  {seeAllJobs ? (
                    <div className="icon-filter">
                      <Link to={seeAllJobs}>
                        <div className="text">{t('seo-all-job-results')}</div>
                      </Link>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ModalComponent
        showModal={showModal}
        handleModal={handleModal}
        className="create-alert_modal"
      >
        {sitecoreContext?.LoginType === 'ADB2C' && (
          <JobAlert
            key="body"
            jobAlertOptions={frequency}
            jobAlertSuccessMessage={alertSuccess}
            dashboardUrl={dashboardURL?.value}
            handleModal={handleModal}
            showModal={showModal}
            searchCriteria={searchCriteria}
          />
        )}
        {sitecoreContext?.LoginType === 'ROMA' && (
          <JobAlertByEmail
            key="body"
            jobAlertOptions={frequency}
            jobAlertSuccessMessage={alertSuccess}
            handleModal={handleModal}
            consentText={consentText}
            showModal={showModal}
            searchCriteria={searchCriteria}
          />
        )}
      </ModalComponent>
    </section>
  );
};

SEOJobResultsRibbon.defaultProps = {
  searchResultCount: 0,
  totalCount: 0,
  t: () => {},
  sitecoreContext: {},
};

SEOJobResultsRibbon.propTypes = {
  searchResultCount: PropTypes.number,
  totalCount: PropTypes.number,
  t: PropTypes.func,
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(withTranslation()(SEOJobResultsRibbon));
