/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useHistory } from 'react-router-dom';
import TextboxComplex from '../../globals/forms/TextboxComplex';
import Button from '../../globals/buttons/Button';
import { HeaderContext } from '../../SiteHeader/HamburgerMenu/HeaderContext';

import LocationSearch from '../../globals/forms/LocationSearch';
import {
  isObjNotEmpty,
  getSearchURLParamsUpdated,
  parseFilterData,
  verifySearchJobPath,
} from '../../../../utils/helperUtils';
import { SEARCH } from '../../../../constants';
import { saveSelectedLocation } from '../../../../redux/actions';

/**
 * @description - Handle submit.
 * @return {node} - structure.
 */
const handleFormSubmit = ({
  values,
  urlVal,
  history,
  setMenuOpen,
  setMenuSection,
  searchLocation,
  params,
  textName,
  isInHeader,
  setValue,
  pathname,
  dispatch,
}) => {
  const paramVal = new URLSearchParams();
  if (isObjNotEmpty(searchLocation)) {
    dispatch(saveSelectedLocation(searchLocation));
  }
  const finalParams = getSearchURLParamsUpdated(
    paramVal,
    values[textName],
    searchLocation
  );
  if (pathname === urlVal) {
    history.replace({
      pathname: urlVal,
      search: String(decodeURIComponent(finalParams)),
    });
  } else {
    history.push({
      pathname: urlVal,
      search: String(decodeURIComponent(finalParams)),
    });
  }
  setMenuOpen(false);
  setMenuSection('');
  if (isInHeader) {
    setValue(textName, null);
  } else {
    setValue(textName, params.get('searchKeyword'));
  }
};

/**
 * @param {object} props - Input props.
 * @returns {Node} - HTML form.
 */
const FormElements = (props) => {
  const { isInHeader, textName, register, locationSelected, buttonCssClass } = props;
  const { sitecoreContext, searchLocation, onInputChange, handleSubmit } = props;
  const { getValues, locName, t, onSubmit } = props;
  const { errors, control } = props;

  /**
   *
   * @param {*} searchLoc -search location
   * @return {object} -action
   */
  const validateLocation = (searchLoc) => {
    return !(
      searchLoc &&
      (!isObjNotEmpty(searchLocation) ||
        (isObjNotEmpty(searchLocation) && searchLocation.place !== searchLoc))
    );
  };

  return (
    <form className="row" onSubmit={handleSubmit(onSubmit)}>
      <div className={`col-xl-6 col-lg-5`}>
        <TextboxComplex
          id={`sr_job_${isInHeader && 'header'}`}
          label={
            isInHeader
              ? t('job-title-industry-skill')
              : t('jobs-search-keyword-label')
          }
          isSearchEnabled={false}
          name={textName}
          register={register}
          value={getValues(textName)}
          showOrgVal
          labelMsg={
            getValues(textName)
              ? isInHeader
                ? `Search ${t('job-title-industry-skill')}`
                : `Search ${t('jobs-search-keyword-label')}`
              : isInHeader
              ? `${t('job-title-industry-skill')} edit blank`
              : `${t('jobs-search-keyword-label')} edit blank`
          }
        />
      </div>
      <div className={`col-xl-4 col-lg-4`}>
        <Controller
          as={LocationSearch}
          control={control}
          onLocationSelect={locationSelected}
          label={
            isInHeader
              ? t('search-location-header')
              : t('jobs-search-location-label')
          }
          region={sitecoreContext?.Country?.code}
          errorMessage={t('invalid-location')}
          id={`sr_location_${isInHeader && 'header'}`}
          name={locName}
          disableEnterSubmit={true}
          rules={{ validate: validateLocation }}
          showError={errors && errors[locName]}
          selectedLocation={searchLocation}
          changeHandler={onInputChange}
          defaultValue={searchLocation?.place}
          showCurrentLocation={isInHeader ? true : false}
        />
      </div>
      <div className={`col-xl-2 col-lg-3`}>
        <Button
          cssClass={buttonCssClass}
          text={t('search-jobsearch')}
          handleButtonClick={handleSubmit(onSubmit)}
          typeProp={{ type: 'submit' }}
        />
      </div>
    </form>
  );
};

/**
 * @description - Job search form.
 * @param {object} props - Input props.
 * @returns {Node} -Search form.
 */
const CompanyJobSearchForm = (props) => {
  const { mode, t, location, sitecoreContext, urlVal, buttonCssClass } = props;
  const { isFilterExists, dispatch, locationState } = props;
  const history = useHistory();
  const params = new URLSearchParams(location?.search);
  const headerVals = useContext(HeaderContext) || {};
  const { isInHeader, setMenuOpen, setMenuSection, menuOpen } = headerVals;
  const textName = `searchJobText${isInHeader ? 'Header' : ''}`;
  const locName = `searchLocation${isInHeader ? 'Header' : ''}`;
  const filter = typeof window !== 'undefined' ? parseFilterData() : undefined;
  const filterVal = filter || {};
  let locValue = {};
  if (!isInHeader && filterVal.latitude) {
    locValue = {
      lat: filterVal.latitude,
      lng: filterVal.longitude,
      place: decodeURIComponent(filterVal.place),
    };
  }
  const [searchLocation, setSrLocation] = useState(locValue || {});
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    errors,
    control,
    reset,
    clearErrors,
  } = useForm({
    mode: 'onClick',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      searchJobText:
        params.get('mode') !== 'site' ? params.get('searchKeyword') : '',
      searchJobTextHeader: '',
      searchLocation: '',
      searchLocationHeader: searchLocation?.place,
    },
  });

  /**
   * @description - On submit/search function.
   * @param {object} values - Form values.
   * @returns {undefined} - Sets the params and redirect.
   */
  const onSubmit = (values = {}) => {
    handleFormSubmit({
      values,
      urlVal,
      history,
      setMenuOpen,
      setMenuSection,
      searchLocation,
      params,
      textName,
      isInHeader,
      setValue,
      pathname: location?.pathname,
      dispatch,
    });
  };

  /**
   * @description function called followed by place selection
   * @param {object} locVal  - geometry of location
   * @returns {undefined} - no return
   */
  const locationSelected = (locVal) => {
    if (locVal) {
      setSrLocation(locVal);
      setValue(locName, locVal.place);
      clearErrors(locName);
    } else {
      setSrLocation({});
    }
  };

  /**
   * @description - On input location change.
   * @param {string} value - Entered value.
   * @returns {undefined}
   */
  const onInputChange = (value) => {
    setValue(locName, value);
    if (value === '') {
      setSrLocation({});
      // This is needed since the location is placed at the url
      // and will prefill that location when search location textfield is cleared.
      params.delete('longitude');
      params.delete('latitude');
      params.delete('place');
      history.replace({
        pathname: urlVal,
        search: String(decodeURIComponent(params.toString())),
      });
      clearErrors(locName);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    if (
      !isInHeader &&
      params.get('mode') !== 'site' &&
      verifySearchJobPath(location?.pathname, urlVal, sitecoreContext?.language)
    ) {
      setValue(textName, params.get('searchKeyword'));
      const filterObj = parseFilterData() || {};
      if (
        sitecoreContext?.route?.fields?.company?.fields?.key?.value &&
        sitecoreContext?.route?.fields?.company?.fields?.key?.value !== ''
      ) {
        filterObj.company =
          sitecoreContext?.route?.fields?.company?.fields?.key?.value;
      }

      const locObj = {
        lat: filterObj.latitude,
        lng: filterObj.longitude,
        place: decodeURIComponent(filterObj.place),
      };
      setSrLocation(locObj);
      if (localStorage) {
        localStorage.setItem(
          SEARCH.JOB_SEARCH.SAVED_SEARCH_KEY,
          JSON.stringify({
            searchKeyword: params.get('searchKeyword'),
            filters: filterObj,
          })
        );
        localStorage.setItem(
          SEARCH.JOB_SEARCH.SAVED_SEARCH_PARAMS,
          location?.search
        );
      }
    }
  }, [location, isInHeader, setValue, menuOpen]);

  useMemo(() => {
    if (isInHeader && isObjNotEmpty(locationState)) {
      setSrLocation(locationState);
    }
  }, [locationState]);

  useEffect(() => {
    if (isInHeader) {
      clearErrors(locName);
      setValue(textName, '');
    }
  }, [location]);

  return (
    <div
      id={`search-job-${isInHeader && 'header'}`}
      role="tabpanel"
      aria-labelledby="search-job-tab"
      tabIndex={0}
    >
      <div
        className={`search-jobs ${mode !== 'site' ? 'active' : ''} ${
          !isFilterExists && !isInHeader ? 'no-filter' : ''
        }`}
      >
        <FormElements
          textName={textName}
          register={register}
          locationSelected={locationSelected}
          sitecoreContext={sitecoreContext}
          searchLocation={searchLocation}
          onInputChange={onInputChange}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isInHeader={isInHeader}
          t={t}
          getValues={getValues}
          locName={locName}
          errors={errors}
          control={control}
          reset={reset}
          buttonCssClass={buttonCssClass}
        />
      </div>
    </div>
  );
};

/**
 * @description Mapping the state to props.
 * @param {object} state - state.
 * @returns {object} - Slice of state.
 */
const mapStateToProps = (state) => {
  return {
    locationState: state.jobLocationReducer.location,
  };
};

export default connect(mapStateToProps)(withSitecoreContext()(CompanyJobSearchForm));

CompanyJobSearchForm.propTypes = {
  mode: PropTypes.string.isRequired,
  isInHeader: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.string,
  urlVal: PropTypes.string,
  isFilterExists: PropTypes.bool,
  locationState: PropTypes.string,
  dispatch: PropTypes.func,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

CompanyJobSearchForm.defaultProps = {
  mode: '',
  isInHeader: false,
  t: () => {},
  location: '',
  urlVal: '',
  isFilterExists: false,
  locationState: '',
  dispatch: () => {},
  sitecoreContext: {},
};

FormElements.defaultProps = {
  isInHeader: false,
  textName: '',
  register: () => {},
  locationSelected: () => {},
  sitecoreContext: {},
  searchLocation: {
    place: '',
  },
  onInputChange: () => {},
  handleSubmit: () => {},
  getValues: () => {},
  locName: '',
  t: () => {},
  onSubmit: () => {},
  errors: {},
  control: {},
};

FormElements.propTypes = {
  isInHeader: PropTypes.bool,
  textName: PropTypes.string,
  register: PropTypes.func,
  locationSelected: PropTypes.func,
  sitecoreContext: PropTypes.shape({}),
  searchLocation: PropTypes.shape({
    place: PropTypes.string,
  }),
  onInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  getValues: PropTypes.func,
  locName: PropTypes.string,
  t: PropTypes.func,
  onSubmit: PropTypes.func,
  errors: PropTypes.shape({}),
  control: PropTypes.shape({}),
};
