/*eslint-disable*/
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { authstates } from '../../../../../utils/enums';
import Button from '../../../../core/globals/buttons/Button';
import AuthWrapper from '../../../../core/LoginMSAL/AuthWrapper';

const CreateAccount = ({ fields, sitecoreContext }) => {
  const { text, title, ctaText, ctaUrl } = fields;
  return (
    <div className="create-profile-success-block col-lg-6 offset-lg-3">
      <div className="build-profile">
        <div className="titleText">{title?.value}</div>
        <div className="sub-text">{text?.value}</div>
        <div className="btnContainer">
          <AuthWrapper>
            {({ login }) => {
              return (
                <div className="cta">
                  <Button
                    cssClass="profile-nav-next"
                    text={ctaText?.value}
                    handleButtonClick={() => {
                      login(null, 'createAccountAfterJobapply', ctaUrl?.value?.href);
                    }}
                  />
                </div>
              );
            }}
          </AuthWrapper>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(CreateAccount);
