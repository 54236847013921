import { SORT_JOBS } from './../actions/actionTypes';

const initialSate = {
  sortType: '',
};

/**
 * @description - to get the sort type of jobs
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */

const sortJobsReducer = (state = initialSate, action) => {
  switch (action.type) {
    case SORT_JOBS.SORT:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default sortJobsReducer;
