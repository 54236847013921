import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import CardRelatedContent from '../Cards/CardRelatedContent';
import { DATE_FORMATS } from '../../../utils/enums';

/**
 * @description - Sidebar Related Content component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const RelatedArticles = ({ fields, t, sitecoreContext }) => {
  const { items = [] } = fields;
  const dateFormat = sitecoreContext?.Country?.dateFormat || DATE_FORMATS.LOCALE;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  return (
    <div className="article-sidebar">
      <div className="related-article-cards">
        <div className="sidebar-title">{t('you-may-also-like')}</div>
        {items.map((item, index) => (
          <Fragment key={index}>
            <CardRelatedContent
              subTitle={item.fields?.contentType}
              date={
                item.fields?.date && moment(item.fields?.date).format(dateFormat)
              }
              text={item.fields?.headline}
              ctaUrl={item.fields?.url}
              image={item.fields?.image}
              cssClass={index === 0 ? 'first-card' : ''}
              brandName={brandName}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

RelatedArticles.defaultProps = {
  fields: {},
};

RelatedArticles.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

export default withSitecoreContext()(withTranslation()(RelatedArticles));
