import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import AccordionBlock from '../../../Accordion';

/**
 * @description - DashboardAccountAccordion component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const DashboardAccountAccordion = ({ fields, data, t }) => {
  const { text, allowMultipleOpen } = fields;
  return (
    <section
      className="profile-accordion-section dashboard-accordian"
      aria-labelledby="title"
      aria-describedby="info"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            {text?.value && (
              <div className="row">
                <div className="col-md-12">
                  <Text field={text} tag="h2" className="acc-text" id="info" />
                </div>
              </div>
            )}
            {data && data.length > 0 && (
              <div className="row">
                <div className="col">
                  <AccordionBlock
                    allowMultipleOpen={
                      allowMultipleOpen?.value ? allowMultipleOpen.value : false
                    }
                    data={data}
                    showExpandButton={false}
                    t={t}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

DashboardAccountAccordion.defaultProps = {
  t: (val) => val,
  fields: {},
};

DashboardAccountAccordion.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
};

export default withTranslation()(DashboardAccountAccordion);
