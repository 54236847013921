/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */
import { useState, useEffect } from 'react';
import { GET_ITEM_DETAILS } from '../../../../constants';
import { dataFetcher } from '../../../../dataFetcher';

/**
 * @description - Get ComponentSettings Details
 * @param {object} props - Input props
 * @returns {Node} - Links for route
 */

const ComponentSettings = (rendering) => {
  const [componentSetting, setComponentSetting] = useState({});
  const itemId = rendering?.params?.componentColor;

  /**
   * @description - Get Item Details By Item Id.
   * @returns {undefined}
   */
  const getItemDetails = (id) => {
    dataFetcher(`${GET_ITEM_DETAILS}?itemID=${id}`).then((response) => {
      setComponentSetting(response);
    });
  };
  useEffect(() => {
    itemId && getItemDetails(itemId);
  }, [itemId]);

  const fontColor = componentSetting?.data?.FontColor || {};
  const backgroundColor = componentSetting?.data?.BackgroundColor || '';
  const cssClass = componentSetting?.data?.CssClass?.toLowerCase() || 'light';

  let bg_color_code = [];
  bg_color_code = backgroundColor ? backgroundColor : '';
  bg_color_code =
    bg_color_code?.indexOf(',') > -1 ? bg_color_code?.split(',') : bg_color_code;
  if (backgroundColor && backgroundColor.indexOf(',') > -1) {
    bg_color_code = `linear-gradient(${bg_color_code[0]}, ${bg_color_code[1]})`;
  } else {
    bg_color_code = bg_color_code;
  }
  const componentData = {
    fontColor: fontColor,
    bgColor: bg_color_code,
    cssClass: cssClass,
  };
  return componentData;
};

ComponentSettings.propTypes = {
  rendering: {},
};

ComponentSettings.defaultProps = {
  rendering: {
    params: {
      componentColor: '',
    },
  },
};

export default ComponentSettings;
