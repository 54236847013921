/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { RENEW_ALERT, API_STATUS_CODES } from '../../../constants';
import { usePostData } from '../../../hooks/http-client';
import Loader from '../../core/ErrorBoundary/Loader';

import './renewAlert.scss';

/**
 *@param {Object} location -location
 @param {Function} t -Function.
 @return {node} -HTML Template.
 */
const RenewAlert = ({ location, t }) => {
  const { loading, postWithParam, error } = usePostData();
  const [message, setMessage] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    if (!params.get('cid') && !params.get('alertid')) {
      setMessage({ type: 'error', info: t('renew-alert-fail') });
    } else {
      postWithParam(
        RENEW_ALERT.RENEW,
        null,
        { cid: params.get('cid'), alertIds: params.get('alertid') },
        handleRenewResponse
      );
    }
  }, []);

  useEffect(() => {
    if (error) {
      setMessage({ type: 'error', info: t('http-error') });
    }
  }, [error]);

  /**
   *
   * @param {object} response - api response
   * @returns {undefined} - no returns
   */
  const handleRenewResponse = (response) => {
    if (response.data.Status === API_STATUS_CODES.SUCCESS) {
      setMessage({ type: 'success', info: t('renew-alert-success') });
    } else {
      setMessage({ type: 'error', info: t('renew-alert-fail') });
    }
  };

  return (
    <section className="container renew-section">
      {loading ? (
        <Loader />
      ) : (
        <div className={`info ${message?.type}`} role="alert">
          {message?.info}
        </div>
      )}
    </section>
  );
};
RenewAlert.defaultProps = {
  location: {},
  t: () => {},
};

RenewAlert.propTypes = {
  location: PropTypes.shape({}),
  t: PropTypes.func,
};

export default withTranslation()(withRouter(RenewAlert));
