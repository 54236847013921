import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../../globals/link/ButtonLink';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import '../page-banners.scss';
import { useDispatch } from 'react-redux';
import { bannerLoaded } from '../../../../redux/actions/actions';

/**
 * @description  EmployersSplashBanner component
 * @param {*} props - Input props.
 * @returns {node} - html
 */
const EmployersSplashBanner = (props) => {
  const { fields } = props;
  const { title, text, ctaText, ctaUrl, backgroundImage, foregroundImage } = fields;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(bannerLoaded('EmployersSplashBanner', true));
  });
  return (
    <section className={`splashbanner animate--in employers`}>
      <div className="gradient__overlay"></div>
      <div className="rectangle"></div>
      <div className="rectangle2"></div>
      <img
        className="bknd__image"
        src={backgroundImage.value.src}
        height={backgroundImage.value.height}
        width={backgroundImage.value.width}
        alt={backgroundImage.value.alt}
      />
      <div className="content__wrap container">
        <div className="content">
          <h1 className="headline">{title.value}</h1>
          <p className="text reversed">{text.value}</p>
          <ButtonLink
            cssClass="reversed"
            ctaText={ctaText.value}
            ctaUrl={ctaUrl.value}
            arrow
          />
        </div>
        <div className="person__imagewrap">
          <Image className="person__image" field={foregroundImage} />
        </div>
      </div>
    </section>
  );
};

EmployersSplashBanner.defaultProps = {
  fields: {
    title: { value: '' },
    text: { value: '' },
    ctaText: { value: '' },
    ctaUrl: {
      value: null,
    },
    backgroundImage: {
      value: {
        src: null,
        alt: null,
        width: null,
        height: null,
      },
    },
    foregroundImage: {
      value: {
        src: null,
        alt: null,
        width: null,
        height: null,
      },
    },
  },
};

EmployersSplashBanner.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string }),
    text: PropTypes.shape({ value: PropTypes.string }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    foregroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      }),
    }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
      }),
    }),
  }),
};

export default withTranslation()(EmployersSplashBanner);
