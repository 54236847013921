import React from 'react';
import PropTypes from 'prop-types';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - This is a placeholder component for todo pages.
 * @param {object} props - Input props.
 * @returns {Node} - HTML to render.
 */
const PageUnderConstruction = (props) => {
  const { fields } = props;
  return (
    <div className="container-fluid  pt-3 px-0">
      <div className="card text-center">
        <div className="card-header">
          <Text tag="div" field={fields.pageName}></Text>
        </div>
        <div className="card-body">
          <Text tag="div" field={fields.message}></Text>
        </div>
      </div>
    </div>
  );
};

PageUnderConstruction.propTypes = {
  fields: PropTypes.shape({
    pageName: PropTypes.shape({
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
};

PageUnderConstruction.defaultProps = {
  fields: {
    pageName: { value: '' },
    message: { value: 'This page is under construction' },
  },
};

export default withSitecoreContext()(PageUnderConstruction);
