/* eslint-disable */
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import DynamicForm from '../../../core/Forms/DynamicForm';
import { savePublicPosition } from '../../../../redux/actions/actions';
import { formObjIteration } from '../../Utils/helper';
import './public_position.scss';

/**
 * @description - Public Position Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const PublicPosition = ({ inputRef, ...props }) => {
  const { fields = {}, profileInfo } = props;
  const { title, subTitle, text, bigText, items = [] } = fields;
  const [data, setData] = useState({});
  const {
    register,
    handleSubmit,
    getValues,
    errors,
    trigger,
    setValue,
    watch,
    control,
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
  });
  const formSubmitRef = useRef();
  /**
   * @description - onsubmit hander.
   * @returns {undefined}
   */
  const onSubmit = (formData) => {
    //Navigate to next page on form submission
    props.dispatch(
      savePublicPosition({
        ...formData,
      })
    );
    props.onSubmitHandler();
  };

  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    onSubmitFunc(formattedData);
  };

  useEffect(() => {
    if (profileInfo?.Id) {
      setData({
        data: profileInfo?.PersonalDetails?.data?.[0] || {},
        attachment: profileInfo?.AttachmentDetails,
      });
    }
  }, [profileInfo]);

  /**
   * @description - useImperativeHandle function.
   * @returns {undefined}
   */
  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      let event;
      if (typeof Event === 'function') {
        event = new Event('submit', { cancelable: true });
      } else {
        event = document.createEvent('Event');
        event.initEvent('submit', false, { cancelable: true });
      }
      formSubmitRef.current.dispatchEvent(event);
    },
  }));

  return (
    <div className="public-position">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">{title?.value}</h1>
            <div className="text">{subTitle?.value}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-lg-4 ">
            <div className="publicPositionForm">
              <div className="form-text">{text?.value}</div>
              <div className="public-position-form">
                <form
                  onSubmit={handleSubmit(transform(onSubmit))}
                  ref={formSubmitRef}
                >
                  <DynamicForm
                    formData={items}
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    trigger={trigger}
                    watch={watch}
                    control={control}
                    values={data}
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-3">
            <p className="static-text">{bigText?.value}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

PublicPosition.propTypes = {
  profileInfo: PropTypes.shape({}).isRequired,
  candidateJobDetails: PropTypes.object,
  t: PropTypes.func.isRequired,
};

PublicPosition.defaultProps = {
  profileInfo: {},
  candidateJobDetails: {},
};

const mapStateToProps = (state) => {
  return { profileInfo: state.profileInfoReducer.data };
};

const ConnectedComponent = connect(mapStateToProps)(PublicPosition);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
