import { JOB_ALERTS } from '../actions/actionTypes';

const initialSate = {
  clearAlerts: false,
};
/**
 * @description - Consent Reducer function
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */
const consentReducer = (state = initialSate, action) => {
  switch (action.type) {
    case JOB_ALERTS.CLEAR:
      return { ...state, clearAlerts: true };
    default:
      return state;
  }
};

export default consentReducer;
