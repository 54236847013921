/* eslint-disable max-lines */
const fields = {
  title: {
    value: 'How is MyPath Different?',
  },
  text: {
    value:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.',
  },
  mode: {
    value: false,
  },
  allowMultipleOpen: {
    value: false,
  },
  count: {
    value: '5',
  },
  items: [
    {
      id: '218f9b25-63b2-4417-9963-981fdcc9d8c9',
      name: 'Item 1',
      displayName: 'Item 1',
      fields: {
        title: {
          value: 'title 1',
        },
        content: {
          value: '1111',
        },
      },
    },
    {
      id: 'f7120fd4-65bf-408a-9131-6a6895914f17',
      name: 'Item 2',
      displayName: 'Item 2',
      fields: {
        title: {
          value: 'title 2',
        },
        content: {
          value: '22',
        },
      },
    },
    {
      id: '78c24095-36ac-42a1-b627-cdefee78aa6a',
      name: 'Item 3',
      displayName: 'Item 3',
      fields: {
        title: {
          value: 'title 3',
        },
        content: {
          value: '333',
        },
      },
    },
    {
      id: 'abbefa21-d038-457a-9d82-1d1196a779e1',
      name: 'Item 4',
      displayName: 'Item 4',
      fields: {
        title: {
          value: 'title 4',
        },
        content: {
          value: '4444',
        },
      },
    },
    {
      id: 'e3f96642-5a88-4bb3-b26c-45796fefcddb',
      name: 'Item 5',
      displayName: 'Item 5',
      fields: {
        title: {
          value: 'title 5',
        },
        content: {
          value: '5555',
        },
      },
    },
    {
      id: 'f4f90e45-784c-4284-a514-bca28dd7fb61',
      name: 'Item 6',
      displayName: 'Item 6',
      fields: {
        title: {
          value: 'title 6',
        },
        content: {
          value: '66',
        },
      },
    },
  ],
};

export default fields;
