import { SORTORDER } from '../../../../../utils/enums';
import moment from 'moment';

/*eslint-disable*/
export const dropDownFormat = (
  optionData = [],
  type,
  start,
  count,
  sortOrder = SORTORDER.ASCENDING,
  dateFormat
) => {
  const data = optionData && optionData !== null ? optionData : [];
  switch (type) {
    case 'NormalDropdown': {
      let formattedData = [];
      data?.map((item) => {
        let tempObj = {};
        tempObj.value = item.fields?.key?.value
          ? item.fields.key.value
          : item.fields?.label?.value;
        tempObj.label = item.fields?.label?.value;
        formattedData.push(tempObj);
      });
      return formattedData;
    }
    case 'DateDropdownAPI': {
      let formattedData = [];
      data?.map((item) => {
        let tempObj = {};
        tempObj.value = item;
        tempObj.label = moment(item).format(dateFormat);
        formattedData.push(tempObj);
      });
      return formattedData;
    }
    case 'DateDropdown': {
      let formattedData = [];
      data?.map((item) => {
        let tempObj = {};
        tempObj.value = item.fields?.date?.value;
        tempObj.label = moment(item.fields?.date?.value).format(dateFormat);
        formattedData.push(tempObj);
      });
      return formattedData;
    }
    case 'typeahead': {
      let formattedData = [];
      data?.map((item) => {
        let tempObj = {};
        tempObj.value = item.fields?.key?.value
          ? item.fields.key.value
          : item.fields?.label?.value;
        tempObj.label = item.fields?.label?.value;
        formattedData.push(tempObj);
      });
      return formattedData;
    }
    case 'TypeaheadDropdown': {
      let formattedData = [];
      data?.map((item) => {
        let tempObj = {};
        tempObj.value = item.fields?.key?.value
          ? item.fields.key.value
          : item.fields?.label?.value;
        tempObj.label = item.fields?.label?.value;
        formattedData.push(tempObj);
      });
      return formattedData;
    }

    case 'DropdownAPI': {
      let formattedData = [];
      data?.map((item) => {
        let tempObj = {};
        tempObj.value = item?.id;
        tempObj.label = item?.name;
        formattedData.push(tempObj);
      });
      return formattedData;
    }
    case 'NumberDropdown': {
      let formattedData = [];
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const end = currentYear + count;
      if (sortOrder === SORTORDER.ASCENDING) {
        for (let i = start; i <= end; i++) {
          let tempObj = {};
          tempObj.value = `${i}`;
          tempObj.label = `${i}`;
          formattedData.push(tempObj);
        }
      } else {
        for (let i = end; i >= start; i--) {
          let tempObj = {};
          tempObj.value = `${i}`;
          tempObj.label = `${i}`;
          formattedData.push(tempObj);
        }
      }
      return formattedData;
    }
    case 'complexSelect': {
      let formattedData = [];
      data?.map((item) => {
        let tempObj = {};
        tempObj.value = item?.id;
        tempObj.label = item?.name;
        formattedData.push(tempObj);
      });
      return formattedData;
    }
    case 'chekBoxSelect': {
      let formattedData = [];
      data?.map((item) => {
        let tempObj = {};
        tempObj.value = item;
        tempObj.id = item;
        formattedData.push(tempObj);
      });
      return formattedData;
    }
    case 'radio': {
      const formattedOptions = [];
      data?.map((item, index) => {
        const tempObj = {};
        tempObj.id = `${type}_${index}`;
        tempObj.text = item?.fields?.label?.value;
        tempObj.value = item?.fields?.key?.value
          ? item?.fields?.key?.value
          : item?.fields?.label?.value;
        tempObj.disabled = false;
        formattedOptions.push(tempObj);
      });
      return formattedOptions;
    }
    default:
      return [];
  }
};
