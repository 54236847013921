import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SearchFiltersPanel from './SearchFiltersPanel';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - Filter component for jobs.
 * @param {object} param0 - Filter params.
 * @returns {Node} - Filter elements.
 */
const Filters = ({ t, fields }) => {
  return (
    <Fragment>
      <div className="search-filters-global search-filters--active">
        <div className="search-filters-panel-global">
          <div className="container">
            <SearchFiltersPanel setFilterActive={true} t={t} fields={fields} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Filters.propTypes = {
  filter: PropTypes.string,
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
};

Filters.defaultProps = {
  filter: '',
  fields: {},
};

export default withSitecoreContext()(withTranslation()(Filters));
