import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Text, Link as JssLink } from '@sitecore-jss/sitecore-jss-react';

import {
  showString,
  checkHref,
  checkLinkType,
  checkJSSObject,
} from '../../../../utils/helperUtils';
import './links.scss';
import './buttons.scss';

/**
 * @description - TextLink Component.
 * @param {Object} Params - Input Parameters.
 * @returns {Node} - HTML Template.
 */
const TextLink = ({
  cssClass,
  ctaText,
  ctaUrl,
  ctaIcon,
  arrow,
  externalLink,
  tabIndex,
  ctaLabel,
}) => {
  const isJSSObject = checkJSSObject(ctaUrl);
  let linkVal = null;
  const textItem = (
    <Fragment>
      <div className={`cta-text ${cssClass}`}>
        {ctaText && ctaText.value ? <Text field={ctaText} /> : showString(ctaText)}
        {arrow && <div className="arrow-icon" aria-hidden="true"></div>}
      </div>
      {ctaIcon && (
        <div className="cta-icon">
          <img src={ctaIcon} alt="icon" />
        </div>
      )}
    </Fragment>
  );
  const propVal = {
    className: cssClass,
    ...(ctaLabel
      ? { 'aria-label': ctaLabel }
      : ctaUrl?.value?.target || ctaUrl?.target == '_blank'
      ? { 'aria-label': `${ctaText} opens in a new window/tab` }
      : {}),
  };
  if ((isJSSObject && checkLinkType(ctaUrl)) || (!isJSSObject && !externalLink)) {
    linkVal = (
      <Link
        to={isJSSObject ? checkHref(ctaUrl) : ctaUrl}
        {...propVal}
        tabIndex={tabIndex}
        target={ctaUrl?.value?.target || ctaUrl?.target}
      >
        {textItem}
      </Link>
    );
  } else {
    const urlVal = isJSSObject ? ctaUrl : { href: ctaUrl };
    linkVal = (
      <JssLink field={urlVal?.value || urlVal} {...propVal}>
        {textItem}
      </JssLink>
    );
  }
  return ctaUrl ? <div className="textLink">{linkVal}</div> : null;
};

TextLink.defaultProps = {
  cssClass: '',
  ctaText: '',
  ctaUrl: '',
  ctaIcon: '',
  arrow: false,
  externalLink: false,
  tabIndex: 0,
  ctaLabel: '',
};

TextLink.propTypes = {
  cssClass: PropTypes.string,
  ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ctaUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  ctaIcon: PropTypes.string,
  arrow: PropTypes.bool,
  externalLink: PropTypes.bool,
  tabIndex: PropTypes.number,
  ctaLabel: PropTypes.string,
};

export default TextLink;
