/* eslint-disable */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * @description - to list accordion items
 * @param {*} props - props
 * @returns {HTML} - accordion html
 */
const AccordionItem = (props) => {
  // const [categoriesChunks, seCategoriesChunks] = useState([]);
  const { linkItems, title, active, onAccordionClick, index, cssClass } = props;
  // const chunk_size = Math.ceil(linkItems?.categories?.length / 3);

  // useEffect(() => {
  //   let index = 0;
  //   const arrayLength = linkItems?.categories?.length;
  //   const tempArray = [];

  //   for (index = 0; index < arrayLength; index += chunk_size) {
  //     const myChunk = linkItems?.categories?.slice(index, index + chunk_size);
  //     tempArray.push(myChunk);
  //   }

  //   seCategoriesChunks(tempArray);
  // }, []);

  return (
    <div className={`accordion-block ${active ? 'active' : ''}`}>
      <button
        className="icon"
        onClick={() => (active ? onAccordionClick() : onAccordionClick(index))}
        aria-label={`toggle accordion ${title}`}
        aria-expanded={active}
      ></button>
      <div
        className="accordion-title"
        onClick={() => (active ? onAccordionClick() : onAccordionClick(index))}
        role="presentation"
      >
        {title}
      </div>
      <div className="accordion-content clearfix">
        <div className="row">
          {linkItems?.categories.map((chunk, index) => {
            return (
              <div
                className={`${cssClass ? cssClass : 'col-md-4'} category-list`}
                key={index}
              >
                {/* {Array.isArray(chunk) && */}
                {/* chunk.map((category, index) =>  */}
                {
                  <ul className="locations">
                    {chunk.categoryURL ? (
                      <li key={index}>
                        {
                          // <a href={category?.categoryURL}>
                          //   {category?.categoryName}
                          // </a>
                          <Link to={chunk?.categoryURL}>{chunk?.categoryName}</Link>
                        }
                      </li>
                    ) : null}
                  </ul>
                }
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

AccordionItem.defaultProps = {
  linkItems: {},
  title: '',
  onAccordionClick: () => {},
  active: false,
  index: 0,
};

AccordionItem.propTypes = {
  linkItems: PropTypes.shape({}),
  title: PropTypes.string,
  onAccordionClick: PropTypes.func,
  active: PropTypes.bool,
  index: PropTypes.number,
};

export default AccordionItem;
