/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './sidebarcards.scss';

/**
 * @description to show benefits details
 * @param {object} fields - card data object
 * @param {function} t - translate function
 * @returns {node} - html
 */
const SidebarCardBenefits = ({ fields, t, sitecoreContext }) => {
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase();
  return (
    <div className="benefits-sidebar">
      <h2 className={`sidebar-title ${brandName}`}>{t('interesting-for-you')}</h2>

      <div className="address-container">
        <ul>
          {fields?.items?.benefits &&
            fields?.items?.benefits?.length > 0 &&
            fields?.items?.benefits.map((item, index) => {
              return <li key={index}> {item} </li>;
            })}
        </ul>
      </div>
    </div>
  );
};

SidebarCardBenefits.defaultProps = {
  fields: {},
  t: () => {},
  sitecoreContext: {},
};

SidebarCardBenefits.propTypes = {
  fields: PropTypes.shape({}),
  t: PropTypes.func,
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(withTranslation()(SidebarCardBenefits));
