import React from 'react';
import PropTypes from 'prop-types';
import TextLink from '../globals/link/TextLink';
import ButtonLink from '../globals/link/ButtonLink';
import { setAltUrlForImg, getImg } from '../../../utils/helperUtils';
import { Text, Image, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import './featurette.scss';
/**
 * @description - featurette component;
 * @param {object}  props - Input props;
 * @returns{Node} - return html block;
 */
const Featurette = (props) => {
  const { fields } = props;
  const { backgroundimage } = fields;
  const background_color =
    fields.backgroundColor?.value === 'GREY' ? 'grey' : 'white';
  const externalLinkType =
    fields?.ctaUrl?.value?.linktype === 'external' ? true : false;
  return (
    <div
      className={`featurette-section ${fields.mode?.value} ${background_color}`}
      style={{
        backgroundImage: backgroundimage
          ? `url(${mediaApi.updateImageUrl(getImg(backgroundimage))})
        )})`
          : null,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-4 offset-lg-1">
            <div className="featurette-imgs">
              <div className="blurred">
                <Image field={setAltUrlForImg(fields.image, '')} />
              </div>
              <Image field={setAltUrlForImg(fields.image, '')} />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="featurette-body">
              <h5 className="title">
                <Text field={fields.title} />
              </h5>
              {fields.nameText && (
                <h2 className="name">
                  <Text field={fields.nameText} />
                </h2>
              )}
              {fields.subTitle && (
                <p className="sub-title">
                  <Text field={fields.subTitle} />
                </p>
              )}
              <p className="large">
                <Text field={fields.text} />
              </p>
              {fields.mode?.value === 'standard' ||
              fields.mode?.value === 'profilebackground' ? (
                <ButtonLink
                  ctaText={fields?.ctaText}
                  ctaUrl={fields?.ctaUrl}
                  externalLink={externalLinkType}
                />
              ) : (
                <TextLink
                  ctaText={fields?.ctaText?.value}
                  ctaUrl={fields?.ctaUrl?.value}
                  externalLink={externalLinkType}
                  arrow
                />
              )}
              <div className="quote"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Featurette.defaultProps = {
  fields: {
    title: {},
    subTitle: {},
    nameText: {},
    text: {},
    image: { value: { src: 'http://via.placeholder.com/300', alt: 'img' } },
    altText: {},
    ctaText: {},
    ctaUrl: {},
    backgroundColor: {},
    backgroundimage: {},
    mode: { value: 'standard' },
    componentBackgroundColor: [],
  },
};

Featurette.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    subTitle: {
      value: PropTypes.string,
    },
    nameText: {
      value: PropTypes.string,
    },
    text: {
      value: PropTypes.string,
    },
    image: {
      value: PropTypes.string,
    },
    altText: {
      value: PropTypes.string,
    },
    ctaText: {
      value: PropTypes.string,
    },
    ctaUrl: {
      value: PropTypes.string,
    },
    backgroundColor: {
      value: PropTypes.string,
    },
    backgroundimage: {
      value: PropTypes.string,
    },
    mode: {
      value: PropTypes.oneOf(['standard', 'profile', 'quote']),
    },
    componentBackgroundColor: {
      value: PropTypes.arrayOf(PropTypes.shape({})),
    },
  }),
};

export default Featurette;
