/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import TextButton from '../globals/buttons/TextButton';
import useCurrentWidth from '../../../hooks/useCurrentWidth';
import './readmore.scss';

/**
 * @description - Card Read More for card scrolls.
 * @returns {Node} - HTML card.
 */
const ReadMore = ({ t, children, cardVerticalPadding }) => {
  const childRef = useRef();
  const [isReadMore, setIsReadMore] = useState(false);
  const [enableHide, setEnableHide] = useState(false);
  const width = useCurrentWidth();

  /**
   * @description - Renders the read more button
   * @returns {undefined}
   */
  const readMoreElRender = () => {
    hideText();
    const el = childRef.current;
    if (el && el.offsetHeight < el.scrollHeight - cardVerticalPadding) {
      setIsReadMore(true);
    } else {
      setIsReadMore(false);
    }
  };

  useEffect(() => {
    readMoreElRender();
  }, [children, width]);

  /**
   * @description - Shows complete text.
   * @returns {undefined}
   */
  const showText = () => {
    const el = childRef.current;
    if (el) {
      el.style.overflowY = 'auto';
      setEnableHide(true);
    }
  };

  /**
   * @description - Hides complete text.
   * @returns {undefined}
   */
  const hideText = () => {
    const el = childRef.current;
    if (el) {
      el.scrollTop = 0;
      el.style.overflowY = 'hidden';
      setEnableHide(false);
    }
  };

  return (
    <div ref={childRef} className="card-body">
      {children}
      {isReadMore ? (
        enableHide ? (
          <TextButton
            text={t('card-hide-read-more')}
            cssClass={'hide-read-more'}
            handleTextClick={hideText}
          />
        ) : (
          <TextButton
            text={t('card-read-more')}
            cssClass={'button-read-more'}
            handleTextClick={showText}
          />
        )
      ) : null}
    </div>
  );
};

ReadMore.defaultProps = {
  t: () => {},
  children: null,
  cardVerticalPadding: 24,
};

ReadMore.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  cardVerticalPadding: PropTypes.number.isRequired,
};

export default withTranslation()(ReadMore);
