import React from 'react';

export const FilterContext = React.createContext({
  contentType: '',
  setContentType: () => null,
  topic: '',
  setTopic: () => null,
  subTopic: '',
  setSubTopic: () => null,
  t: () => null,
  location: {},
});

export const PartnerFilterContext = React.createContext({
  updateType: '',
  setUpdateType: () => null,
  t: (val) => val,
});

export const CollapseFilterContext = React.createContext({
  isCollapse: '',
  sortby: '',
  collapseButton: '',
  setExpandedFilters: () => null,
  isCompanyJobSearch: false,
  mediaIcons: [],
});
