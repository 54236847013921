/* eslint-disable */
import { PROFILE_INFO } from '../actions/actionTypes';

const initialState = {
  data: {
    isRedirect: false,
  },
  loading: true,
};

const profileInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_INFO.SAVE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case PROFILE_INFO.CLEAR:
      return initialState;
    case PROFILE_INFO.ERROR:
      return {
        ...state,
        loading: false,
        data: {
          isRedirect: true,
          error: true,
        },
      };
    default:
      return state;
  }
};
export default profileInfoReducer;
