import React from 'react';
import TextLink from '../globals/link/TextLink';
import PropTypes from 'prop-types';

import { setAltUrlForImg } from '../../../utils/helperUtils';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import './cards.scss';
/**
 * @description - cardinfoblock component
 * @param {object} props  - input props
 * @returns{Node} - HTML block;
 */
const CardInfo = ({
  title,
  text,
  icon,
  ctaText,
  ctaUrl,
  mode,
  readMoreColour,
  brandName,
}) => {
  return (
    <div className={`card ${mode}`}>
      <div className="img-container">
        <Image className="infoIcon" field={setAltUrlForImg(icon, '')} />
      </div>
      <div className="card-wrapper">
        <div className="card-body">
          <h4 className="title">{title.value}</h4>
          <p>
            <Text field={text} />
          </p>
        </div>
        {ctaUrl && (
          <div>
            <span className="align-bottom">
              {brandName === 'manpower' ? (
                <TextLink ctaText={ctaText} ctaUrl={ctaUrl} />
              ) : (
                <TextLink
                  cssClass={`link-${readMoreColour.toLowerCase()}`}
                  ctaText={ctaText}
                  ctaUrl={ctaUrl}
                  arrow
                />
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

CardInfo.defaultProps = {
  title: '',
  text: '',
  mode: '',
  icon: '',
  altText: '',
  ctaText: 'Read more',
  ctaUrl: '#',
  readMoreColour: '',
  brandName: '',
};

CardInfo.propTypes = {
  title: {
    value: PropTypes.string,
  },
  text: {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  },
  altText: {
    value: PropTypes.string,
  },
  mode: {
    value: PropTypes.oneOf(['dark', 'light']),
  },
  ctaText: {
    value: PropTypes.string,
  },
  ctaUrl: {
    value: PropTypes.string,
  },
  readMoreColour: {
    value: PropTypes.string,
  },
  icon: {
    value: PropTypes.string.isRequired,
  },
  brandName: {
    value: PropTypes.string,
  },
};

export default CardInfo;
