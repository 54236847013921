import React from 'react';
import PropTypes from 'prop-types';

import ModalComponent from '../Modal';
import Button from '../../core/globals/buttons/Button';

import './boundaries.scss';

/**
 * @description - to show inline alert
 * @param {string} message - message to show
 * @param {string} type - type of alert
 * @returns {node} - html
 */
const AlertPopUp = ({
  message,
  visibility,
  handleModal,
  callback,
  btnOkText,
  btnCancelText,
}) => {
  /**
   * @description to handle alert pop up interaction
   * @param {*} action - true/false
   * @returns {undefined} - no retunrs
   */
  const handleAction = (action) => {
    handleModal();
    if (action === true && callback) {
      callback();
    }
  };

  return (
    <ModalComponent showModal={visibility} handleModal={handleModal} size="md">
      <div className="alert-popup-body" key="body">
        <p>{message}</p>
      </div>
      <div key="footer" className="alert-popup-footer">
        <div className="footer-items">
          {btnOkText && (
            <Button
              text={btnOkText}
              handleButtonClick={() => handleAction(true)}
              cssClass="btn-save"
            />
          )}
        </div>
        <div className="footer-items">
          {btnCancelText && (
            <Button
              text={btnCancelText}
              handleButtonClick={() => handleAction(false)}
              cssClass="btn-save"
            />
          )}
        </div>
      </div>
    </ModalComponent>
  );
};

AlertPopUp.propTypes = {
  message: PropTypes.string,
  visibility: PropTypes.bool,
  handleModal: PropTypes.func,
  callback: PropTypes.func,
  type: PropTypes.oneOf(['error', 'success', '']),
};

AlertPopUp.defaultProps = {
  message: 'Some Error occurred.',
  visibility: false,
  handleModal: () => {},
  callback: () => {},
  type: '',
};

export default AlertPopUp;
