import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import './seoJobLink.scss';

/**
 * @description - sub navigation tile component
 * @param {string} title - title to be displayed
 * @param {string} icon - icon to be displayed
 * @param {string} url - url to be redirected on click
 * @returns {Node} - HTML
 */
const SEOJobLink = ({ jobList }) => (
  <RichText field={jobList} tag="div" editable={false} className="row" />
);

SEOJobLink.defaultProps = {
  jobList: [],
};

SEOJobLink.propTypes = {
  jobList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SEOJobLink;
