import axios from 'axios';
import { logoutSuccess } from '../redux/actions/loginActions';
import { authstates } from './enums';

/**
 * @description - to set authorization header
 * @param {object} store - Store Object.
 * @returns {undefined}
 */
export const setAuthHeader = (store) => {
  /**
   * Request Interceptor.
   */
  axios.interceptors.request.use((config) => {
    try {
      try {
        const {
          loginReducer: {
            idToken: {
              idToken: { rawIdToken },
            },
            user,
          },
        } = store.getState();
        if (typeof localStorage !== 'undefined') {
          const userSession = JSON.parse(localStorage.getItem('usersession'));
          if (userSession?.candidateId)
            config.headers.candidateId = userSession.candidateId;
        }
        config.headers.Authorization = `Bearer ${rawIdToken}`;
        config.headers.Firstname = user.firstName
          ? encodeURIComponent(user.firstName)
          : '';
        config.headers.Lastname = user.lastName
          ? encodeURIComponent(user.lastName)
          : '';
        config.headers.middlename = user.middlename
          ? encodeURIComponent(user.middlename)
          : '';
        config.headers.nie = user.nie ? encodeURIComponent(user.nie) : '';
        config.headers.bhid = user.bhid ? encodeURIComponent(user.bhid) : '';
        config.headers.newUser = user.newUser
          ? encodeURIComponent(user.newUser)
          : '';
      } catch (e) {}
      return config;
    } catch (e) {}
  });

  /**
   * Response Interceptor.
   */
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const {
        loginReducer: { state },
      } = store.getState();
      if (error.response.status === 401 && state === authstates.AUTHENTICATED) {
        store.dispatch(logoutSuccess());
      }
      return Promise.reject(error);
    }
  );
};
