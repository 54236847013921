import React from 'react';
import Button from '../../../core/globals/buttons/Button';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

/**
 * @description - CreateProfile Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const CreateAccountNav = ({ handleCreateAccount, t }) => {
  return (
    <section className="create-account-nav">
      <Button text={t('create-account')} handleButtonClick={handleCreateAccount} />
      <div className="create-account-text">
        {t('already-have-an-account')}
        <span className="textLink">
          <a href="/">{t('log-in')}</a>
        </span>
      </div>
    </section>
  );
};

CreateAccountNav.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CreateAccountNav);
