/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { FacebookProvider, Login } from 'react-facebook';

import ButtonWithIcon from './ButtonWithIcon';
import Button from './Button';
/**
 * @description - Facebook Login Button.
 * @returns {Node} - Button node.
 */
const FacebookButton = ({
  text,
  appId,
  onLogged,
  onError,
  trigger,
  clearErrors,
}) => {
  /**
   * @description - Callback function.
   * @param {Object} response - Response.
   * @returns {undefined}
   */
  const responseFacebook = (response) => {
    const { profile, tokenDetail } = response;
    if (tokenDetail) {
      const { name, email } = profile;
      const { userID, accessToken } = tokenDetail;
      onLogged({ name, email, userID, accessToken, SSOID: 'Facebook' });
    }
  };

  /**
   * @description - Callback function.
   * @param {Object} event - Event Object.
   * @param {Object} handleClick - handleClick function.
   * @returns {undefined}
   */
  const checkConsent = async (event, handleClick) => {
    clearErrors();
    const result = await trigger('consent1_hidden');
    if (result == true || result == undefined) {
      handleClick(event);
    }
  };

  return (
    <FacebookProvider appId={appId}>
      <Login
        scope="public_profile,email"
        onCompleted={responseFacebook}
        onError={onError}
      >
        {({ handleClick }) => (
          <ButtonWithIcon
            btnIcon="facebook"
            cssClass="blue"
            text={text}
            arrow={false}
            handleButtonClick={(event) =>
              trigger ? checkConsent(event, handleClick) : handleClick(event)
            }
          />
        )}
      </Login>
    </FacebookProvider>
  );
};

FacebookButton.defaultProps = {
  text: '',
  appId: '',
  onLogged: () => {},
  onError: () => {},
  trigger: () => {},
};

FacebookButton.propTypes = {
  text: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  onLogged: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

export default FacebookButton;
