/* eslint-disable*/
import React, {
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import DynamicForm from '../../../../core/Forms/DynamicForm';
import {
  booleanRes,
  formObjIteration,
  recursiveWithConstantCallback,
} from '../../../Utils/helper';
import {
  saveJobApplyFormData,
  saveJobApplyFormState,
  saveJobApplyFormTriggerData,
  saveJobApplyValidFormList,
} from '../../../../../redux/actions/actions';
import { jobApply } from '../../../../../utils/enums';
import {
  traverseAndDataExist,
  isObjNotEmpty,
  JSONParser,
} from '../../../../../utils/helperUtils';
import TextButton from '../../../../core/globals/buttons/TextButton';
import Loader from '../../../../core/ErrorBoundary/Loader';

const MultiForm = ({ inputRef, ...props }) => {
  const { section, componentName, t, hideForm = false } = props;
  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);
  const formSubmitRef = useRef();
  const {
    register,
    errors,
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
    watch,
    trigger,
    handleSubmit,
    formState,
    reset,
  } = useForm({ mode: 'all', revalidate: 'onBlur' });

  const [fieldLength, setFieldLength] = useState(0);

  const { isValid, isDirty, touched } = formState;
  const [newFieldArr, setFieldArr] = useState(new Array(section?.fields?.items));
  const { fields = {} } = section;
  const { title, abstract, apiKeyMapper, buttonText, isMandatory } = fields;
  const apiMapper = apiKeyMapper?.value
    ? apiKeyMapper?.value
    : jobApply?.[componentName];
  const buttonTextValues = JSONParser(buttonText?.value);

  const dispatch = useDispatch();

  const jobAppplyFormState = useSelector(
    (state) => state?.jobAppplyFormStateReducer
  );
  const jobAppplyFormData = useSelector((state) => state?.jobApplyFormDataReducer);

  const [candidateId, setCandidateId] = useState('');
  const [deletedId, setDeletedId] = useState([]);
  const [data, setData] = useState({});
  const [fileUpload, setFileUpload] = useState({});
  const [deletedFileList, setDeletedFileList] = useState([]);
  const [formError, showFormError] = useState(false);
  const [isDelete, setUploadDelete] = useState(false);
  const [isClear, setIsCleared] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profileInfo?.Id) {
      setCandidateId(profileInfo?.Id);
      if (hideForm) {
        setData({ data: [], attachment: null });
        setFieldArr([]);
      } else if (profileInfo?.[apiMapper]?.formCount > 0) {
        setData({
          ...profileInfo?.[apiMapper]?.data,
          attachment: profileInfo?.AttachmentDetails,
        });
        setFieldArr(
          new Array(profileInfo?.[apiMapper]?.formCount).fill(section?.fields?.items)
        );
      } else {
        setData({ data: [], attachment: null });
        setFieldArr(new Array(section?.fields?.items));
      }
    }
  }, [profileInfo]);

  const handleUpload = (fileData, name) => {
    const fileList = fileUpload;
    const obj = { [name]: fileData };
    if (fileUpload.hasOwnProperty(name)) {
      delete fileList[name];
    }
    setFileUpload({ ...fileList, ...obj });
  };

  const handleDelete = (id, name, index) => {
    if (fileUpload.hasOwnProperty(name)) {
      const fileList = fileUpload;
      const temp = fileUpload[name];
      delete temp[index];
      delete fileList[name];
      const newtemp = temp?.filter((item) => item);
      const obj = { ...fileUpload, [name]: newtemp };
      setFileUpload({ ...fileList, ...obj });
    }
    if (id) {
      setDeletedFileList([...deletedFileList, { fileId: id, type: name }]);
    }
    setUploadDelete(true);
  };

  useEffect(() => {
    let isEmpty = !traverseAndDataExist(getValues());
    dispatch(
      saveJobApplyFormState({
        ...jobAppplyFormState,
        [componentName]: { isDirty, isValid, isEmpty, touched, isDelete, isClear },
      })
    );
  }, [isDirty, isValid, touched, isDelete, isClear]);

  useEffect(() => {
    let temp = [...newFieldArr];
    temp = temp.filter((item) => item !== undefined);
    setFieldLength(temp.length);
  }, [newFieldArr]);

  const onSubmit = (data) => {
    let formData = data?.[componentName];
    const fileData = {
      files: { ...jobAppplyFormData?.data?.fileData?.files, ...fileUpload },
      DeletedFiles: jobAppplyFormData?.data?.fileData?.DeletedFiles
        ? [
            ...new Set([
              ...jobAppplyFormData?.data?.fileData?.DeletedFiles,
              ...deletedFileList,
            ]),
          ]
        : deletedFileList,
    };
    const deletedFormData = {
      [`Deleted${apiMapper}`]: jobAppplyFormData?.data?.[`Deleted${apiMapper}`]
        ? [...jobAppplyFormData?.data?.[`Deleted${apiMapper}`], deletedId]
        : deletedId?.length > 0
        ? deletedId
        : undefined,
    };
    dispatch(
      saveJobApplyFormData({
        [componentName]: formData?.filter((d) => d),
        ...deletedFormData,
        fileData,
      })
    );
    dispatch(saveJobApplyValidFormList(componentName));
  };

  const externalClick = () => {
    let event;
    if (typeof Event === 'function') {
      event = new Event('submit', { cancelable: true });
    } else {
      event = document.createEvent('Event');
      event.initEvent('submit', false, { cancelable: true });
    }
    formSubmitRef?.current?.dispatchEvent(event);
  };

  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      showFormError(true);
      setTimeout(() => dispatch(saveJobApplyFormTriggerData(componentName)), 50);
      externalClick();
    },
    invalidTrigger: () => {
      showFormError(true);
    },
  }));

  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    formattedData = recursiveWithConstantCallback(formattedData, booleanRes);
    onSubmitFunc(formattedData);
  };

  const handleChange = () => {
    setUploadDelete(true);
    showFormError(true);
  };

  const _clearItem = (idx) => {
    _handleRemoveItem(idx);
    setData({ data: [], attachment: null });
    setFieldArr([]);
    reset();
    setLoading(true);
    setTimeout(() => {
      setFieldArr(new Array(section?.fields?.items));
      showFormError(isMandatory?.value);
      setLoading(false);
    }, 100);
    setIsCleared(true);
  };

  const _handleRemoveItem = (idx) => {
    const temp = [...newFieldArr];
    delete temp[idx];
    setDeletedId([...deletedId, data[idx]?.id]);
    setFieldArr(temp);
    setUploadDelete(true);
  };

  /**
   * @description - Add button click handler function.
   * @returns {undefined}.
   */
  const _handleAddItem = () => {
    const items = [...section?.fields?.items];
    setFieldArr([...newFieldArr, items]);
  };

  return (
    <div className={`multi-form ${hideForm ? 'hide-form' : ''}`}>
      {title?.value && newFieldArr.length > 0 && (
        <RichText tag="div" className="title-section" field={title} />
      )}
      {abstract?.value && newFieldArr.length > 0 && (
        <RichText tag="div" className="text-section" field={abstract} />
      )}
      {loading && (
        <Loader
          loaderMsg={''}
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: '#C25700',
          }}
          customTextStyle={{ color: '#C25700' }}
        />
      )}
      <form
        autoComplete="off"
        onSubmit={handleSubmit(transform(onSubmit))}
        ref={formSubmitRef}
        key={`${componentName}_form`}
        id={`${componentName}_form`}
      >
        {newFieldArr.map((item, idx) => {
          if (item) {
            return (
              <React.Fragment>
                <DynamicForm
                  formData={item}
                  register={register}
                  errors={formError || isObjNotEmpty(touched) ? errors : ''}
                  getValues={getValues}
                  setValue={setValue}
                  setError={setError}
                  trigger={trigger}
                  watch={watch}
                  clearErrors={clearErrors}
                  handleUpload={handleUpload}
                  handleDelete={handleDelete}
                  handleChange={handleChange}
                  control={control}
                  validateOnLoad={true}
                  values={{
                    ...data,
                    data: data?.[idx],
                  }}
                  formName={componentName}
                  customIndex={idx}
                  APIQuerryParams={{ cid: candidateId }}
                  readOnlyFields={profileInfo?.FieldConfig?.readOnlyFields}
                  fieldsToHide={profileInfo?.FieldConfig?.fieldsToHide}
                  formState={formState}
                />
                {fieldLength > 1 && (
                  <div className="remove-btn">
                    <TextButton
                      cssClass="blue"
                      text={buttonTextValues?.remove || t('remove')}
                      handleTextClick={() => _handleRemoveItem(idx)}
                    />
                  </div>
                )}
                {fieldLength === 1 && (
                  <div className="remove-btn">
                    <TextButton
                      cssClass="blue"
                      text={buttonTextValues?.clear || t('clear')}
                      handleTextClick={() => _clearItem(idx)}
                    />
                  </div>
                )}
              </React.Fragment>
            );
          }
        })}
      </form>
      <div className="add-form">
        <TextButton
          cssClass={`blue ${!isValid && 'grey-disabled'}`}
          text={buttonTextValues?.add || t('add')}
          isDisabled={!isValid}
          handleTextClick={_handleAddItem}
        />
      </div>
    </div>
  );
};

MultiForm.defaultProps = {
  section: {},
};

MultiForm.propTypes = {
  section: PropTypes.shape({}),
};

const ConnectedComponent = withTranslation()(MultiForm);

export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
