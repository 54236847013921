/*eslint-disable */
import React, { useState, useRef } from 'react';
import FilterItem from './ArticleFilterItem';
import SearchIcon from '../../../assets/images/icon__search.svg';
import { withTranslation } from 'react-i18next';
import './articlefilters.scss';

/**
 *
 * @param {*} props
 * @returns
 */
const ArticleFilters = (props) => {
  const { t } = props;
  const audience = [];
  const [selectedFilterVal, setFilterVals] = useState(props?.data); //initial value from header link

  /**
   * setting the initial article filter values
   */
  if (props?.filterBlocks && props?.filterBlocks?.audience?.values?.length > 0) {
    for (let i = 0; i < props?.filterBlocks?.audience?.values?.length; i++) {
      audience.push({
        label: props?.filterBlocks?.audience?.label,
        name: 'audience',
        val: props?.filterBlocks?.audience?.values[i],
      });
    }
  }
  const contentTypes = [];
  if (props?.filterBlocks && props?.filterBlocks?.contentTypes?.values?.length > 0) {
    for (let i = 0; i < props?.filterBlocks?.contentTypes?.values?.length; i++) {
      contentTypes.push({
        name: 'contentTypes',
        val: props?.filterBlocks?.contentTypes?.values[i],
        label: props?.filterBlocks?.contentTypes?.label,
      });
    }
  }
  const clientTopics = [];
  const jobSeekerTopics = [];
  if (props?.filterBlocks && props?.filterBlocks?.topics?.length > 0) {
    for (let i = 0; i < props?.filterBlocks?.topics?.length; i++) {
      if (
        props?.filterBlocks?.topics[i].label == t('jobseeker') &&
        props?.filterBlocks?.topics[i]?.values.length > 0
      ) {
        for (let j = 0; j < props?.filterBlocks?.topics[i]?.values.length; j++) {
          jobSeekerTopics.push({
            name: 'jobSeekerTopics',
            val: props?.filterBlocks?.topics[i]?.values[j],
            label: props?.filterBlocks?.topics[i]?.label,
          });
        }
      } else {
        for (let j = 0; j < props?.filterBlocks?.topics[i]?.values.length; j++) {
          clientTopics.push({
            name: 'clientTopics',
            val: props?.filterBlocks?.topics[i]?.values[j],
            label: props?.filterBlocks?.topics[i]?.label,
          });
        }
      }
    }
  }

  const ref = useRef();
  const textInput = React.createRef();
  /**
   * @description - getArticleFilters.
   * @returns{undefined}
   */
  const getArticleFilters = () => {
    props.cancelFilterValsToBlocks();
  };
  let newArray = [];

  /**
   *@description - getSelectedFilterValues.
   * @param {array} values=[]
   *  @returns{undefined}
   */
  const getSelectedFilterValues = (values = []) => {
    let vals = [];
    vals = values.filter(
      (el) => el?.key !== '' && el?.key !== undefined && el?.key !== null
    );
    newArray = [];
    newArray.push(vals);
    containsObject(newArray, selectedFilterVal);
    if (newArray.length > 0) {
      newArray = selectedFilterVal.concat(newArray);
      setFilterVals(newArray);
      setFilterVals(newArray);
    }
    props.setFilterValsToBlocks(values);
  };
  // eslint-disable-next-line require-jsdoc
  const containsObject = (newArray, selectedVals) => {
    for (let j = 0; j < selectedVals?.length; ) {
      for (let k = 0; k < selectedVals[j].length; ) {
        for (let i = 0; i < newArray[0]?.length; ) {
          if (selectedVals[j][k].title === newArray[0][i].title) {
            newArray[0].splice(i, 1);
          }
          i++;
        }
        k++;
      }
      j++;
    }
  };

  /**
   *@description - selectedOption.
   * @param {array} values=[]
   *  @returns{undefined}
   */
  const selectedOption = (values) => {
    props.sendFilterItemtoblog(values);
  };
  const setFiltervalsFromheader = (values, result) => {
    newArray.push(values);
    if (result !== 'remove') {
      containsObject(newArray, selectedFilterVal);
    }
    let vals = [];
    vals = values.filter(
      (el) => el?.key !== '' && el?.key !== undefined && el?.key !== null
    );
    newArray = [];
    newArray.push(vals);
    setFilterVals(newArray);
    props.setSelectedValues(values);
  };

  // eslint-disable-next-line valid-jsdoc
  /**
   *@description - updateArticle.
   * @param {object} e - Event object.
   * * @param {array} item=[]
   *  @returns{undefined}
   */
  const updateArticle = (e, item, index) => {
    props.updateArticle(e, item, index, selectedFilterVal);
    setFilterVals(selectedFilterVal);
  };

  /**
   * @description - update article.
   * @param {object} e - Event object.
   * @returns {undefined}
   */
  const onEnter = (e) => {
    if (e && (e.keyCode === 13 || e.keyCode === 32)) {
      updateArticle();
    }
  };

  /**
   * @description - handleClick.
   * @returns {undefined}
   */
  function handleClick() {
    props.sendDataToParent(textInput.current.value);
  }
  const onSearchEnter = (e) => {
    if (e && e.keyCode === 13) {
      handleClick();
    }
  };
  return (
    <section className="article-filters">
      <div className="form-block-wrapper">
        <div className="form-block ">
          <input
            ref={textInput}
            className=" "
            id="search_blog"
            type="text"
            placeholder={t('search-blogpost')}
            aria-label="Search Blog Posts"
            onKeyUp={onSearchEnter}
          />

          <button
            className="searchbtn"
            aria-label="Search"
            style={{
              backgroundImage: `url(${SearchIcon})`,
            }}
            onClick={handleClick}
          ></button>
        </div>
      </div>
      <div className="fullwrapper">
        <div className="filter">
          {selectedFilterVal?.length > 0
            ? selectedFilterVal.map(
                (item) =>
                  item.length > 0 &&
                  item.map((val, index) => (
                    <div className="filterwrapper">
                      <span key={`filteritem_${index}`} className="closebutton">
                        <span className="">{val?.title}</span>
                      </span>
                      <span
                        className="closeDetail"
                        role="button"
                        ref={ref}
                        onClick={(e) => updateArticle(e, val, index)}
                        tabIndex={0}
                        onKeyUp={onEnter}
                        aria-label="Close button"
                      >
                        x
                      </span>
                    </div>
                  ))
              )
            : ''}
        </div>

        <FilterItem
          text={t('filter-result')}
          iconAfter={<div className="down-arrow" />}
          iconBefore={<div className="filter-icon" />}
          getSelectedFilterValues={(values) => getSelectedFilterValues(values)}
          getArticleFilters={(values) => getArticleFilters(values)}
          dropdown="job-filters"
          dropdownSize=""
          optionsList={[audience, contentTypes, clientTopics, jobSeekerTopics]}
          emptyText={'Filter Result'}
          selectedOption={(values) => selectedOption(values)}
          setFiltervals={(values, result) => setFiltervalsFromheader(values, result)}
          headerClickData={props?.headerClickData}
        />
      </div>
    </section>
  );
};

export default withTranslation()(ArticleFilters);
