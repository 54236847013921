import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { mediaApi, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';

import { DATE_FORMATS } from '../../../utils/enums';
import FavIcon from '../../../assets/images/favicon_manpower.ico';
import { isDataExists } from '../../../utils/helperUtils';

/**
 * @description - Head tags details (Meta, Title, etc).
 * @param {object} props - Input props.
 * @returns {Node} - HTML head to append.
 */
const MetaData = ({ fields, sitecoreContext }) => {
  const { items = {} } = fields;
  const {
    alternateUrls = [],
    canonicalUrl,
    metaTitle,
    metaDescription,
    pageID,
  } = items;
  const { favicon, imageUrl, metaKeywords, schema, facebookDomain } = items;
  const { javaScript, css, twitterSiteContent, twitterCardContent } = items;
  const { robots, retention } = items;

  return (
    <Helmet>
      <title>{metaTitle || 'Welcome to ManpowerGroup'}</title>
      {retention ? <meta name="robots" content={retention} /> : null}
      {robots ? (
        <meta
          name="robots"
          content={`unavailable_after: ${moment
            .parseZone(robots)
            .format(DATE_FORMATS.ROBOTS_FORMAT)}`}
        />
      ) : null}
      {metaDescription ? (
        <meta name="description" content={metaDescription} />
      ) : null}
      {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
      {favicon ? (
        <link rel="shortcut icon" href={mediaApi.updateImageUrl(favicon)} />
      ) : (
        <link rel="shortcut icon" href={FavIcon} />
      )}
      {metaKeywords ? <meta name="keywords" content={metaKeywords} /> : null}
      {isDataExists(alternateUrls)
        ? alternateUrls.map((item, index) => {
            const { alternativeUrl, language } = item;
            return (
              <link
                rel="alternate"
                key={`${language}_${index}`}
                href={alternativeUrl}
                hrefLang={language}
              />
            );
          })
        : null}
      {isDataExists(alternateUrls)
        ? alternateUrls.map((item, index) => {
            const { language } = item;
            return (
              <meta
                property="og:locale"
                key={`${language}_${index}`}
                content={language}
              />
            );
          })
        : null}
      {sitecoreContext?.site?.name === 'Manpower-Norway' && pageID && (
        <meta name="pageID" content={pageID} />
      )}

      {/* OG section */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:secure_url" content={imageUrl} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta name="twitter:card" content={twitterCardContent} />
      <meta name="twitter:site" content={twitterSiteContent} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:description" content={metaDescription} />
      {facebookDomain && (
        <meta name="facebook-domain-verification" content={facebookDomain} />
      )}
      {css ? <style type="text/css">{css}</style> : null}
      {javaScript ? <script type="text/javascript">{javaScript}</script> : null}
      {schema ? <script type="application/ld+json">{schema}</script> : null}
    </Helmet>
  );
};

MetaData.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.shape({}).isRequired,
  }),
  sitecoreContext: PropTypes.shape({}),
};

MetaData.defaultProps = {
  fields: {},
  sitecoreContext: {},
};

export default withSitecoreContext()(MetaData);
