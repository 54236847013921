import React from 'react';
import PropTypes from 'prop-types';
import ConnectorButtonLink from '../globals/buttons/ConnectorButtonLink';
import './featurette.scss';
import { setAltUrlForImg, getImg } from '../../../utils/helperUtils';
import { Text, Image, mediaApi } from '@sitecore-jss/sitecore-jss-react';
/**
 * @description - FeaturetteConnector component;
 * @param {object}  props - Input props;
 * @returns{Node} - return html block;
 */
const FeaturetteConnector = (props) => {
  const { fields } = props;
  const {
    title,
    subTitle,
    text,
    image,
    ctaText,
    ctaUrl,
    backgroundImage,
    backgroundColor,
  } = fields;
  const background_color = backgroundColor?.value === 'GREY' ? 'grey' : 'white';
  return (
    <div
      className={`featurette-section connector ${background_color}`}
      style={{
        backgroundImage: backgroundImage
          ? `url(${mediaApi.updateImageUrl(getImg(backgroundImage))})`
          : null,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-5 offset-lg-1">
            <div className="featurette-imgs">
              <div className="blurred">
                <Image field={setAltUrlForImg(image, 'connector-image')} />
              </div>
              <Image field={setAltUrlForImg(image, 'connector-image')} />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="featurette-body">
              <div className="sub-title">
                <Text field={subTitle} />
              </div>
              <div className="title">
                <Text field={title} />
              </div>
              <p className="large">
                <Text field={text} />
              </p>
              <ConnectorButtonLink ctaText={ctaText} ctaUrl={ctaUrl} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturetteConnector.defaultProps = {
  fields: {
    title: {},
    subTitle: {},
    text: {},
    image: { value: { src: 'http://via.placeholder.com/500', alt: 'img' } },
    altText: {},
    ctaText: {},
    ctaUrl: {},
    backgroundImage: {},
    backgroundColor: {},
  },
};

FeaturetteConnector.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    subTitle: {
      value: PropTypes.string,
    },

    text: {
      value: PropTypes.string,
    },
    image: {
      value: PropTypes.string,
    },
    altText: {
      value: PropTypes.string,
    },
    ctaText: {
      value: PropTypes.string,
    },
    ctaUrl: {
      value: PropTypes.string,
    },
    backgroundImage: {
      value: PropTypes.string,
    },
    backgroundColor: {
      value: PropTypes.string,
    },
  }),
};

export default FeaturetteConnector;
