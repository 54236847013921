import { PROFILE_EMPTY_FIELDS } from '../actions/actionTypes';

const initialState = {};
/**
 * @description - Profile Empty fields  Reducer function
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */
const profileEmptyFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_EMPTY_FIELDS.SAVE:
      return {
        ...state,
        ...action.payload,
      };
    case PROFILE_EMPTY_FIELDS.CLEAR:
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default profileEmptyFieldsReducer;
