/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ProfileItemTemplate from '../Template/ProfileItem';
import { missingInfo } from '../Template/MissingInfoTemplate';
import { dashboardItems } from '../../../../../../utils/enums';
import Loader from '../../../../../core/ErrorBoundary/Loader';

/**
 * @description - MultiView component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const MultiView = ({ fields, profileInfo, t, componentName, getEmptyFields }) => {
  const { items, apiKeyMapper } = fields;
  const [newFieldArr, setFieldArr] = useState([]);
  const [data, setData] = useState([]);
  const [candidateId, setCandidateId] = useState('');
  const [loading, setLoading] = useState(true);
  const compName = apiKeyMapper?.value || dashboardItems?.[componentName];

  useEffect(() => {
    if (profileInfo?.Id) {
      setLoading(false);
      const personalDetailsData = {
        data: profileInfo?.[compName]?.data,
        attachment: profileInfo?.AttachmentDetails,
      };
      setData(personalDetailsData);
      setCandidateId(profileInfo?.Id);
      if (items?.length > 0 && profileInfo?.[compName]?.formCount > 0) {
        setFieldArr(new Array(profileInfo?.[compName]?.formCount).fill(items[0]));
      }
      //Empty Field check
      const formData = profileInfo?.[compName]?.data;
      let expFieldItems = items;
      if (expFieldItems.length > 0 && profileInfo?.[compName]?.formCount > 0) {
        expFieldItems = new Array(profileInfo?.[compName]?.formCount).fill(items[0]);
      }
      let emptyCount = 0;
      const emptyObj = {};
      const array = [];
      if (formData && formData?.length === 0) {
        emptyCount = expFieldItems?.length;
      } else {
        expFieldItems &&
          expFieldItems.map((item, index) => {
            array.push(
              missingInfo(item, {
                data: formData?.[index],
                attachment: profileInfo?.AttachmentDetails,
              })
            );
          });
        array.map((item) => {
          Object.keys(item).map((k) => {
            if (k === 'emptyCount') {
              emptyCount = emptyCount + item[k];
            }
          });
        });
      }
      emptyObj[componentName] = {};
      emptyObj[componentName].isValid = emptyCount;
      getEmptyFields(emptyObj);
    }
  }, [profileInfo]);

  return (
    <div className="accordion-container-block">
      {data?.data?.length > 0 ? (
        newFieldArr.map((block, index) => (
          <ProfileItemTemplate
            key={index}
            data={data}
            candidateId={candidateId}
            block={block}
            t={t}
            id={index}
            cssClass="exp_section"
          />
        ))
      ) : loading ? (
        <Loader
          loaderMsg={t('fetching-your-data')}
          loaderWithMsg
          customLoaderStyle={{
            width: '2rem',
            height: '2rem',
            color: '#C25700',
          }}
          customTextStyle={{ color: '#C25700' }}
        />
      ) : (
        <div className="no-content-data">{t(`no-${componentName}-data`)}</div>
      )}
    </div>
  );
};

MultiView.defaultProps = {
  fields: {},
  t: (val) => val,
  getEmptyFields: () => {},
};

MultiView.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
  getEmptyFields: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(MultiView));
