import { distance } from '../../../utils/locationUtils';

/**
 * @description - to calculate nearest office locations
 * @param {*} props - loation properties
 * @param {string} lat - latitude of from location
 * @param {string} lng - longitude of from location
 * @param {boolean} highlightNearest - to hide and show large pointer
 * @returns {Array} - list of office locations as nearest first
 */
export const NearbyFinder = (props, lat, lng, highlightNearest = true) => {
  const { fields } = props;

  /**
   * @description -  calculate disatnce and add to location list array
   * @param {number} lat - from location latitude
   * @param {number} lng - from location longitude
   * @param {boolean} highlightNearest - to hide and show large pointer
   * @returns {Array} - office location list with distance
   */
  const findDistance = (lat, lng, highlightNearest) => {
    if (Array.isArray(fields?.items)) {
      fields.items.forEach((item, index) => {
        fields.items[index].fields['distance'] = distance(
          parseFloat(item.fields?.latitude?.value),
          parseFloat(item.fields?.longtitude?.value),
          lat,
          lng
        );
      });
      //sort based in distance
      if (highlightNearest) {
        fields.items.sort((a, b) =>
          a.fields?.distance > b.fields?.distance ? 1 : -1
        );
      }

      fields.items.map((marker, index) => {
        marker.lat = parseFloat(marker?.fields?.latitude?.value);
        marker.lng = parseFloat(marker?.fields?.longtitude?.value);
        marker.locationUrl = marker?.fields?.ViewDetailsUrl?.value?.href;
        marker.isPrimary = highlightNearest === true && index === 0 ? true : false;
      });

      return fields.items;
    }
  };
  return Promise.resolve(findDistance(lat, lng, highlightNearest));
};
