import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ButtonLink from '../../../../core/globals/link/ButtonLink';
import '../../apply_job_flow.scss';
import ProgressBar from '../../../../core/ProgressBar';

/**
 * @description - Success component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML template.
 */
const JobApplySuccessProfile = ({ fields, t }) => {
  const { text, title, percentage, buttonText, link } = fields;
  const newProfileTemplate = (
    <div className="build-profile">
      <div className="titleText">{title}</div>
      <div className="sub-text">{text}</div>
      {buttonText && (
        <div className="btnContainer">
          <ButtonLink
            ctaText={buttonText}
            ctaUrl={{ href: link, linktype: 'internal' }}
            arrow
          />
        </div>
      )}
    </div>
  );

  /**
   * @description - Template for partially complete profile.
   * @returns {Node} - HTML Template.
   */
  const partiallyCompleteProfileTemplate = () => {
    const titleText = title?.replace('(_)', percentage);
    return (
      <div className="build-profile">
        <div className="titleText">{titleText}</div>
        <div className="col-lg-6 col-xl-6 col-md-6 col-sm-8 customStyle">
          <ProgressBar
            completed={percentage}
            primaryColor={'#1c3e6b'}
            secondaryColor={'#30588f'}
            gradient
          />
        </div>
        <div className="tipText">
          <strong>{t('tip')}&nbsp;</strong>
          <span>{text}</span>
        </div>
        {buttonText && (
          <div className="btnContainer">
            <ButtonLink
              ctaText={buttonText}
              ctaUrl={{ href: link, linktype: 'internal' }}
              arrow
            />
          </div>
        )}
      </div>
    );
  };
  const completedProfileTemplate = (
    <div className="build-profile">
      <div className="titleText">{title}</div>
      <div className="sub-text">{text}</div>
      {buttonText && (
        <div className="btnContainer">
          <ButtonLink
            ctaText={buttonText}
            ctaUrl={{ href: link, linktype: 'internal' }}
            arrow
          />
        </div>
      )}
    </div>
  );

  /**
   * @description - Function to get the respective template based on percentage.
   * @returns {Node} - HTML Template.
   */
  const getSuccessTemplate = () => {
    if (percentage === 0) return newProfileTemplate;
    const template =
      percentage >= 100
        ? completedProfileTemplate
        : partiallyCompleteProfileTemplate();
    return template;
  };
  return (
    <div className="create-profile-success-block col-lg-6 offset-lg-3">
      {Object.keys(fields).length > 0 && getSuccessTemplate()}
    </div>
  );
};

JobApplySuccessProfile.propTypes = {
  fields: PropTypes.shape({
    text: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

JobApplySuccessProfile.defaultProps = {
  fields: {},
  t: (val) => val,
};

export default withTranslation()(JobApplySuccessProfile);
