import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  mediaApi,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import TextButton from '../globals/buttons/TextButton';
import StaticLink from '../globals/link/StaticLink';

import { showString } from '../../../utils/helperUtils';
import { DATE_FORMATS } from '../../../utils/enums';
import './cardarticle.scss';

/**
 * @description - Render button or arraow link.
 * @param {string} mode - Mode for display.
 * @param {string|object} ctaText - CTA name.
 * @returns {Node} - Button or arrow link.
 */
const renderButton = (mode, ctaText) => {
  switch (mode) {
    case 'arrow':
      return <div className="article-icon arrow-icon" />;
    case 'link':
      return <StaticLink cssClass="articles-link" ctaText={ctaText} arrow />;
    case 'text':
      return <TextButton cssClass="cta" text={ctaText} />;
    default:
      return <div className="arrow-icon" aria-hidden="true" />;
  }
};

/**
 * @description - Article Card.
 * @param {object} param0 - Input params.
 * @returns {Node} - HTML card component.
 */
const CardArticle = ({
  title,
  subTitle,
  date,
  img,
  ctaUrl,
  ctaText,
  ctaMode,
  isFeatured,
  sitecoreContext,
}) => {
  const imgVal = mediaApi.updateImageUrl(img);

  const dateFormat = sitecoreContext?.Country?.dateFormat || DATE_FORMATS.LOCALE;
  return (
    <Link
      to={ctaUrl}
      tabIndex={-1}
      className={`card article card--wipe ${isFeatured ? 'featured' : ''}`}
    >
      <span className="card--wipe--apollo" />
      {isFeatured === true && (
        <img className="article-image" alt={title} src={imgVal} />
      )}
      <div className="card-body">
        {subTitle && (
          <div className="sub-title">
            {subTitle?.value ? <Text field={subTitle} /> : showString(subTitle)}
          </div>
        )}
        <div className="date">{date ? moment(date).format(dateFormat) : ''}</div>
        <div className="title">{title}</div>
        {renderButton(ctaMode, ctaText, ctaUrl)}
      </div>
    </Link>
  );
};

CardArticle.defaultProps = {
  title: { value: '' },
  subTitle: '',
  date: { value: new Date() },
  img: { src: 'http://via.placeholder.com/600x330' },
  altText: '',
  ctaUrl: '#',
  ctaText: 'Read more',
  ctaMode: 'arrow',
  isFeatured: false,
  dateFormat: DATE_FORMATS.DEFAULT_US,
  sitecoreContext: {},
};

CardArticle.propTypes = {
  title: PropTypes.shape({ value: PropTypes.string }),
  subTitle: PropTypes.oneOfType([
    PropTypes.shape({ value: PropTypes.string }),
    PropTypes.string,
  ]),
  date: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  img: PropTypes.shape({}),
  altText: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaText: PropTypes.string,
  ctaMode: PropTypes.oneOf(['arrow', 'text']).isRequired,
  isFeatured: PropTypes.bool,
  dateFormat: PropTypes.string,
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(withTranslation()(CardArticle));
