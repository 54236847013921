/*eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ButtonLink from '../../../core/globals/link/ButtonLink';
import { authstates } from '../../../../utils/enums';
import Button from '../../../core/globals/buttons/ButtonWithIcon';
import TextButton from '../../../core/globals/buttons/TextButton.jsx';
import AlertInline from '../../ErrorBoundary/AlertInline';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useSelector } from 'react-redux';
import { isNullorEmpty } from '../../../../../src/utils/helperUtils';
/**
 *
 * @param {*} apply
 * @param {*} jobDetails
 */
const ApplyJob = ({
  apply,
  jobDetails,
  reversedClass,
  blue = '',
  reversed = '',
  applyJobUrl,
  dashboardURL,
  dashboardText,
  t,
  revenue,
  classDetails = '',
  cssClass = '',
  sitecoreContext,
  expapply,
}) => {
  const history = useHistory();
  const {
    positionID,
    jobID,
    jobItemID,
    jobType,
    jobOfferType,
    jobTitle,
    companyName,
    jobLocation,
    employmentType,
    domain,
    education,
    salaryRate,
    siteName,
    applyUrl,
    applicationType,
    clientID,
    addtionalForDataLayer,
    externalApplyUrl,
    jobBrandText,
  } = jobDetails;

  const isMirroredJob =
    sitecoreContext?.app.toLowerCase().indexOf('manpower-germany') > -1 ? 1 : 0;
  const domainURL = applyUrl && new URL(applyUrl);
  const countryName = sitecoreContext?.app?.toLowerCase();

  const authStates = {
    AUTHENTICATED: 'Authenticated',
    UNAUTHENTICATED: 'Non-authenticated',
    PROGRESS: 'PROGRESS',
  };

  const mirroredJobURL =
    applyUrl &&
    domainURL &&
    `${domainURL?.protocol}//${domainURL?.hostname}/${
      sitecoreContext?.language || ''
    }/candidate/jobapply?id=${jobItemID}`;
  const [utmParameters, setutmParameters] = useState({});
  let sourceValue;
  if (!isNullorEmpty(utmParameters?.UTM_Source)) {
    sourceValue =
      utmParameters.UTM_Source +
      '/' +
      (isNullorEmpty(utmParameters.UTM_Medium) ? null : utmParameters.UTM_Medium) +
      '/' +
      (isNullorEmpty(utmParameters.UTM_Campaign)
        ? null
        : utmParameters.UTM_Campaign) +
      '/' +
      (isNullorEmpty(utmParameters.UTM_Content) ? null : utmParameters.UTM_Content);
  } else {
    sourceValue = !isNullorEmpty(utmParameters?.Referer)
      ? utmParameters.Referer
      : null;
  }
  const params = new URLSearchParams();
  const loginReducer = useSelector((state) => state.loginReducer);
  params.set('positionID', positionID || '');
  params.set('source', sourceValue);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const jobSessionQueryParams = sessionStorage.getItem('utmParams')
        ? sessionStorage.getItem('utmParams')
        : sessionStorage.getItem('utmReferrer');
      if (jobSessionQueryParams) {
        const parsedJobSessionQueryParams = jobSessionQueryParams
          ? JSON.parse(jobSessionQueryParams)
          : {};
        setutmParameters(parsedJobSessionQueryParams);
      }
    }
  }, []);

  const addToDatalayer = () => {
    const isNorway = sitecoreContext?.language?.toLowerCase() === 'nb';
    if (window?.dataLayer) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          add: {
            products: [
              {
                name: jobTitle || 'na',
                id: jobID || positionID || 'na',
                brand: addtionalForDataLayer?.companyName
                  ? addtionalForDataLayer?.companyName
                  : companyName || 'na',
                category: domain || 'na',
                variant: employmentType || 'na',
                price:
                  sitecoreContext?.Country?.revenuePerApplication || revenue || 'na',
                quantity: 1,
                dimension11: `Location: ${
                  jobLocation ? jobLocation : 'na'
                } | Education: ${
                  addtionalForDataLayer?.education
                    ? addtionalForDataLayer?.education.join(', ')
                    : education
                    ? education.join(', ')
                    : 'na'
                } | Salary: ${salaryRate ? salaryRate : 'na'} | Hours: ${
                  jobType ? jobType : 'na'
                }`,
                dimension12: `${
                  authStates?.[loginReducer?.state || 'UNAUTHENTICATED']
                }`,
                dimension13: `${
                  isNorway ?
                    (jobOfferType) ?
                      (jobOfferType.toLowerCase()==='private')
                         ?'private job | na'
                        : 'na'
                      : 'na'
                    : authStates?.[
                        loginReducer?.state || authStates?.UNAUTHENTICATED
                      ] === authStates?.UNAUTHENTICATED
                    ? 'quick apply'
                    : 'standard'
                }`,
                dimension14: 'na',
                dimension15: 'na',
                dimension16: clientID ? clientID : 'na',
                dimension17: jobLocation ? jobLocation : 'na',
                dimension18: addtionalForDataLayer?.education
                  ? addtionalForDataLayer?.education.join(', ')
                  : education
                  ? education.join(', ')
                  : 'na',
                dimension19: salaryRate ? salaryRate : 'na',
                dimension20: jobType ? jobType : 'na',
              },
            ],
          },
        },
      });
    }
  };

  const externalButtonLink = () => {
    return (
      <TextButton
        cssClass={`text-flow ${reversedClass ? 'external-cta' : ''}`}
        text={
          siteName.includes('AMS')
            ? t('ams-apply-now').replace('{0}', companyName?.toUpperCase())
            : t('ext-apply-now').replace('{0}', companyName?.toUpperCase())
        }
        handleTextClick={() => {
          addToDatalayer();
          applyUrl ? window.open(applyUrl) : window.open(externalApplyUrl);
        }}
        linkClass={'hyper-link'}
      />
    );
  };

  return sitecoreContext?.LoginType === 'ROMA' ? (
    <div className="cta">
      {siteName && siteName.includes('AMS') ? (
        externalButtonLink()
      ) : !isNullorEmpty(externalApplyUrl) && isNullorEmpty(applyUrl) ? (
        externalButtonLink()
      ) : (
        <ButtonLink
          cssClass={`${reversed ? 'reversed' : ''} ${
            reversedClass ? 'reversed-search' : ''
          } ${blue ? 'blue' : ''}`}
          ctaText={
            jobBrandText && jobBrandText
              ? { value: `${expapply} ${jobBrandText}` }
              : { value: apply }
          }
          ctaUrl={{
            href: applyUrl
              ? applyUrl
              : {
                  pathname: applyJobUrl?.href,
                  search: String(params),
                },
            linktype: applyUrl ? 'external' : 'internal',
            target: applyUrl ? '_blank' : applyJobUrl?.target,
          }}
          arrow
          handleButtonClick={() => {
            addToDatalayer();
          }}
        />
      )}
    </div>
  ) : (
    <div className="cta">
      {!jobDetails?.isApplied ? (
        applyUrl ? (
          <TextButton
            cssClass={`text-flow ${reversedClass ? 'external-cta' : ''}`}
            text={
              companyName
                ? t('apply with company as').replace(
                    '{0}',
                    companyName.toUpperCase()
                  )
                : t('apply on company website')
            }
            handleTextClick={() => {
              addToDatalayer();
              isMirroredJob ? window.open(mirroredJobURL) : window.open(applyUrl);
            }}
            linkClass={'hyper-link'}
          />
        ) : (
          <Button
            cssClass={`${reversed ? 'reversed' : ''} ${
              reversedClass ? 'reversed-search' : ''
            } ${blue ? 'blue' : ''}`}
            text={apply}
            handleButtonClick={() => {
              addToDatalayer();
              history.push({
                pathname:
                  applyJobUrl?.href ||
                  `/${sitecoreContext?.language || ''}/candidate/jobapply`,
                search: String('?id=' + jobItemID),
              });
            }}
          />
        )
      ) : (
        <div className="status-applied">
          <Button
            cssClass={`${reversed ? 'reversed' : ''} ${
              reversedClass ? 'reversed-search' : ''
            } ${blue ? 'blue' : ''}`}
            text={dashboardText}
            handleButtonClick={() => {
              addToDatalayer();
              history.push({
                pathname: dashboardURL,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

ApplyJob.defaultProps = {
  jobDetails: {},
  applyJobUrl: {},
  blue: '',
  reversedClass: false,
  reversed: '',
  apply: '',
  cssClass: '',
};

ApplyJob.propTypes = {
  jobDetails: PropTypes.shape({}),
  applyJobUrl: PropTypes.shape({}),
  blue: PropTypes.string,
  reversed: PropTypes.string,
  apply: PropTypes.string,
};

export default withRouter(withTranslation()(withSitecoreContext()(ApplyJob)));
