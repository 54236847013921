import React, { useState, useEffect } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import Button from '../../../../core/globals/buttons/Button';
import DynamicForm from '../../../../core/Forms/DynamicForm';
import { dataFetcher } from '../../../../../dataFetcher';
import Loader from '../../../../core/ErrorBoundary/Loader';
import Error from '../../../../core/ErrorBoundary/Error';
import { getCandidateDetailsApi } from '../../../../../services/apiServices/candidateService';
import { clearJobAlerts } from '../../../../../redux/actions';
import { formObjIteration } from '../../../Utils/helper';
import { ACCOUNT_SETTINGS, API_STATUS_CODES } from '../../../../../constants';
import './ConsentForm.scss';

/**
 * @description - ConsentForm component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const ConsentForm = ({
  fields,
  t,
  profileInfo,
  theme,
  closeConsentPopup,
  isConsentPopUp,
  ctaText,
}) => {
  const { subTitle, items, jobAlertConnectorField } = fields;
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [apiLoading, setApiDataLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const { register, handleSubmit, getValues, errors, setValue, control } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
    shouldFocusError: false,
  });

  useEffect(() => {
    if (profileInfo?.Id || isConsentPopUp) {
      setprofileDataLoading(false);
      setData({ data: profileInfo?.PersonalDetails?.data?.[0] });
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);

  /**
   * @description - error notification based on api response.
   * @returns {undefined}
   */
  const errorNotification = () => {
    toast.error(t('error-message'), {
      position: 'top-center',
    });
    setApiDataLoading(false);
  };

  /**
   * @description - clear Job alerts based on job consent status.
   * @returns {undefined}
   */
  const clearJobAlertBasedonConsentCheck = (consentVal) => {
    const consentValues = find(flatten(map(items, 'fields.items')), {
      fields: { name: { value: jobAlertConnectorField?.value } },
    })?.fields?.values;
    const consentFilterval =
      consentValues.length > 0
        ? find(consentValues, {
            fields: { label: { value: consentVal } },
          })?.fields?.key?.value
        : consentVal;
    if (consentFilterval === false || consentFilterval === 'false') {
      dispatch(clearJobAlerts());
    }
  };

  /**
   * @description - Save Consent changes.
   * @returns {undefined}
   */
  const onSubmit = (data) => {
    setApiDataLoading(true);
    const jobConsentVal = jobAlertConnectorField?.value
      ? get(data, jobAlertConnectorField?.value)
      : undefined;
    const postData = {
      id: profileInfo?.Id,
      jobAlertFlag: jobConsentVal,
      ...data,
    };
    dataFetcher(ACCOUNT_SETTINGS.CONSENT.UPDATE_CONSENT, 'POST', postData).then(
      (res) => {
        if (res?.data?.status === API_STATUS_CODES.SUCCESS) {
          getCandidateDetailsApi({}, dispatch);
          toast.success(t('details-saved-successfully'), {
            position: 'top-center',
          });
          if (closeConsentPopup) closeConsentPopup();
          if (jobConsentVal) {
            clearJobAlertBasedonConsentCheck(jobConsentVal);
          }
          setApiDataLoading(false);
        } else {
          if (closeConsentPopup) closeConsentPopup();
          errorNotification();
        }
      },
      () => {
        if (closeConsentPopup) closeConsentPopup();
        errorNotification();
      }
    );
  };

  /**
   * @description - tranform layer.
   * @returns {undefined}
   */
  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    onSubmitFunc(formattedData);
  };

  return (
    <div className="dashboard-agent dashboard-block consent-section">
      {subTitle?.value && <RichText field={subTitle} tag="p" className="content" />}
      {profileDataLoading ? (
        <Loader
          loaderMsg={t('fetching-your-data')}
          loaderWithMsg
          customLoaderStyle={{
            width: '4rem',
            height: '4rem',
            color: theme?.secondaryButton,
          }}
          customTextStyle={{ color: theme?.secondaryButton }}
        />
      ) : error ? (
        <div className="apiError">
          <Error errorMsg={t('error-message')} t={t} />
        </div>
      ) : (
        <form autoComplete="off">
          {items?.map((section, index) => {
            return (
              <>
                {section?.fields?.text?.value && (
                  <RichText
                    field={section.fields.text}
                    tag="p"
                    className="content"
                  />
                )}
                <div className={`form-section form_${index}`}>
                  <DynamicForm
                    formData={section?.fields?.items}
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    values={data}
                    control={control}
                    validateOnLoad={false}
                    t={t}
                  />
                </div>
                {section?.fields?.showLineBar?.value && (
                  <div className="seperatorLine" />
                )}
              </>
            );
          })}
          <Button
            text={ctaText?.value ? ctaText?.value : t('save-changes')}
            cssClass="orange-bg"
            handleButtonClick={handleSubmit(transform(onSubmit))}
            isLoading={apiLoading}
          />
        </form>
      )}
    </div>
  );
};

ConsentForm.defaultProps = {
  fields: {},
};

ConsentForm.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired,
};

/**
 * @description - Map reducer state to prop.
 * @param {*} state - State value.
 * @returns {object} - State object
 */
const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(connect(mapStateToProps)(ConsentForm));
