/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getTitle } from '../../../../utils/helperUtils';

/**
 * @description - Accordion Section component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const SearchAccordionSection = ({
  onClick,
  id,
  title,
  isOpen,
  focus,
  filterContent,
  t,
  sitecoreContext,
  setOpenSession,
  selected = {},
  sortOption,
  defaultValues = {},
  icons,
}) => {
  const focusRef = useRef(null);
  const {
    industry = {},
    specialization = {},
    contract = {},
    distance = {},
  } = defaultValues;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
  const [expanded, setExpanded] = useState(false);

  let { iconTooltip } = {};

  const setIcons = () => {
    if (Array.isArray(icons)) {
      icons.forEach((iconItem) => {
        switch (iconItem?.itemName) {
          case 'Disability Tooltip':
            iconTooltip = iconItem.icon;
            break;
          default:
            break;
        }
      });
    }
  };

  setIcons();

  /**
   * @description - to handle accordian click
   * @returns {undefined}
   */
  const handleClick = () => {
    onClick(id);
  };

  /**
   * @description - to handle accordian toggle
   * @param {*} event - click eventി
   * @returns {undefined} - nothing
   */
  const handleToggleClick = (event) => {
    if (event.keyCode === 13) {
      onClick(id);
    }
  };

  useEffect(() => {
    if (focus && focusRef.current) {
      focusRef.current.focus();
    }
  });

  //adding intial filter state to array
  useEffect(() => {
    setOpenSession((prevState) => ({ ...prevState, ...{ [id]: isOpen } }));
  }, []);

  return (
    <div
      className={'accordion-block ' + (isOpen ? 'active' : '')}
      role="listitem"
      id={`accordion${id}`}
    >
      <div
        className="icon"
        onKeyDown={handleToggleClick}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-label={`toggle accordian ${t(getTitle(title))}`}
        aria-expanded={isOpen}
        ref={focus ? focusRef : null}
        onBlur={() => {
          focusRef.current = null;
        }}
      ></div>
      <h3>
        <div
          className={`accordion-title ${expanded ? 'show-tooltip' : ''}`}
          onClick={handleClick}
        >
          {`${t(getTitle(title))}${title === 'sortOrder' ? ':' : ''}`}
          {title === 'sortOrder' ? (
            <>
              <div className="sort-order">
                &nbsp;
                {selected?.sortOrder?.[0] !== undefined &&
                selected?.sortOrder?.length > 0
                  ? `${selected?.sortOrder?.[0]?.value}`
                  : sortOption && `${sortOption}`}
              </div>
            </>
          ) : (
            ''
          )}
          {Object.keys(selected)?.map((item) =>
            item === title &&
            selected?.[item].length > 0 &&
            selected?.[item][0]?.value !== industry?.value &&
            selected?.[item][0]?.value !== specialization?.value &&
            selected?.[item][0]?.id !== distance?.value &&
            selected?.[item][0]?.value !== contract?.value ? (
              <div className={`filter-dot  ${brandName}`}></div>
            ) : (
              ''
            )
          )}
          {title === 'showDisabilityIcon' ? (
            <div
              className="info-icon"
              role="button"
              tabIndex={0}
              onMouseOver={() => setExpanded(true)}
              onFocus={() => setExpanded(true)}
              onMouseLeave={() => setExpanded(false)}
            >
              <img src={mediaApi.updateImageUrl(iconTooltip)} alt="Tooltip icon" />
            </div>
          ) : (
            ''
          )}
        </div>
      </h3>
      {expanded && (
        <div className="disability-tooltip" id="disability-tooltip" role="tooltip">
          <div className="disability-dropdown-inside">
            <div className="triangle"></div>
            {t('disable-tooltip-content')}
          </div>
        </div>
      )}
      {filterContent && (
        <div className="accordion-content clearfix">{filterContent}</div>
      )}
    </div>
  );
};

SearchAccordionSection.defaultProps = {
  isOpen: '',
  id: '',
  title: { value: '' },
  content: { value: '' },
};
SearchAccordionSection.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  content: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
};

export default withTranslation()(SearchAccordionSection);
