/* eslint-disable max-lines-per-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { setAltUrlForImg } from '../../../utils/helperUtils';
import { Text, Link as JssLink, Image } from '@sitecore-jss/sitecore-jss-react';
import './RomaView.scss';

/**
 * @description _ RomaView Component.
 * @param {object} props - Props passed.
 * @returns {Node} - HTML node.
 */
const RomaView = ({ fields }) => {
  const [focused, setFocused] = useState(false);

  return (
    <React.Fragment>
      <div
        className={`main-nav-item login ${focused ? 'link-focused' : ''} `}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setFocused(false);
          }
        }}
        onMouseOver={() => setFocused(true)}
        onMouseLeave={() => setFocused(false)}
      >
        <a
          href="#"
          aria-label="Login"
          aria-haspopup="true"
          aria-expanded={focused}
          aria-controls="dd-menu"
        >
          <span>
            <div className="user-icon"></div>
            <div className="login__text">
              <Text field={fields.title} />
            </div>
          </span>
        </a>
        <div
          className={`main-nav-dropdown login-dropdown sweden`}
          style={{ display: !focused ? 'none' : 'block' }}
        >
          <div className="main-nav-dropdown-inside-se" id="dd-menu">
            <div className="triangle login-triangle"></div>
            {fields && fields.items && fields.items.length
              ? fields.items.map((item, indx) => (
                  <div key={item.id} className="dropdown-se-options">
                    <Fragment>
                      {item?.fields?.ctaUrl?.value?.linktype === 'external' ? (
                        <JssLink
                          field={item?.fields?.ctaUrl}
                          className={`login-option ${item?.fields?.color?.value}`}
                          editable={false}
                        >
                          <Image
                            className="icon-login"
                            field={setAltUrlForImg(
                              item?.fields?.image,
                              'login-icon'
                            )}
                          />
                          <Text
                            field={item?.fields?.ctaText}
                            className={item?.fields?.color}
                          />
                        </JssLink>
                      ) : (
                        <Link
                          to={item?.fields?.ctaUrl?.value?.href}
                          className={`login-option ${item?.fields?.color?.value}`}
                        >
                          <Image
                            className="icon-login"
                            field={setAltUrlForImg(
                              item?.fields?.image,
                              'login-icon'
                            )}
                          />
                          <Text field={item?.fields?.ctaText} />
                        </Link>
                      )}
                      {indx == 0 ? <div className="section-line"></div> : null}
                    </Fragment>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

RomaView.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isMobile: PropTypes.bool.isRequired,
};

RomaView.defaultProps = {
  fields: {
    items: [],
  },
};

export default withTranslation()(RomaView);
