/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  RichText,
  Image,
  mediaApi,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getImg } from '../../../utils/helperUtils';
import ButtonLink from '../../core/globals/link/ButtonLink';
import ComponentSettings from '../globals/ComponentSettings';
import './page-banners.scss';

/**
 * @description - Mobile App Page Banner component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const MobileAppBanner = (props) => {
  const { fields, sitecoreContext, rendering } = props;
  const {
    title,
    subTitle,
    text,
    //ctaUrl,
    backgroundImage,
    foregroundImage,
    items,
    parentPageLink,
  } = fields;
  const data = ComponentSettings(rendering);
  const compId = rendering?.params?.anchorLinkId || 'mobile-app-banner';
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';

  const styleObj = {
    backgroundImage: backgroundImage
      ? `url(${mediaApi.updateImageUrl(getImg(backgroundImage))})`
      : null,
  };

  return (
    <section
      className={`banner-section insights app ${brandName} ${data?.cssClass}`}
      id={`${compId}`}
      style={{
        background: data?.bgColor ? data?.bgColor : '',
      }}
    >
      <div
        className={`${
          countryName === 'poland'
            ? 'banner-section__overlay__pl'
            : 'banner-section__overlay'
        }`}
        style={{ ...styleObj }}
      ></div>

      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-lg-1 order-2">
            <div className="content-wrapper">
              {subTitle &&
                (parentPageLink?.value.href ? (
                  <Link to={parentPageLink?.value?.href}>
                    <div
                      className="sub-title reversed"
                      style={{ color: data?.fontColor }}
                    >
                      <Text field={subTitle} />
                    </div>
                  </Link>
                ) : (
                  <div
                    className="sub-title reversed"
                    style={{ color: data?.fontColor }}
                  >
                    <Text field={subTitle} />
                  </div>
                ))}
              {title && (
                <h1
                  className="title reversed"
                  aria-describedby="banner-description"
                  style={{ color: data?.fontColor }}
                >
                  <Text field={title} />
                </h1>
              )}

              {text && (
                <RichText
                  field={text}
                  className="large reversed"
                  tag="p"
                  id="banner-description"
                  style={{ color: data?.fontColor }}
                />
              )}
              <div className="cta-wrapper">
                {items?.map((item) => {
                  return item?.fields?.svgImage?.value?.src ? (
                    <a
                      href={item?.fields?.url?.value?.href}
                      aria-label="Go to home"
                      target={item?.fields?.url?.value?.target}
                      rel="noopener noreferrer nofollow"
                      title="App Store"
                      className="utility-icon"
                    >
                      <Image
                        field={item?.fields?.svgImage}
                        className="icon-badges"
                      />
                    </a>
                  ) : (
                    <ButtonLink
                      cssClass="reversed"
                      ctaText={item?.fields?.ctaText}
                      ctaUrl={item?.fields?.url}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-lg-2 order-1 image-wrapper">
            {foregroundImage?.value?.src && (
              <Image
                className="banner-image"
                editable={true}
                field={foregroundImage}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

MobileAppBanner.defaultProps = {
  fields: {
    title: { value: '' },
    subTitle: { value: '' },
    text: {
      value: '',
    },
    ctaText: { value: '' },
    ctaUrl: { value: null },
    backgroundImage: {
      value: { src: '' },
    },
    foregroundImage: {
      value: { src: '' },
    },
    color: {
      value: '',
    },
    parentPageLink: { value: null },
  },
  sitecoreContext: {},
  rendering: {},
  t: PropTypes.func.isRequired,
};

MobileAppBanner.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    subTitle: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: PropTypes.shape({ value: PropTypes.string }),
    ctaUrl: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
    backgroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    foregroundImage: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
    }),
    color: PropTypes.shape({ value: PropTypes.string }),
    parentPageLink: PropTypes.shape({
      value: PropTypes.shape({
        href: PropTypes.string,
      }),
    }),
  }).isRequired,
  sitecoreContext: {},
  rendering: {},
  t: PropTypes.func.isRequired,
};
export default withTranslation()(withSitecoreContext()(MobileAppBanner));
