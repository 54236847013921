/* eslint-disable max-lines-per-function */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { useLocation } from 'react-router-dom';
import Table from '../../../../core/Table';
import { useFetchData, usePostData } from '../../../../../hooks/http-client';
import { ACCOUNT_SETTINGS, API_STATUS_CODES } from '../../../../../constants';
import Button from '../../../../core/globals/buttons/Button';
import AlertPopUp from '../../../../core/ErrorBoundary/AlertPopUp';
import AlertInline from '../../../../core/ErrorBoundary/AlertInline';
import '../../../Dashboard/dashboard.scss';
/**
 * @description - Communication Preference component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const CommunicationPreference = (props) => {
  const { t, icon, fields } = props;
  const { frequency } = fields;
  const { load, errorMsg } = useFetchData();
  const { write, error } = usePostData();
  const [alertList, setAlertList] = useState([]);
  const [response, setResponse] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [delFunc, setDelFunc] = useState(null);
  const [message, setMessage] = useState({});
  const [updateProgress, setUpdateProgress] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);

  const location = useLocation();
  const contactId = new URLSearchParams(location.search).get('contactId');
  useEffect(() => {
    if (error) {
      setDataLoading(false);
      setMessage({
        ...message,
        ...{ type: 'error', message: t('http-error') },
      });
    }
  }, [error]);

  useEffect(() => {
    if (errorMsg) {
      setDataLoading(false);
    }
  }, [errorMsg]);

  /**
   * @description to handle alert pop up
   * @returns {undefind} no return
   */
  const handleModal = () => {
    setShowModal(!showModal);
  };

  /**
   * @description to update alert frquency
   * @param {string} value - new frequency
   * @param {string} id - job alert id
   * @returns {undefined} - no return
   */
  const handleAlertUpdate = (value, id) => {
    response.map((alertItem) => {
      if (alertItem.alertID === id) {
        alertItem.frequencyType = value;
      }
      return alertItem;
    });
    prepareTableRows(response);
  };

  /**
   * @description to delete job alert
   * @param {number} id - job alert id
   * @returns {undefined} no returns
   */
  const handleDelete = (id, contactId) => {
    setMessage(null);
    write(
      `${ACCOUNT_SETTINGS.COMMUNICATION_PREFERENCES.DELETE_ALERT_API}?alertID=${id}&contactId=${contactId}`,
      null,
      handleDeleteResponse
    );
  };

  /**
   *
   * @param {object} response - api response
   * @returns {undefined} - no returns
   */
  const handleDeleteResponse = (response) => {
    if (response.data.status === API_STATUS_CODES.SUCCESS) {
      getAlerts(contactId);
    }
  };

  const columns = [
    {
      selector: 'title',
      grow: 2,
      cell: (row) => {
        return <div className="alert-title">{row.title}</div>;
      },
    },
    {
      selector: 'view',
      right: true,
      cell: (row) => {
        return (
          <div className="view-or-remove">
            <Dropdown>
              <Dropdown.Toggle className="btn-dp-down" id="dropdown-basic">
                {frequency?.map((item) => {
                  if (item?.fields?.key?.value === row.view) {
                    return item?.fields?.label?.value?.toUpperCase();
                  }
                })}
                <div className="drop-icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {frequency?.map((item, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        handleAlertUpdate(item?.fields?.key?.value, row.id);
                      }}
                    >
                      {item?.fields?.label?.value?.toUpperCase()}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <div className="vertical-seperator" />
            <button
              className="remove-cell"
              onClick={() => {
                setDelFunc(row.id);
                handleModal();
              }}
            >
              {t('remove')}
            </button>
          </div>
        );
      },
    },
  ];

  /**
   * @description - callback for successful api reponse
   * @param {object} response - api response object
   * @returns {undefined} - no returns
   */
  const setData = (response) => {
    setDataLoading(false);
    setResponse(response.data.jobAlerts);
    prepareTableRows(response.data.jobAlerts);
  };

  /**
   *
   * @param {array} alertList - list of alert items
   * @returns {undefined} - no return
   */
  const prepareTableRows = (alertList = []) => {
    const data = [];
    alertList.forEach((alerts, index) => {
      data[index] = {
        title: alerts.jobAlertName,
        view: alerts.frequencyType,
        id: alerts.alertID,
      };
    });
    setAlertList(data);
  };

  /**
   * @description get alert list
   * @returns {undefined} nothing
   */
  const getAlerts = (contactId) => {
    load(
      `${ACCOUNT_SETTINGS.COMMUNICATION_PREFERENCES.GET_ALERT_API}?contactId=${contactId}`,
      null,
      setData
    );
  };

  /**
   * @description to save alert changes
   * @returns {undefined} - nothing
   */
  const handleSave = () => {
    setMessage(null);
    setUpdateProgress(true);
    write(
      ACCOUNT_SETTINGS.COMMUNICATION_PREFERENCES.UPDATE_JOB_ALERT_API,
      {
        contactId: contactId,
        jobAlerts: response,
      },
      handleSaveResponse
    );
  };

  /**
   * to handle save response
   * @param {object} apiresponse - alert update api response
   * @returns {undefined} - no returns
   */
  const handleSaveResponse = (apiresponse) => {
    setUpdateProgress(false);
    setMessage(null);
    if (apiresponse.data.status === API_STATUS_CODES.SUCCESS) {
      setMessage({
        ...message,
        ...{ type: 'success', message: t('job-alert-updated') },
      });
    } else {
      setMessage({
        ...message,
        ...{ type: 'error', message: t('job-alert-update-failed') },
      });
    }
  };

  useEffect(() => {
    getAlerts(contactId);
  }, []);

  return (
    <div className="container communication-container">
      <div className="row justify-content-md-center">
        <div className="col align-self-center">
          <div className="col-wrapper">
            <div className="dashboard-agent dashboard-block communication-preferences">
              <div className="title">{fields?.title?.value} </div>
              <p className="description">{fields?.description?.value}</p>
              <br />
              <Table
                noTableHead={true}
                columns={columns}
                icon={icon}
                row={alertList}
                showHeader={false}
                progressPending={dataLoading}
              />
              <br />
              <div className="btn-container text-center">
                {alertList.length > 0 && (
                  <Button
                    text={t('save-changes')}
                    handleButtonClick={handleSave}
                    isLoading={updateProgress}
                    cssClass="orange-bg"
                  />
                )}
                {message?.type && (
                  <AlertInline
                    message={message?.message}
                    type={message?.type}
                    key={new Date()}
                  ></AlertInline>
                )}
              </div>
            </div>
            <AlertPopUp
              message={t('delete-confirmation-jobalert')}
              btnOkText={t('yes')}
              btnCancelText={t('no')}
              visibility={showModal}
              handleModal={handleModal}
              callback={() => handleDelete(delFunc, contactId)}
            ></AlertPopUp>
          </div>
        </div>
      </div>
    </div>
  );
};
CommunicationPreference.defaultProps = {
  title: 'Saved Jobs',
  t: () => {},
};

CommunicationPreference.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func,
};

export default withTranslation()(CommunicationPreference);
