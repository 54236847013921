import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import TextLink from '../globals/link/TextLink';
import { showString } from '../../../utils/helperUtils';
import { DATE_FORMATS } from '../../../utils/enums';
import './cards.scss';

/**
 * @description - Render button or arraow link.
 * @param {string} mode - Mode for display.
 * @param {string|object} ctaText - CTA name.
 * @returns {Node} - Button or arrow link.
 */
const CardArticleCta = ({ title, subTitle, date, ctaText, ctaUrl, dateFormat }) => (
  <div className="card article-ctascroll card--wipe">
    <span className="card--wipe--apollo" />
    <div className="card-body">
      {subTitle && (
        <div className="sub-title articleCta">
          {subTitle?.value ? <Text field={subTitle} /> : showString(subTitle)}
        </div>
      )}
      <div className="date">{date ? moment(date).format(dateFormat) : ''}</div>
      <div className="title">
        {title?.value ? <Text field={title} /> : showString(title)}
      </div>
    </div>
    <div className="cta__wrap card-articlefooter">
      <TextLink ctaText={ctaText} ctaUrl={ctaUrl} arrow />
    </div>
  </div>
);

CardArticleCta.defaultProps = {
  title: '',
  subTitle: '',
  date: 'January 1, 1001',
  ctaText: 'Read More',
  ctaUrl: '',
  dateFormat: DATE_FORMATS.DEFAULT_US,
  external: false,
};

CardArticleCta.propTypes = {
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  dateFormat: PropTypes.string.isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  date: PropTypes.string.isRequired,
  ctaText: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  ctaUrl: PropTypes.string.isRequired,
  external: PropTypes.bool,
};

export default CardArticleCta;
