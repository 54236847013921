/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import ModalComponent from '../Modal';
import Accordion from './Accordion';
import TypeAheadDropDownAdvanced from '../globals/dropdown/TypeAheadDropDownAdvanced';
import { isNullorEmpty, stickyHeader } from '../../../utils/helperUtils';

import './siteheaderlanguage.scss';

/**
 * @description - to show countrylinks
 * @param {*} param0
 * @returns {HTML} - countrylinks
 */
const SiteHeaderLanguage = (props) => {
  const { linkItems, linkItemsManpower, t, sitecoreContext } = props;
  const { items, selected } = linkItems;
  const [showModal, setShowModal] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [showAccordion, setShowAccordion] = useState(false);
  const [cookies, setCookie] = useCookies(['first-visit']);
  const country_code = sitecoreContext?.Country?.code;

  useEffect(() => {
    if (showModal) {
      stickyHeader(document.querySelector('.site-header'));
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showModal]);

  useEffect(() => {
    prepareCountryLinks();
  }, [items]);

  const prepareCountryLinks = () => {
    let countries = [];
    const itemList = [];
    Array.isArray(items) &&
      items.forEach((region) => {
        countries = [...countries, ...region?.countries];
      });

    countries.forEach((country, index) => {
      const text = (
        <div key={index}>
          <span>{country?.countryName}</span>
          {country.languages.map((language, index) => {
            // to change to browser language
            switchToUserLang(language, navigator.language || navigator.userLanguage);
            return (
              <>
                {'|'}
                <a
                  href="#"
                  key={index}
                  onClick={() => {
                    language?.redirectURL?.includes(window?.location?.hostname)
                      ? (window.location = language?.redirectURL)
                      : window.open(language?.redirectURL);
                  }}
                  target={`${
                    language?.redirectURL?.includes(window?.location?.hostname)
                      ? ''
                      : '_blank'
                  }`}
                >
                  {language?.name}
                </a>
              </>
            );
          })}
        </div>
      );

      itemList.push({
        value: country?.countryName,
        text: text,
      });
    });
    setCountryList(itemList);
  };

  /**
   * @description to redirect to browser language selected
   * @returns {undefined} - no returns
   */
  const switchToUserLang = (language, userLanguage = '') => {
    if (
      userLanguage.toLowerCase() === language?.isoCode?.toLowerCase() &&
      !cookies['first-visit'] & (window.location.pathname === '/') && // user visiting home page first time
      userLanguage.toLowerCase() !== sitecoreContext?.languageISO?.toLowerCase() &&
      language?.redirectURL?.includes(window?.location?.hostname)
    ) {
      setCookie('first-visit', 'true', { path: '/' });
      window.location = language?.redirectURL;
    }
  };

  const doShowAccordion = () => setShowAccordion(true);
  const doHideAccordion = () => setShowAccordion(false);

  /**
   * to check mobile view or not
   * @returns {Boolean}
   */
  const isMobile = () => {
    let mobileSlider = document.querySelector('.mobile--actives');
    let styles = getComputedStyle(mobileSlider);
    return styles.display == 'none' ? true : false;
  };

  /**
   * @description to show job alert dialog
   * @returns {undefined} - nothing
   */
  const handleModal = () => {
    setShowModal(!showModal);
    // hide sidebar in mobile view
    if (isMobile()) {
      document.getElementsByClassName('close-text')[0]?.click();
    }
  };

  /**
   * to handle click outside modal
   */
  const handleClickOutside = (event) => {
    if (!document.querySelector('.modal')?.contains(event.target)) {
      setShowModal(false);
    }
  };

  return (
    <div className="country-links">
      <div className="lang-nav">
        {linkItemsManpower?.length > 0 &&
          linkItemsManpower.map(
            (item, index) =>
              !isNullorEmpty(item?.fields?.ctaText?.value) && (
                <div className="link_title">
                  <a
                    id={index}
                    aria-label={
                      item?.fields?.ctaText?.value + ', opens in a new window/tab'
                    }
                    href={item?.fields?.ctaUrl?.value?.href}
                    target={item?.fields?.ctaUrl?.value?.target}
                  >
                    {item?.fields?.ctaText?.value}
                  </a>
                </div>
              )
          )}
        <div className="language-wrapper">
          <button
            className="country"
            onClick={() => handleModal()}
            aria-label="(Manpower-Global) pop up opens"
          >
            <div className="title">
              {Array.isArray(selected) &&
                selected.length > 0 &&
                selected[0]?.country}
            </div>
            {Array.isArray(selected) &&
              selected.length > 0 &&
              '(' + selected[0]?.language + ')'}
          </button>
        </div>
      </div>

      <ModalComponent
        showModal={showModal}
        handleModal={handleModal}
        className="fullscreen"
        backdrop={false}
        removeIndex={true}
      >
        <div key="header" className="container">
          <div onClick={() => handleModal()} className="close">
            {'close'}
          </div>
        </div>
        <div key="body">
          <div className="country-search">
            <TypeAheadDropDownAdvanced
              items={countryList}
              textField="text"
              valueField="value"
              label={t('country-links-title')}
              floatingLabel={t('country-links-placeholder')}
              seletedItem={
                Array.isArray(selected) && selected.length > 0
                  ? selected[0]?.country
                  : ''
              }
              onItemSelect={() => {}}
              brandName={'manpower'}
              dropdown={true}
              onClear={doShowAccordion}
              onSearch={doHideAccordion}
              noDataMessage={t('countrylinks-no-result-found')}
            />
          </div>
          <Accordion
            linkItems={linkItems}
            showAccordion={showAccordion}
            doShowAccordion={doShowAccordion}
          />
        </div>
      </ModalComponent>
    </div>
  );
};

SiteHeaderLanguage.defaultProps = {
  linkItems: {},
  linkItemsManpower: {},
  t: () => {},
};

SiteHeaderLanguage.propTypes = {
  linkItems: PropTypes.shape({}),
  linkItemsManpower: PropTypes.shape({}),
  t: PropTypes.func,
};

export default withTranslation()(SiteHeaderLanguage);
