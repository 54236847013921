/*eslint-disable*/
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, connect } from 'react-redux';
import { toast } from 'react-toastify';
import useStateRef from 'react-usestateref';
import {
  saveFormActions,
  saveProfileInfoForm,
  saveProfileInfoFormName,
  saveProfileInfoFormNameIfFormValid,
  saveFormSubmitting,
  saveFormData,
} from '../../../../../redux/actions/actions';
import DynamicForm from '../../../../core/Forms/DynamicForm';
import TextButton from '../../../../core/globals/buttons/TextButton';
import Button from '../../../../core/globals/buttons/Button';
import {
  traverseAndDataExist,
  isObjNotEmpty,
  JSONParser,
} from '../../../../../utils/helperUtils';
import { formObjIteration } from '../../../Utils/helper';
import { dataFetcher } from '../../../../../dataFetcher';
import { API } from '../../../../../constants';
import Loader from '../../../../core/ErrorBoundary/Loader';
import { BuildProfileContext } from '../..';
import { saveCandidateProfile } from '../../../../../services/apiServices/candidateService';
import { buildProfileItems } from '../../../../../utils/enums';

/**
 * @description - MultipleForm component.
 * @param {Object} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const MultipleForm = ({ inputRef, ...props }) => {
  const {
    profileInfo,
    fields,
    t,
    handleClick,
    accordionId,
    lastAccordionIndex,
    profileInfoFormData,
    formData,
    getEmptyFields,
    componentName,
    setLoader,
  } = props;

  const {
    buttonText,
    saveEndPoint,
    apiList,
    apiKeyMapper,
    isContentLoaderRequired,
  } = fields;

  const {
    register,
    handleSubmit,
    getValues,
    errors,
    setValue,
    control,
    formState,
    trigger,
    watch,
    setError,
    clearErrors,
    reset,
    unregister,
  } = useForm({
    mode: 'onChange',
    revalidate: 'onChange',
    shouldFocusError: true,
    defaultValues: {},
  });

  const formSubmitRef = useRef();
  const dispatch = useDispatch();
  const [newFieldArr, setFieldArr] = useState([]);
  const [data, setData] = useState([]);
  const [fileUpload, setFileUpload, fileUploadRef] = useStateRef({});
  const [fieldList, setFieldList, fieldListRef] = useStateRef([]);
  const [deletedList, setDeletedList, deletedListRef] = useStateRef([]);
  const [triggered, setTriggered, triggeredRef] = useStateRef(false);
  const [isDelete, setUploadDelete] = useState(false);
  const [isUpload, setUploadStatus] = useState(false);
  const [isParse, setParse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, showFormError] = useState(false);
  const [isClear, setIsCleared] = useState(false);
  const [saveClickStatus, setSaveClickStatus] = useState(false);
  const [fieldLength, setFieldLength] = useState(0);
  const [apiCollection, setApiCollection] = useState({});
  const [apiCalledCount, setApiCalledCount] = useState(0);
  const { isValid, isDirty, touched, isValidating } = formState;
  const value = useContext(BuildProfileContext);
  const mandatorySection = value?.mandatorySection;
  const [candidateId, setCandidateId] = useState('');
  const apiEndPoints = apiList?.value?.split(',')?.filter((el) => el !== '') || [];
  const apiMapper = apiKeyMapper?.value
    ? apiKeyMapper?.value
    : buildProfileItems[componentName];

  const buttonTextJSON = buttonText?.value ? JSONParser(buttonText?.value) : {};

  useEffect(() => {
    if (
      isContentLoaderRequired?.value &&
      mandatorySection?.includes(componentName)
    ) {
      setLoader(true);
    }
    if (apiEndPoints && apiEndPoints?.length > 0) {
      triggerApiCollection();
    }
  }, []);

  useEffect(() => {
    let temp = [...newFieldArr];
    temp = temp.filter((item) => item !== undefined);
    setFieldLength(temp.length);
  }, [newFieldArr]);

  const triggerApiCollection = () => {
    let count = 0;
    let collectionObj = {};
    apiEndPoints.map((api) => {
      dataFetcher(`${API.HOST}${getApi(api)}`).then(
        (response) => {
          collectionObj = response?.data && {
            ...collectionObj,
            [getApi(api)]: response?.data,
          };
          count++;
          setApiCollection(collectionObj);
          setApiCalledCount(count);
        },
        () => {
          collectionObj = { ...collectionObj, [getApi(api)]: [] };
          count++;
          setApiCollection(collectionObj);
          setApiCalledCount(count);
        }
      );
    });
  };

  useEffect(() => {
    if (apiCalledCount !== 0 && apiCalledCount === apiEndPoints?.length) {
      setLoader(false);
    }
    getEmptyFields({ [componentName]: { isValid, touched, isParse, isClear } });
  }, [isValid, touched, isClear, apiCalledCount, isParse]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        saveFormActions({
          [componentName]: {
            isDirty,
            isValid,
            touched,
            isDelete,
            isClear,
            isUpload,
          },
        })
      );
    }, 20);
  }, [isValid, isValidating, isDirty, touched, isDelete, isClear, isUpload]);

  /**
     * @description - Get APi
     * @param {string} apiEndPoint - endpoint
     * @returns {string} - Formatted data.
  
     */
  const getApi = (apiEndPoint, APIQuerryParams = {}) => {
    let api = apiEndPoint;
    if (
      Object.keys(APIQuerryParams).length > 0 &&
      apiEndPoint &&
      apiEndPoint !== null
    ) {
      Object.keys(APIQuerryParams).forEach((param) => {
        api = api.replace(`{${param}}`, `${param}=${APIQuerryParams[param]}`);
      });
    }
    return api;
  };

  const formReset = () => {
    setData({ data: [] });
    setFieldArr([]);
    reset(
      {},
      {
        touched: false,
        isDirty: false,
        isValid: true,
      }
    );
  };

  const saveFn = (resData) => {
    toast.success(t('details-saved-successfully'), {
      position: 'top-center',
    });
    dispatch(saveFormSubmitting(false));
    setSaveClickStatus(false);
    setFileUpload({});
    setFieldList([]);
    setDeletedList([]);
    setUploadDelete(false);
    setUploadStatus(false);
    setIsCleared(false);
    formReset();
    dispatch(
      saveFormData({
        ...formData.data,
        ...{
          [apiMapper]: {
            data: resData?.[componentName] || [],
            formCount: resData?.[componentName]?.length || 0,
          },
        },
      })
    );
    dispatch(
      saveProfileInfoForm({
        ...profileInfoFormData.data,
        [componentName]: undefined,
        [`Deleted${apiMapper}`]: undefined,
        DeletedFiles: undefined,
      })
    );
    setTimeout(() => {
      setFieldArr(new Array(resData?.[componentName]?.length).fill(fields?.items));
      setData({
        data: resData?.[componentName] || [],
        attachment: resData?.['attachments'] || [],
      });
    }, 100);
    if (lastAccordionIndex !== accordionId) handleClick(parseInt(accordionId) + 1);
  };

  const showError = (msg) => {
    toast.error(t(msg), {
      position: 'top-center',
    });
    setSaveClickStatus(false);
    dispatch(saveFormSubmitting(false));
  };

  const onSubmit = (inputData) => {
    const postData = {
      ...inputData,
      files: isObjNotEmpty(profileInfoFormData?.data?.files)
        ? { ...profileInfoFormData?.data?.files, ...fileUploadRef.current }
        : fileUploadRef.current,
      DeletedFiles:
        profileInfoFormData?.data?.DeletedFiles?.length > 0
          ? [
              ...new Set([
                ...profileInfoFormData?.data?.DeletedFiles,
                ...fieldListRef.current,
              ]),
            ]
          : fieldListRef.current.length > 0
          ? fieldListRef.current
          : undefined,
      [`Deleted${apiMapper}`]:
        profileInfoFormData?.data?.[`Deleted${apiMapper}`]?.length > 0
          ? [
              ...new Set([
                ...profileInfoFormData?.data?.[`Deleted${apiMapper}`],
                ...deletedListRef.current,
              ]),
            ]
          : deletedListRef.current?.length > 0
          ? deletedListRef.current
          : undefined,
    };
    if (triggeredRef.current) {
      setTriggered(false);
      //Copy of profile Reducer with updated form values
      dispatch(
        saveFormData({
          ...formData.data,
          ...{
            [apiMapper]: {
              data: inputData || [],
              formCount: inputData?.length || 0,
            },
          },
        })
      );
      dispatch(
        saveProfileInfoForm({
          ...profileInfoFormData.data,
          ...postData,
        })
      );
      dispatch(saveProfileInfoFormNameIfFormValid(componentName));
    } else {
      if (
        (isObjNotEmpty(touched) && isValid) ||
        profileInfoFormData?.parse ||
        isDelete ||
        isClear
      ) {
        dispatch(saveFormSubmitting(true));
        setSaveClickStatus(true);
        saveCandidateProfile(
          saveEndPoint?.value,
          postData,
          fileUploadRef.current,
          saveFn,
          showError
        );
      } else {
        toast.error(t('no-changes'), {
          position: 'top-center',
        });
      }
    }
  };

  const transform = (onSubmitFunc) => (data) => {
    let formattedData = Object.assign({}, data);
    formattedData = formObjIteration(data);
    formattedData[componentName] = formattedData?.[componentName]?.filter(
      (exp) => exp !== null
    );
    onSubmitFunc(formattedData);
  };

  useEffect(() => {
    if (profileInfo?.Id) {
      formReset();
      if (profileInfo?.[apiMapper]?.formCount > 0) {
        setTimeout(() => {
          setCandidateId(profileInfo?.Id);
          setData({
            data: profileInfo?.[apiMapper]?.data || [],
            attachment: profileInfo?.AttachmentDetails,
          });
          setFieldArr(
            new Array(profileInfo?.[apiMapper]?.formCount).fill(fields?.items)
          );
        }, 100);
        if (profileInfoFormData?.parse) {
          setParse(true);
          showFormError(true);
        }
      } else {
        setData({ data: [] });
        setFieldArr(new Array(fields?.items));
      }
    }
  }, [profileInfo]);

  /**
   * @description - Add button click handler function.
   * @returns {undefined}.
   */
  const _handleAddItem = () => {
    const items = [...fields?.items];
    setFieldArr([...newFieldArr, items]);
  };

  const deleteUploadedFile = (temp, id, data) => {
    let item = '';
    temp.map((item) => {
      if (item.fields.inputType.value === 'FileUpload') {
        item = item.fields.name.value;
        return;
      }
    });
    const deletedKey = `${componentName}[${id}].[${item}]`;
    const fileList = fileUploadRef.current;
    if (fileUploadRef.current.hasOwnProperty(deletedKey)) {
      delete fileList[deletedKey];
      setFileUpload(fileList);
    } else {
      data &&
        item &&
        data[item].map((fileItem) => {
          if (fileItem?.id) {
            setFieldList([...fieldListRef.current, fileItem?.id]);
          }
        });
    }
  };

  const _clearItem = (idx) => {
    let idList = data?.data[idx]?.id
      ? [...deletedListRef.current, data?.data[idx]?.id]
      : deletedListRef.current;
    setDeletedList(idList);
    // assigning the list to temp variable
    const temp = [...newFieldArr];
    deleteUploadedFile(temp[idx], idx, data[idx]);
    formReset();
    setLoading(true);
    setTimeout(() => {
      setFieldArr(new Array(fields?.items));
      showFormError(mandatorySection?.includes(componentName));
      setLoading(false);
      setIsCleared(true);
    }, 10);
  };

  const _handleRemoveItem = (idx) => {
    if (data?.data[idx]?.id)
      setDeletedList([...deletedListRef.current, data?.data[idx]?.id]);
    // assigning the list to temp variable
    const temp = [...newFieldArr];
    deleteUploadedFile(temp[idx], idx, data[idx]);
    delete temp[idx];
    // updating the list
    setFieldArr(temp);
    setUploadDelete(true);
  };

  const handleNext = () => {
    if (isClear && !isDirty) {
      onSubmit({});
    } else {
      showFormError(true);
      externalClick();
    }
  };

  const externalClick = () => {
    let event;
    if (typeof Event === 'function') {
      event = new Event('submit', { cancelable: true });
    } else {
      event = document.createEvent('Event');
      event.initEvent('submit', false, { cancelable: true });
    }
    if (isClear && !isDirty) {
      onSubmit({});
    } else {
      formSubmitRef?.current?.dispatchEvent(event);
    }
  };

  useImperativeHandle(inputRef, () => ({
    trigger: () => {
      setTriggered(true);
      setTimeout(() => {
        dispatch(saveProfileInfoFormName(componentName));
      }, 50);
      externalClick();
    },
    invalidTrigger: () => {
      showFormError(true);
      handleClick(parseInt(accordionId));
    },
  }));

  const handleUpload = (fileData, name) => {
    const fileList = fileUploadRef.current;
    const obj = { [name]: fileData };
    if (fileUploadRef.current.hasOwnProperty(name)) {
      delete fileList[name];
    }
    setFileUpload({ ...fileList, ...obj });
    setUploadStatus(true);
  };

  const handleDelete = (id, name, index) => {
    if (fileUploadRef.current.hasOwnProperty(name)) {
      const temp = fileUploadRef.current[name];
      delete temp[index];
      const newtemp = temp?.filter((item) => item);
      setFileUpload({ ...fileUploadRef.current, [name]: newtemp });
    }
    if (id) {
      setFieldList([...fieldListRef.current, id]);
    }
    setUploadDelete(true);
  };

  const handleChange = () => {
    setUploadDelete(true);
    showFormError(true);
  };

  const contentLoading = (val) => {
    if (mandatorySection?.includes(componentName)) {
      setLoader(val);
    }
  };

  return (
    <div className="personalInfo">
      <div className="build-profile multi-form">
        <div className="text">{fields?.subTitle?.value}</div>
        <section className="forms signinup">
          {loading && (
            <Loader
              loaderMsg={''}
              loaderWithMsg
              customLoaderStyle={{
                width: '4rem',
                height: '4rem',
                color: '#C25700',
              }}
              customTextStyle={{ color: '#C25700' }}
            />
          )}
          <form
            autoComplete="off"
            onSubmit={handleSubmit(transform(onSubmit))}
            ref={formSubmitRef}
          >
            {newFieldArr.map((item, idx) => {
              if (item) {
                return (
                  <div className="formBlock" key={`item_${idx}`} id={`item_${idx}`}>
                    <div className="form-text">{fields?.formText?.value}</div>
                    <div className="personal-info-form">
                      <DynamicForm
                        customIndex={idx}
                        formName={componentName}
                        formData={item}
                        register={register}
                        errors={formError || isObjNotEmpty(touched) ? errors : ''}
                        handleChange={handleChange}
                        getValues={getValues}
                        values={{ ...data, data: data?.data?.[idx] }}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                        control={control}
                        trigger={trigger}
                        watch={watch}
                        validateOnLoad={true}
                        handleUpload={handleUpload}
                        handleDelete={handleDelete}
                        apiCollection={apiCollection}
                        apiList={apiList?.value}
                        formState={formState}
                        APIQuerryParams={{ cid: candidateId }}
                        unregister={unregister}
                        contentLoading={contentLoading}
                      />
                      <div className="remove-btn">
                        {fieldLength === 1 ? (
                          <TextButton
                            cssClass="blue"
                            text={`- ${buttonTextJSON?.clear}`}
                            handleTextClick={() => _clearItem(idx)}
                          />
                        ) : (
                          <TextButton
                            cssClass="blue"
                            text={`- ${buttonTextJSON?.remove}`}
                            handleTextClick={() => _handleRemoveItem(idx)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div className="add-form">
              <TextButton
                cssClass={`blue ${!isValid && 'grey-disabled'}`}
                text={buttonTextJSON?.add}
                isDisabled={!isValid}
                handleTextClick={handleSubmit(transform(_handleAddItem))}
              />
            </div>
          </form>
          <Button
            text={t('save-section')}
            cssClass={`${
              isValid || (!isDirty && isClear) ? 'button-enabled' : 'button-disabled'
            }`}
            handleButtonClick={handleNext}
            isLoading={saveClickStatus}
            isDisabled={saveClickStatus}
          />
        </section>
      </div>
    </div>
  );
};

MultipleForm.defaultProps = {
  fields: {},
  profileInfo: {},
  profileInfoFormData: {},
  formData: {},
  profileEmptyFieldsData: {},
  getEmptyFields: () => {},
  setLoader: () => {},
  t: () => {},
};

MultipleForm.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}).isRequired,
  profileInfoFormData: PropTypes.shape({}).isRequired,
  formData: PropTypes.shape({}),
  profileEmptyFieldsData: PropTypes.shape({}).isRequired,
  getEmptyFields: PropTypes.func,
  setLoader: PropTypes.func,
  t: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
    profileEmptyFieldsData: state.profileEmptyFieldsReducer,
    profileInfoFormData: state.profileInfoFormReducer,
    formData: state.formDataReducer,
    buildProfileFormState: state.buildProfileFormReducer,
  };
};

const ConnectedComponent = connect(mapStateToProps)(MultipleForm);
export default forwardRef((props, ref) => {
  return <ConnectedComponent {...props} inputRef={ref} />;
});
