import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TextButton from '../globals/buttons/TextButton';
import './seo-jobs.scss';

/**
 * @description - to show job search links based on category
 * @returns {HTML} - html
 */
const SEOJobsByCategory = ({ fields = {}, t }) => {
  const { items } = fields;
  const { jobCategoryItems, color, label } = items;
  const [jobLimit, setJobLimit] = useState(6);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (window) {
      resizeEvent();
      window.addEventListener('resize', resizeEvent);
    }
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  /**
   * @description to get width on load and resize
   * @param {string} setValue - url
   * @returns {null} - empty
   */
  const resizeEvent = () => {
    if (window) setMobileView(window.innerWidth < 768);
  };

  /**
   * @description to show next set of results
   * @param {string} setValue - url
   * @returns {null} - empty
   */
  const loadMore = () => {
    setJobLimit(jobLimit + 6);
  };

  return (
    <section className={`seo-jobs ${color ? `seo-jobs--${color}` : ``}`}>
      <div className="container">
        <div className="row">
          <h2 className="seo-jobs__category">{label}</h2>
          <div className="col-sm-12 col-md-12 col-xl-12">
            {!mobileView ? (
              <div className="row">
                {jobCategoryItems &&
                  Array.isArray(jobCategoryItems) &&
                  jobCategoryItems?.map((job, index) => {
                    return (
                      <div className="col-sm-6 col-md-3 col-xl-3" key={index}>
                        <div className="seo-jobs__category-item">
                          {job?.linkType === 'external' ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={job?.url}
                            >
                              {job?.title}
                            </a>
                          ) : (
                            <Link to={job?.url}>{job?.title}</Link>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="row">
                {jobCategoryItems &&
                  Array.isArray(jobCategoryItems) &&
                  jobCategoryItems?.slice(0, jobLimit).map((job, index) => {
                    return (
                      <div className="col-sm-6 col-md-3 col-xl-3" key={index}>
                        <div className="seo-jobs__category-item">
                          {job?.linkType === 'external' ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={job?.url}
                            >
                              {job?.title}
                            </a>
                          ) : (
                            <Link to={job?.url}>{job?.title}</Link>
                          )}
                        </div>
                      </div>
                    );
                  })}
                {jobCategoryItems &&
                  jobCategoryItems?.length > 6 &&
                  jobLimit < jobCategoryItems?.length && (
                    <div className="col-sm-6 col-md-3 col-xl-3">
                      <div className="seo-jobs__category-item">
                        <TextButton
                          text={t('seo-see-more')}
                          handleTextClick={loadMore}
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

SEOJobsByCategory.defaultProps = {
  fields: {},
};
SEOJobsByCategory.propTypes = {
  fields: PropTypes.shape({}),
  t: PropTypes.shape({}).isRequired,
};

export default withTranslation()(SEOJobsByCategory);
