import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ButtonLink from '../../../../core/globals/link/ButtonLink';
import AccordionBlock from '../../../Accordion';
import Loader from '../../../../core/ErrorBoundary/Loader';
import Error from '../../../../core/ErrorBoundary/Error';
import { getCandidateDetailsApi } from '../../../../../services/apiServices/candidateService';
import {
  clearProfileInfo,
  clearFormData,
} from '../../../../../redux/actions/actions';

/**
 * @description - DashboardProfileAccordion component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const DashboardProfileAccordion = ({ fields, data, t, profileInfo, dispatch }) => {
  const { text, allowMultipleOpen, ctaText, ctaUrl } = fields;
  const [profileDataLoading, setprofileDataLoading] = useState(true);
  const [percent, setpercent] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (profileInfo?.Id) {
      setprofileDataLoading(false);
      const percentValue = parseInt(profileInfo?.ProfilePercentage);
      setpercent(percentValue);
    } else if (profileInfo?.error) {
      setprofileDataLoading(false);
      setError(true);
    }
  }, [profileInfo]);

  const handleApiRefresh = () => {
    dispatch(clearProfileInfo());
    dispatch(clearFormData());
    setTimeout(() => getCandidateDetailsApi({}, dispatch), 500);
  };

  return (
    <section
      className="profile-accordion-section"
      aria-labelledby="title"
      aria-describedby="info"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            {text?.value && (
              <div className="row">
                <div className="col-md-12">
                  <Text field={text} tag="h1" className="text" id="info" />
                </div>
              </div>
            )}
            {profileDataLoading ? (
              <Loader
                loaderMsg={t('fetching-your-data')}
                loaderWithMsg
                customLoaderStyle={{
                  width: '4rem',
                  height: '4rem',
                  color: '#C25700',
                }}
                customTextStyle={{ color: '#C25700' }}
              />
            ) : error ? (
              <div className="apiError">
                <Error
                  errorMsg={t('error-message')}
                  t={t}
                  refreshApi={true}
                  handleApiRefresh={handleApiRefresh}
                />
              </div>
            ) : (
              <>
                {data && data.length > 0 && percent > 0 && (
                  <>
                    <div className="row">
                      <div className="col">
                        <AccordionBlock
                          allowMultipleOpen={
                            allowMultipleOpen?.value
                              ? allowMultipleOpen.value
                              : false
                          }
                          data={data}
                          showExpandButton={true}
                          t={t}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <ButtonLink
                          cssClass="orange"
                          cssArrowClass="styleArrow"
                          ctaText={ctaText}
                          ctaUrl={ctaUrl}
                          externalLink={
                            ctaUrl?.value?.linktype === 'internal' ? false : true
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

DashboardProfileAccordion.defaultProps = {
  t: (val) => val,
  fields: {},
  profileInfo: {},
  dispatch: () => {},
  data: [],
};

DashboardProfileAccordion.propTypes = {
  t: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  profileInfo: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.arrayOf({}).isRequired,
};

const mapStateToProps = (state) => {
  return {
    profileInfo: state.profileInfoReducer.data,
  };
};

export default withTranslation()(
  connect(mapStateToProps)(DashboardProfileAccordion)
);
