import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import AccordionItem from './AccordionItem';
import '../Accordion/accordion.scss';
import './seoHyperlinks.scss';

/**
 * @description - to render accordion
 * @param {*} param0 -Input props.
 * @returns {HTML} -  accordion html
 */
const SEOAccordion = ({ offset, linkItems, accordionMode, cssClass }) => {
  const items = linkItems;
  const [openAccordion, setOpenAccordion] = useState();

  /**
   * @description - to handle accordion open
   * @param {*} index -index
   * @return {undefined}
   */
  const handleAccordion = (index) => {
    setOpenAccordion(index);
  };

  return (
    <section className={`accordion-section-seo ${accordionMode?.value}`}>
      <div className="container">
        <div className="row">
          <div className={offset ? 'col-lg-10 offset-lg-1' : 'col-lg-12'}>
            <div className="row">
              <div className="col">
                {Array.isArray(items) &&
                  items.map((item, index) => {
                    return (
                      <AccordionItem
                        title={item?.accordionName}
                        linkItems={item}
                        active={index === openAccordion ? true : false}
                        onAccordionClick={handleAccordion}
                        key={index}
                        index={index}
                        cssClass={cssClass}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SEOAccordion.defaultProps = {
  accordionMode: {},
  cssClass: '',
  offset: '',
  linkItems: {
    items: [],
  },
};

SEOAccordion.propTypes = {
  accordionMode: PropTypes.shape({}),
  cssClass: PropTypes.string,
  offset: PropTypes.string,
  linkItems: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
};

export default withTranslation()(SEOAccordion);
