/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';
import { isNullorEmpty, stringToBoolean } from '../../../../utils/helperUtils';

const RadioToggle = (props) => {
  const {
    options,
    name,
    value,
    selectHandler,
    customStyle,
    showLabelText,
    customError,
    inlineLabel,
    readOnly,
    t,
    label,
    notRequired,
    register,
    dataValidations,
    mandatory,
    text,
    id,
    watch,
    formName,
    isHideField,
    showBasedOnFieldName,
    hideBasedOnFieldName,
    validateOnLoad,
    setValue,
    getValues,
    disableIfDataExist,
  } = props;
  const [selected, setSelected] = useState(value);
  const fieldError = get(customError, name);
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;

  const requiredData = getValidationDatabyType(dataValidations, 'required');

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;
  const readOnlyBasedOnValue = (disabled) =>
    disableIfDataExist && !isNullorEmpty(value) ? true : disabled;

  const mandatoryFlag = !isNullorEmpty(mandatory) ? stringToBoolean(mandatory) : true;

  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    param = value ? { ...param, shouldDirty: true } : param;
    setSelected(value);
    setValue(name, value, param);
  }, [value]);

  /**
   *
   */
  const handleSelect = (event, option) => {
    let evn = event || window.event;
    let target = evn.srcElement || evn.target;
    if (evn && target) {
      setSelected(target.value);
      if (selectHandler) {
        selectHandler(evn, option);
      }
    }
  };

  /**
   *
   * @param {*} option
   */
  const unSelectRadio = (event, value) => {
    let evn = event || window.event;
    if (notRequired && selected === value) {
      setSelected('');
      setValue(name, '', param);
      if (selectHandler) {
        selectHandler(evn, '');
      }
    }
  };
  return (
    <div
      className={`form-block formFields ${!showField || hideField ? 'hide' : ''}`}
      style={customStyle}
    >
      {!inlineLabel && showLabelText && (
        <div className="form-check-label labelText">
          {text?.value ? (
            <RichText field={text} editable={false} className="checkBoxLabel" />
          ) : (
            label
          )}
        </div>
      )}
      <div
        className={`form-radio-group checklist ${fieldError ? 'error withoutBg' : ''
          } ${inlineLabel ? 'radioInline' : ''}`}
        role="radiogroup"
      >
        <div className="inlineLabel">
          {inlineLabel && <div className="form-check-label">{label}:</div>}
        </div>
        <ul
          className={`form-radio-group checklist ${fieldError ? 'error withoutBg' : ''
            }`}
          style={props?.toggleStyle}
          role="radiogroup"
        >
          {options.map((option) => {
            const { id, text, value } = option || {};
            const val = value || text;
            return (
              <li className="checklist__item" key={name + id}>
                <div className="form-check radio-toggle">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={name}
                    id={name + id}
                    value={val}
                    checked={getValues && getValues(name) === val}
                    onChange={(event) => handleSelect(event, option)}
                    onClick={(event) => unSelectRadio(event, val)}
                    onKeyUp={(e) => {
                      if (e && e.keyCode === 13) unSelectRadio(e, val);
                    }}
                    disabled={readOnlyBasedOnValue(readOnly)}
                    ref={
                      register
                        ? register({
                          required:
                            showField && requiredData?.fields?.value?.value && mandatoryFlag
                              ? !hideField
                              : false,
                        })
                        : () => { }
                    }
                  />
                  <label className="form-check-label" htmlFor={name + id}>
                    {text}
                  </label>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                '{0}',
                label?.replace('*', '')
              )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
    </div>
  );
};

RadioToggle.defaultProps = {
  options: [],
  value: '',
  selectHandler: () => { },
  setValue: () => { },
  getValues: () => { },
  t: () => { },
  notRequired: false,
  customStyle: {},
  showLabelText: false,
  toggleStyle: {},
  inlineLabel: false,
};

RadioToggle.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ),
  customStyle: PropTypes.shape({}),
  toggleStyle: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  selectHandler: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  t: PropTypes.func,
  notRequired: PropTypes.bool,
  showLabelText: PropTypes.bool,
  inlineLabel: PropTypes.bool,
};

export default RadioToggle;
