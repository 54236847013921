/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  patternRegexCheck,
  validateDNI,
} from '../DynamicForm/helper/validationFactory';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import { checkValueTypeAndGetTheCount } from '../../../business/Utils/helper';
import { isNullorEmpty, stringToBoolean } from '../../../../utils/helperUtils';

/**
 * @description - Dynamic Textarea component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template for dashboard.
 */
const Textarea = ({
  id,
  name,
  label,
  icon,
  customStyle,
  readOnly,
  customError,
  hintText,
  register,
  rows,
  dataValidations,
  mandatory,
  connectorField,
  connectorType,
  getValues,
  watch,
  showBasedOnFieldName,
  hideBasedOnFieldName,
  isHideField,
  setValue,
  value,
  validateOnLoad,
  formName,
  t,
  requiredValidation = [],
  handleChange,
  control,
}) => {
  const fieldError = get(customError, name);
  const patternData = getValidationDatabyType(dataValidations, 'pattern');
  const requiredData =
    requiredValidation?.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  let param = validateOnLoad ? { shouldValidate: true } : {};
  const maximumData = getValidationDatabyType(dataValidations, 'maximum');
  const minimumData = getValidationDatabyType(dataValidations, 'minimum');
  const dniValidator = getValidationDatabyType(dataValidations, 'dni');
  const [showField, setShowField] = useState(true);
  const [hideField, setHideField] = useState(isHideField);
  const showFieldName =
    formName && showBasedOnFieldName
      ? `${formName}[${showBasedOnFieldName}]`
      : showBasedOnFieldName;
  const hideFieldName =
    formName && hideBasedOnFieldName
      ? `${formName}[${hideBasedOnFieldName}]`
      : hideBasedOnFieldName;

  const showFieldValue = showFieldName ? watch(showFieldName) : null;
  const hideFieldValue = hideFieldName ? watch(hideFieldName) : null;
  const mandatoryFlag = !isNullorEmpty(mandatory) ? stringToBoolean(mandatory) : true;

  useEffect(() => {
    if (showFieldValue !== null && typeof showFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(showFieldValue);
      setShowField(isFieldHasVal);
    }
  }, [showFieldValue]);

  useEffect(() => {
    if (hideFieldValue !== null && typeof hideFieldValue !== 'undefined') {
      let isFieldHasVal = checkValueTypeAndGetTheCount(hideFieldValue);
      setHideField(isFieldHasVal);
    }
  }, [hideFieldValue]);

  useEffect(() => {
    param = value ? { ...param, shouldDirty: true } : param;
    setValue(name, value, param);
  }, [value]);

  return (
    <div
      className={`form-block complex ${fieldError ? 'error withoutBg' : ''} ${!showField || hideField ? 'hide' : ''
        }`}
      style={customStyle}
    >
      <div
        className="text-area-outer"
        htmlFor={id}
        onClick={() => {
          document.getElementById(id).focus();
        }}
      >
        <Controller
          name={name}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <textarea
              className={getValues && getValues(name) ? 'filled' : ''}
              id={id}
              value={value || ''}
              rows={rows}
              autoComplete="off"
              readOnly={readOnly}
              aria-required={
                showField && requiredData?.fields?.value?.value ? !hideField : false
              }
              {...(fieldError
                ? { 'aria-invalid': true, 'aria-describedby': `err_${id}` }
                : '')}
              {...((getValues && getValues(name)) || value
                ? {}
                : { 'aria-label': `${label} edit blank` })}
              onBlur={onBlur}
              onChange={(selected) => {
                let selectedVal = selected?.target?.value
                  ? selected?.target?.value
                  : selected;
                onChange(selectedVal);
              }}
            />
          )}
          rules={{
            required:
              showField && requiredData?.fields?.value?.value && mandatoryFlag ? !hideField : false,
            validate: {
              dniValidationCheck: (value) =>
                (dniValidator?.fields?.value?.value === 'true' ||
                  dniValidator?.fields?.value?.value === true
                  ? validateDNI(value)
                  : true) ||
                dniValidator?.fields?.message?.value ||
                t('validation-failed'),
            },
            maxLength: {
              value: maximumData?.fields?.value?.value || null,
              message: maximumData?.fields?.message?.value || t('validation-failed'),
            },
            minLength: {
              value: minimumData?.fields?.value?.value || null,
              message: minimumData?.fields?.message?.value || t('validation-failed'),
            },
            pattern: {
              value: patternData?.fields?.value?.value
                ? patternRegexCheck(patternData.fields.value.value)
                : '',
              message: patternData?.fields?.message?.value || t('validation-failed'),
            },
          }}
        />
        {/* below code has been commented as part of ADO item -> https://dev.azure.com/GISProjects-Sitecore/GIS-Sitecore%20Support-EnhancementsDefects/_workitems/edit/38144 */}
        {/* {label && (
          <label htmlFor={id}>
            <div className="label-text">
              {icon && (
                <div className="input-icon">
                  <span className="input-icon"></span>
                </div>
              )}
              {label}
            </div>
          </label>
        )} */}
      </div>
      {fieldError && (
        <div className="error-msg" id={`err_${id}`} role="alert">
          {fieldError.type === 'required'
            ? requiredData?.fields?.message?.value
              ? requiredData?.fields?.message?.value?.replace(
                '{0}',
                label?.replace('*', '')
              )
              : t('mandatory-field-message').replace('{0}', label?.replace('*', ''))
            : fieldError?.message?.replace('{0}', label?.replace('*', ''))}
        </div>
      )}
      {hintText && <div className="hintText">{hintText}</div>}
    </div>
  );
};

Textarea.defaultProps = {
  id: '',
  name: '',
  label: '',
  placeholder: '',
  icon: '',
  readOnly: false,
  setValue: () => { },
  t: () => { },
  handleChange: () => { },
};

Textarea.propTypes = {
  id: PropTypes.string.isRequired,
  t: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.string,
  setValue: PropTypes.func,
  readOnly: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default Textarea;
