/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './upload-block.scss';
import TextButton from '../../../globals/buttons/TextButton';

/**
 * @description - File upload Component.
 * @param {*} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const FileList = (props) => {
  const { data, removeFile, downloadFile, hasDocType = false } = props;
  return (
    <div className="upload-file-container">
      {data?.map((item, index) => (
        <div className={`file-item ${!hasDocType ? 'no-doctype' : ''}`} key={index}>
          <div className="fileDetailsContainer">
            <div
              className="file-name"
              onClick={() => {
                downloadFile(index);
              }}
              title={item?.name}
            >
              {item?.name}
            </div>
            {hasDocType && (
              <div className="selected-type">
                <span>|</span>
                <span title={item?.type}>{item?.label || item?.type}</span>
              </div>
            )}
          </div>
          <div className="action-icons">
            {!hasDocType && (
              <div
                className="download-icon-blue"
                onClick={() => downloadFile(index)}
              />
            )}
            <div className="close-icon" onClick={() => removeFile(index)} />
          </div>
        </div>
      ))}
    </div>
  );
};

FileList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  hasDocType: PropTypes.bool,
};

FileList.defaultProps = {
  data: [],
  hasDocType: false,
};

export default FileList;
