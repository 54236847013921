import React from 'react';
import PropTypes from 'prop-types';
import TextLink from '../globals/link/TextLink';
import JobsForm from '../PageBanners/BannerJobs/JobsForm';
import {
  withSitecoreContext,
  isExperienceEditorActive,
  mediaApi,
} from '@sitecore-jss/sitecore-jss-react';
import { getImg } from '../../../utils/helperUtils';
/**
 * @description - HomeCareerBlock component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const HomeCareerBlock = (props) => {
  const { fields, sitecoreContext } = props;
  const {
    title,
    text,
    ctaText,
    ctaUrl,
    findJobsCtaText,
    backgroundImage,
    backgroundImage1,
  } = fields;
  const brand_name = sitecoreContext?.Country?.brandName?.toLowerCase() || 'experis';

  const getBackgroundStyle = () => {
    let style = {
      backgroundImage: `url(${mediaApi.updateImageUrl(
        getImg(backgroundImage)
      )}),url(${mediaApi.updateImageUrl(getImg(backgroundImage))})`,
    };
    if (backgroundImage1 && backgroundImage1?.value?.src) {
      style = {
        backgroundImage: `url(${mediaApi.updateImageUrl(
          getImg(backgroundImage1)
        )}), url${mediaApi.updateImageUrl(getImg(backgroundImage))})`,
        position: 'top left, bottom right',
      };
    }
    return style;
  };
  return (
    <section
      className={`home-career-block ${brand_name}`}
      style={getBackgroundStyle()}
    >
      <div className="bg-img1"></div>
      <div className="bg-img2"></div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <h2 className="title">{title?.value}</h2>
              <div className="text">
                <p className="large">{text?.value}</p>
              </div>
              <div className="form">
                {isExperienceEditorActive() ? (
                  <div>Open preview to see the job search form</div>
                ) : (
                  <JobsForm ctaText={findJobsCtaText?.value} />
                )}
              </div>
              <div className="careerpath cta">
                <TextLink
                  cssClass="reversed"
                  ctaText={ctaText}
                  ctaUrl={ctaUrl}
                  arrow
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HomeCareerBlock.defaultProps = {
  title: '',
  text: '',
  namePlaceHolder: '',
  locationPlaceHolder: '',
  findJobsCtaText: '',
  ctaText: '#',
  ctaUrl: '',
  submitUrl: '/',
  backgroundImage: '',
  fields: {
    title: '',
    text: '',
    ctaText: '',
    ctaUrl: '',
    findJobsCtaText: '',
    backgroundImage: '',
    backgroundImage1: '',
  },
  sitecoreContext: {},
};

HomeCareerBlock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  namePlaceHolder: PropTypes.string.isRequired,
  locationPlaceHolder: PropTypes.string.isRequired,
  findJobsCtaText: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  submitUrl: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
    findJobsCtaText: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundImage1: PropTypes.string,
  }),
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(HomeCareerBlock);
