import React from 'react';
import PropTypes from 'prop-types';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import TextLink from '../globals/link/TextLink';
import { showString, isNullorEmpty } from '../../../utils/helperUtils';
import { DATE_FORMATS } from '../../../utils/enums';
import './cards.scss';

/**
 * @description - Card Event.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const CardEvent = ({
  headline,
  startDate,
  ctaText,
  ctaUrl,
  external,
  location,
  locationPin,
  isPartner,
  sitecoreContext,
}) => {
  const dateFormat = sitecoreContext?.Country?.dateFormat || DATE_FORMATS.LOCALE;
  return (
    <div className="card event card--wipe" role="link">
      <span className="card--wipe--apollo" />

      <div className="card-body">
        <div className="date">
          {isPartner && startDate
            ? startDate
            : startDate
            ? moment(startDate).format(dateFormat)
            : ''}
        </div>
        {!isNullorEmpty(location) ? (
          <div className="location">
            <img src={locationPin} alt="location Pin" />
            <div>
              {location?.value ? <Text field={location} /> : showString(location)}
            </div>
          </div>
        ) : null}
        <h3 className="title">
          {headline.value ? <Text field={headline} /> : showString(headline)}
        </h3>
      </div>
      <div className="cta__wrap card-eventfooter">
        <TextLink ctaText={ctaText} ctaUrl={ctaUrl} externalLink={external} arrow />
      </div>
    </div>
  );
};

CardEvent.defaultProps = {
  headline: '',
  startDate: '',
  ctaText: '',
  ctaUrl: '#',
  location: '',
  locationPin: '',
  external: false,
  isPartner: false,
};

CardEvent.propTypes = {
  headline: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  location: PropTypes.string,
  locationPin: PropTypes.string,
  external: PropTypes.bool,
  isPartner: PropTypes.bool,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

export default withSitecoreContext()(CardEvent);
