import {
  PARSE,
  IS_FORM_SUBMITTING,
  PROFILE_INFO_FORM,
} from '../actions/actionTypes';

const initialState = {
  data: {},
  triggeredComponents: [],
  validComponents: [],
  isFormSubmitting: false,
  parse: false,
};
/**
 * @description - Profile info form Reducer function
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */
const profileInfoFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_INFO_FORM.SAVE:
      return {
        ...state,
        data: action.payload,
      };
    case PROFILE_INFO_FORM.SAVE_COUNT_IF_VALID:
      return {
        ...state,
        validComponents: [...new Set([...state.validComponents, action.payload])],
      };
    case PROFILE_INFO_FORM.SAVE_COUNT:
      return {
        ...state,
        triggeredComponents: [
          ...new Set([...state.triggeredComponents, action.payload]),
        ],
      };
    case PROFILE_INFO_FORM.CLEAR_COUNT:
      return {
        ...state,
        triggeredComponents: [],
        validComponents: [],
      };
    case PROFILE_INFO_FORM.CLEAR:
      return initialState;
    case IS_FORM_SUBMITTING.SET:
      return {
        ...state,
        isFormSubmitting: action.payload,
      };
    case PARSE.SET:
      return {
        ...state,
        parse: action.payload,
      };
    default:
      return state;
  }
};
export default profileInfoFormReducer;
