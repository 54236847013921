/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description - AccordionWithRTL Section component.
 * @param {object} props - Input props
 * @returns {Node} - HTML node.
 */
const AccordionSectionWithRTL = ({ onClick, id, title, content, isOpen, focus }) => {
  const focusRef = useRef(null);
  /**
   * @description - to handle accordian click
   * @returns {undefined}
   */
  const handleClick = () => {
    onClick(id);
  };

  /**
   * @description - to handle accordian toggle
   * @param {*} event - click eventി
   * @returns {undefined} - nothing
   */
  const handleToggleClick = (event) => {
    if (event.keyCode === 13) {
      onClick(id);
    }
  };

  useEffect(() => {
    if (focus && focusRef.current) {
      focusRef.current.focus();
    }
  });
  return (
    <div
      className={'accordion-block ' + (isOpen ? 'active' : '')}
      role="listitem"
      id={`accordion${id}`}
    >
      <div
        className="icon"
        onKeyDown={handleToggleClick}
        onClick={handleClick}
        role="button"
        tabIndex={0}
        aria-label={`toggle accordian ${title?.value}`}
        aria-expanded={isOpen}
        ref={focus ? focusRef : null}
        onBlur={() => {
          focusRef.current = null;
        }}
      ></div>
      <Text
        field={title}
        tag="h3"
        className="accordion-title"
        onClick={handleClick}
      />
      <RichText field={content} className="accordion-content clearfix" />
    </div>
  );
};

AccordionSectionWithRTL.defaultProps = {
  isOpen: '',
  id: '',
  title: { value: '' },
  content: { value: '' },
};
AccordionSectionWithRTL.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  content: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
};

export default AccordionSectionWithRTL;
