/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { geolocated, geoPropTypes } from 'react-geolocated';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';

import Map from '../../Map/Map';
import { NearbyFinder } from '../../Map/NearbyFinder';
import LocationsNearbyItem from '../LocationsNearbyItem/LocationsNearbyItem';
import LocationSearch from '../../../core/globals/forms/LocationSearch';

import '../locations.scss';

/**
 * @description - location nearby component
 */

/**
 * @description to show location nearby
 * @param {*} props - component properties including location details
 * @returns {Node} -html
 */
const LocationsNearby = (props) => {
  const {
    isGeolocationEnabled,
    coords,
    t,
    fields,
    sitecoreContext,
    mapInit,
  } = props;
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (coords) {
      setTimeout(() => {
        updateNearbyLocation(props, coords?.latitude, coords?.longitude, true);
      }, 1000);
    }
  }, [coords]);

  /**
   * @description to find nearest location
   * @param {*} props - component properties
   * @param {*} latitude - from loation latitude
   * @param {*} longitude - from location longitude
   * @param {*} highlightNearest - if to show nearest location highlighted
   * @returns {undefined}
   */
  const updateNearbyLocation = (props, latitude, longitude, highlightNearest) => {
    setMarkers('');
    NearbyFinder(props, latitude, longitude, highlightNearest).then(
      (sortedNearbyLocations) => {
        if (
          JSON.stringify(markers) !==
          JSON.stringify(sortedNearbyLocations.slice(0, 2))
        ) {
          setMarkers(sortedNearbyLocations.slice(0, 2));
        }
      }
    );
  };

  /**
   * @description action followed by the place selection
   * @param {*} location - location geometry
   * @returns {undefined} - nothing
   */
  const locationSelected = (location) => {
    location
      ? updateNearbyLocation(props, location.lat, location.lng, true)
      : setMarkers([]);
  };

  /**
   * @description - to render google map
   * @returns {Node} - HTML
   */

  return (
    <section className="near-locations">
      <div className="container">
        <div className="row">
          <div className="col offset-xl-0" role="heading">
            <div className="title"> {t('locations-near-you')}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-5 col-lg-6 offset-xl-0 order-2 order-lg-1">
            {!isGeolocationEnabled ? (
              <div className="search-location">
                <p role="alert">
                  <Text field={fields.alertText} />
                </p>
                <LocationSearch
                  onLocationSelect={locationSelected}
                  label={t('location')}
                  region={sitecoreContext.Country?.code}
                  errorMessage={t('invalid-location')}
                ></LocationSearch>
              </div>
            ) : (
              ''
            )}
            <div className="near-locations-list" role="list">
              {markers && markers.length > 0
                ? markers?.map((item) => (
                    <LocationsNearbyItem
                      key={item?.fields?.title}
                      title={item?.fields?.title}
                      address={item?.fields?.address}
                      hours={item?.fields?.hours}
                      phone={item?.fields?.phone}
                      hoursLabel={t('hours')}
                      phoneLabel={t('phone')}
                      ctaText={t('view-details')}
                      ctaUrl={item?.fields?.ViewDetailsUrl}
                    />
                  ))
                : ''}
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 order-1 order-lg-2 g-map">
            {markers && mapInit && (
              <Map
                markers={markers}
                locationFinder={false}
                onPinClick={() => {}}
                fitBound={true}
                countryCode={sitecoreContext?.Country?.code}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

LocationsNearby.defaultProps = {
  props: {
    fields: {
      title: {
        value: 'Locations near you',
      },
      ctaText: {
        value: 'See all locations',
      },
      ctaUrl: {
        value: {
          href: '#',
        },
      },
      items: [
        {
          fields: {
            title: {
              value: 'Office #1 Chicago - W Monroe',
            },
            address: {
              value: ' 525 West Monroe Street Suite 1500 Chicago, IL 60661',
            },
            hours: {
              value: ' Mon-Fri: 7-3 PM Sat-Sun: Closed',
            },
            phone: {
              value: '(312) 980-4900',
            },
            latitude: {
              value: '11.867351',
            },
            longtitude: {
              value: '77.148290',
            },
            ctaUrl: {
              value: {
                href: '#',
              },
            },
          },
        },
      ],
    },
  },
};

LocationsNearby.propTypes = {
  props: PropTypes.shape({
    fields: PropTypes.shape({
      title: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
      ctaText: PropTypes.shape({
        value: PropTypes.string.isRequired,
      }),
      ctaUrl: PropTypes.shape({
        value: PropTypes.shape({
          href: PropTypes.string.isRequired,
        }),
      }),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          fields: PropTypes.shape({
            title: PropTypes.shape({
              value: PropTypes.string.isRequired,
            }),
            address: PropTypes.shape({
              value: PropTypes.string.isRequired,
            }),
            hours: PropTypes.shape({
              value: PropTypes.string.isRequired,
            }),
            phone: PropTypes.shape({
              value: PropTypes.string.isRequired,
            }),
            latitude: PropTypes.shape({
              value: PropTypes.string.isRequired,
            }),
            longtitude: PropTypes.shape({
              value: PropTypes.string.isRequired,
            }),
            ctaUrl: PropTypes.shape({
              value: PropTypes.shape({
                href: PropTypes.string.isRequired,
              }),
            }),
          }),
        })
      ),
    }),
  }),
};

LocationsNearby.propTypes = { ...LocationsNearby.propTypes, ...geoPropTypes };
/**
 * @description _ map state to props
 * @param {*} state - state.
 * @returns {*} - Action.
 */
const mapStateToProps = (state) => ({
  mapInit: state.mapReducer.data,
});

export default withTranslation()(
  withSitecoreContext()(
    connect(mapStateToProps)(
      geolocated({
        positionOptions: {
          enableHighAccuracy: false,
        },
        userDecisionTimeout: 20000,
      })(LocationsNearby)
    )
  )
);
