import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { getImg } from '../../../utils/helperUtils';
import { mediaApi, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import TextLink from '../globals/link/TextLink';
import VidyardPlayer from '../../business/VidyardPlayer';
import WistiaPlayer from '../../business/WistiaPlayer';
import YoutubePlayer from '../../business/YoutubePlayer';
import VimeoPlayer from '../../business/VimeoPlayer';
import './SuccessStories.scss';

/**
 * @description - Component.
 * @param {Object} props - Props passed.
 * @returns {Node} - Element.
 */
const SuccessStories = (props) => {
  const { fields, sitecoreContext } = props;
  const { title, text, backgroundImage } = fields;
  const class_name = backgroundImage !== '' ? 'bknd' : '';
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || '';
  useEffect(() => {
    /**
     * @description - loads the fancybox library.
     * @returns {*} - Returns nothing.
     */
    async function loadFancy() {
      await require('@fancyapps/fancybox');
      await require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
    }

    $(function () {
      if (!window.jQuery) window.jQuery = $;
      loadFancy();
    });
  }, []);

  return (
    <section
      className={`ss__block ${class_name}`}
      id="successstories"
      style={{
        backgroundImage: `url(${mediaApi.updateImageUrl(getImg(backgroundImage))})`,
      }}
    >
      <div className="container">
        <h2 className="headline">{title?.value}</h2>
        <p className="text">{text?.value}</p>
        <div className="row">
          <div className="player-wrapper"></div>
          {fields?.items?.map((item, index) => (
            <div className="col-md-6 video-cont" key={index}>
              {item?.fields?.source?.value === 'Youtube' && (
                <YoutubePlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title}
                />
              )}
              {item?.fields?.source?.value === 'Wistia' && (
                <WistiaPlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title?.value}
                />
              )}
              {item?.fields?.source?.value === 'Vidyard' && (
                <VidyardPlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title}
                />
              )}
              {item?.fields?.source?.value === 'Vimeo' && (
                <VimeoPlayer
                  playerid={item?.id}
                  videoUrl={item?.fields?.videoCode?.value}
                  videoPreviewImg={item?.fields?.thumbnail}
                  videoDuration={item?.fields?.duration}
                  title={item?.fields?.title}
                />
              )}
            </div>
          ))}
        </div>
        <div className={`cta__wrap ${brandName}`}>
          <TextLink
            ctaText={fields?.ctaText}
            ctaUrl={fields?.ctaUrl}
            cssClass="button-text--large"
          />
        </div>
      </div>
    </section>
  );
};

SuccessStories.defaultProps = {
  fields: {
    title: '',
    items: [],
    text: '',
    backgroundImage: '',
  },
  sitecoreContext: {},
};

SuccessStories.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    text: PropTypes.string,
    backgroundImage: PropTypes.string,
  }),
  sitecoreContext: PropTypes.shape({}),
};

export default withSitecoreContext()(SuccessStories);
