import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CongratsMsg from '../../../core/CongratsMsg';
import './create_account_success.scss';
import ButtonLink from '../../../core/globals/link/ButtonLink';
import TextButton from '../../../core/globals/buttons/TextButton';

/**
 *
 */
const CreateAccountSuccess = ({ name, jobName, t }) => (
  <div className="create-account-success-flex">
    <CongratsMsg
      header={`Congratulations ${name}!`}
      message="Your account has been created."
      info="You can update your account settings at anytime."
    />
    <div className="create-account-success-text">
      <div className="build-profile">Build you profile to apply to</div>
      <div className="create-account-job-name">{jobName}</div>
    </div>
    <div className="create-account-success-button">
      <ButtonLink ctaText={t('build-profile')} ctaUrl="/en/Profile" />
    </div>
    <div className="create-account-success-text-button">
      <TextButton cssClass="dark-gray" text="BROWSE JOBS" />
    </div>
  </div>
);

CreateAccountSuccess.propTypes = {
  name: PropTypes.string,
  t: PropTypes.func.isRequired,
  jobName: PropTypes.string,
};

CreateAccountSuccess.defaultProps = {
  name: 'Mark',
  jobName: 'BI Software Engineer/Data Architect',
};

export default withTranslation()(CreateAccountSuccess);
