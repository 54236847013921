import React from 'react';
import PropTypes from 'prop-types';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

import './hyperlink.scss';

/**
 * @description - sub navigation tile component
 * @param {string} title - title to be displayed
 * @param {string} icon - icon to be displayed
 * @param {string} url - url to be redirected on click
 * @returns {Node} - HTML
 */
const Hyperlink = ({ title, icon, url, blockType, queryParams }) => {
  let fullUrl = queryParams?.value
    ? `${url?.value?.href}?${queryParams?.value}`
    : url?.value?.href;
  fullUrl = fullUrl?.replace('??', '?');
  return blockType?.value ? (
    url?.value?.linktype === 'external' ? (
      <a
        href={url?.value.href}
        className="navbox image--wipe"
        target={url?.value?.target}
        rel={url?.value?.target ? 'noopener noreferrer' : ''}
      >
        <figure role="presentation">
          {icon ? (
            <>
              <div className="imageContainer">
                <Image className="icon" media={icon} />
              </div>
              <figcaption className="caption" role="presentation">
                <Text field={title} editable={false} />
              </figcaption>
            </>
          ) : (
            <figcaption className="caption" role="presentation">
              <Text field={title} editable={false} />
            </figcaption>
          )}
        </figure>
        <span className="wipe--apollo" />
      </a>
    ) : (
      <Link to={fullUrl} className="navbox image--wipe">
        <figure role="presentation">
          {icon ? (
            <>
              <div className="imageContainer">
                <Image className="icon" media={icon} />
              </div>
              <figcaption className="caption" role="presentation">
                <Text field={title} editable={false} />
              </figcaption>
            </>
          ) : (
            <figcaption className="caption" role="presentation">
              <Text field={title} editable={false} />
            </figcaption>
          )}
        </figure>
        <span className="wipe--apollo" />
      </Link>
    )
  ) : (
    <div className="col-sm-6 col-md-3 col-xl-3">
      <div className="seo-jobs__category-item">
        {url?.value?.linktype === 'external' ? (
          <a
            target={url?.value?.target}
            rel={url?.value?.target ? 'noopener noreferrer' : ''}
            href={url?.value.href}
          >
            {title?.value}
          </a>
        ) : (
          <Link to={url?.value?.href}>{title?.value}</Link>
        )}
      </div>
    </div>
  );
};
Hyperlink.defaultProps = {
  title: '',
  icon: '',
  url: '',
  queryParams: '',
  blockType: '',
};

Hyperlink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  queryParams: PropTypes.string.isRequired,
  blockType: PropTypes.string.isRequired,
};

export default Hyperlink;
