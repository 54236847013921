import React from 'react';
import PropTypes from 'prop-types';

import './boundaries.scss';

/**
 * @description - to show inline alert
 * @param {string} message - message to show
 * @param {string} type - type of alert
 * @returns {node} - html
 */
const AlertInline = ({ message, type }) => (
  <div className={`alert-inline ${type}`} aria-live="assertive" role="alert">
    <p>{message}</p>
  </div>
);

AlertInline.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['error', 'success']),
};

AlertInline.defaultProps = {
  message: '',
  type: '',
};

export default AlertInline;
