/* eslint-disable */
import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import CardGrid from '../Cards/CardGrid';
import { geolocated, geoPropTypes } from 'react-geolocated';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import TextLink from './../globals/link/TextLink';
import { usePostData } from '../../../hooks/http-client';
import { SEARCH } from '../../../constants';
import Loader from '../ErrorBoundary/Loader';
import { isNullorEmpty } from '../../../utils/helperUtils';
import './cardblock.scss';
/**
 * @description-  CardBlockJob grid component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */

/**
 * @description to show job card grid
 * @param {object} props - card grid properties
 * @returns {node} -html
 */

const CustomCardBlock = (props) => {
  const { response, loading, write } = usePostData();
  const { coords, sitecoreContext, t, fields, params } = props;
  const { jobCards = [], title, url, seeAllJobs, items = [] } = fields;
  const [searchPath, setSearchpath] = useState('');

  /**
   * @description - call to card scroll api
   * @param {object} queryParams - request body
   * @returns {undefined}
   */

  useEffect(() => {
    // url and props.params.pageType === 'Job', company url navigate else default job search
    const path =
      !isNullorEmpty(response?.data?.url) && props.params.pageType === 'Job'
        ? response?.data?.url
        : sitecoreContext.Country?.jobSearchURL;
    setSearchpath({
      pathname: path,
      search: localStorage.getItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_PARAMS),
    });
  }, [response]);

  return (
    <Fragment>
      {loading && <Loader />}
      {jobCards?.length > 0 && (
        <section className="card-grid ">
          <div className="container">
            <div className="card-grid__header">
              <h2 className="title">{title}</h2>
              <div className="cta">
                <TextLink
                  cssClass=""
                  ctaText={t('see-all')}
                  ctaUrl={
                    seeAllJobs ? seeAllJobs : sitecoreContext.Country?.jobSearchURL
                  }
                />
              </div>
            </div>
            <div className="cards__wrap">
              <div className="row">
                {jobCards?.map((job) => {
                  return (
                    <div className="col-lg-6">
                      <CardGrid
                        title={job?.jobTitle}
                        location={job?.jobLocation}
                        subTitle={job?.employmentType}
                        img={job.image}
                        date={job?.publishfromDate}
                        ctaUrl={job?.jobURL}
                        isSaved={job?.isSaved}
                        icons={items}
                        jobItemId={job?.jobItemId}
                        isExpired={job?.isExpired}
                        jobDetails={job}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

CustomCardBlock.defaultProps = {
  coords: {},
  sitecoreContext: {},
  params: {},
  backgroundColor: '',
  t: () => {},
  fields: {},
};

CustomCardBlock.propTypes = {
  coords: PropTypes.shape({}),
  sitecoreContext: PropTypes.shape({}),
  params: PropTypes.shape({}),
  backgroundColor: PropTypes.string,
  t: PropTypes.func,
  ...geoPropTypes,
  fields: PropTypes.shape({}),
};

export default geolocated()(
  withTranslation()(withSitecoreContext()(CustomCardBlock))
);
