/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import './congratsMsg.scss';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

/**
 *
 */
const CongratsMsg = ({ header, message, info, icon }) => (
  <div className="container greet-container">
    <div className="congrats-section">
      <h1 className="text">
        {header}
        {icon && <div className="success-icon" role="img" aria-label="success" />}
      </h1>
      <div className="text">{message}</div>
      <div className="info">{info}</div>
    </div>
  </div>
);

CongratsMsg.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  icon: PropTypes.bool,
};

CongratsMsg.defaultProps = {
  icon: false,
};

export default CongratsMsg;
