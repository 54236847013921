/*eslint-disable*/
import React, { useEffect, useState, useRef } from 'react';
import Select from '../globals/forms/Select';
import Button from '../globals/buttons/Button';
import PropTypes from 'prop-types';
import TextButton from '../globals/buttons/TextButton';
import './jobfilters.scss';

import { withTranslation } from 'react-i18next';
const JobFilters = (props) => {
  const {
    optionsList,
    title,
    getSelectedFilterValues,
    getArticleFilters,
    cancelFilters,
    headerClickData,
  } = props;
  const { t } = props;
  const [audience, setAudience] = useState('');
  const [cntType, setCntType] = useState('');
  const [clientTopic, setClientTopic] = useState('');
  const [jobSeekerTopic, setJobSeekerTopic] = useState('');
  const [dropdownOptionList, setDropdownOptionList] = useState([]);
  const [selectedVals, setSelectedVals] = useState([]);
  const [valueArray, setValueArray] = useState([]);
  const [audienceOption, setAudienceOption] = useState([]);
  const [contentTypesOption, setContentTypesOption] = useState([]);
  const [clientTopicsOption, setClientTopicsOption] = useState([]);
  const [jobSeekerTopicsOption, setJobSeekerTopicsOption] = useState([]);
  let ref = useRef({ optionsList }).current;
  let audienceOptionList = [];
  let cntTypesOptionList = [];
  let clientTopicsOptionList = [];
  let jobSeekerTopicsOptionList = [];
  const cancelFilter = () => {
    cancelFilters();
    getArticleFilters();
    setSelectedVals('');
    setSelectedVals([]);
  };
  const updateArticle = () => {
    let vals = [];
    vals = selectedVals.filter(
      (el) => el?.title !== '' && el?.title !== undefined && el?.title !== null
    );
    getSelectedFilterValues(selectedVals);
    cancelFilters();
    getArticleFilters();
    setSelectedVals('');
    setSelectedVals([]);
  };

  useEffect(() => {
    let formattedOptionsList = [];
    if (optionsList?.length > 0) {
      ref = { optionsList };
      optionsList.map((option) => {
        let tempOptionList = [];
        if (option && option?.length > 0) {
          for (let i = 0; i < option?.length; i++) {
            tempOptionList.push({
              name: option[i]?.name,
              title: option[i]?.val.value,
              key: option[i]?.val.key,
              value: option[i]?.val.key,
              label: option[i]?.label,
            });
          }
        }
        tempOptionList.length > 0 ? formattedOptionsList.push(tempOptionList) : '';
      });
      setDropdownOptionList(formattedOptionsList);
    }

    if (formattedOptionsList?.length > 0) {
      formattedOptionsList.map((options) => {
        if (options && options?.length > 0) {
          for (let i = 0; i < options?.length; i++) {
            {
              if (options[i].name === 'audience') {
                audienceOptionList.push({
                  name: options[i]?.name,
                  title: options[i]?.title,
                  key: options[i]?.key,
                  value: options[i]?.key,
                });
              }
              setAudienceOption(audienceOptionList);
              if (options[i].name === 'contentTypes') {
                cntTypesOptionList.push({
                  name: options[i]?.name,
                  title: options[i]?.title,
                  key: options[i]?.key,
                  value: options[i]?.key,
                });
              }
              setContentTypesOption(cntTypesOptionList);
              if (options[i].name === 'clientTopics') {
                clientTopicsOptionList.push({
                  name: options[i]?.name,
                  title: options[i]?.title,
                  key: options[i]?.key,
                  value: options[i]?.key,
                });
              }
              setClientTopicsOption(clientTopicsOptionList);
              if (options[i].name === 'jobSeekerTopics') {
                jobSeekerTopicsOptionList.push({
                  name: options[i]?.name,
                  title: options[i]?.title,
                  key: options[i]?.key,
                  value: options[i]?.key,
                });
              }
              setJobSeekerTopicsOption(jobSeekerTopicsOptionList);
            }
          }
        }
      });
    }
  }, [optionsList]);
  useEffect(() => {
    if (headerClickData?.length > 0) {
      let result = [];
      let result1 = [];
      let topic_result1 = [];
      let newheaderArray = [];
      newheaderArray = headerClickData[1]?.contentTypes?.values;
      for (let j = 0; j < headerClickData[0]?.audience?.length; ) {
        if (
          headerClickData[0]?.audience[j]?.value == '' ||
          headerClickData[0]?.audience[j]?.value == undefined
        ) {
          for (let i = 0; i < headerClickData[1]?.audience?.values?.length; i++) {
            if (
              headerClickData[1]?.audience?.values[i]?.key ===
              headerClickData[0]?.audience[j]?.key
            ) {
              result.push({
                name: 'audience',
                title: headerClickData[1]?.audience?.values[i]?.value,
                key: headerClickData[1]?.audience?.values[i]?.key,
                value: headerClickData[1]?.audience?.values[i]?.key,
                label: headerClickData[1]?.audience?.values[i]?.value,
              });
            }
          }
        } else {
          result.push({
            name: 'audience',
            title: headerClickData[0]?.audience[j]?.value,
            key: headerClickData[0]?.audience[j]?.key,
            value: headerClickData[0]?.audience[j]?.key,
            label: headerClickData[0]?.audience[j]?.value,
          });
        }
        j++;
      }
      setAudience(result);

      if (headerClickData[0]?.contentTypes?.length > 0) {
        for (let j = 0; j < headerClickData[0]?.contentTypes?.length; ) {
          if (headerClickData[0]?.contentTypes[j]?.value == '') {
            for (
              let i = 0;
              i < headerClickData[1]?.contentTypes?.values?.length;
              i++
            ) {
              if (
                headerClickData[1]?.contentTypes?.values[i]?.key ===
                headerClickData[0]?.contentTypes[j]?.key
              ) {
                result1.push({
                  name: 'contentTypes',
                  title: headerClickData[1]?.contentTypes?.values[i]?.value,
                  key: headerClickData[1]?.contentTypes?.values[i]?.key,
                  value: headerClickData[1]?.contentTypes?.values[i]?.key,
                  label: headerClickData[1]?.contentTypes?.values[i]?.value,
                });
              }
            }
          } else {
            result1.push({
              name: 'contentTypes',
              title: headerClickData[0]?.contentTypes[j]?.value,
              key: headerClickData[0]?.contentTypes[j]?.key,
              value: headerClickData[0]?.contentTypes[j]?.key,
              label: headerClickData[0]?.contentTypes[j]?.value,
            });
          }
          j++;
        }
        result = result.concat(result1);
      }

      if (headerClickData[0]?.topics?.length > 0) {
        for (let l = 0; l < headerClickData[0]?.topics?.length; ) {
          if (headerClickData[0]?.topics[l]?.value == '') {
            for (let k = 0; k < headerClickData[1]?.topics?.length; ) {
              for (
                let topval = 0;
                topval < headerClickData[1]?.topics[k].values.length;

              ) {
                if (
                  headerClickData[1]?.topics[k]?.values[topval]?.key ===
                  headerClickData[0]?.topics[l]?.key
                ) {
                  topic_result1.push({
                    name: 'topics',
                    title: headerClickData[1]?.topics[k]?.values[topval]?.value,
                    key: headerClickData[1]?.topics[k]?.values[topval]?.key,
                    value: headerClickData[1]?.topics[k]?.values[topval]?.key,
                    label: headerClickData[1]?.topics[k]?.values[topval]?.value,
                  });
                }
                topval++;
              }
              k++;
            }
          } else {
            topic_result1.push({
              name: 'topics',
              title: headerClickData[0]?.topics[l]?.value,
              key: headerClickData[0]?.topics[l]?.key,
              value: headerClickData[0]?.topics[l]?.key,
              label: headerClickData[0]?.topics[l]?.value,
            });
          }
          l++;
        }
        result = result.concat(topic_result1);
      }
      props.setFiltervals(result, headerClickData[2]);
    }
  }, [headerClickData]);

  let newArray = [];
  let newArray1 = [];
  const getFilterItems = (value) => {
    newArray1.push(value);
    if (value?.name === 'audience' && selectedVals.length === 1) {
      setAudience(newArray1);
      props.selectedOption(newArray1);
    }
    if (value?.name === 'contentTypes') {
      setCntType(value);
      newArray1 = audience.concat(newArray1);
    }
    if (value?.name === 'clientTopics') {
      setClientTopic(value);
    }
    if (value?.name === 'jobseekerTopics') {
      setJobSeekerTopic(value);
    }
  };

  const handleSelect = (e, value, position) => {
    newArray[position] = value;

    selectedVals[position] = value;
    getFilterItems(value);
    setSelectedVals((selectedVals) => [...selectedVals]);
  };

  return (
    <div className="job-filters">
      <div className="title">{title}</div>
      {dropdownOptionList?.map((option, index) => (
        <div className="dropdown" key={option[0]?.label}>
          <Select
            id={`jobFilter_${option[0]?.label}`}
            placeholder={`${option[0]?.label}`}
            showPlaceholder
            options={option}
            defaultValue={''}
            noneOption={`-- ${t('none')} --`}
            selectHandler={(e, val) => handleSelect(e, val, index)}
            selectedValue={selectedVals[index]?.key || '""'}
          />
        </div>
      ))}

      <div className="cta">
        <TextButton
          cssClass="dark-gray"
          className="cancel_btn"
          text={t('cancel')}
          handleTextClick={() => cancelFilter()}
        />
        <Button
          className="update_btn"
          text={t('update')}
          handleButtonClick={() => updateArticle()}
        />
      </div>
    </div>
  );
};

JobFilters.propTypes = {
  getSelectedFilterValues: PropTypes.func,
  getArticleFilters: PropTypes.func,
};

JobFilters.defaultProps = {
  getSelectedFilterValues: () => {},
};

export default withTranslation()(JobFilters);
