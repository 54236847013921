import { PROFILE_PIC } from '../actions/actionTypes';

const initialState = {
  data: '',
};
/**
 * @description - profile pic Reducer function
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */
const profilePicReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_PIC.GET:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
export default profilePicReducer;
