/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import Button from '../../../core/globals/buttons/Button';
import Modal from '../../../core/Modal';
import DynamicForm from '../../../core/Forms/DynamicForm';
import { ROMA_INTEGRATION, API_STATUS_CODES } from '../../../../constants';
import { usePostData } from '../../../../hooks/http-client';
import SnackBar from '../../../core/ErrorBoundary/SnackBar';
import { useSnackbar } from '../../../../hooks/useSnackbar';

import './forgotPassword.scss';

/**
 * @description - Forgot password component.
 * @param {object} props - Input props.
 * @returns {Node} - HTML to render.
 */
const ForgetPassword = ({ fields, t }) => {
  const { items = [], Title, Abstract } = fields;
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { isActive, message, openSnackBar } = useSnackbar();
  const { register, handleSubmit, errors, getValues, setValue, control } = useForm({
    mode: 'onClick',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });
  const { write, error, loading } = usePostData();
  const forgotpswdRef = useRef();

  useEffect(() => {
    if (error) {
      openSnackBar(t('http-error'));
    }
  }, [error]);

  useEffect(() => {
    if (showModal) forgotpswdRef.current.focus();
  }, [showModal]);

  /**
   * @description to show file remove alert dialog
   * @returns {undefined}
   */
  const handleModal = () => {
    setShowModal(false);
  };

  /**
   * @description - Method to Close success modal.
   * @returns {undefined}
   */
  const handleSuccessModal = () => {
    setShowSuccess(false);
  };

  /**
   * @description - On Submit Handler.
   * @param {object} values - Form values.
   * @returns {undefined}
   */
  const onSubmit = (values = {}) => {
    write(
      `${ROMA_INTEGRATION.ROMA_AUTHENTICATE}`,
      {
        Request: {
          PositionID: null,
          Source: null,
        },
        Application: {
          UserName: values.forgotPwd[0].emailAddress,
          email: values.forgotPwd[0].emailAddress,
          LegalNoticeSignedDate: '',
          MarketingConsentSignedDate: '',
          Action: 'resetpassword',
        },
      },
      handleResponse
    );
  };

  /**
   * @description - Handle success/failure response.
   * @param {object} response - Resposne object.
   * @returns {undefined}
   */
  const handleResponse = (response) => {
    if (response.data.Status === API_STATUS_CODES.SUCCESS) {
      handleModal();
      setShowSuccess(true);
    } else if (response.data.Status === API_STATUS_CODES.NOT_FOUND) {
      openSnackBar(t('roma-wrong-username'));
    } else {
      openSnackBar(t('roma-error'));
    }
  };

  return (
    <Fragment>
      <div className="pwd-reset-section">
        <div
          className="forget-pwd-link"
          onClick={() => setShowModal(true)}
          role="button"
          aria-label="Open Forget password modal"
          tabIndex={0}
          onKeyUp={(e) => {
            if (e && e.keyCode === 13) {
              setShowModal(true);
            }
          }}
        >
          {t('roma-forgot-password')}
        </div>
      </div>
      <Modal
        showModal={showModal}
        handleModal={handleModal}
        size="lg"
        removeIndex={true}
      >
        <div
          key="header"
          className="pwd-modal-header header-text"
          tabIndex="-1"
          ref={forgotpswdRef}
        >
          <Text field={Title} />
        </div>
        <div className="pwd-modal-container" key="body">
          <div className="sub-title">
            <Text field={Abstract} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="reset-form">
            <DynamicForm
              formData={items?.length > 0 ? items : []}
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              formName="forgotPwd"
              customIndex={0}
              control={control}
            />
            <Button
              text={t('roma-reset-password')}
              handleButtonClick={handleSubmit(onSubmit)}
              type="submit"
              cssClass="reset-btn"
              isLoading={loading}
            />
            <Button
              text={t('roma-cancel')}
              cssClass="blue"
              handleButtonClick={handleModal}
            />
          </form>
          <SnackBar isActive={isActive} message={message} />
        </div>
      </Modal>

      <Modal showModal={showSuccess} handleModal={handleSuccessModal} size="lg">
        <div className="pwd-modal-success-body" key="body">
          <div className="header-text reset-text">
            {t('roma-reset-password-confirmation')}
          </div>
          <Button
            text={t('ok')}
            cssClass="ok-btn"
            handleButtonClick={handleSuccessModal}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

ForgetPassword.propTypes = {
  fields: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    Title: PropTypes.shape({
      value: PropTypes.string,
    }),
    Abstract: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  t: PropTypes.func.isRequired,
};

ForgetPassword.defaultProps = {
  fields: {},
};

export default withTranslation()(ForgetPassword);
