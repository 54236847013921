import React from 'react';
import PropTypes from 'prop-types';
import ButtonLink from '../globals/link/ButtonLink';
import { Text, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import { checkDataExists } from '../../../utils/helperUtils';
import './cards.scss';
/**
 *
 * @param {*} param0 - site params
 * @returns {*} - data
 */
const CardSubpageDiamond = ({ title, text, img, altText, ctaText, ctaUrl }) => {
  const externalLinkType = ctaUrl?.value?.linktype === 'internal' ? false : true;
  const imgVal = mediaApi.updateImageUrl(img.value.src);
  return (
    <div className="flex-wrapper">
      <div className="diamond-wrapper">
        <div className="diamond">
          <img className="image" src={imgVal} alt={altText} />
        </div>
        <div className="card-body-diamond subpage-subcard ">
          <p className="large title">
            <Text field={title} />
          </p>
          <p className="large">
            <Text field={text} />
          </p>
          {checkDataExists(ctaUrl) && checkDataExists(ctaText) && (
            <ButtonLink
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              ctaLabel={`${title} ${ctaText}`}
              cssClass="orange-border"
              externalLink={externalLinkType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CardSubpageDiamond.defaultProps = {
  title: '',
  text: '',
  img: 'http://via.placeholder.com/500x500',
  altText: '',
  ctaText: 'Learn more',
  ctaUrl: '#',
};

CardSubpageDiamond.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  altText: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
};

export default CardSubpageDiamond;
