import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbLink from '../BreadcrumbLink';
import './BreadcrumbNavigation.scss';
/**
 * @description - BreadcrumbNavigation component.
 * @param {Object} fields - Input props.
 * @param {Object} brandName - Input string.
 * @returns {Node} - HTML template for BreadcrumbNavigation.
 */
const BreadcrumbNavigation = ({ fields }) => {
  const data = Array.isArray(fields?.BreadCrumbs)
    ? fields.BreadCrumbs
    : fields?.BreadCrumbs?.value;
  return (
    <>
      {data && data.length > 0 ? (
        <div
          role="navigation"
          aria-label="Breadcrumb"
          className="seo_breadcrumbcontainer"
        >
          <ul className="seo_breadcrumb">
            {data.map((item, index) => (
              <BreadcrumbLink
                key={item.id || `breadcrumb_${index}`}
                text={item.name}
                url={item.path}
                isLastItem={data.length - 1 === index ? true : false}
                isJobbIncludes={item.IsJobInclude}
              />
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
};

BreadcrumbNavigation.defaultProps = {
  fields: {
    BreadCrumbs: [
      {
        name: '',
        path: '',
      },
    ],
  },
};
BreadcrumbNavigation.propTypes = {
  fields: PropTypes.shape({
    BreadCrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        path: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default BreadcrumbNavigation;
