import { SCREENER_QUESTIONS } from '../actions/actionTypes';

const initialState = {
  data: undefined,
};
/**
 * @description - Screener ouestion Reducer
 * @param {object} state - state object
 * @param {object} action - reducer action object
 * @returns {object} - new sate
 */
const screenerQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCREENER_QUESTIONS.SAVE:
      return {
        ...state,
        data: action.payload,
      };
    case SCREENER_QUESTIONS.CLEAR:
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
};
export default screenerQuestionsReducer;
