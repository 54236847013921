/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { geolocated, geoPropTypes } from 'react-geolocated';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  isDataExists,
  isObjNotEmpty,
  isNullorEmpty,
} from '../../../../../utils/helperUtils';
import TextLink from '../../../../core/globals/link/TextLink';
import Table from '../../../../core/Table';
import { DASHBOARD, SEARCH } from '../../../../../constants';
import { usePostData } from '../../../../../hooks/http-client';

const queryParams = {
  JobFilters: {
    searchkeyword: '',
    latitude: null,
    longitude: null,
    company: '',
  },
  PageType: '',
  PageItemId: '',
  UserLatitude: null,
  UserLongitude: null,
  HasLocation: false,
};

/**
 * @description - DashboardSuggestedJobs component.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Node.
 */
const DashboardSuggestedJobs = (props) => {
  const { coords, t, sitecoreContext, fields } = props;
  const { title, icon } = fields;
  const { write, error } = usePostData();

  const [data, setData] = useState([]);
  const [searchPath, setSearchpath] = useState('');
  const [queryItems, setQueryItems] = useState(null);

  /**
   * @description - call to card scroll api
   * @param {object} queryParams - request body
   * @returns {undefined}
   */
  const getSuggestedJobs = (queryParams) => {
    write(`${DASHBOARD.SUGGESTED_JOBS.JOBS_API}`, queryParams, prepareData);
  };

  const columns = [
    {
      selector: 'icon',
      grow: 0,
      cell: (row) => (
        <div
          style={{
            backgroundImage: `url(${row?.icon})`,
            displayName: '',
          }}
          className="ibm-logo-container"
        />
      ),
    },
    {
      selector: 'title',
      grow: 3,
      cell: (row) => {
        return <div className="job-title">{row.title}</div>;
      },
    },
    {
      selector: 'view',
      right: true,
      cell: (row) => (
        <div className="view_btn">
          <TextLink
            ctaText={t('view')}
            cssClass="primary"
            ctaUrl={`${row.view}`}
            style={{
              displayName: '',
            }}
          />
        </div>
      ),
    },
  ];

  /**
   * @description prepare data for suggested jobs
   * @param {object} response - api response
   * @returns {undefined} - no return
   */
  const prepareData = (response) => {
    const jobs = [];
    const jobCards = response?.data?.jobCards
      ? isDataExists(response.data.jobCards)
        ? response.data.jobCards
        : []
      : [];
    jobCards.map((job, index) => {
      jobs[index] = {
        icon: job.companyLogo,
        title: job?.jobTitle,
        view: job?.jobURL,
      };
    });
    setData(jobs);
  };

  useEffect(() => {
    const jobSearchFilters = JSON.parse(
      localStorage.getItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_KEY)
    );
    queryParams.JobFilters.searchkeyword = jobSearchFilters?.searchKeyword;
    queryParams.JobFilters.latitude = jobSearchFilters?.filters?.latitude;
    queryParams.JobFilters.longitude = jobSearchFilters?.filters?.longitude;

    if (coords) {
      queryParams.UserLatitude = coords.latitude;
      queryParams.UserLongitude = coords.longitude;
      queryParams.HasLocation = true;
    }
    queryParams.PageType = DASHBOARD.SUGGESTED_JOBS.PAGE_TYPE;
    queryParams.PageItemId = '';
    if (jobSearchFilters?.filters?.company)
      queryParams.JobFilters.company = jobSearchFilters?.filters?.company;
    else delete queryParams.JobFilters.company;
    setQueryItems(queryParams);
    if (
      coords ||
      (jobSearchFilters &&
        (!isNullorEmpty(jobSearchFilters?.searchKeyword) ||
          !isObjNotEmpty(jobSearchFilters?.filters)))
    ) {
      getSuggestedJobs(queryParams);
    }
  }, [coords]);

  useEffect(() => {
    const searchParams = localStorage.getItem(SEARCH.JOB_SEARCH.SAVED_SEARCH_PARAMS);
    setSearchpath(`${sitecoreContext?.Country?.jobSearchURL}${searchParams}`);
  }, []);

  return (
    <>
      {data.length > 0 && (
        <div className="dashboard-block normal suggested-jobs">
          <Table
            columns={columns}
            row={data}
            title={title}
            icon={icon}
            headerClasses="header-class"
            noTableHead={true}
            error={error}
            handleRefresh={() => getSuggestedJobs(queryItems)}
          />

          <div className="view-more-container">
            <TextLink
              cssClass="secondary blue"
              ctaText={t('view-all')}
              ctaUrl={searchPath}
              arrow
            />
          </div>
        </div>
      )}
    </>
  );
};

DashboardSuggestedJobs.defaultProps = {
  t: () => {},
  sitecoreContext: {},
  coords: {},
  fields: {
    title: { value: 'SUGGESTED JOBS' },
    icon: { value: '' },
  },
};

DashboardSuggestedJobs.propTypes = {
  t: PropTypes.func,
  sitecoreContext: PropTypes.shape({}),
  coords: PropTypes.shape({}),
  fields: PropTypes.shape({}).isRequired,
  ...geoPropTypes,
};

export default geolocated()(
  withTranslation()(withSitecoreContext()(DashboardSuggestedJobs))
);
