/* eslint-disable */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { useDispatch, connect, useSelector } from 'react-redux';
import { SEARCH, API_STATUS_CODES, DASHBOARD } from '../../../constants';
import { addSavedJob } from '../../../redux/actions/actions';
import AuthWrapper from '../../core/LoginMSAL/AuthWrapper';
import { usePostData } from '../../../hooks/http-client';
import { authstates } from '../../../utils/enums';
import AlertInline from '../../core/ErrorBoundary/AlertInline';

import './actionbarjob.scss';

/**
 * @description - to show save icon
 * @param {object} props - job action bar properties
 * @returns {node} html
 */
const ActionBarJobSave = (props) => {
  const { sitecoreContext, t, icons, jobItemID, isSaved, jobDetails } = props;

  let { iconSave, iconSaved } = {};

  const [error, setError] = useState('');
  const [saved, setSaved] = useState();
  const [loading, setLoading] = useState(false);
  const { write } = usePostData();
  const dispatch = useDispatch();
  const loginReducer = useSelector((state) => state.loginReducer);
  const loginState = loginReducer?.state;
  useEffect(() => {
    setSaved(isSaved);
  }, [isSaved]);
  const country_name = sitecoreContext?.Country?.name;

  /**
   * @description to set social share icons
   * @returns {undefined} -  no returns
   */
  const setIcons = () => {
    if (Array.isArray(icons)) {
      icons.forEach((iconItem) => {
        switch (iconItem?.name) {
          case 'Save':
            iconSave = iconItem?.fields?.icon?.value?.src;
            break;
          case 'Saved':
            iconSaved = iconItem?.fields?.icon?.value?.src;
            break;
          default:
            break;
        }
      });
    }
  };

  setIcons();

  /**
   * @description - to save job
   * @param {function} login - to login
   * @param {string} authenticationState - current auth state
   * @returns {undefined} -
   */
  const handleSave = async (login, authenticationState) => {
    setLoading(true);
    setError('');
    authenticationState === authstates.AUTHENTICATED
      ? await axios
          .get(`${SEARCH.JOB_SEARCH.SAVE_JOB_API}`, {
            params: { jobItemID: jobItemID },
          })
          .then((result) => {
            setLoading(false);
            if (result.data.status === API_STATUS_CODES.SUCCESS) {
              setSaved(true);
              dispatch(addSavedJob(jobItemID));
            } else if (result.data.status === API_STATUS_CODES.DATA_EXIST) {
              dispatch(addSavedJob(jobItemID));
            }
          })
          .catch(() => {
            setSaved(false);
            setLoading(false);
            setError(t('error-save'));
          })
      : login();
  };

  const handleRemove = (jobItemID) => {
    write(`${DASHBOARD.SAVED_JOBS.DELETE}?jobID=${jobItemID}`, null);
    setSaved(false);
  };

  return (
    <>
      <div className={'job-actionbar-save ' + country_name}>
        {iconSave && iconSaved && (
          <AuthWrapper>
            {({ login, authenticationState }) => {
              return (
                <>
                  {loginReducer.initialized &&
                    loginReducer.state === authstates.AUTHENTICATED && (
                      <button
                        className="save"
                        onClick={(e) => {
                          if (window?.dataLayer) {
                            window.dataLayer.push({
                              event: 'saveVacancy',
                              products: {
                                name: jobDetails?.jobTitle,
                                id: jobDetails?.jobID,
                                brand: jobDetails?.addtionalForDataLayer?.companyName
                                  ? jobDetails?.addtionalForDataLayer?.companyName
                                  : jobDetails?.companyName
                                  ? jobDetails?.companyName
                                  : null,
                                category: jobDetails?.domain,
                                variant: jobDetails?.employmentType,
                                dimension11: `Location: ${
                                  jobDetails?.jobLocation
                                } | Education: ${
                                  jobDetails?.addtionalForDataLayer?.education
                                    ? jobDetails?.addtionalForDataLayer?.education.join(
                                        ', '
                                      )
                                    : jobDetails?.education
                                    ? jobDetails?.education.join(', ')
                                    : undefined
                                } | Salary: ${jobDetails?.salaryRate} | Hours: ${
                                  jobDetails?.jobType
                                }`,
                                dimension12: `${
                                  authstates?.[loginState || 'UNAUTHENTICATED']
                                }`,
                                dimension13: jobDetails?.addtionalForDataLayer
                                  ?.applicationType
                                  ? jobDetails?.addtionalForDataLayer
                                      ?.applicationType
                                  : jobDetails?.applicationType
                                  ? jobDetails?.applicationType
                                  : undefined,
                                dimension14: undefined,
                                dimension15: undefined,
                                dimension16: jobDetails?.clientID
                                  ? jobDetails?.clientID
                                  : undefined,
                                dimension17: jobDetails?.jobLocation
                                  ? jobDetails?.jobLocation
                                  : undefined,
                                dimension18: jobDetails?.addtionalForDataLayer
                                  ?.education
                                  ? jobDetails?.addtionalForDataLayer?.education.join(
                                      ', '
                                    )
                                  : jobDetails?.education
                                  ? jobDetails?.education.join(', ')
                                  : undefined,
                                dimension19: jobDetails?.salaryRate
                                  ? jobDetails?.salaryRate
                                  : undefined,
                                dimension20: jobDetails?.jobType
                                  ? jobDetails?.jobType
                                  : undefined,
                              },
                            });
                          }
                          e.preventDefault();
                          !saved && handleSave(login, authenticationState);
                          saved && handleRemove(jobItemID);
                        }}
                      >
                        <div className="icon-bookmark">
                          {!loading ? (
                            <>
                              {!saved && (
                                <img
                                  src={mediaApi.updateImageUrl(iconSave)}
                                  className="save_icon"
                                  alt=""
                                />
                              )}
                              {saved && !error && (
                                <img
                                  src={mediaApi.updateImageUrl(iconSaved)}
                                  alt=""
                                />
                              )}
                            </>
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </div>
                        {saved && !error ? t('saved') : t('')}
                      </button>
                    )}
                </>
              );
            }}
          </AuthWrapper>
        )}
      </div>
      <div className="action-bar-jobSave-messages">
        {error && <AlertInline message={error} type="error" />}
      </div>
    </>
  );
};

ActionBarJobSave.defaultProps = {
  sitecoreContext: {},
  t: () => {},
  icons: [],
  jobItemID: '',
  isSaved: false,
};

ActionBarJobSave.propTypes = {
  sitecoreContext: PropTypes.shape({}),
  t: PropTypes.func,
  icons: PropTypes.arrayOf([]),
  jobItemID: PropTypes.string,
  isSaved: PropTypes.bool,
};

export default withTranslation()(withSitecoreContext()(ActionBarJobSave));
